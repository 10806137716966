<template>
  <div class="container">
    <header class="header">
      <div class="header_left_section">
        <div
          :class="[
            { open: isSidebarVisible },
            'humburger',
            isCrmUser() ? 'humburgerCRM' : '',
          ]"
          v-if="showHamburger"
          @click="toggleSidebar"
        >
          <div class="icon-left"></div>
          <div class="icon-right"></div>
        </div>
        <div>
          <div
            class="backBtn"
            @click="backButtonRoute()"
            v-if="isNotificationPage"
          >
            <img
              src="../../pages/leadManagement/components/assets/backIcon.svg"
            />
            <span>Back</span>
          </div>
          <router-link
            v-if="!isNotificationPage"
            :class="['logo', isCrmUser() ? 'logoCRM' : '']"
            :to="{ name: 'home' }"
          >
            <img
              :src="logoBasedOnDomain"
              alt="Panelicon"
              :class="isCrmUser() ? 'logoForCRM' : 'imgLogo'"
            />
            <span
              class="app-version-text"
              :class="{ 'app-version-text-show': VITE_APP_ENVIRONMENT == 'dev' }"
              >{{ APP_VERSION }}</span
            >
          </router-link>
        </div>
      </div>
      <div class="header_right_section">
        <router-link
          :to="{ name: 'credits' }"
          class="credit_button_wrapper"
          v-if="isCreditsVisible"
        >
          <div class="credit_button_text">
            <div>Credit Balance:</div>
            <div class="credit_balance" v-loading="isFetchingCreditBalance">{{ formatNumber(totalCredits, 'decimal', countryCode )  }}</div>
          </div>
          <div class="arrow_icon">
            <img
              src="../../assets/img/Group 2019.svg"
              alt="view tranaction history"
            />
          </div>
        </router-link>
        <component
          :is="isMobileView ? 'router-link' : 'div'"
          class="notification-bell"
          :class="{ inactive: !isNotificationsLive }"
          :to="{ name: 'notifications' }"
          v-if="isNotificationsAvailable"
        >
        <img
            src="../../assets/img/bell-Icon-task.svg"
            alt="bell-icon"
            @click.self="handleClick"
          />
          <img
            src="../../assets/img/bell-notification.svg"
            alt="unRead"
            class="unread-notification"
            @click.self="handleClick"
            v-show="unreadNotifications"
          />
        </component>
        <div
          class="sub_logo"
          @click="uploadLogo()"
          v-loading="isUpdatingImage"
        >
          <router-link
            v-if="organisation_logo"
            :to="{ name: 'organisationSummary' }"
            event
            class="logo-link"
          >
            <img
              v-if="organisation_logo"
              :src="resolveImageUrl(organisation_logo)"
              alt="Solar"
            />
          </router-link>
          <div class="upload-image" v-else>
            <p style="width: max-content;">
              Upload Logo
            </p>
          </div>
        </div>
      </div>
    </header>
    <NotificationPopUp
      @showDrawer="showDrawer"
      @openNotification="handleOpenDrawer"
      :isModal="false"
      :notificationsList="notificationsList"
      :isVisible="isNotificationOpen"
      :isNextPage="computeIsNextPage"
      @closeNotification="closeNotification"
      @loadMoreNotifications="loadMoreNotifications"
      @handleReadAll="handleReadAll"
      @handleUnreadToggle="handleUnreadToggle"
      :notificationFetchError="notificationFetchError"
      :disableScroll="disableScroll"
      :loaderErrorMessage="loaderErrorMessage"
      @switchTabs="onSwitchTabs"
    />
    <leadsDrawer
      v-if="isDrawerOpen"
      :drawer.sync="isDrawerOpen"
      :lead="selectedLead"
      :activeTab="'first'"
      :focusNote="false"
      @close="[(isDrawerOpen = false), (selectedLead = null)]"
    />
    <GlobalDrawer
      :title="'View Task'"
      :isOpen="isTaskDrawerOpen"
      :drawer-size="750"
      :handleCloseDialog="handleTaskDrawerClose"
      class="task-drawer-dialog"
    >
      <template v-if="isTaskDrawerOpen" v-slot:body>
        <taskDrawer
          v-if="isTaskDrawerOpen"
          :reminderData.sync="reminderDetails"
          @updateTask="updateTask"
          @deleteTask="onDeleteTask"
          :mode="'view'"
          :taskData="selectedLead"
          @allowDocumentUpload="handleAllowDocUpload"
          :documentUploaded="documentUploaded"
          @documentUploading="documentUploaded = false"
        />
      </template>
    </GlobalDrawer>
    <setReminder
      :key="setKey"
      :showCreateDrawer="showSetReminderDrawer"
      @close="showSetReminderDrawer = false"
      @save="onSave"
      :drawerSize="400"
      :page="'task'"
    />

    <GlobalDrawer
          :isOpen="isDesignOrderDrawerOpen&&selectedLead?true:false"
          :title="''"
          :handleCloseDialog="handleCloseDesignOrderDrawer"
        >
          <template v-slot:body>
            
            <DesignOrdersDrawer
              :propsData="{
                order: selectedLead,
                users: selectedLead?.users||[],
                orderDetailsPopVisible: true,
                orderStatusOptions: [],
                handleOrderUpdate: handleOrderUpdate,
                drawer: isDesignOrderDrawerOpen,
              }"
              @save="drawer = false"
              :drawerSize="700"
              @close="drawer = false"
          
            />
          </template>
        </GlobalDrawer>
    <input
    type="file"
    :style="{
      color: !isAdmin ? '#999' : '#222',
      cursor: !isAdmin
        ? 'not-allowed'
        : 'pointer',
        display:'none',
    }"
    :disabled="!isAdmin"
    accept=".jpeg,.jpg,.png,"
    @change="uploadFile($event)"
    ref="fileInput"
    name="image"
    id="fileInput"
    />
  </div>
</template>

<script>
import {
  isCrmUser,
  isExpertServicesOrg,
  fetchOrganisationDetails,
  fetchUserDetails,
  formatNumberWithCommas,
  initializeNotifications,
  getUserFromLocalStorage,
  getOrganisationFromLocalStorage,
  isAdmin,
  blobToBase64,
  fetchOrganisationId,
} from "../../utils";
import debounce from "debounce";
import { mapActions, mapState } from "pinia";
import { useCreditsStore } from "../../stores/credits";
import { useMiscStore } from "../../stores/misc";
import { useUiStore } from "../../stores/ui";
import { APP_VERSION } from "../../constants";
import solarLabsLogo from "../../assets/drop/70cdd171-53f.png";
import { useRootStore } from "../../stores/root";
import API from "../../services/api";
import NotificationPopUp from "../../pages/notificationPopUp/NotificationPopUp.vue";
import leadsDrawer from "../../pages/leadManagement/components/leadsDrawer.vue";
import taskDrawer from "../../pages/taskManagement/components/taskDrawerDynamic.vue";
import GlobalDrawer from "../../pages/commonComponents/allDrawer/globalDrawer.vue";
import { VITE_APP_ENVIRONMENT } from "../../constants";
import { getLogoBasedOnDomain } from "@/constants.js";
import setReminder from "../../pages/setReminder/setReminder.vue";
import { formatNumber } from "../../utils/numberFormat/currencyFormatter.js"
import DesignOrdersDrawer from "../../pages/designOrders/components/designOrdersDrawer.vue";
import { resolveImageUrl } from "../../pages/webProposal/utils/utils.js";

export default {
  components: {
    NotificationPopUp,
    leadsDrawer,
    taskDrawer,
    GlobalDrawer,
    setReminder,
    DesignOrdersDrawer
  },
  data() {
    return {
      documentUploaded: false,
      allowUpload: false,
      debounceChecker: false,
      reminderDetails: {},
      setKey: 0,
      showSetReminderDrawer: false,
      loaderErrorMessage: '',
      disableScroll: true,
      notificationFetchError: false,
      logoBasedOnDomain: getLogoBasedOnDomain,
      userId: null,
      isUnreadOnly: false,
      homeOwnerScroll: {},
      homeownerNames: [],
      homeownerSources: "",
      homeownerSeperator: "",
      isNotificationPage: false,
      isDrawerOpen: false,
      isTaskDrawerOpen: false,
      selectedLead: null,
      solarLabsLogo: solarLabsLogo,
      isSidebarOpen: false,
      organisation_logo: getOrganisationFromLocalStorage().logo,
      APP_VERSION,
      isNotificationOpen: false,
      notificationsList: [],
      collaboratorsNextURL: null,
      directNextURL: null,
      VITE_APP_ENVIRONMENT,
      countryCode: getOrganisationFromLocalStorage().country_code || "US",
      isDesignOrderDrawerOpen:false,
      isAdmin: isAdmin(),
      organisationSettingsLogoURL: "",
      isUpdatingImage: false,
    };
  },
  computed: {
    ...mapState(useCreditsStore, {
      credits: "GET_CREDIT_BALANCE",
      isFetchingCreditBalance: (state) => state.isFetchingCreditBalance
    }),
    ...mapState(useUiStore, {
      isMobileView: state => state.isMobileView
    }),
    ...mapState(useMiscStore, {
      unreadNotifications: (state) =>
        state.notificationState.unreadNotifications,
      newNotificationAlert: (state) => state.notificationState.tempMessage,
      isNotificationsLive: (state) => state.notificationState.isLive,
      notificationsManager: (state) => state.notificationsManager
    }),
    computeIsNextPage() {
      return {
        direct: !this.directNextURL,
        collab: !this.collaboratorsNextURL,
      };
    },
    totalCredits() {
      let total =
        this.credits.purchased_credits + this.credits.promotional_credits;
      return formatNumberWithCommas(total);
    },
    isSidebarVisible() {
      return useRootStore().sidebarStatus;
    },
    flagForUS() {
      return getUserFromLocalStorage().isUSFlagEnabled;
    },
    isCreditsVisible() {
      return !isCrmUser()
    },
    isNotificationsAvailable() {
      return isCrmUser()||isExpertServicesOrg()
    },
    showHamburger(){
      if(this.isNotificationPage || ['orderCreation','consumptionProfileEnergy'].includes(this.$route.name)){
        return false
      }
      else
      return true;
    }
  },
  methods: {
    ...mapActions(useMiscStore, ["SET_NOTIFICATION_STATE"]),
    ...mapActions(useUiStore, ["SET_ACTIVE_DESIGN_SECTION","SET_ACTIVE_LEAD_SUMMARY_TAB","SET_CLOSE_CHAT_BOX"]),

    onSwitchTabs(){
      this.loaderErrorMessage = ''
    },
    handleAllowDocUpload(){
      this.allowUpload = true;
    },
    handleOnClick() {
      this.selectedLead.isTask = true;
      this.reminderDetails = this.selectedLead.reminder_details;
      //open task drawer and pass the task value
    },
    async onSave(reminderDetails) {
      this.reminderDetails = {
        reminder_sent_at: reminderDetails.reminder_sent_at,
        notes: reminderDetails.notes,
      };
      if (this.selectedLead.id) {
        this.setReminder(this.selectedLead.id);
      }
      this.showSetReminderDrawer = false;
    },
    onSetReminder() {
      this.setKey++;
      this.showSetReminderDrawer = true;
    },
    async setReminder(id) {
      let response = await API.TASKS.POST_SETREMINDER_TASK(id, reminderDetails);
      this.loadingStateButton = false;
      this.$message({
        showClose: true,
        message: "Reminder has been set successfully.",
        type: "success",
        center: true,
      });
    },
    isCrmUser,
    toggleSidebar() {
      useRootStore().toggleSidebar();
    },
    ...mapActions(useMiscStore, ["SET_NOTIFICATION_STATE"]),
    async handleReadAll() {
      try {
        await API.NOTIFICATIONS.MARK_ALL_READ();
        if (this.notificationsList) {
          this.notificationsList = this.notificationsList.map((e) =>
            e.map((innerElement) => {
              return { ...innerElement, is_read: true };
            })
          );
        }
        this.SET_NOTIFICATION_STATE(false);
      } catch (err) {
        console.error(err);
      }
    },
    handleUnreadToggle(bool) {
      this.isUnreadOnly = bool;
      this.loaderErrorMessage = ''
    },
    checkNotificationType(notification) {
      let info = {};
      if (notification.content_obj_owner_id === this.userId) {
        info.index = 0;
      } else {
        info.index = 1;
      }
      if (this.isNotificationOpen)
        this.notificationsList[info.index].unshift({
          notification_details: notification,
          is_read: false,
        });
    },
    async loadMoreNotifications(type) {
      if(this.debounceChecker){
                this.debounceChecker = !this.debounceChecker
                return
            }
            setTimeout(() => {
                this.debounceChecker = !this.debounceChecker
            }, 500)
      this.loaderErrorMessage = ''
      try {
        if (type === "collab" && this.collaboratorsNextURL) {
          let newPage = await API.TASKS.LOAD_MORE_LEADS(
            this.collaboratorsNextURL
          );
          this.notificationsList = this.notificationsList.map((e, index) => {
            if (index === 1) {
              return [...e, ...newPage.data.results];
            }
            return e;
          });
          this.collaboratorsNextURL = newPage.data.next;
        } else if (type === "direct" && this.directNextURL) {
          let newPage = await API.TASKS.LOAD_MORE_LEADS(this.directNextURL);
          this.notificationsList = this.notificationsList.map((e, index) => {
            if (index === 0) {
              return [...e, ...newPage.data.results];
            }
            return e;
          });
          this.directNextURL = newPage.data.next;
        }
      } catch (err) {
          this.loaderErrorMessage = 'There was an error while fetching more notifications.'
        console.error(err)
      }
    },
    handleTaskDrawerClose() {
      this.isTaskDrawerOpen = false;
      this.selectedLead = null;
    },
    backButtonRoute() {
      this.$router.goBackOrGoHome();
    },
    showDrawer(type) {
      if (type === "lead") {
        this.isDrawerOpen = !!type;
      } else if(type === "task") {
        this.isTaskDrawerOpen = !!type;
      } else if(type === "order") {
        this.isDesignOrderDrawerOpen = !!type;
      }
    },
    async getMoreLeadsData(nextURL) {
      let { data } = await API.TASKS.LOAD_MORE_LEADS(nextURL);
      return { ...data };
    },
    handleOpenDrawer(value) {
      if(value?.type=="redirectdesign"){
        this.SET_ACTIVE_DESIGN_SECTION('designSummary');
        this.SET_ACTIVE_LEAD_SUMMARY_TAB('design');
        this.SET_CLOSE_CHAT_BOX(false);
        this.$router.push({ name: 'leadSummary:design', params:{leadId:value?.lead_id,designId:value?.design_id} }); 
        // let myParams = `/leadSummary/${value?.lead_id}/design/${value?.design_id}`;
        // window.location.href = window.location.origin + myParams;
      }
      this.isNotificationOpen = false;
      this.selectedLead = value;
    
    },
    async handleClick() {
      if(this.isMobileView) return
      this.SET_NOTIFICATION_STATE(false)
      this.isNotificationOpen = true;
      let queries = {
        // notification_for: 'all',
        type: "direct",
      };
      if (this.isUnreadOnly) queries.unread_only = true;
      try {
        let tempArr = [];
        let resp = await API.NOTIFICATIONS.GET_ALL_NOTIFICATIONS(queries);
        let results = resp.data.results;
        tempArr.push(results);
        if (resp.data.next) {
          let secondPage = await API.TASKS.LOAD_MORE_LEADS(resp.data.next);
          tempArr[0] = [...tempArr[0], ...secondPage.data.results];
          this.directNextURL = secondPage.data.next;
        }
        queries.type = "collaboration";
        resp = await API.NOTIFICATIONS.GET_ALL_NOTIFICATIONS(queries);
        tempArr.push(resp.data.results);
        if (resp.data.next) {
          let secondPage = await API.TASKS.LOAD_MORE_LEADS(resp.data.next);
          tempArr[1] = [...tempArr[1], ...secondPage.data.results];
          this.collaboratorsNextURL = secondPage.data.next;
        }
        this.notificationsList = tempArr;
        this.notificationFetchError = false;
        this.disableScroll = false;
      } catch (err) {
        console.error(err);
        this.notificationFetchError = true;
      }
    },
    closeNotification() {
      this.isNotificationOpen = false;
      this.notificationsList = [];
      this.nextCollaboratorUrl = null;
      this.notificationFetchError = false;
      this.disableScroll = true
      this.loaderErrorMessage = ''
      //this.SET_NOTIFICATION_STATE(false)
      //this.handleReadAll()
    },
    async getOrgLogo() {
      let organisationData = getOrganisationFromLocalStorage()
      let img = new Image();
      try {
        // If existing logo url works, use that
        img.src = organisationData.logo;
        let prom = new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
        await prom;
        this.organisation_logo = organisationData.logo;
      } catch {
        // If not, fetch new url
        organisationData = await fetchOrganisationDetails();
        this.organisation_logo = organisationData.logo;
      }
    },
    async getOrgLogoForUS() {
      let userData = getUserFromLocalStorage();
      let organisationData = getOrganisationFromLocalStorage()
      let img = new Image();
      try {
        // If existing logo url works, use that
        img.src = userData.logo;
        let prom = new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
        await fetchUserDetails();
        await prom;
        this.organisation_logo = organisationData.logo;
      } catch {
        // If not, fetch new url
        userData = await fetchUserDetails();
        this.organisation_logo = userData.logo;
        if (!userData.logo) {
          this.getOrgLogo();
        }
      }
    },
    async updateTaskData() {
      try {
        if(this.allowUpload){
          this.documentUploaded = true;
          this.allowUpload = false
        }
      } catch (err) {
        console.error(err)
        this.$message({
              showClose: true,
              message: 'Unable to update task',
              type: "error",
              center: true,
            });
      }
    },
    onDeleteTask(taskId) {
      this.isTaskDrawerOpen = false
    },
    formatNumber,
    handleCloseDesignOrderDrawer(){
      this.isDesignOrderDrawerOpen = false
    },
    handleOrderUpdate(){

    },
    navigateToOrgSummary() {
      window.dispatchEvent(new CustomEvent("click-navbar-logo"));
      this.$router.push({ name: 'organisationSummary' })
    },
    uploadLogo(){
      if(this.organisation_logo)
        this.navigateToOrgSummary()
      else{
        if (!this.isAdmin) return;
        // Programmatically click the hidden file input
        const fileInput  = document.getElementById("fileInput")
        fileInput.click();
  
        // Create and dispatch a custom event to trigger the @change event
        // handles the case when we delete and reupload the same logo (non-reactive)
        const event = new Event("change", { bubbles: true, cancelable: true });
        fileInput.dispatchEvent(event);
      }

    },
    async uploadFile(event) {

      let file = event.target.files[0];
      if (!file) return;

      const isLt256KB = file.size / 1024 < 10000;
      const vm = this;
      // taking the base64 string if image size is less than 256KB
      if (!isLt256KB) {
        this.$message.error("Image size can not exceed 256KB!");
      } else {
        vm.organisationSettingsLogoURL = await blobToBase64(file)
        vm.resizeImage(vm.organisationSettingsLogoURL, file.type);
      }

      this.isUpdatingImage = true
      let organisationId = fetchOrganisationId()
      const response = await API.ORGANISATION.PATCH_ORGANISATION_SETTINGS(
        organisationId,
        {
          logo: vm.organisationSettingsLogoURL,
        }
      );
      localStorage.setItem("organisation", JSON.stringify(response.data));
      this.dispatchLogoChangeEvent()
      this.isUpdatingImage = false
    }, 
    dispatchLogoChangeEvent() {
      window.dispatchEvent(
        new CustomEvent("user-logo-changed", {
          detail: {
            storage: JSON.parse(localStorage.getItem("organisation")).logo,
          },
        })
      );
    },
    resizeImage: function (logoUrl, fileType) {
      let maxDim = 1500;
      const vm = this;
      let image = document.createElement("img");
      image.onload = () => {
        if (image.height > maxDim || image.width > maxDim) {
          let newW, newH;
          if (image.height > image.width) {
            newH = maxDim;
            newW = image.width * (newH / image.height);
          } else {
            newW = maxDim;
            newH = image.height * (newW / image.width);
          }
          this.$message({
            showClose: true,
            message: "The uploaded image has been resized to 1500px",
            type: "warning",
            center: true,
          });
          let canvas = document.createElement("canvas");
          canvas.width = newW;
          canvas.height = newH;
          const context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
          let new_image_url = context.canvas.toDataURL(fileType, 90);
          vm.organisationSettingsLogoURL  = new_image_url;
        }
      };
      image.src = logoUrl;
    },
    resolveImageUrl
  },
  async mounted() {
    this.userId = getUserFromLocalStorage().user_id;
    this.isNotificationPage = this.$route.name === "notifications";
    // recieving event if the user has changed the logo in organisation summary and updating
    // here as Local Storage is not reactive
    window.addEventListener("user-logo-changed", (event) => {
      this.organisation_logo = event.detail.storage;
    });
    if (this.flagForUS) this.getOrgLogoForUS();
    else this.getOrgLogo();
  },
  async created() {
    // Initialize Notifications
    if (this.isNotificationsAvailable && !this.notificationsManager) {
      API.NOTIFICATIONS.GET_NEW_NOTIFICATION_STATUS().then(resp => {
        this.SET_NOTIFICATION_STATE(resp.data);
      })
      initializeNotifications()
    }

    // Other things
    this.updateTask = debounce(this.updateTaskData, 300);   
  },
  watch: {
    newNotificationAlert(value) {
      this.checkNotificationType({ ...value });
    },
    isUnreadOnly: {
      deep: true,
      handler(value) {
        this.handleClick();
      },
    },
  },
};
</script>
<style type="text/css" scoped>
.container{
  position: sticky;
  top: 0;
  z-index: 500;
}
.backBtn {
  font-size: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 11px;
}
.app-version-text {
  display: none;
  opacity: 0;
}

.app-version-text-show {
  display: unset;
  opacity: unset;
}

.header_left_section {
  display: flex;
  align-items: center;
}

.header_right_section {
  display: flex;
  align-items: center;
  column-gap: 2rem;
  height: 100%;
}

.credit_button_wrapper {
  max-height: 100%;
  padding: 0.6rem;
  border-radius: 4px;
  background-color: #e8edf2;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  cursor: pointer;
}

.credit_button_text {
  font-size: 14px;
  color: #1c3366;
  display: flex;
  row-gap: 0.4rem;
  flex-direction: column;
}
.credit_button_text  >>> .el-loading-spinner{
  top: 0px !important;
}

.credit_button_text .credit_balance {
  font-size: 16px;
  font-weight: 600;
}

.credit_button_wrapper .arrow_icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.header {
  display: flex;
  padding: 0 32px 0 24px;
  height: var(--top-navbar-height);
  border-bottom: 1px solid var(--step-100);
  background: var(--white);
  position: sticky;
  top: 0;
  z-index: 432;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .header {
    padding: 0 24px;
    justify-content: space-between;
  }
  .header_right_section {
    column-gap: 1rem;
  }
  .credit_button_wrapper {
    border-radius: initial;
  }
}

.header .humburger {
  margin-right: 12px;
}
@media (min-width: 1281px) {
  .header .humburger {
    display: none;
  }
  .header .humburgerCRM {
    display: block;
  }
}

.credit_button_wrapper {
  display: inline-flex;
  align-items: center;
}

.upload-image{
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px 16px;
  color: #777;
  cursor: pointer;
}

.sub_logo {
  width: 120px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-link, .sub_logo img {
  max-height: 70px;

}

.header a.logo {
  padding: 0 16px 0 0;
  display: flex;
  min-height: 100px;
  align-items: center;
  height: 100%;
}

.header a.logoCRM {
  border-right: none !important;
}
@media (min-width: 1281px) {
  .header a.logo {
    /* border-right: 1px solid var(--step-100); */
    min-width: 236px;
  }
}
@media (max-width: 1280px) {
  .header a.logo {
    min-height: 56px;
  }
}
.imgLogo {
  max-width: 200px;
  max-height: 65px;
  margin: auto;
}
.logoForCRM {
  max-width: 160px !important;
  max-height: 65px;
  margin: auto auto auto 16px;
}
@media (max-width: 1280px) {
  .imgLogo {
    max-width: 200px;
    max-height: 65px;
    margin-left: 0px;
  }
  .logoForCRM {
    max-width: 160px !important;
    max-height: 65px;
    margin: 0px;
  }
}
@media (max-width: 767px) {
  .header a.logo {
    display: none;
  }
}
.humburger {
  position: relative;
  width: 22px;
  height: 22px;
}
.humburger .icon-left {
  transition-duration: 0.5s;
  position: absolute;
  height: 3px;
  width: 10px;
  top: 10px;
  background-color: var(--step-200);
}
.humburger .icon-left:before {
  transition-duration: 0.5s;
  position: absolute;
  width: 10px;
  height: 3px;
  background-color: var(--step-200);
  content: "";
  top: -8px;
}
.humburger .icon-left:after {
  transition-duration: 0.5s;
  position: absolute;
  width: 10px;
  height: 3px;
  background-color: var(--step-200);
  content: "";
  top: 8px;
}
.humburger .icon-left:hover {
  cursor: pointer;
}
.humburger .icon-right {
  transition-duration: 0.5s;
  position: absolute;
  height: 3px;
  width: 10px;
  top: 10px;
  background-color: var(--step-200);
  left: 10px;
}
.humburger .icon-right:before {
  transition-duration: 0.5s;
  position: absolute;
  width: 10px;
  height: 3px;
  background-color: var(--step-200);
  content: "";
  top: -8px;
}
.humburger .icon-right:after {
  transition-duration: 0.5s;
  position: absolute;
  width: 10px;
  height: 3px;
  background-color: var(--step-200);
  content: "";
  top: 8px;
}
.humburger.open .icon-left {
  transition-duration: 0.5s;
  background: transparent;
}
.humburger.open .icon-left:before {
  transform: rotateZ(45deg) scaleX(1.2) translate(2px, 2px);
}
.humburger.open .icon-left:after {
  transform: rotateZ(-45deg) scaleX(1.2) translate(2px, -2px);
}
.humburger.open .icon-right {
  transition-duration: 0.5s;
  background: transparent;
}
.humburger.open .icon-right:before {
  transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 2px);
}
.humburger.open .icon-right:after {
  transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -2px);
}
.humburger:hover {
  cursor: pointer;
}

@media (max-width: 1280px) {
  .header .humburger {
    display: block !important;
  }

  .logo {
    border: none !important;
  }
}
.unread-notification {
  position: relative;
  top: -15px;
  left: -7px;
}
.notification-bell {
  cursor: pointer;
  transition: 0.3s;
}

.notification-bell.inactive {
  opacity: 0.3;
}
</style>
