
<div class="card" id="chat">
  <div class="card_header" @click="toggleCollapse">
    <div class="heading-container">
      <el-row>
        <el-col :span="12" class="left-content">
          <h4 v-if="isESOrg()">
            {{
              orderData.owner_name && orderData.owner_name !== "NA"
                ? orderData.owner_name
                : `Installer`
            }}
          </h4>
          <h4 v-else>
            {{ `Expert Services` }}
          </h4>
        </el-col>
        <el-col :span="12" class="right-content">
          <h4>
            <i
              :class="
                collapsed == false
                  ? 'fas fa-angle-down'
                  : 'fas fa-angle-up'
              "
            ></i>
          </h4>
        </el-col>
      </el-row>

      <div class="hover_information">
        <i class="fas fa-info-circle"></i>
        <div class="tooltip">
          <p>You can talk to the designer here related to this project.</p>
        </div>
      </div>
    </div>
    <img
      src="../../../../assets/img/arrows-angle-expand.png"
      class="expand"
    />
  </div>
  <div class="chatBoxContainer" v-show="!collapsed" v-loading="chatloader"   > 
    <div
      class="card_content"
      id="messageContainer"
      v-if="allChatMessages && allChatMessages?.length != 0"
      @scroll="handleScroll"
     
    >
      <div
        class="contentShow"
        id="messageContainer-content"
        v-for="(data, index) in allChatMessages"
        :key="index"
      >
        <div
          class="userMsgCont"
          v-if="!currentUser(data.user_id) && data.message_type === 'message'"
        >
          <!-- <p class="userName">{{ data.user_name }}</p> -->
          <div class="userMsgDiv">
            <div
              v-if="
                data.documents?.length === 0 && data.message_type === 'message'
              "
              class="userMsg"
            >
              <div v-html="formattedString(data.message)"></div>

          </div>

            <div
              v-if="
                data.documents?.length > 0 && data.message_type === 'message'
              "
              @click="isDropdown = true"
              class="userMsg"
              id="userMsgID"
            >
              <div
                v-for="document in data.documents"
                :key="document.id"
                class="documentFeedMessage"
                id="userMsgID"
              >
              <span><i class="el-icon-document"></i></span>  
             <p class="file-displayname">{{ document.display_name }}</p>   
                <span
                  class="download-container"
                  @click="downloadDocument(document)"
                >
                  <img src="./assests/Download.svg" class="allDwnldIcon"
                /></span>
            
          </div>
            

              <div class="userMsg">
                <div v-html="formattedString(data.message)"></div>
              </div>
            </div>
            <p class="userTime">
              {{ convertUTCInLocalDate(data.timestamp) }}
            </p>
          </div>
        </div>

        <div class="ourMsgCont" v-else-if="data.message_type!='event'" >
         
          <div
            v-if="
              data.documents?.length === 0 && data.message_type === 'message'
            "
            class="ourMsg"
          >
            <div v-html="formattedString(data.message)"></div>
        </div>
          <div
            v-if="
              data.documents?.length > 0 && data.message_type === 'message'
            "
            class="dropdownMenu"
          >
            <div
              @click="isDropdown = document.id"
              v-for="document in data.documents"
              :key="document.id"
              class="documentFeedMessage"
              id="userMsgID"
            >
           <span><i class="el-icon-document"></i></span>   
          <p class="file-displayname"> {{ document.display_name }} </p>   
              <span
                class="download-container"
                @click="downloadDocument(document)"
              >
                <img src="./assests/Download.svg" class="allDwnldIcon"
              /></span>
            
        </div>

            <div class="ourMsg">
              <div v-html="formattedString(data.message)"></div>


            </div>

          </div>
          <p class="ourTime"  >{{ convertUTCInLocalDate(data.timestamp) }}</p>

        </div>
        <div class="eventContainer">

          <div class="eventMessages" v-if="data.message_type === 'event'">
            <div v-html="formattedString(data.message)"></div>
            <el-row>    <div class="ourEventTime">{{ convertUTCInLocalDate(data.timestamp) }}</div></el-row>
        

          </div>
        </div>
      </div>
    </div>
    <div class="noChat" v-else>
      <img src="../../../../assets/drop/chat-dots.png" class="chatIcon" />
      <p class="noChatContent">
        You can discuss doubt or share additional information regarding this
        project with the designer here.
      </p>
    </div>
    <div class="msgContainer">
      <img src="../../../../assets/img/Group 1804.png" class="emoji" />
      <img src="../../../../assets/img/Group 1805.png" class="filesIcon" />
      <div class="footer-container">
        <div class="msgInput">
         
          <el-row
            style="
              margin-left: 35px;
              background-color: #e8edf2;
              margin-right: 35px;
            "
          >
            <!-- First row of footer-content -->
            <el-col :span="12" v-for="(file, index) in fileList" :key="index">
              <div class="file-preview">
                <span class="previewContainer">
                  <i class="el-icon-document"></i>
                  <span
                    ><p class="filename">{{ file.name }}</p></span
                  >
                  <span class="removeFile" @click="removeFile(index)"
                    >&times;</span
                  >
                </span>
              </div>
            </el-col>
          </el-row>

          <div class="footer-content">
            <el-upload
              class="upload-demo"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :onChange="handleOnChange"
              :before-upload="handleBeforeUpload"
              action=""
              :before-remove="handleBeforeRemove"
              :on-success="handleSuccess"
              :on-progress="handleOnProgress"
              :submit="handleOnSubmitFinal"
              :on-error="handleOnError"
              :http-request="handleHttpReq"
              :on-exceed="handleOnExceed"
              :file-list="fileList"
              multiple
              :show-file-list="false"
              thumbnail-mode
              ref="uploader"
            >
              <img
                v-if="$props.orderData?.project"
                src="./assests/upload.svg"
                class="chatIcon"
                @click="handleFileUpload"
              />
            </el-upload>

            <!-- <input class="chat-message-input" type="text" v-model="messageVmodal" v-on:keyup.enter="chatSend" /> -->
          
                          <el-input
                          class="chat-message-input"
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 4}"
            placeholder=""
            v-model="messageVmodal"
            @keydown.native="handleEnter"
            >
          </el-input>

            <el-button type="primary" class="sendBtn" @click="chatSend">
              ➤
            </el-button>
          </div>
        </div>
      </div>
      <div id="recMsgFromWS"></div>
    </div>
  </div>
</div>
