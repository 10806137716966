
  <div ref="blockRef" class="block" :class="isDrawer ? '' : 'lead'">   
  <div class="timeline-container" :class="[isDrawer ? 'timeline-container-drawer' : '']" v-loading="isLoadingActivities">
  <el-timeline :reverse="reverse">
    <el-timeline-item
      v-for="(activity, index) in activityComputed"
      :key="index"
      :style="[index===activityComputed.length-1 ? {'marginTop': '20px'} : {}  ]"
      :hide-timestamp="true"
      :icon="getActivityIcon(activity)"
      :type="type"
      :timestamp="activity.timestamp">
      <div class="container">
        <div class="wholeItem" :class="window.width<minWidth ? 'wholeItemMedia': ''">
            <div class="dateBlock" :class="window.width<minWidth ? 'dateBlockMedia': ''">
              <span>{{getDateAndTime(activity.activitylog_created).date}}</span>
            </div>
            <div class="activityContent" :class="window.width<minWidth ? 'activityContentMedia': ''"  @click="onClickActivity(activity)">
                <p class="activityName">
                    {{ activity.name }}
                </p>
            </div>
            <div class="timeDiv" :class="window.width<minWidth ? 'timeDivMedia': ''">
                <p class="timeClass">
                    {{ getDateAndTime(activity.activitylog_created).time }}
                </p>
            </div>
        </div>
        <p class="activityDesc" :class="window.width<minWidth?'activityDescMedia':''"  @click="onClickActivity(activity)">
          {{ getTruncatedDescription(activity) }}
          <span v-if="showSeeMore(activity)" class="see-more">...See More</span>
        </p>
        <div class="docs"   v-for="(doc, index) in activity.documents" :key="index" v-show="(doc.document_container !=='solution_contracts' || !organisation?.vip_for_power_gazebo) && activity.documents.length" :class="window.width<minWidth?'docsMedia':''">
            <p class="docText">Documents:</p>
            <div class="allDocs">
              <div class="specificDoc"  v-loading="doc.id == fileIdToBeDeleted && isDeleteInProgress">
                <p class="docName">{{getTruncatedFileName(doc.display_name, 14)}}</p>
                <div class="actionIcons">
                  <img src="./assets/Download.svg" class="downloadIcon" @click="downloadClick(doc)"/>
                  <!-- <b-icon v-if="doc.document_container !=='solution_contracts'"
                    icon="x"
                    :class="['delete-doc-btn', isDeleteInProgress ? 'disabled-delete-doc-btn' : '']"
                    @click="deleteDocument(doc.id)"
                  ></b-icon> -->
                </div>
              </div>
            </div>
          </div>
        </div>
    </el-timeline-item>
  </el-timeline>
  </div>
  <div class="add-button-mobile" @click="resetDrawerValues(); showTimelineDrawer = true;">
    <b-icon icon="plus-lg" style="color: white; font-size: 16px;"></b-icon>
  </div>
  <component
    :is="isMobileView ? 'el-drawer' : 'div' "
    direction="btt"
    custom-class="timeline-tab-drawer-mobile"
    :visible.sync="showTimelineDrawer"
	  size="100%"
    :with-header="false"
  >
      <el-card class="boxCard">
        <div v-show="isMobileView" class="add-drawer-header">
          <b-icon class="back-arrow" @click="onCloseActivityDrawer" icon="arrow-left-short"/>
          <span class="add-note-span"> {{ isEditModeEnabled ? 'Edit Note' :'Add Note' }}</span>
        </div>
        <div class="callerClass">
          <div class="optionsDrop">
            <el-select v-model="selectedValue" placeholder="Select">
              <template v-slot:prefix >
                <i :class="getSelectedIconClass" class="iconClass"></i>
              </template>
              <el-option
                v-for="item in activityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                <i :class="item.icon"></i> {{ item.label }}
              </el-option>
            </el-select>
          </div>
          <div class="activity-sentence">
            <p class="lilText">
              <span>
                {{getSelectedText}}
              </span>
              <span class="nameTo">
                <abbr :title="leadInfo?.name || leadInfo?.lead_details?.name" class="abbrTag">
                  {{ leadNameComputed }}
                </abbr>
              </span> {{selectedValue=="general" ? 'made a note' : ''}}
              <span style="color: #777;">
                at
              </span>
            </p>
            <div class="timePicker">
              <input
                class="inputTime"
                type="time"
                v-validate="'required'"
                name="time"
                v-model="pickedTime"
                placeholder="Select a time"
              />
            </div>
            <span style="color: #777;">
              on
            </span>
            <!-- <datePicker/> -->
            <el-date-picker
              v-model="pickedDate"
              type="date"
              :picker-options="pickerOptions"
              :clearable="false"
              :format="computedDateFormat"
              value-format="yyyy-MM-dd"
              placeholder="Pick a day">
            </el-date-picker>
          </div>
        </div>
        <div class="descriptionText">
          <el-input type="textarea" :rows="4" resize="none" placeholder="Add your notes here..." v-model="notes" ref="addNoteInput" class="describe" :autofocus="true" v-validate="descValidation" name="description"></el-input>
          <p
            class="formErrors"
            style="color: red"
            v-show="errors.has('description') && isDisabled"
          >
            {{ errors.first('description') }}
          </p>
        </div>
          <div class="outerBtn">
            <div class="allFiles" :style="{marginTop: isUpdateDisabled && '14px'}">
              <div v-for="(file, index) in fileList" :key="index" class="fileName">
                <p>{{getTruncatedFileName(file.name,14)}}</p>
                <span class="cross" @click="onClose(index)" style="font-weight: bolder;">&#x2716;</span>
              </div>
            </div>
            <div class="uploadAndSave">
              <div class="file-input">
                <input id="file-upload" type="file" class="file-input-element" multiple ref="file" @change="onChange">
                <label for="file-upload" class="file-label">
                  <img src="./assets/upload.svg" alt="" class="iconUpload" v-if="!isEditModeEnabled">
                </label>
              </div>
              <div class="saveButton">
                <el-tooltip
                  :disabled="projectPermissionObject.add_activitylog"
                  effect="dark"
                  placement="top-start"
                  :content="'You dont have permission to change the activity timeline'"
                >
                  <span>
                    <el-button type="primary" :disabled="isUpdateDisabled || !notes.length" :loading="isLoading"
                    :class="['submitBtn', notes.length ? '' : 'disabled-update-btn']" @click="submitDetails()">
                    Update
                    </el-button>
                  </span>
                </el-tooltip>
              </div>
            </div>
        </div>
      </el-card>
  </component>
  <!-- <all-drawer :drawer="editDrawer" @save="reminderDrawer = false" :componentName="componentName" :drawerSize="700" @close="editDrawer = false" :isDrawer="isDrawer"/> -->
  <editActivity :showCreateDrawer="showCreateDrawer" @close="showCreateDrawer=false" :isDrawer="isDrawer" @save="onSave" :key="editKey"/>
  <DeleteProjectDocument
    v-if="isDeleteProjectDocumentPopupOpen"
    :isDeleteProjectDocumentPopupOpen="isDeleteProjectDocumentPopupOpen"
    @cancelDelete="isDeleteProjectDocumentPopupOpen = false"
    @confirmDelete="confirmDelete"
  />
</div>
