<template>
  <div class="filter-container">
    <p class="filter-title">Filter</p>
    <div>
      <div class="select-container">
        <div
          v-for="(filterOuter, inx) in filteredData"
          :key="inx"
        >
          <div v-if="inx > 0" class="operator-container">
            <p style="width: 40px">{{ handleOperator(inx) }}</p>
            <p>
              <span class="icon-container">
                <b-icon
                  @click="toggleOperator('and', inx)"
                  :style="{
                    color: upIconColor[inx],
                    marginRight: '0.5rem',
                  }"
                  class="custom-arrow"
                  :icon="'caret-up-fill'"
                ></b-icon>
                <b-icon
                  @click="toggleOperator('or', inx)"
                  :style="{
                    color: downIconColor[inx],
                    marginRight: '0.5rem',
                  }"
                  class="custom-arrow"
                  :icon="'caret-down-fill'"
                ></b-icon>
              </span>
            </p>
          </div>
          <div class="select-body">
            <div v-for="(filterInner, indx) in filterOuter" :key="indx">
              <div class="spacing-container">
                <SearchSelectView
                  v-if="filterInner.type === 'select'&&filterInner.ValueMetricType === 'FK'"
                  :items="filterInner"
                  :selectedValue="filterInner.selectedValue"
                  @item-selected="selectedOptionValue"
                  :indexOfOptions="inx"
                  :seletedSource="filterInner.isDisabled"
                  :placeholderText="filterInner.toolTip"
                  :options="items.options"
                />

                <SelectComponentView
                  v-if="filterInner.type === 'select'&&filterInner.ValueMetricType !== 'FK'"
                  :items="filterInner"
                  :selectedValue="filterInner.selectedValue"
                  @item-selected="selectedOptionValue"
                  :indexOfOptions="inx"
                  :seletedSource="filterInner.isDisabled"
                  :placeholderText="filterInner.toolTip"
                />
                <InputView
                  v-if="filterInner.type === 'input'"
                  :itemValue="filterInner"
                  :selectedValue="filterInner?.selectedValue || ''"
                  :indexOfOptions="inx"
                  @item-selected="selectedOptionValue"
                  :seletedSource="filterInner.isDisabled"
                />
                <DatePickerView
                  v-if="filterInner.type === 'date'"
                  :itemValue="filterInner"
                  :selectedValue="filterInner?.selectedValue || ''"
                  :indexOfOptions="inx"
                  @item-selected="selectedOptionValue"
                  :seletedSource="filterInner.isDisabled"
                />
                <NumberInput
                  v-if="filterInner.type === 'number'"
                  :itemValue="filterInner"
                  :selectedValue="filterInner?.selectedValue || ''"
                  :indexOfOptions="inx"
                  @item-selected="selectedOptionValue"
                  :seletedSource="filterInner.isDisabled"
                />

                <MenuView
                  v-if="filterInner.type === 'menu'"
                  :itemValue="filterInner"
                  :selectedValue="filterInner?.selectedValue || ''"
                  :indexOfOptions="inx"
                  @item-selected=" selectedOptionValue"
                  :filterColumnOption="filterColumnOption"
                  :seletedSource="filterInner.isDisabled"
                  :placeholderText="filterInner.toolTip"
                />
              </div>
            </div>
            <div :span="1" @click="removeFilter(inx)">
              <p class="cross" v-if="inx===filterData.length-1"><b-icon :icon="'x'"></b-icon></p>
            </div>
          </div>
        </div>
        <div v-if="filterData.length > 1">
          <p>{{ selectedExpression }}</p>
        </div>

        <div style=" padding-top: 1rem;">
          <p
            style="
              color: #1c3366;
              font-weight: 600;
              font-size: 1rem;
             
              display: flex;
              cursor: pointer;
              width:125px;
            "
            @click="handleaddFilter"
          >
            <span
              ><b-icon
                :style="{
                  color: '#1c3366',
                  marginRight: '0.5rem',
                }"
                class="custom-icon-size"
                :icon="'plus-circle-fill'"
              ></b-icon></span
            >{{ `Add Filter` }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputView from "./inputView.vue";
import SelectComponentView from "./selectComponentView.vue";
import NumberInput from "./numberInput.vue";
import DatePickerView from "./datePickerView.vue";
import API from "../../../../services/api";
import MenuView from "./menuView.vue";
import SearchSelectView from "./searchSelectView.vue";
export default {
  emits: [
    "update:isFilterEmpty",
    "filter-details",
    
  ],
  name: "filterView",
  components: {
    SelectComponentView,
    InputView,
    NumberInput,
    DatePickerView,
    MenuView,
    SearchSelectView
},
  data() {
    return {
      filterData: [],
      selectedOperator: [],
      upIconColor: [],
      downIconColor: [],
      addFilter: [
        {
          title: "Column",
          type: "select",
          selectedValue: "",
          prop: "metric",
          placeholder: "Select Column",
          options: this.$props.filterColumnOption || [],
          isStar: true,
          isDisabled:this.$props.seletedSource,
        },
        {
          title: "Operator",
          type: "select",
          prop: "operator",
          selectedValue: "",
          placeholder: "Select Operator",
          options: [],
          isStar: true,
          isDisabled:'',
          toolTip:"Select Column"
        },
        {
          title: "Value",
          type: "select",
          prop: "value",
          selectedValue: "",
          placeholder: "Select Value",
          options: [],
          operatorOptions: [],
          disabledPrev: false,
          isStar: true,
          isDisabled:'',
          toolTip:"Select Operator"
        },
      ],
      selectedCondition: {},
      selectedExpression: "",
      tempArr: [],
      selectedMenuData:''
    };
  },
  computed: {

    filteredData() {
      return this.filterData.length > 0 ? this.filterData : [];
    }
  },
  props: {
    items:{
      type:Object,
      default() {
        return {}
      }
    },
    filterColumnOption: { 
      type: Array, 
      default() {
        return []
      }
    },
    otherFilterOptions: { 
      type: Object, 
      default() {
        return {}
      }
    },
    selectedFilterDetails: {
      type: Object,
      default() {
        return {}
      }
    },
    seletedSource: {
      type: String,
      default: 'Leads',
    },
    isFilterEmpty:{
      type:Boolean,
      default:false
    },
    leadOptions:{
      type:Array,
      default() {
        return []
      }
    }
  },
  mounted() {
    this.selectedExpression =
      this.$props.selectedFilterDetails?.expression || "";
    this.selectedCondition =
      this.$props.selectedFilterDetails?.conditions || {};
    const matches =
      this.$props.selectedFilterDetails?.expression?.match(/and|or/g);
    this.selectedOperator = matches ? matches : [];
    if (this.selectedOperator.length) {
      this.selectedOperator.map((d, ind) => {
        this.toggleOperator(d, ind + 1);
      });
    }
    // this.selectedOperator.unshift("and");

    if(this.$props.filterColumnOption.length>0){
      this.handleaddSelectedOptions();

    }
  },
  methods: {
    getValueType(value,defaultType,metricType) {
   
      if(defaultType==='menu'){
        return 'menu'
      }

      if(metricType==='char'||metricType==='float'||metricType==='currency'){
        switch (value.operator) {
          case 'isnull':
      case "isnotnull":
      return 'empty';
default : 
return defaultType
        }
        

       
      }
      if(metricType==='datetime'){
        switch (value.operator) {
          case 'isnull':
      case "isnotnull":
        case "today":
          case "tommorrow":
            case "tommorrowonwards":
              case "yesterday":
                case "tillyesterday":
                  case "lastmonth":
                    case "nextmonth":
                      case "lastweek":
                          case "thisweek":
                            case "nextweek":
                             case "thismonth":
      return 'empty';
default : 
return defaultType
        }
        

       
      }

      if(metricType==='FK'||metricType==='choice'){
        switch (value.operator) {
        case "iexact":
  case "is":
  case "equal":
    case 'isnot':
      case 'notequal':
    return 'select';
    case 'isnull':
      case "isnotnull":
      return 'empty';
      case "contains":
        return 'input';
        default:

          return 'input'  ;
        }

      }

  //     if(defaultType==='date'){
  //       switch (value.operator) {
  //       case "isnull":
  //         return 'empty';
  //         default:
  //           return defaultType
  //     }
  //   }

  //   if(defaultType==='number'){
  //       switch (value.operator) {
  //       case "isnull":
  //         return 'empty';
  //         default:
  //           return defaultType
  //     }
  //   }

  //     if(defaultType!=='date'&&defaultType!=='number'){
  //       switch (value.operator) {
  //       case "iexact":
  // case "is":
  // case "equal":
  //   return 'select';
  //   case 'isnull':
  //     return 'empty';
  //     case "contains":
  //       case "isnotnull":
  //       return 'input';
  //       default:

  //         return defaultType  ;
  //     }
  //     }else{
  //       return defaultType

  //     }
   
    },
  async  handleaddSelectedOptions() {
      // Create a deep copy of groupedRows
      let selectedOptions = this.$props.selectedFilterDetails?.conditions || {};
      selectedOptions = Object.values(selectedOptions).map((item) => item);
let tempArray=[]
      selectedOptions.map(async (d, index) => {
        const metricOption = this.$props.filterColumnOption.find(
          (data) => data.metric === d.metric
        );

        // const piplineOption = this.$props.filterColumnOption.find(
        //   (data) => data.metric === "pipeline__id"
        // );

        const piplineOption = this.$props.leadOptions.find(
          (data) => data.metric === "pipeline__id"
        );

        const optionsList = await this.getOperatorOptions(
          metricOption,
          piplineOption,
          d
        );

        const operator = optionsList.options?.find(
          (op) => op.operator === d.operator
        );

        if (optionsList.valueOptions?.length > 0) {
          optionsList.valueOptions.map((d) => {
                d.label = d.name ? `${d.name}` : d.label;
              });
            }
const selectedValueData=optionsList?.valueOptions?.find(op=>{
  if(op.id){
  return op.id===d.value
  }else{
 return   op.value===d.value
  }
})

        const valueData = selectedValueData?selectedValueData?.label||selectedValueData?.name:'';
        const newRow = JSON.parse(JSON.stringify(this.addFilter));
        newRow.map((val) => {
          if (val.prop === "metric") {
            val.related_model_property=d.related_model_property
            val.selectedValue = metricOption?.label;
            val.isDisabled=this.$props.seletedSource;
            val.metric=d.metric;
            val.metric_type=d.metric_type
          }
          if (val.prop === "operator") {
            if(optionsList){
      val.metricType=optionsList?.metricType
            val.valueType=optionsList?.valueType
            }
      
            if( metricOption?.label){
              val.isDisabled=this.$props.seletedSource
            }
            if(d?.isValueEmpty){
              val.isValueEmpty=d.isValueEmpty
            }
            val.selectedValue = operator?.label;
            val.options = optionsList?.options || [];
          }
          if (val.prop === "value") {
            // if(d.optionObject){
            //   val.optionObject=d.optionObject;
            // }
            val.ValueMetricType=d?.metric_type;
            if(optionsList){
              val.metricType=optionsList?.metricType;
            
              val.nextUrl=optionsList?.nextUrl||''

            }
      
           
if(operator?.label){
  val.isDisabled=this.$props.seletedSource
}


val.selectedValue =(d['metric']==='stage_object__id'||d['metric']===  "lead__stage_object__id"||d.isValueEmpty==='input'||d.isValueEmpty==='date'||d.isValueEmpty==='number')?d.value: valueData?.label ? valueData.label : valueData||'NA';

if(!valueData){
  val.noData='NA'
}else{
  val.noData=''
}
            val.type = optionsList.valueType;
            if(d?.additional_conditions){
val.additional_conditions=d.additional_conditions
            }
          
            val.options = optionsList?.valueOptions || [];
            if(d?.isValueEmpty||operator?.value_required===false){
              val.type=d?.isValueEmpty||'empty'
            }
            val.metric_type=metricOption?.metric_type;
            val.related_model_id=metricOption?.related_model_id;
          }
        });

        // this.filterData =await [...this.filterData, newRow];
        tempArray[index]=newRow;
        this.filterData=   tempArray.filter(flt=>flt?.length)
       
      });
    },
    async getFilterValues(option, pipline,id) {
    
      let queryParams = "";
      if (option.metric_type === "FK") {
        queryParams = {
          related_model_id: option.metric === "stage_object__id"|| option.metric ==="lead__stage_object__id"? pipline.related_model_id: option.related_model_id,

          contains: "",
        };
        if (pipline && option.metric === "stage_object__id"||  option.metric ==="lead__stage_object__id") {
          // queryParams.pipeline_id = 2;
       
        }
        if(id){
          queryParams.include = id;
        }
      }
      if (option.metric_type === "choice") {
        queryParams = {
          metric: option.metric,
        };
      }
      if (queryParams) {
        let params = new URLSearchParams(queryParams).toString();

        try {
          const { data } =
            await API.CRM_DASHBOARD.GET_WIDGET_FILTER_VALUE_OPTIONS(params);

            const result={
              data:data.results ? data.results : data,
              nextUrl:data?.next||''
            }

          return result;
        } catch (err) {
          console.error(err);
        }
      }
    },
    handleaddFilter(index) {
      const newRow = JSON.parse(JSON.stringify(this.addFilter));

      this.filterData = [...this.filterData, newRow];
      this.upIconColor.push("black");
      this.downIconColor.push("#999");
      if (this.filterData.length > 1) {
        this.selectedOperator.push("and");
  
      }
      this. selectedOptionValue(
      'metric',
      {},
      "",
      [],
      index,
   
    )
      // let obj={}
      // newRow.map((ft,indx)=>{
        
      //   obj[ft.prop]=''


      // })
      // this.selectedCondition[index+1]=obj


    },
    handleOperator(index) {
      return this.selectedOperator[index - 1]
        ? this.selectedOperator[index - 1] === "and"
          ? "AND"
          : "OR"
        : "AND";
    },
    toggleOperator(operator, index) {
      if (operator === "and") {
        this.upIconColor[index] = "black";
        this.downIconColor[index] = "#999";
      } else {
        this.upIconColor[index] = "#999";
        this.downIconColor[index] = "black";
      }
      this.selectedOperator[index - 1] = operator;

      this.selectedExpression = "";
      this.filterData.map((d, inx) => {
        let obj = {};
        // d.map((data) => {
        //   if (data[data.prop]) {
        //     obj[data.prop] = data[data.prop];
        //   } else {
        //     obj[data.prop] = this.selectedCondition[inx + 1][data.prop]
        //       ? this.selectedCondition[inx + 1][data.prop]
        //       : "";
        //   }
        // });
        // this.selectedCondition[inx + 1] = obj;
        this.selectedExpression =
       `${inx>0?"(":''}`+ this.selectedExpression +
          `${inx + 1} `+`${inx>0?")":""}`+
          `${this.selectedOperator[inx] ? this.selectedOperator[inx] : ""} ` ;
      });
      this.$emit('update:isFilterEmpty',false);
      this.$emit(
        "filter-details",
        this.selectedCondition,
        this.selectedExpression
      );
    },
    removeFilter(index) {
      this.filterData = this.filterData.filter((d, ind) => ind !== index);
      this.selectedOperator = this.selectedOperator.filter(
        (d, ind) => ind !== index - 1
      );

      this.upIconColor= this.upIconColor.filter((iu,inx)=>inx!==index);
        this.downIconColor=this.downIconColor.filter((iu,inx)=>inx!==index);
        delete this.selectedCondition[index+1]
        if(this.filterData.length===0){
          this.$emit('update:isFilterEmpty',true);
        }
    },
    async getOperatorOptions(data, pipline,filterData) {
      let tempObj = { options: [], valueType: "" };

      switch (data.metric_type) {
        case "char":
          this.operatorOptions = this.$props.otherFilterOptions?.string || [];
          tempObj.options = this.$props.otherFilterOptions?.string || [];
          tempObj.valueType = "input";
          tempObj.metricType=data.metric_type
          return tempObj;

        case "FK":
          this.operatorOptions = this.$props.otherFilterOptions?.FK || [];
          tempObj.options = this.$props.otherFilterOptions?.FK || [];
          const valueOption = await this.getFilterValues(data, pipline,filterData?.id||filterData?.value);
          tempObj.valueType =data.metric==='stage_object__id'||  data.metric==="lead__stage_object__id"?"menu" : "select";
          tempObj.valueOptions = valueOption?.data||[];
          tempObj.nextUrl=valueOption?.nextUrl||''
          tempObj.metricType=data.metric_type
          return tempObj;
        case "choice":
          this.operatorOptions = this.$props.otherFilterOptions?.string || [];
          const valueOptions = await this.getFilterValues(data, pipline);
          tempObj.options = this.$props.otherFilterOptions?.string || [];
          tempObj.valueType = "select";
          tempObj.valueOptions = valueOptions?.data||[];
          tempObj.nextUrl=valueOptions?.nextUrl||''
          return tempObj;

        case "float":
        case "currency":

          this.operatorOptions = this.$props.otherFilterOptions?.number || [];
          tempObj.options = this.$props.otherFilterOptions?.number || [];
          tempObj.valueType = "number";
          tempObj.metricType=data.metric_type
          return tempObj;
        case "datetime":
          this.operatorOptions = this.$props.otherFilterOptions?.datetime || [];
          tempObj.options = this.$props.otherFilterOptions?.datetime || [];
          tempObj.valueType = "date";
          tempObj.metricType=data.metric_type
          return tempObj;
      }
    },
    getCondtion(){
      
    },
    async selectedOptionValue(
      typeData,
      data,
      value,
      itemData,
      index,
      isGrouped,
      selectedColumns,
      piplineData,
      optionObject
    ) {

      let columnData='';
      let condition = { metric: "", operator: "", value: "" };
      // const piplineOption = this.$props.filterColumnOption.find(
      //   (data) => data.metric === "pipeline__id"
      // );

      const piplineOption = this.$props.leadOptions.find(
          (data) => data.metric === "pipeline__id"
        );

      let result = "";
      let valueType = "";
      if (typeData === "metric") {
        columnData=value
        result = await this.getOperatorOptions(value, piplineOption,data);
      }
      if (typeData === "operator") {

        // valueType=    await this.getValueType(value,result?.valueType,result?.metricType);
      }

  if(this.filterData?.length>0)  {  
    this.filterData[index]?.map((d) => {
       
        if (typeData === "metric" && d.prop === "metric") {
         d.related_model_property=value.related_model_property;
         d.metric_type=value.metric_type;
          d.selectedValue = data?.label;
          d.metric = data?.metric;
        }
        if (typeData === "operator" && d.prop === "operator") {
          valueType=     this.getValueType(value,d?.valueType,d?.metricType);

          d.selectedValue = data?.label;
          d.operator = data?.operator;
          
        }
        if (typeData === "value" && d.prop === "value") {
          d.selectedValue =d['metric']==='stage_object__id'||d['metric']===  "lead__stage_object__id"?data?.id : data?.label || data;
          
if(data){

  d.noData=''
}
          if(piplineData){
            d['additional_conditions']={
              operator: "equal",
                    metric:this.filterData[index][0]['metric']==='stage_object__id'? "pipeline__id":'lead__pipeline__id',
                    value: piplineData?.id||''
            }
          }
          d.value =data.id?data.id : data?.value || data;
        // if(optionObject){
        //   d.optionObject=optionObject;
        // }
       
        }
        if(d.prop==='operator'){
          if(valueType){
            d.isValueEmpty=valueType
          }
          if(columnData){

            if(result){
              d.valueType=result.valueType

            }
              d.metricType=columnData?.metric_type

            }
        }
        if (d.prop === "value" ){
          if(columnData){
              d.metricType=columnData?.metric_type;
              d.ValueMetricType=columnData?.metric_type;
d.metric_type=columnData?.metric_type;
d.related_model_id=columnData?.related_model_id
            }
          if(valueType){
             d.type =  valueType

          }
        }
        if (d.prop === "operator"&&typeData==='metric'&&value){
          d.isDisabled=this.$props.seletedSource;
          d.selectedValue=''

        }
        if (d.prop === "value"&&typeData==='metric'&&value){
          d.selectedValue='';
        }
        if (d.prop === "value"&&typeData==='operator'&&value){
          d.isDisabled=this.$props.seletedSource;
          d.selectedValue='';

        }
        if (result) {
          if (d.prop === "operator") {
            d.options = result?.options || [];
          }
          if (d.prop === "value") {
            d.nextUrl=result?.nextUrl
            d.typeCheck=result?.valueType;
            if(columnData){
              d.metricType=columnData?.metric_type;
              d.ValueMetricType=columnData?.metric_type;

            }

            d.type = result?.valueType;

            if (result.valueOptions?.length > 0) {
              result.valueOptions.map((d) => {
                d.label = d.name ? `${d.name}` : d.label;
              });
            }

            if (data.metric === "stage_object__id"|| data.metric === "lead__stage_object__id") {
              d.type = "menu";
            }
            if(piplineData){
              d.piplineData=piplineData;
            }

            d.options = result?.valueOptions || [];
          }
        }
      });

      this.selectedExpression = "";
      this.filterData.map((d, inx) => {
        let obj = {};

        d.map((data) => {

         if(data.prop==='metric'){
          obj.related_model_property=data?.related_model_property||'';
          obj.metric_type=data?.metric_type||''
         }
        //  if(data.optionObject){
        //   obj['optionObject']=data.optionObject;
        //  }
         if(data.isValueEmpty){
          obj['isValueEmpty']=data.isValueEmpty;
         }
          if(data.additional_conditions){
            obj['additional_conditions']=data.additional_conditions
          }
          if(this.filterData[index]&&(this.filterData[index][0]['metric']==='stage_object__id'|| this.filterData[index][0]['metric']=== "lead__stage_object__id")&&data?.piplineData){
            
            obj['additional_conditions']={
              operator: "equal",
                    metric:this.filterData[index][0]['metric']!=='lead__stage_object__id'? "pipeline__id":"lead__pipeline__id",
                    value: data.piplineData?.id||''
            }
          }
          if (data[data.prop]&&data.selectedValue) {
            obj[data.prop] = data[data.prop];
          } 
          
          else {
            if(data.selectedValue){
              obj[data.prop] =
              this.selectedCondition[inx + 1] &&
              this.selectedCondition[inx + 1][data.prop]
                ? this.selectedCondition[inx + 1][data.prop]
                : "";
            }else{
              obj[data.prop] =''
            }
           
          }
        });
        this.selectedCondition[inx + 1] = obj;
        this.selectedExpression =
       `${inx>0?"(":''}`+ this.selectedExpression +
          `${inx + 1} `+`${inx>0?")":""}`+
          `${this.selectedOperator[inx] ? this.selectedOperator[inx] : ""} ` ;
      });}
      this.$emit('update:isFilterEmpty',false);

      this.$emit(
        "filter-details",
        this.selectedCondition,
        this.selectedExpression
      );

      // this.selectedCondition[index] = condition;
    },
    handleSelectedMenuValue(piplineDetails,stageDetails,index){
      let metricCheck=''
      this.filterData[index].map((d)=>{
      
        if(d.prop==='metric'){
          metricCheck=d
        }
        if (d.prop === "value" ){
          d.selectedValue=stageDetails?.id||'';
          d.additional_conditions={
            operator: "equal",
                    metric: "pipeline__id",
                    value: piplineDetails?.id||''
          }
        }
      })
    }
  },
  watch: {
    items:{
      immediate:true,
      handler(val){
      }
    },
    filterColumnOption: {
      immediate: true,
      handler(val) {
        this.filterData=[]
        this.$emit('update:isFilterEmpty',true)
        this.addFilter.map((d) => {
          if (d.title === "Column") {
            d.options = val;
          }
        });
      },
    },

selectedSource:{
  immediate:true,
  handler(val,old){
  this.filterData.map(fd=>{
    if(fd.prop==='metric'){
      fd.isDisabled=val
    }
  })}
},

    filterData(val) {
     
      this.filterData = val;
      this.selectedExpression = "";
      this.filterData.map((d, inx) => {
        let obj = {};
        // d.map((data) => {
        //   if (data[data.prop]) {
        //     obj[data.prop] = data[data.prop];
        //   } else {
        //     obj[data.prop] = this.selectedCondition[inx + 1][data.prop]
        //       ? this.selectedCondition[inx + 1][data.prop]
        //       : "";
        //   }
        // });
        // this.selectedCondition[inx + 1] = obj;
        this.selectedExpression =
       `${inx>0?"(":''}`+ this.selectedExpression +
          `${inx + 1} `+`${inx>0?")":""}`+
          `${this.selectedOperator[inx] ? this.selectedOperator[inx] : ""} ` ;
      });
      // this.$emit(
      //   "filter-details",
      //   this.selectedCondition,
      //   this.selectedExpression
      // );
    },
    operatorOptions(val) {},
    selectedOperator: {
      immediate: true,
      handler(val) {
        this.selectedExpression = "";
        this.filterData.map((d, inx) => {
          let obj = {};
          // d.map((data) => {
          //   if (data[data.prop]) {
          //     obj[data.prop] = data[data.prop];
          //   } else {
          //     obj[data.prop] = this.selectedCondition[inx + 1][data.prop]
          //       ? this.selectedCondition[inx + 1][data.prop]
          //       : "";
          //   }
          // });
          // this.selectedCondition[inx + 1] = obj;
          this.selectedExpression =
       `${inx>0?"(":''}`+ this.selectedExpression +
          `${inx + 1} `+`${inx>0?")":""}`+
          `${this.selectedOperator[inx] ? this.selectedOperator[inx] : ""} ` ;
        });

        if (this.selectedCondition) {
          this.$emit('update:isFilterEmpty',false);
          this.$emit(
            "filter-details",
            this.selectedCondition,
            this.selectedExpression
          );
        }
      },
    },
  },
};
</script>

<style scoped>
.filter-title {
  font-size: 14px;
  font-weight: 600;
}
.spacing-container {
  padding-right: 0.5rem;
}
.title {
  padding-bottom: 1rem;
}
.cross {
  padding-top: 29px;
  font-size: 1.5rem;
  cursor: pointer;
}
.select-container >>> .el-input__inner::placeholder {
  font-size: 12px;
  color: #777;
}
.select-container >>> .el-input__inner {
  min-width:138px !important;
}
.select-body {
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
}
.custom-icon-size {
  font-size: 1.2rem;
}
.icon-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.operator-container {
  display: flex;

  align-items: center;
}
.custom-arrow {
  font-size: 12px;
}
</style>
