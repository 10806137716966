<template>
    <div class="all-container">
        <el-dialog 
        :title="header"
         :visible.sync="openDrawer" 
          append-to-body 
        :close-on-click-modal="false"
          :before-close="onCloseClick"
          class="dialog">
          <div class="email-dialog-conatiner">
           <div class="top-email-format">
              <div class="main-container">
                    <div>
                        <p class="main-title">
                        Title
                        </p>
                        <el-input
                            v-model="title"
                            type="text" 
                            :style="dataTimePickupStyles"
                            placeholder="Please Enter Title"
                            maxlength="100" 
                            class="input-el"
                           /> 
                    </div>
              </div>
              <div class="main-container">
                    <div class="date-container">
                        <div class="inner-container">
                            <p class="main-title">
                                Date
                                <span class="required-class">
                                    *
                                </span>
                            </p>
                             <div>
                                 <el-date-picker
                                     v-model="date"
                                     type="date"
                                     placeholder="Select Date"
                                     style="width: 100%;"
                                     :style="dataTimePickupStyles"
                                      :picker-options="datePickerOptions"
                                         :clearable="false"
                                 />
                             </div>
                        </div>
                        <div class="inner-container">
                            <p class="main-title">
                                Start Time
                                <span class="required-class">
                                    *
                                </span>
                            </p>
                             <div>
                                <el-tooltip 
                                class="item" 
                                effect="dark" 
                                :content="!date ? 'Add Date' : 'Add Start Time'"
                                 placement="top-start"
                                 >
                                    <el-time-select
                                        v-model="startTime"
                                        placeholder="Select time"
                                        :style="dataTimePickupStyles"
                                        :picker-options="startPickerOption"
                                        :disabled="!date"
                                           :clearable="false"
                                    />
                                </el-tooltip>
                             </div>
                        </div>
                        <div class="inner-container">
                            <p class="main-title">
                                End Time
                                <span class="required-class">
                                    *
                                </span>
                            </p>
                             <div>
                                <el-tooltip 
                                class="item" 
                                effect="dark" 
                                :content="!startTime && !date ? 'Add Date and Time' : !startTime ? 'Add Start Time':'Add End Time'"
                                 placement="top-start"
                                 >
                                    <el-time-select
                                        v-model="endTime"
                                        :style="dataTimePickupStyles"
                                        placeholder="Select time"
                                        :picker-options="endPickerOption"
                                        :disabled="!startTime || !date"
                                        :clearable="false"
                                    />
                                    </el-tooltip>
                             </div>
                        </div>
                    </div>
              </div>
              <!-- Attendees---------------------------------------------------------------------------------- -->

              <div class="email-header">
                <p class="main-title">
                    Attendees 
                    <span class="required-class">
                                    *
                                </span>
                    : 
                </p>
                <div
                v-for="(item, index) in avatar"
                :key="index"
                 class="email-details-container"
                 >
                 <el-tooltip
                    class="item" 
                   effect="dark"
                    :content="item"
                     placement="top-start"
                     >
                   <p 
                   class="email-send-to"
                   :style="{ color:item == admin ? '#777' : '#409EFF;' }"
                   >
                   {{ item }}
                </p>
                   </el-tooltip>
                   <el-tooltip
                    class="item" 
                    effect="dark"
                    :content="item == admin ? 'Cannot be removed': 'Remove Email'"
                     placement="top-start"
                     >
                        <img
                         src="./assets/X.svg"
                         alt="close"
                         class="close_image_class"
                         :style="{cursor: item == admin ? 'not-allowed' : 'pointer' }"
                         @click="handleRemoveAttendees(item)"
                         >
                    </el-tooltip>
                </div>
                <div>
                    <div class="email_input_new">
                    <input 
                        v-if="showEmailInputField"
                        ref="emailInput"
                        v-model="addedAttendees"
                        name="email"
                        type="text"
                        class="input_email"
                        :style="handleEmailError()"
                        @blur="handleAddAttendees()"
                        @focus="handleRemoveError()"
                        @keydown="handleKeyEvents($event)"
                    >
                </div>
                </div>
      
                <el-tooltip
                    class="item" 
                   effect="dark"
                     :content="!showEmailInputField ? 'Add Email' : checkEmailValidation(addedAttendees) ? 'Disabled':'Remove Email'"
                     placement="top-start"
                     >
                     <div 
                     class="add-more-email"
                     :style="{ 
                        marginLeft: showEmailInputField ? '10px' : '0px' , 
                     cursor:checkEmailValidation(addedAttendees) ? 'not-allowed': 'pointer'
                     }"
                     @click="addNewEmailField()"
                     >
                     <i 
                     v-if="!showEmailInputField"
                     class="el-icon-plus"
                     />

                     <i 
                     v-else 
                     class="el-icon-close"
                     />
                     </div>
                    </el-tooltip>
            </div>

              <!-- body -->
              <div 
            class="email_body_class"
             >
                <el-input
                    v-model="description"
                    type="textarea"
                    :autosize="false"
                    show-word-limit
                    placeholder="Type details for this new meeting"
                    />
            </div>
           </div>
           <div class="bottom-email-format">
            <div class="bottom-left-container">
            </div>
            <div 
            class="bottom-right-container"
            style="margin-left: auto;"
            >
            <el-tooltip
             class="item"
             effect="dark" 
            :content="contentMeeting"
             placement="top-start"
             >
            <el-button
            type="primary"
            :disabled="handleSaveButtonDisability"
            @click="onOperationalButtonClick"
            >
            <p v-if="!loading">
                        Save
                    </p>
                    <i 
                    v-else
                    class="el-icon-loading" 
                     />
            </el-button>
        </el-tooltip>
            </div>
           </div>
          </div>
        </el-dialog>
</div>
</template>

<script>
import {formatDateTime, getCurrentTime, addMinutesToTime, checkDateStatus, compareTimes} from "./utils/utils"
import {checkEmailValidation} from "../../core/utils/utils";
import {calculateDuration, formatDate, convertToUTC,formatDateForOutlook,convertToUTCForMicrosOft} from "./utils/utils"
import API from "@/services/api/";
import { useLeadStore } from '../../stores/lead';
import { mapState } from 'pinia';
import {useEmailStore} from "../../stores/email"
import { DateTime } from "luxon";
export default {

    emits: ["onClickClose", "addMeeting", "updateMeeting"],
props:{
    openDrawer: {
      type: Boolean,
      default: false,
    },

    flag: {
      type: String,
      default: "create",
    },
    admin: {
      type: String,
      default: "admin",
    },
    meetingData:{
        type: Object,
        default: () => {},
    },
    leadData:{
        type:Object,
        default:() => {},
    },
     header:{
        type: String,
        default: "Create New Meeting",
     },
     tabType:{
        type: String,
        default: "leadSummary",
     }
  },

  data(){
    return{
     title:"",
     date:"",
     startTime:"",
     endTime:"",
     description:"",
     avatar:[],
     addedAttendees:"",
     isTeamSelected:true,
     showEmailInputField:false,
     loading:false,
     emailError:false,
     allowToAddFields:true,
     datePickerOptions: {
        disabledDate(date) {
                // Get the current date with time set to midnight
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        // Disable dates before today
        return date < today;
        }
      }
    }
  },

  computed:{

    ...mapState(useLeadStore, {
            leadInfo: state => state
        }),
    ...mapState(useEmailStore,{
        isMicrosoftAccount:state=>state.isMicrosoftAccount,
        isGoogleAccount:state=>state.isGoogleAccount
    }),    
    dataTimePickupStyles(){
        return (
            {
                width:"100%",
                border:"1px solid #999",
                borderRadius:"4px",
                color:"black",
                marginTop:"8px",
            }
        ) 
    },



    handleSaveButtonDisability(){
            if(!this.date || !this.startTime || !this.endTime || this.avatar.length<=0){
                return true;
            }else if (!compareTimes(this.startTime, this.endTime)) {
                return true;
            }else{
                return false
            }
    },

    contentMeeting(){
        if(!this.date || !this.startTime || !this.endTime || this.avatar.length<=0){
                return "Fill all the mandatory fields."
            }else if (!compareTimes(this.startTime, this.endTime)) {
                return "Start time should be lesser than end time"
            }else{
                return "Save"
            }
    },

    startPickerOption(){
        const isToday =checkDateStatus(this.date)
        const currentTime = !isToday ?  getCurrentTime() : "00:00"
        return{
            start: currentTime ? currentTime: "00:00",
            step: '00:15',
            end: '23:30',
        }
    },


    endPickerOption(){
        const endStartTime = addMinutesToTime(this.startTime,15)
        return {
            start: endStartTime? endStartTime : "00:00",
            step: '00:15',
            end: '23:45',
        }
    },
    },

watch:{
    showEmailInputField(newValue){
        if(newValue){
            this.$nextTick(() => {
          this.$refs.emailInput.focus();
        });
        }
    }
},
  

  mounted(){
    if(this.$props.flag == "edit"){
        if(this.isMicrosoftAccount){
            this.meetingData.attendees.map(item =>this.avatar.push(item.emailAddress.address)
)
        }else{
            this.meetingData.attendees.map(item => this.avatar.push(item.email))
        }
        this.handleAddEditData()
    }

    if(this.$props.flag == "create"){
    this.avatar.push(this.admin)
        if(this.tabType == "designSummary"){
            this.avatar.push(this.leadInfo.email)
        }else{
            if(this.leadData.lead_details.email){
            this.avatar.push(this.leadData.lead_details.email)
        }
        else{
            this.avatar.push(this.leadData.email)
        }
        }
    }
  },

  methods:{
    compareTimes,
    checkEmailValidation,
    onCloseClick(){
        this.$emit('onClickClose');
    },


   async  onOperationalButtonClick(){
    this.loading=true;
        const data = {
            summary:this.title,
            description:this.description,
            start_time:formatDateTime(this.date, this.startTime),
            end_time:formatDateTime(this.date, this.endTime),
            attendees:this.avatar,
            ...(this.isMicrosoftAccount
    ? {
        timeZone:DateTime.now().zoneName
    } 
    : {
        notify_via_email: this.isTeamSelected,
        notification_minutes: 15,
      }),
      
        }

        switch(this.$props.flag){
            case "create":
                try{
                    const response =this.isGoogleAccount? await API.EXTERNAL_INTEGRATION.CREATE_EVENT(data): await API.EXTERNAL_INTEGRATION.CREATE_MICROSOFT_EVENT(data);
                    const newUpdatedData = {
                        subject: response.data.data.summary,
                        organized_by : response.data.data.organizer.email,
                        attending_members : response.data.data.attendees ? response.data.data.attendees : [],
                        duration : response.data.data.start.dateTime ? calculateDuration(response.data.data.start.dateTime,response.data.data.end.dateTime ) : "N/A",
                        time : response.data.data.start.dateTime ?this.isMicrosoftAccount?formatDateForOutlook(response.data.data.start) : formatDate( response.data.data.start.dateTime) :   response.data.data.start.date,
                        date :   response.data.data.start.dateTime ? response.data.data.start.dateTime.split("T")[0] :  response.data.data.start.date,
                         start_time :  response.data.data.start.dateTime ?this.isMicrosoftAccount?convertToUTCForMicrosOft(response.data.data.start.dateTime) : convertToUTC(response.data.data.start.dateTime): response.data.data.start_time = "00:00",
                        end_time :  response.data.data.start.dateTime ?this.isMicrosoftAccount?convertToUTCForMicrosOft(response.data.data.end.dateTime) : convertToUTC(response.data.data.end.dateTime): response.data.data.end_time = "00:00",
                        description:response.data?.data?.description,
                        ...response.data.data,
                    } 
                    if(this.isMicrosoftAccount){
                        newUpdatedData['htmlLink']=response.data.data.webLink;
                        newUpdatedData['hangoutLink']=response.data.data.onlineMeeting.joinUrl;
                        newUpdatedData['organized_by']=response.data.data.organizer.emailAddress.address;
                        const htmlBody=response.data.data.bodyPreview.replace(/(\r\n[\s\S]*)/, "");
                        newUpdatedData['description']=htmlBody;
                    }
                    if(this.checkLeadEmailPresent()){
                        this.$emit("addMeeting", newUpdatedData)
                    }
                    this.$message({
                     showClose: true,
                    message: 'Meetings created successfully',
                    type: "success",
                    center: true
                    })
                }catch(err){
                    this.$message({
                showClose: true,
                message: 'Can not create meeting, please disconnect and Integrate again with all the meeting related permissions.',
                type: "error",
                center: true
                })
                console.log(err)    
                }
                break;
            case "edit":
            try{
                const response =this.isGoogleAccount? await API.EXTERNAL_INTEGRATION.EDIT_EVENT(this.$props.meetingData.id, data):await API.EXTERNAL_INTEGRATION.EDIT_MICROSOFT_EVENT(this.$props.meetingData.id, data)
                    const newUpdatedData = {
                        subject: response.data.data.summary,
                        organized_by : response.data.data.organizer.email,
                        attending_members : response.data.data.attendees ? response.data.data.attendees : [],
                        duration : response.data.data.start.dateTime ? calculateDuration(response.data.data.start.dateTime,response.data.data.end.dateTime ) : "N/A",
                        time : response.data.data.start.dateTime ?this.isMicrosoftAccount?formatDateForOutlook(response.data.data.start) : formatDate( response.data.data.start.dateTime) :   response.data.data.start.date,
                        date :   response.data.data.start.dateTime ? response.data.data.start.dateTime.split("T")[0] :  response.data.data.start.date,
                         start_time :  response.data.data.start.dateTime ? convertToUTC(response.data.data.start.dateTime): response.data.data.start_time = "00:00",
                        end_time :  response.data.data.start.dateTime ? convertToUTC(response.data.data.end.dateTime): response.data.data.end_time = "00:00",
                      ...response.data.data,
                    } 
                    this.$emit("updateMeeting", newUpdatedData)
                    this.$message({
                    showClose: true,
                    message: 'Meeting edited successfully',
                    type: "success",
                    center: true
                    })
                }catch(err){
                    this.$message({
                showClose: true,
                message: 'Can not edit meeting, please disconnect and Integrate again with all the meeting related permissions.',
                type: "error",
                center: true
                })
                console.log(err)    
                }
                break;
                default:
                break;
        }
        this.loading=false;
        this.onCloseClick()

    },

    handleAddEditData(){
        this.title = this.$props?.meetingData?.summary||this.$props?.meetingData?.subject
        this.date=this.$props.meetingData.date,
        this.startTime=this.$props.meetingData.start_time
        this.endTime=this.$props.meetingData.end_time
        this.description=this.$props.meetingData.description
    },
 

    handleAddAttendees(e){
            if(!checkEmailValidation(this.addedAttendees)){
                this.emailError = true
                return
            }
            this.avatar.push(this.addedAttendees)
            this.addedAttendees=""
            this.showEmailInputField = false
            this.emailError = false
            this.allowToAddFields = false
            setTimeout(() => this.allowToAddFields = true, 100)
    },

    handleRemoveError(){
        this.emailError = false
    },
    handleRemoveAttendees(item){
        if(item == this.admin){
            return
        }
      const updatedAvatars = this.avatar.filter(data => data !== item)
      this.avatar = updatedAvatars
    },

    handleKeyEvents(event){
        this.emailError = false
        if(event.key == "Enter"){
            this.handleAddAttendees()
        }
    },

    addNewEmailField(){
    this.emailError = false
        if(checkEmailValidation(this.addedAttendees)){
                return
            }
            if(this.allowToAddFields){
                this.addedAttendees = ""
                this.showEmailInputField = !this.showEmailInputField
            }else{
                this.allowToAddFields = true
                return
            }

    },

    handleEmailError(){
        if(this.emailError){
            return (
                {
                border: "1px solid red",
                color:"red"
            }
            )
            }else{
                return
            }
        },

        checkLeadEmailPresent(){
        let leadEmail = " "
        if(this.tabType == "designSummary"){
            leadEmail = this.leadInfo.email
        }else{
            leadEmail = this.leadData.lead_details ? this.leadData.lead_details.email :this.leadData.email
        }

        const toEmailCheck = this.avatar.includes(leadEmail)
        return toEmailCheck
    },


  }
};

</script>

<style  scoped>

.dialog >>> .el-dialog{
    border-radius: 8px;;
    padding-bottom: 10px
}

.dialog >>> .el-dialog__header{
    background-color: #E8EDF2;
    border-radius: 8px 8px 0px 0px;
}

.dialog >>> .el-dialog__title{
    color: black !important;
    width: 180px;
    margin-right: auto;
    font-size: 16px;
    font-weight: 600;
}

.dialog >>> .el-dialog__headerbtn .el-dialog__close{
    color: black  !important  ;
    font-size: larger;
    font-weight: bold;
}

.dialog >>> .el-dialog__headerbtn .el-dialog__close :hover{
    color: black  !important  ;
    font-size: larger;
    font-weight: bold;
}

.main-container{
    margin-top: 20px;
}

.main-container >>> .el-input__inner{
    border: none;
}

.main-container >>> .el-input__inner::placeholder{
   color: #777777;
   font-size: 16px;
   font-weight: 400;

}

.email_body_class >>> .el-textarea__inner{
    resize: none;
    height: 30vh;
    border: none;
}

.email_body_class >>> .el-textarea__inner::-webkit-scrollbar-thumb{
    cursor:pointer;
}

.email_body_class >>>.el-textarea__inner::placeholder{
   color: #777777;
   font-size: 16px;
   font-weight: 400;
}

.email_body_class {
border-bottom:1px solid #CCCCCC;
}

.main-title{
    font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 22px; /* 157.143% */
text-transform: capitalize;
color: #777777;

}

.input-class{
border-radius: 4px;
border: 1px solid var(--Grey-Scale-999, #999);
background: var(--White, #FFF);
width: 100%;
display: flex;
padding: 12px;
align-items: center;
gap: 8px;
align-self: stretch;
margin-top: 10px
}

.date-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.required-class{
    color: red;
}

.time-input {
    width: 100%;
}

.avatar-input{
    width: 100%; 
    border: 1.5px solid #999;
     padding: 1px 0px 1px 5px; 
     border-radius: 4px;
}

.editor{
    width: 100%;
    margin-top: 10px;
}

.bottom-left-container{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.bottom-email-format{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px
}

.bottom-text{
color: #000;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.image-placeholder{
cursor: pointer;
}

.image-class{
    cursor: pointer;
}

.email-header{
    display: flex;
    flex-wrap:wrap;
    align-items: center;
    gap:5px;
    border-bottom: 1px solid #CCCCCC;
    border-top:1px solid #CCCCCC; ;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.email-details-container{
    display: flex;
    width: 170px;
    padding: 10px;
    background-color:  #ECF5FF;
    margin-left: 10px;
    border: 1px solid #CCCCCC;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    cursor: pointer;
}

.email-send-to{
    color: #409EFF;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.close_image_class{
    height: 18px;
    width: 18px;
}
.add-more-email{
    display: flex;
    width: 35px;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: #777777;
    border: 1px solid #CCCCCC;
    margin-left: 10px;
    border-radius: 4px;
    cursor: pointer;
}

.input_email {
    padding: 10px;
    background-color: #ECF5FF;
    border: 1px solid #CCCCCC;
    color: #409EFF;
    margin-left: 10px;
    border-radius: 4px 0px 0px 4px;
    margin-right: -10px
}

.input-el::placeholder{
    color: red;
}

.input-el::-webkit-input-placeholder {
  color: #888888; /* Change this to your desired color */
}
</style>