<template>
  <div class="parentContainer" v-loading.fullscreen="isLoading">
    <el-drawer title="I am the title" :visible.sync="drawer" :with-header="false" :before-close="onClose" :size="computedDrawerSize"
      :append-to-body="true">
      <div class="loader-div" v-loading="!$props.lead" v-if="$props.lead === null"></div>
      <div v-if="$props.lead !== null" class="lead-drawer">
        <div class="headerLD">
          <el-button class="shareBtn" @click="isShareProposalPopupVisible = true">Share Proposal</el-button>
          <div class="header-left-buttons-container">
            <router-link
              :to="{
                name: 'leadSummary',
                params: { leadId: lead?.lead_details?.id },
              }"
              @click.native="onClickViewFullDetails"
            >
              <div class="fullDetContainer el-button el-button--primary">
                <p class="fullDet">View Full Details</p>
                <span style="font-size: 1.5em; display: flex; align-items: center;">
                  <b-icon icon="arrow-right-short"></b-icon>
                </span>
              </div>
            </router-link>
            <span class="cross" style="cursor: pointer" @click="onClose()">&#x2716;</span>
          </div>
        </div>
        <div class="detContainer" ref="detContainer">
          <div class="lead-details">
            <p class="shrtForm" :style="{
              backgroundColor: generateColorFromName(lead?.lead_details?.name) || '#1c3366',
            }">
             {{ extractInitials(lead?.lead_details?.name) }}
            </p>
            <div class="name-and-share-container">
              <p class="name">
                <abbr :title="lead?.lead_details?.name" class="abbrTag">
                  {{ lead?.lead_details?.name }}
                 
                </abbr>
              </p>
              <div class="icons-container">
         
              <img src="./assets/share.svg" class="shareIcon" @click="openShareProjectPopup()" />
              <el-tooltip
                  class="item"
                  effect="dark"
                  :content="leadDeleteTooltip"
                  placement="top"
                  :disabled="!disableLeadDelete"
                >
          <el-button type="text"  
          @click="deleteLeadConfirmation"
          :disabled="disableLeadDelete"
          >     
             <b-icon
        icon="trash"
        :style="{color: disableLeadDelete ? '#ccc' : '#777', marginLeft: '16px', transform: 'scale(1.5)'}"       
      ></b-icon>
    </el-button>
    
      </el-tooltip>
            </div>
            </div>
            <span class="res" style="display: flex;align-items: center;">({{ lead?.project_type }})  
             <span style="display: flex;align-items: center;" v-if="lead?.is_shared">                           
                     <img style="padding-left: 0.5rem; "    src="../../../assets/img/sharedIcon.svg" alt="">
              </span>
              <span style="display: flex;align-items: center;" v-if="(!lead?.is_shared&&lead?.lead_details?.workflow_type==='d2c_vip_external')">                           
                     <img style="padding-left: 0.5rem; "    src="../../../assets/img/bordered-building.svg" alt="">
              </span>
              </span>
            <div class="number-and-email">
              <span class="number">{{ lead?.lead_details?.phone }}</span>
              <div class="email">
                <img src="./assets/mail.svg" alt="">
                <span class="email-value">
                  {{ lead?.lead_details?.email }}
                </span>
              </div>
            </div>
            <p class="address">{{ lead?.address || "Unknown Address" }}</p>
          </div>
          <div class="gridContainer">
            <div class="boxOne">
              <p class="label">Owner</p>
              <div class="flexContainer4">
                <infiniteScrollUsers :user.sync="selectedUser" :isUserListDisabled="isPublicShared" :crmMode="true"
                  style="flex-grow: 1" @updatePMdata="updateOwnerInLead" />
              </div>
            </div>
            <div class="boxOne">
              <p class="label">Pipeline</p>

              <p style="min-height: 40px; display: flex; align-items: center">
                {{ handlePipeline(pipelineData) }}
              </p>
            </div>
            <div class="boxOne">
              <p class="label">Stage</p>
              <!-- <p>{{ lead.lead_details.stage }}</p> -->
              <el-select v-model="stageData" placeholder="Stages" @change="updateStageInLead">
                <el-option v-for="stage in handleStage(pipelineData)" :key="stage.id" :label="stage.name"
                  :value="stage.id">
                  {{ stage.name }}
                  <b-icon style="margin-left: 10px" :color="iconColor(stage)" :icon="showIcon(stage)"></b-icon>
                </el-option>
              </el-select>

              <div v-if="errorStage">
                <p style="color: red">This field is required</p>
              </div>
            </div>
            <div class="boxOne">
              <p class="label">Lead Source</p>
              <!-- <el-select v-if="(!lead?.is_shared||organisationData?.secondary_org_details.length>0)" v-model="lead.lead_details.lead_source_object" placeholder="Lead Source"
                @change="updateLeadSource()">
                <el-option v-for="item in leadSourceOptions" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select> -->
              <p  style="min-height: 40px; display: flex; align-items: center">
                {{ handleLeadSource(lead.lead_details.lead_source_object,lead) }}
              </p>
            </div>
            <div v-if="isRelianceOrg" class="boxOne">
              <p class="label">Region</p>
              <el-select v-model="lead.lead_details.region" placeholder="Region" @change="updateRegion()">
                <el-option v-for="item in regionsOptions" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="boxOne">
              <p class="label">Probability</p>
              <el-input placeholder="Probability" type="number" ref="inputElement" v-model="lead.lead_details.probability"
                @change="updateProbability" :min="0" :max="100" @input="validateInput">
                <template v-slot:append><span class="suffex">%</span></template>
              </el-input>
            </div>
          </div>
          <div style="padding: 0px 24px; margin-bottom: 16px">
            <p class="label">Tags</p>
            <Tags v-if="tagsFetched" :tagsInProps="lead.tags" :lead="lead" :isUsingStore="false" :currentRowNumber="currentRowNumber"
              @updated-tags="emitUpdatedTags" />
          </div>
          <!-- <div style="padding: 0px 24px 24px 24px"> -->
          <Tabs v-if="$props.lead !== null" :lead="lead" :activeTab="activeTab" :project_id="lead.id"
            :focusAddNote="focusNote" :isDrawer="true" :allLeadList="allLeadList" :allUserList="allUserList" />
        </div>
      </div>
    </el-drawer>
    <ShareProject v-if="$props.lead !== null" :shareDialogBoxVisible.sync="shareDialogBoxVisible" :project_id="lead.id" :lead="lead" />
    <ShareProposalPopup v-if="isShareProposalPopupVisible && $props.lead !== null"
      :isShareProposalPopupVisible.sync="isShareProposalPopupVisible" :lead="lead" />
    <DealValue v-if="dealCheck" :isDeleteProjectDocumentPopupOpen="true" :title="`Deal Value Check`"
      :info="`Deal value for this lead is ${currencySymbol}0.`" :info2="` Do you want to continue without updating it?`"
      @cancelDelete="cancelDelete" @confirmDelete="confirmDelete" />

      <div style="z-index:1000">
      <DeleteDialog :dialogVisible="deleteLeadConfirmationDialog" :footerButtonType="'primary'" :footerButtonText="'Yes'"
            :disableCancel="false" :width="'400px'" :deleteMessage="`Are you sure you want to delete this lead?`" @handleClick="handleDeleteLead" @handleClose="deleteLeadConfirmationDialog=false" 
            :dialogTitle="''" :infoMessage="batteryDetailsMessage"
            >
          </DeleteDialog>

        </div>
  </div>
</template>

<script>
import Tabs from "./tabs.vue";
import Tags from "./tags.vue";
import { mapActions, mapState } from "pinia";
import DealValue from "./dealValueCheckDialog.vue";
import { useProjectStore } from "../../../stores/project";
import { useLeadStore } from "../../../stores/lead";
import {
  isRelianceOrg,
  getLeadPipelineStages,
  getLeadSourceOptions,
  generateColorFromName,
  isCrmUser,
  getUiFromStorage,
  extractInitials,
  isAdmin,
  isDocProposalDisabled,
  consumptionPresent,
  leadDeleteToolTip,
} from "../../../utils";
import { useUiStore } from "../../../stores/ui";
import { getCurrencySymbol } from "../../../utils/numberFormat/currencyFormatter";
import ShareProject from "../../project/components/projectNameAndActions/shareProject.vue";
import API from "@/services/api/";
import infiniteScrollUsers from "@/components/ui/infiniteScrollDropdown/infiniteScrollUsers.vue";
import ShareProposalPopup from "../../../components/ui/shareProposalPopup.vue";
import { useMiscStore } from "../../../stores/misc";
import { useDashboardStore } from "../../../stores/dashboard";
import DeleteDialog from "../../dashboardCRM/components/dashboardV2/deleteDialog.vue";
import {useDocumentStore} from "../../../stores/documents";

export default {

  emits: [
    "update-stage",      
    "close",             
    "update-owner",      
    "update-kanban",     
    "update-dealvalue",  
    "update-probability",
    "update-region",     
    "update-lead-source",
    "updated-tags",      
    "deleted-lead",      
  ],

  components: {
    Tabs,
    Tags,
    DealValue,
    ShareProject,
    infiniteScrollUsers,
    ShareProposalPopup,
    DeleteDialog,
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    lead: {
      type: Object,
      default: () => {
        return {};
      },
    },
    activeTab: {
      type: String,
      default: "active",
    },
    currentRowNumber: {
      type: Number,
      default: 0,
    },
    focusNote: {
      type: Boolean,
      default: false,
    },
    isDrilldown: {
      type: Boolean,
      default: false,
    },
    widgetId: {
      type: Number,
      default: null,
    },
  },
  async created() {
    this.countryCode = JSON.parse(
      localStorage.getItem("organisation")
    ).country_code;
    if (this.lead !== null) {
      this.fetchProjectPermissionForLead();
      this.fetchCurrentLeadInfo();
      await this.setLeadFromData(this.lead);
    }
    // this.selectedUser = "sanchit";
  },
    mounted() {
    this.getPipeline();
    this.getLeadSource();
    this.getRegions();
    this.getTags();
    this.partnersName = this.lead?.lead_details?.partner_agent;  
    this.stagesData = JSON.parse(
      localStorage.getItem("ui")
    ).leadManagement.selectedPipeline;
    if (this.lead !== null) {
  this.GET_CURRENT_PROJECT(this.lead.id)
      this.oldStageValue = this.lead.lead_details.stage_object;
      this.oldPipelineValue = this.lead.lead_details.pipeline;
      this.oldSourcevalue = this.lead.lead_details.lead_source_object;
      this.setDrawerState("leadsDrawer", true);
      this.organisationData= JSON.parse(localStorage.getItem("organisation")) || {};
    }
  },
  destroyed() {
    this.setDrawerState("leadsDrawer", false);
  },
  data() {
    return {
      tagsFetched: false,
      dealCheck: false,
      isProceed: false,
      newStageID: "",
      currencySymbol: "",
      countryCode: "",
      busy: false,
      input: "",
      allLeadList: [],
      allUserList: [],
      pipelineData: "",
      stageData: "",
      errorStage: false,
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
      ],
      value: "",
      stagesData: {},
      selectedUser: {},
      oldSelectedUser: {},
      oldStageValue: null,
      oldPipelineValue: null,
      oldSourcevalue: null,
      isPublicShared: false,
      pipeline: getLeadPipelineStages(),
      leadSourceOptions: [],
      regionsOptions: [],
      shareDialogBoxVisible: false,
      isShareProposalPopupVisible: false,
      updateWidgets: false,
      organisationData:null,
      isLoading: false,
      deleteLeadConfirmationDialog:false,
      leadDeleteTooltip:'Contact your admin to delete this lead.',
      userData:JSON.parse(localStorage.getItem("user")) || {},
      disableLeadDelete:true,
      batteryDetailsMessage:null,
    };
  },

  computed: {
    ...mapState(useProjectStore, {
      projectPermissionObject: "GET_PERMISISON_OBJECT",
      designs: "GET_DESIGNS_DETAILS",
      siteSurveyData:(state)=>state.site_survey,
      project: (state) => state,
    }),
    ...mapState(useUiStore, {
       isMobileView : state => state.isMobileView,
      }),
    ...mapState(useDocumentStore,{
      signedContract:state=>state.signedContract,
    }),
    isRelianceOrg,
    computedDrawerSize() {
      if (this.isMobileView) {
        return "100%";
      } else {
        return "700px";
      }
    },
 
  },

  methods: {
    ...mapActions(useMiscStore, {
      setDrawerState: "SET_DRAWER_STATE",
    }),
    ...mapActions(useProjectStore, ["FETCH_PERMISSION_OBJECT","GET_CURRENT_PROJECT"]),
    ...mapActions(useLeadStore, {
      updateLead: "UPDATE_LEAD_FROM_DRAWER",
    }),
    ...mapActions(useLeadStore, {
      setLeadFromData: "SET_LEAD_FROM_DATA",
    }),
    ...mapActions(useDashboardStore, {
      setDashboard: "SET_DASHBOARD_DETAILS",
    }),
    async getTags() {
      try {
        const response = await API.PROJECTS.FETCH_PROJECT_TAGS(this.lead.id);
        this.lead.tags =response.data;
      } catch (e) {
        this.$message({
          showClose: true,
          message: "Error while fetching the Tags",
          type: "error",
          center: true,
        });
      }
      this.tagsFetched = true;
    },
    validateInput() {
      this.lead.lead_details.probability =
        this.lead.lead_details.probability.replace(/^0|[^0-9]/g, "");
      const input = this.lead.lead_details.probability;
      if (input < 0) {
        this.lead.lead_details.probability = 0;
      } else if (input > 100) {
        this.lead.lead_details.probability = 100;
      }
    },
    cancelDelete() {
      this.isDeleteProjectDocumentPopupOpen = false;
      this.dealCheck = false;
      this.stageData = this.oldStageValue.id;
      this.lead.lead_details.stage = this.oldStageValue.name;
      this.lead.lead_details.pipeline = this.oldPipelineValue;
      this.isProceed = false;
      this.$emit(
        "update-stage",
        this.oldStageValue.name,
        this.currentRowNumber,
        this.lead,
        this.oldStageValue,
        this.oldPipelineValue
      );
    },
    confirmDelete() {
      this.isDeleteProjectDocumentPopupOpen = false;
      this.dealCheck = false;
      this.isProceed = true;
      this.updateStageInLead(this.newStageID);
    },
    async getPipeline() {
      let ui = getUiFromStorage();
      let response = await API.LEADS.GET_PIPELINES();
      this.pipeline = response.data.pipelines;
      ui.leadManagement.pipeline = response.data.pipelines;
      ui.leadManagement.selectedPipeline = response.data.default_pipeline;
    },
    async getLeadSource() {
      try {
        let response = await API.CRMSETTINGS.FETCH_ALL_LEAD_SOURCE();
        this.leadSourceOptions = response.data;
      } catch (e) {
        this.$message({
          showClose: true,
          message: "There was an unknown error while fetching the Lead Sources",
          type: "error",
          center: true,
        });
      }
    },
    async getRegions() {
      try {
        let response = await API.CRMSETTINGS.FETCH_ALL_REGIONS();
        this.regionsOptions = response.data;
      } catch (e) {
        this.$message({
          showClose: true,
          message: "There was an unknown error while fetching the Regions",
          type: "error",
          center: true,
        });
      }
    },
    onClose() {
      this.$emit("close", false);
    },
    fetchCurrentLeadInfo() {
      const organisationInfo= JSON.parse(localStorage.getItem("organisation")) || {};
      const user = this.userData;
      const secondaryOrgs=organisationInfo?.secondary_org_details;
      const leadDetails={...this.lead,...this.lead.lead_details}
      if(this.lead?.is_shared){
        
        if(user?.organisation_id===  leadDetails?.owner?.org_id||user?.organisation_id===  leadDetails?.owner_org_id){  
          typeof leadDetails?.owner === "object"
        ? (this.selectedUser =   leadDetails?.owner?.name)
        : (this.selectedUser =  leadDetails?.owner);
      
       }
       else if(organisationInfo?.is_vip_aggregator){
          typeof leadDetails?.lead_details?.owner === "object"
        ? (this.selectedUser =   leadDetails?.lead_details?.owner?.name)
        : (this.selectedUser =  leadDetails?.lead_details?.owner);
       }       
       else{
        if(organisationInfo?.aggregator){
          let id=leadDetails.owner_org_id || leadDetails?.lead_details?.owner_org_id;
       const result=  secondaryOrgs.find(org=>org.id=== id );
       this.selectedUser= result?result?.name:'_';
        }else if(organisationInfo?.is_vip_aggregator){
          typeof leadDetails?.lead_details?.owner === "object"
        ? (this.selectedUser =   leadDetails?.lead_details?.owner?.name)
        : (this.selectedUser =  leadDetails?.lead_details?.owner);

        }
        else{
          this.selectedUser= organisationInfo?.primary_org_details?.name||'NA';
        }
       }
      
        // this.selectedUser='abc'
      }else{
        typeof leadDetails?.lead_details?.owner === "object"
        ? (this.selectedUser =   leadDetails?.lead_details?.owner?.name)
        : (this.selectedUser =  leadDetails?.lead_details?.owner);
      }
    },
    openShareProjectPopup() {
      if (isCrmUser() && !this.projectPermissionObject["share_project"]) {
        this.$toastr.e("You don't have permission to share this project");
        return;
      }
      this.shareDialogBoxVisible = true;
    },
    async fetchProjectPermissionForLead() {
      try {
        this.FETCH_PERMISSION_OBJECT(this.lead?.id);
      } catch (e) {
        console.error(e);
      }
    },
    async updateOwnerInLead(ownerId, selectedOwnerObj, oldSelectedOwnerObj) {
      this.lead.owner_org_id=selectedOwnerObj.organisation_id;
      if(this.lead?.lead_details.organisation_id!==selectedOwnerObj.organisation_id){
      this.lead.is_shared=true;
     }else{
      this.lead.is_shared=false;
     }
      const patchData = {
        owner: ownerId,
      };
      try {
        this.$emit(
          "update-owner",
          selectedOwnerObj,
          this.currentRowNumber,
          this.lead
        );
        this.$emit("update-kanban", this.lead, selectedOwnerObj);
      const dataresp=  await this.updateLead(
          this.lead.lead_details.id,
          this.lead.id,
          patchData
        );

        if (this.$props.isDrilldown) {
          let updatewidgersData = {
            id: this.$props.widgetId,
            flag: !this.updateWidgets,
          };
          this.setDashboard(updatewidgersData);
        }
      } catch (e) {
        this.$emit(
          "update-owner",
          oldSelectedOwnerObj,
          this.currentRowNumber,
          this.lead
        );
        this.selectedUser = JSON.parse(JSON.stringify(oldSelectedOwnerObj));
        this.$message({
          showClose: true,
          message: "There is an error while updating the information.",
          type: "error",
          center: true,
        });
      }
    },

    handlePipeline(ID) {
      const Pipeline = this.pipeline.find((pipeline) => pipeline.id == ID);
      return Pipeline ? Pipeline.name : ID;
    },

    updatePipelineInLead(val) {
      this.errorStage = true;
      let check = this.pipeline.filter((pipeline) => pipeline.id == val);
      let data = check[0].pipeline_stages.filter(
        (stage) => stage.stage_type == "open"
      );
      this.stagesData = {};
      this.stageData = "";
      this.stagesData = check[0];
    },
    handleStage(id) {
      const stage = this.pipeline.find((pipeline) => pipeline.id == id);
      return stage ? stage.pipeline_stages : {};
    },
    showIcon(id) {
      if (id.stage_type == "close") {
        if (id.close_stage_type == "won") {
          return "hand-thumbs-up";
        } else if (id.close_stage_type == "lost") {
          return "hand-thumbs-down";
        } else {
          return "dash-square";
        }
      }
    },
    iconColor(id) {
      let pipelineData = JSON.parse(localStorage.getItem("ui"));
      if (id.stage_type == "close") {
        if (id.close_stage_type == "won") {
          return "green";
        } else if (id.close_stage_type == "lost") {
          return "red";
        } else {
          return "grey";
        }
      }
    },
    async updateStageInLead(selectedStageId) {
      this.newStageID = selectedStageId;
      this.oldStageValue = this.lead.lead_details.stage_object;
      const selectedStage = this.pipeline.find(
        (pipeline) => pipeline.id === this.pipelineData
      );
      const stage = selectedStage.pipeline_stages.find(
        (stage) => stage.id === selectedStageId
      );
      this.oldStageValue = selectedStage.pipeline_stages.find(
        (stage) => stage.id === this.oldStageValue
      );
      this.currencySymbol = getCurrencySymbol(
        this.lead.country ? this.lead.country.country_code : this.countryCode
      );
      if (
        this.lead.lead_details.deal_value <= 0 &&
        stage.close_stage_type == "won" &&
        this.isProceed == false
      ) {
        this.dealCheck = true;
      } else {
        this.isProceed = false;
        this.lead.lead_details.stage = stage.name;
        this.lead.lead_details.pipeline = this.pipelineData;
        this.lead.lead_details.stage_object = this.stageData;
        const patchData = {
          pipeline: this.lead.lead_details.pipeline,
          stage_object: this.lead.lead_details.stage_object,
        };
        this.$emit(
          "update-stage",
          this.lead.lead_details.stage,
          this.currentRowNumber,
          this.lead,
          this.oldStageValue.name,
          this.oldPipelineValue
        );
        this.errorStage = false;
        try {
          await this.updateLead(
            this.lead.lead_details.id,
            this.lead.id,
            patchData
          );
          this.$emit('update-dealvalue');
          if (this.$props.isDrilldown) {
            let updatewidgersData = {
              id: this.$props.widgetId,
              flag: !this.updateWidgets,
            };
            this.setDashboard(updatewidgersData);
          }
        } catch (e) {
          this.lead.lead_details.stage = this.oldStageValue.name;
          this.lead.lead_details.pipeline = this.oldPipelineValue;
          this.$emit(
            "update-stage",
            this.oldStageValue.name,
            this.currentRowNumber,
            this.lead,
            this.oldStageValue,
            this.oldPipelineValue
          );
          this.$message({
            showClose: true,
            message: "There was an error while updating the stage.",
            type: "error",
            center: true,
          });
        }
      }
    },
    async updateProbability(val) {
      this.lead.lead_details.probability = parseInt(val);
      this.$refs.inputElement.blur();
      const patchData = {
        probability: this.lead.lead_details.probability|| null,
      };
      this.$emit(
        "update-probability",
        this.lead.lead_details.probability,
        this.currentRowNumber,
        this.lead
      );
      try {
        await this.updateLead(
          this.lead.lead_details.id,
          this.lead.id,
          patchData
        );
        this.oldSourcevalue = this.lead.lead_details.probability;
      } catch (error) {
        this.$message({
          showClose: true,
          message: "There was an error while updating the Lead.",
          type: "error",
          center: true,
        });
      }
      this.$emit(
        "update-probability",
        this.oldSourcevalue,
        this.currentRowNumber,
        this.lead
      );
    },
    async updateRegion() {
      const patchData = {
        region: this.lead.lead_details.region,
      };
      this.$emit(
        "update-region",
        this.lead.lead_details.region,
        this.currentRowNumber,
        this.lead
      );
      try {
        await this.updateLead(
          this.lead.lead_details.id,
          this.lead.id,
          patchData
        );
        this.oldSourcevalue = this.lead.lead_details.region;
      } catch (error) {
        this.$message({
          showClose: true,
          message: "There was an error while updating the Lead.",
          type: "error",
          center: true,
        });
      }
      this.$emit(
        "update-region",
        this.oldSourcevalue,
        this.currentRowNumber,
        this.lead
      );
    },
    async updateLeadSource() {
      const patchData = {
        lead_source_object: this.lead.lead_details.lead_source_object,
      };
      this.$emit(
        "update-lead-source",
        this.lead.lead_details.lead_source_object,
        this.currentRowNumber,
        this.lead
      );
      try {
        await this.updateLead(
          this.lead.lead_details.id,
          this.lead.id,
          patchData
        );
        if (this.$props.isDrilldown) {
          let updatewidgersData = {
            id: this.$props.widgetId,
            flag: !this.updateWidgets,
          };
          this.setDashboard(updatewidgersData);
        }
        this.oldSourcevalue = this.lead.lead_details.lead_source_object;
      } catch (error) {
        this.$message({
          showClose: true,
          message: "There was an error while updating the Lead.",
          type: "error",
          center: true,
        });
      }
      this.$emit(
        "update-lead-source",
        this.oldSourcevalue,
        this.currentRowNumber,
        this.lead
      );
    },
    emitUpdatedTags(totalTags, index) {
      this.$emit("updated-tags", totalTags, index);
      this.lead.tags = totalTags
    },
    generateColorFromName,
    isCrmUser,
    handleLeadSource(id,leadDatas){
      const leadData={...leadDatas,...leadDatas?.lead_details}
      const organisationInfo= JSON.parse(localStorage.getItem("organisation")) || {};
      const user = this.userData;
      const secondaryOrgs=organisationInfo?.secondary_org_details;
      let lead= this.leadSourceOptions.find((lead)=> lead.id==id)
      if(leadData?.is_shared){
       if(user?.organisation_id===leadData?.organisation_id){
        return lead ? lead.name : id||'NA';
      
       }else{
        if(organisationInfo?.aggregator){
          const result=  secondaryOrgs.find(org=>org.id===leadData?.organisation_id);
          return result?result?.name:'NA';
        }else{
          return organisationInfo?.primary_org_details?.name||organisationInfo?.arka_sales_details?.name|| 'NA';
        }
       }


      }else{
        return lead ? lead.name : id || 'NA'

      }

     
    },
    onClickViewFullDetails() {
      this.isLoading = true
    },
    extractInitials,
    isAdmin,
    deleteLeadConfirmation(){
   this.deleteLeadConfirmationDialog=true
    },
   async handleDeleteLead(){
    try{
      this.isLoading=true;
      let resp= await API.LEADS.DELETE_LEAD(this.$props.lead.lead_details.id);
      this.$emit("deleted-lead",this.$props.lead);
      this.$message({
          showClose: true,
          message: "Lead successfully removed. For recovery within 30 days, contact our customer success team.",
          type: "success",
          center: true,
        });
      this.isLoading=false;
    }catch(e){
      this.isLoading=false;
      this.$message({
          showClose: true,
          message: "Error while deleting the Lead",
          type: "error",
          center: true,
        });
    }

    },
    handleLeadDeleteDisable(){
   const siteSurveyGenerated=this.siteSurveyData?.length>0?false:true;
   const isDesignCheck=this.designs.find((design)=>(design?.request_expert_service?.order_status))?false:true;
   const isLeadFromSameOrg=this.lead?.lead_details?.organisation_id===this.userData?.organisation_id;
   const isSelfDesignHasProposal=this.designs.filter((design)=>!(design?.request_expert_service?.order_status));
   let isProposalGenerated=false;
   const isBatteryDetailsAvilabvle=isSelfDesignHasProposal.find(b=>b.battery_detail.length>0);
   const isSignedContract=this.signedContract?.length>0?false:true;
   if(isBatteryDetailsAvilabvle){
    this.batteryDetailsMessage="This lead contains battery, reverting of lead with battery won't restore battery.";
   }else{
    this.batteryDetailsMessage=null;
   }
   if(isSelfDesignHasProposal?.length>0){
     isProposalGenerated= isSelfDesignHasProposal.find(d=>(isDocProposalDisabled(d)==false||(d.system_type=='ess_only_design'&&(!d?.versions?.basic_cost || !consumptionPresent(this.project))==false)))?false:true;
   }else{
    isProposalGenerated=true;
   }

if(this.isAdmin()){
  if(isLeadFromSameOrg){
    this.leadDeleteTooltip="This lead cannot be deleted as ";
    const conditionStr =leadDeleteToolTip(isProposalGenerated,siteSurveyGenerated,isDesignCheck,isSignedContract)
    this.leadDeleteTooltip+=conditionStr.join(" , ")
  }else{
    this.leadDeleteTooltip="This lead cannot be deleted as it is a shared lead."
  }

}else{
  this.leadDeleteTooltip="Contact your admin to delete this lead."

}
      this.disableLeadDelete=(this.isAdmin()&&isLeadFromSameOrg&&isDesignCheck&&isProposalGenerated&&siteSurveyGenerated&&isSignedContract) ? false : true;
    }
  
  },
  watch: {
    selectedUser: {
      deep: true,
      immediate: true,
      handler(value, oldValue) {
        if (value?.id) {
          if(!value.is_project_manager){
            this.updateOwnerInLead(value.id, value, oldValue);
          }
        }
      },
    },
    drawer(newDrawer) {
      if (newDrawer) {
        this.setDrawerState("leadsDrawer", true);
      } else {
        this.setDrawerState("leadsDrawer", false);
      }
    },
    lead: {
      deep: true,
      immediate: true,
      async handler(newVal, oldVal) {
        this.lead=newVal
        this.pipelineData = this.$props.lead.lead_details.pipeline;
        this.stageData = this.$props.lead.lead_details.stage_object;
        let response = await API.LEADS.GET_PIPELINES();
        this.pipeline = response.data.pipelines;
        if (!oldVal) {
          // To scroll to the top when the drawer is opened (especially on iPads).
          this.$refs.detContainer.scrollTop = 0
        }
        if(newVal.owner!==oldVal?.owner){
          this.fetchCurrentLeadInfo();
        }
        await this.fetchProjectPermissionForLead();
        await this.setLeadFromData(this.$props.lead);
      },
    },
    design:{
      handler(val){
          this.handleLeadDeleteDisable()
      }
    },
    siteSurveyData:{
      handler(val){
          this.handleLeadDeleteDisable()
      }
    },
    signedContract:{
      handler(val){
        this.handleLeadDeleteDisable();        
      }
    }
  },
};
</script>

<style scoped>
/* .parentContainer {
  position: fixed;
  width: 700px;
  height: calc(100vh);
  top: 0px;
  right: 0px;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
} */

p,
span,
h1,
h2,
h3 {
  word-break: break-word;
}

.parentContainer>>>.el-drawer__body {
  /* overflow: hidden; */
  display: grid;
  grid-template-rows: max-content auto;
}

.parentContainer>>>.el-drawer {
  max-width: 760px;
  min-width: 650px;
  height: 100vh;
}

.headerLD {
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid #ccc;
}

.header-left-buttons-container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.shareBtn {
  padding: 12px 12px;
  border-color: #999999;
}

.fullDetContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 10px;
}

.detContainer {
  overflow-y: auto;
}

.fullDet {
  font-size: 12px;
}

.lead-details {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 16px;
  row-gap: 8px;
  padding: 24px;
}

.name-and-share-container {
  display: flex;
  justify-content: space-between;
}

.shrtForm {
  width: 60px;
  height: 60px;
  display: grid;
  place-items: center;
  font-size: 26px;
  color: #fff;
  background-color: #ef6c00;
  border-radius: 50%;
  grid-row: 1 / 5;
}

.name {
  font-size: 24px;
  font-weight: bold;
  color: #222;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.abbrTag {
  text-decoration: none;
}

.boxOne>>>.el-input-group__append,
.el-input-group__prepend {
  background-color: #e8edf2;
  color: #909399;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 1px solid transparent;
  /* border-radius: 4px; */
  padding: 0 5px;
  width: 1px;
  white-space: nowrap;
}

.res {
  font-size: 16px;
  color: #777;
  text-transform: capitalize;
}

.number-and-email {
  display: flex;
  gap: 8px;
  overflow: auto;
}

.number,
.email,
.address {
  font-size: 14px;
  color: #777;
  display: flex;
  gap: 8px;
  align-items: center;
}

.email {
  overflow: auto;
}

.email-value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.number {
  white-space: nowrap;
}

.address {
  display: grid;
  grid-template-columns: 20px auto;
  align-items: flex-start;
}

.number::before {
  content: "";
  background: url("./assets/call.svg");
  min-width: 20px;
  height: 20px;
  display: block;
}

.address {
  font-size: 16px;
}

.address::before {
  content: "";
  background: url("./assets/distance.svg");
  width: 20px;
  height: 20px;
  display: block;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  margin-bottom: 8px;
  padding: 0px 24px 0px 24px;
}

.flexContainer5 {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;
}

.label {
  font-size: 14px;
  color: #777;
  margin-bottom: 4px;
}

.flexContainer4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.ownerName {
  font-size: 16px;
  color: #222;
}

.shrtFormSmall {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  font-size: 14px;
  color: #fff;
  background-color: #1c3366;
  border-radius: 50%;
}

.tagsContainer {
  margin-bottom: 24px;
}

.flexContainer6 {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 4px;
}

.tags {
  padding: 4px 12px;
  border-radius: 50px;
  background-color: #e8edf2;
  color: #222;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.el-select-dropdown__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addIcon,
.crossIcon,
.shareIcon {
  cursor: pointer;
}

.gridContainer>>>.el-input__inner {
  border: none;
  background-color: #e8edf2;
  height: 40px;
  font-size: 16px;
  color: #222;
  text-overflow: ellipsis;
}

.gridContainer>>>.el-select .el-input .el-select__caret {
  color: #222;
  font-weight: bold;
  position: relative;
  top: 2px;
  transform: rotate(0deg);
}

.gridContainer>>>.el-select .el-input .el-select__caret.is-reverse {
  position: relative;
  top: 0px;
  transform: rotate(180deg);
}

.gridContainer>>>.el-icon-arrow-up:before {
  content: url("./assets/CaretDownFill.svg");
}

.gridContainer>>>.el-input__inner::placeholder {
  font-size: 14px;
  /* color: #222; */
}

.parentContainer>>>.el-select {
  max-width: inherit !important;
}

.shareIcon {
  cursor: pointer;
}

.loader-div {
  margin-top: 50vh;
}

.lead-drawer {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.icons-container{
  display: flex;
  align-items: center;
}
</style>
