
<div class="deleteModule" v-if="isShareDocumentPopupVisible">
  <el-dialog
    :visible="true"
    :close-on-click-modal="false"
    title="Add User"
    class="delete_module"
    append-to-body
  >
    <div class="container">
      <div class="Rectangle">
        <p v-if="requestedServiceType" class="rectContent">
          {{ requestedServiceType }}
        </p>
        <p v-if="!requestedServiceType" class="rectContent">
          Upload Documents
        </p>
        <button
          class="modal-close modal-toggle"
          @click="$emit('update:isShareDocumentPopupVisible', false)"
        >
          <i class="el-dialog__close el-icon el-icon-close"></i>
        </button>
      </div>

      <div
        class="contContainer"
        id="fileContainer"
        @dragover="dragover"
        @drop="drop"
      >
        <!-- <h3 class="containerHeading">Share Documents (Optional)</h3> -->
        <h3 class="containerNote">
          The maximum file size can not exceed 50MB.
        </h3>
        <div class="gridContainer">
          <div class="conditionCont">
            <div class="card" v-for="(file, index) in fileList" :key="index">
              <!-- <img
                src="../../../assets/drop/x-circle-fill.svg"
                class="crossIcon"
                @click="remove(index, file)"
              /> -->
              <img
                :src="
                  fileUploadAssets[
                    `/src/assets/drop/fileUploadImages/${findFileTypeIcon(
                      file
                    )}`
                  ]
                "
              />
              <p class="contentSD" v-if="file.display_name">
                <abbr :title="file.name" class="abbrTag">{{
                  file.display_name
                }}</abbr>
              </p>
              <p class="contentSD" v-else>
                <abbr :title="file.name" class="abbrTag">{{
                  file.name
                }}</abbr>
              </p>
            </div>
            <fileUpload
              @openFiles="openFiles"
              :key="componentKey"
            ></fileUpload>
          </div>
        </div>
      </div>

      <div
        class="footer"
        v-if="$route.name != 'projectSummary' && isCRMMode == false"
      >
        <p class="footerStep">
          Step {{ currentStepInProp
          }}<span class="unBold">/{{ totalSteps }}</span>
        </p>
        <div class="notesBtn">
          <el-button
            class="backBtn"
            @click="$emit('closeShareDocumentPopup', 'previous')"
            :disabled="isExecuting"
            >Back</el-button
          >
          <el-button
            :loading="isExecuting"
            type="primary"
            class="submitBtn"
            @click="handleSave"
            >Save
          </el-button>
        </div>
      </div>
      <div class="footer" v-if="isCRMMode || $route.name == 'projectSummary'">
        <div></div>
        <el-button
          :loading="isExecuting"
          type="primary"
          class="submitBtn"
          @click="saveDocuments(false)"
          >Save</el-button
        >
      </div>
    </div>
  </el-dialog>
</div>
