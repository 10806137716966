<template>
  <div 
    class="task-card" 
    id="task-card" 
    @click="selectRow"
    :style="{ border: selected, margin: taskIndex !== 0 ? '1rem 0rem' : 0, marginBottom: 0 }"
  >
    <el-row class="wrapper" v-if="page == 'Leads'">
      <el-row class="wrapper-avatar">
        <el-col :span="4" class="avatar">
          <el-avatar :icon="task.name ? '' : 'el-icon-user-solid'" :style="{
            backgroundColor: task.color ? task.color : '#1c3366',
            color: 'white',
          }" :src="''">
            <!-- {{ task.name ? task.name.match(/\b(\w)/g).join("") : "" }} -->
            {{ task.name ? extractLetters(task.name) : "" }}
          </el-avatar>
        </el-col>

        <el-col :span="(task?.is_shared||task.lead_details?.workflow_type==='d2c_vip_external')?17:19" class="user-container">
          <p class="name-container" style="
              display: flex;
              justify-content: flex-start;
              align-items: center;
            ">
            <!-- <span
              ><p
                class="overfolw-ellipsis user-name"
                style="max-width: 7.5rem !important"
              >
                {{ task.name ? task.name : "NA" }}
              </p></span
            > -->
            <el-tooltip placement="top">
              <template v-slot:content>
                <div>
                  <span>
                    <p class="lead-name" style="max-width: 7.5rem !important">
                      {{ task.name ? task.name : "NA" }}
                    </p>
                  </span>
                </div>
              </template>
              <span>
                <p class="overfolw-ellipsis lead-name" style="max-width: 7.5rem !important">
                  {{ task.name ? task.name : "NA" }}
                </p>
              </span>
            </el-tooltip>

            <span class="project-type"> {{ `(${task.project_type})` }}</span>
          </p>

          <p class="time">{{ task.created_at }}</p>
        </el-col>
        <el-col :span="2" v-if="task?.is_shared">
          <div>
        <img  style="border-radius: 0 !important;width:auto !important;height: auto !important;" src="../../../../assets/img/sharedIcon.svg" alt="">
      </div>  
      </el-col>
      <el-col :span="2" v-if="(!task?.is_shared&&task.lead_details?.workflow_type==='d2c_vip_external')">
          <div>
        <img  style="border-radius: 0 !important;width:auto !important;height: auto !important;" src="../../../../assets/img/bordered-building.svg" alt="">
      </div>  
      </el-col>
      </el-row>
      <div class="container">
        <el-row class="leadDetcolumn">
          <el-col :span="20" :class="{ 'disabled-content': !(task?.lead_details?.owner_id == ownerId)}"  @click.native.stop="handleReminder">
            <p class="remainder overfolw-ellipsis overfolw-texts">
              {{
                task.reminder_details
                ? dateTimeFormater(task.reminder_details.reminder_sent_at)
                : "Set Reminder"
              }}
            </p>
          </el-col>
        </el-row>
        <el-row class="leadDetcolumn">
          <el-col :span="10" class="body-content">
            <p class="phone">{{ task.phone }}</p>
          </el-col>
        </el-row>
        <el-row class="leadDetcolumn">
          <el-col :span="10" class="body-content">
            <p class="email">
              <span>
                <p class="overfolw-ellipsis overfolw-texts" style="font-size: 0.85rem">
                  {{ task.email }}
                </p>
              </span>
            </p>
          </el-col>
        </el-row>
        <el-row class="leadDetcolumn" v-if="isSOMUsers() && task.d2c_products.length > 0 ">
          <el-col :span="12" class="body-content">
            <div class="d2c-products-container">
              <span v-for="(product, index) in displayedProducts" :key="index" class="d2c-product-pill">
                {{ product }}
              </span>
              <el-tooltip v-if="remainingCount > 0" placement="bottom" effect="dark">
                <template v-slot:content>
                  <div>
                    <p
                      v-for="(product, index) in remainingProducts"
                      :key="index"
                    >
                      {{ product}}
                    </p>
                  </div>
                </template>
                <span class="d2c-product-pill">
                +{{ remainingCount }}
                </span>
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
            
      </div>
    </el-row>
    <el-row class="wrapper" v-else-if="page == 'Task'">
      <el-row class="wrapper-avatar">
        <el-col v-if="task.status == 'new'" @click.native.stop="handleComplete" :span="2" class="avatar">
          <img style="margin: 0; width:13px;height: 16px;" src="../assets/checkCircle.svg" alt="">
        </el-col>
        <el-col v-if="task.status == 'in_progress'" @click.native.stop="handleComplete" :span="2" class="avatar">
          <img style="margin: 0; width:13px;height: 16px;" src="../assets/in_progress.svg" alt="">
        </el-col>
        <el-col v-if="task.status == 'completed'" @click.native.stop="handleComplete" :span="2" class="avatar">
          <img style="margin: 0; width:13px;height: 16px;" src="../assets/completeCheckBox.svg" alt="">
        </el-col>

        <el-col :span="19" style="margin-bottom: 0px" class="user-container">
          <el-tooltip placement="top" :content="task.name">
            <div class="ellipsisDiv" style="max-height: 37px; ">
              <p style="font-size: 14px;text-overflow: ellipsis !important; margin: 0; color: #1C3366;white-space: nowrap; overflow: hidden;" ref="taskName">
                {{ task.name ? task.name : "NA" }}
              </p>
            </div>
          </el-tooltip>
        </el-col>

      </el-row>
      <div class="container">
        <el-row style="gap:0; padding-left: 0;" class="leadDetcolumn">
          <el-col :span="20" @click.native.stop="handleClick(task.lead_details.id)">
            <p class="personBadge overfolw-ellipsis overfolw-texts">
              {{
                task.lead_details.name
              }}
            </p>
          </el-col>
        </el-row>
        <el-row style="gap:0; padding-left: 0;" class="leadDetcolumn">
          
            <el-col v-if="isToday(task.due_by) && task.status !== 'completed'" :span="10"
              style="display:flex; justify-content: space-between; width:100% ;height: 15px;" class="body-content"
              @click.native.stop="emitUpdatedDate">
              <el-tooltip content="Due Date" placement="top" style="margin-right:100px">
              <el-date-picker v-model="task.due_by" class="dueCalender" type="date" unlink-panels
                popper-class="date-picker-popper" :format="'MMM dd, yyyy'" @change="emitUpdatedDate"  :picker-options="pickerOptions">
              </el-date-picker>
            </el-tooltip>
            <el-tooltip :content="task.nudgeTooltipText" placement="top" @click.native.stop="nudgeNotification(task)">
              <img style="width:15px; height: 13px;" src="../assets/Nudge Icon.svg">
            </el-tooltip>
            </el-col>
            <el-col v-else-if="isToday(task.due_by) && task.status == 'completed'" :span="10"
              style="display:flex; justify-content: space-between; width:100% ;height: 15px;" class="body-content"
              @click.native.stop="emitUpdatedDate">
              <el-tooltip content="Due Date" placement="top" style="margin-right:100px">
              <el-date-picker v-model="task.due_by" class="calenderComplete" type="date" unlink-panels  :picker-options="pickerOptions"
                popper-class="date-picker-popper" :format="'MMM dd, yyyy'" 
                @change="emitUpdatedDate">
              </el-date-picker>
            </el-tooltip>
            <el-tooltip :content="task.nudgeTooltipText" placement="top" @click.native.stop="nudgeNotification(task)">
              <img style="width:15px; height: 13px;" src="../assets/Nudge Icon2.svg">
            </el-tooltip>
            </el-col>
            <el-col v-else :span="10" style="display:flex; justify-content: space-between; width:100% ; height: 15px;"
              class="body-content" @click.native.stop="emitUpdatedDate">
              <el-tooltip content="Due Date" placement="top" style="margin-right:100px">
              <el-date-picker v-model="task.due_by" class="calender" type="date" unlink-panels
                popper-class="date-picker-popper" :format="'MMM dd, yyyy'"  @change="emitUpdatedDate"  :picker-options="pickerOptions">
              </el-date-picker>
            </el-tooltip>
            <el-tooltip :content="task.nudgeTooltipText" placement="top" @click.native.stop="nudgeNotification(task)">
              <img style="width:15px; height: 13px;" src="./assets/bell_icon.svg">
            </el-tooltip>
            </el-col>
        </el-row>
        <el-row style="gap:0; padding-left: 0;" class="leadDetcolumn">
          <el-col v-if="task.status !== 'completed'" :span="10" class="body-content" @click.native.stop="openMap(task.lead_details.address)">
            <el-tooltip
              content="Open in Google Maps"
              placement="top"
              :disabled="!task.lead_details.address"
            >
              <p class="location" >
                <span>
                  <p class="overfolw-ellipsis overfolw-texts" style="font-size: 0.85rem">
                    {{ task.lead_details.address || "Unknown Address" }}
                  </p>
                </span>
              </p>
            </el-tooltip>
          </el-col>
          <el-col v-else :span="10" class="body-content"  @click.native.stop="openMap(task.lead_details.address)">
            <p class="locationComplete">
              <span>
                <p class="overfolw-ellipsis overfolw-texts" style="font-size: 0.85rem">
                  {{ task.lead_details.address }}
                </p>
              </span>
            </p>
          </el-col>
        </el-row>
        <el-row style="gap:0; padding-left: 0;" class="leadDetcolumn">
          <el-col :span="10" style="width:100%; display:flex; justify-content: space-between;" class="body-content">
            <div v-if="(admin == 'ADMIN' || manager == true ) " style="width:25%">
              <AvatarHandler :avatars="task.assignees" :overlap="true" :gap="5" :editable="false" :imgWidth="30"
                :fontSize="'12px'" :hover="false" :limit="1" :font-size="'20px'" :removable="false" />
            </div>
            <div>
              <el-col @click.native.stop="handlePriority">

                <div v-if="task.status === 'completed' && task.priority === 'high'"
                  style="display:flex; align-items:center; justify-content:space-between; pointer-events: none;">
                  <img style="height: 32px; width:18.25px; margin: 0; ;" src="../assets/FlagDisabled.svg" alt="">
                  <p style="margin-left: 5px;color:#cccccc">Urgent</p>
                </div>
                <div v-else-if="task.status === 'completed' && task.priority === 'default'"
                  style="display:flex; align-items:center; justify-content:space-between; pointer-events: none;">
                  <img style="height: 32px; width:18.25px; margin: 0; ;" src="../assets/default_priority.svg" alt="">
                  <p style="margin-left: 5px;color:#cccccc">Default</p>
                </div>
                <div v-else-if="task.priority == 'default'"
                  style="display:flex; align-items:center; justify-content:space-between">
                  <img style="height: 32px; width:18.25px; margin: 0; ;" src="../assets/Priority.svg" alt="">
                  <p style="margin-left: 5px;color:#777777">Default</p>
                </div>
                <div v-else style="display:flex; align-items:center; justify-content:space-between">
                  <img style="height: 32px; width:18.25px;  margin: 0;" src="../assets/FlagFill.svg" alt="">
                  <p style="color: red;margin-left: 5px;">Urgent</p>
                </div>
              </el-col>
            </div>
            <div v-if="task.status !== 'completed'"
              style="display:flex; align-items:center; justify-content: space-between;">
              <el-tooltip      class="item"
                  effect="dark"
                  content="Documents"
                  placement="top-start">
              <p style="margin-left: 5px;" class="documents overfolw-ellipsis overfolw-texts">
                {{ task.documents_count }}
              </p>
            </el-tooltip>
                <el-tooltip      class="item"
                  effect="dark"
                  content="Comments"
                  placement="top-start">
              <p style="margin-left: 5px;" class="chat overfolw-ellipsis overfolw-texts">
                {{ task.comments_count }}
              </p>
              </el-tooltip>
            </div>
            <div v-else style="display:flex; align-items:center; justify-content: space-between;">
              <el-tooltip      class="item"
                  effect="dark"
                  content="Documents"
                  placement="top-start">
              <p style="margin-left: 5px;" class="documentsComplete overfolw-ellipsis overfolw-texts">
                {{ task.documents_count }}
              </p>
              </el-tooltip>
          
                <el-tooltip      class="item"
                  effect="dark"
                  content="Comments"
                  placement="top-start">
              <p style="margin-left: 5px;" class="chatComplete overfolw-ellipsis overfolw-texts">
                {{ task.comments_count }}
              </p>
              </el-tooltip>
            </div>

          </el-col>
        </el-row>
      </div>
    </el-row>
    <el-row class="wrapper" v-else>
      <el-row class="spacing">
        <el-col :span="15" class="first-content">
          <p class="label">{{ task.id }}</p>
        </el-col>
        <el-tooltip content="Order type" placement="top" effect="light">
          <el-col :span="9" class="addition-section">
            <el-tag class="tag" :type="task.color" size="mini">
              {{ task.service_type }}
            </el-tag>
          </el-col>
        </el-tooltip>
      </el-row>
      <el-row class="spacing">
        <el-col :span="24" class="first-content">
          <el-tooltip class="item" effect="light" :content="isOrderPowerModel(task)" placement="top-start">
            <img
              v-if="isOrderPowerModel(task)"
              src="../../../../assets/img/gazeboIcons.svg"
              style="width:13.5px;height:13.5px;margin-left:0;margin-right:8px;"
            />
          </el-tooltip>
          <el-tooltip placement="bottom">
            <template v-slot:content>
              <div>
                <el-col :span=20>
                  <p class="label-name">
                    <span style="color:white">{{ task.name }}</span>
                  </p>
                </el-col>
              </div>
            </template>
            <p class="label-name overfolw-ellipsis" style="display: flex; align-items: center;">
              {{ task.name }}
            </p>
          </el-tooltip>
        </el-col>
      </el-row>
      <el-row class="spacing">
        <el-tooltip class="item" effect="light" :content="'Ordered on'" placement="top-start">
          <el-col :span="20" class="first-content">
            <!-- <span
            ><i class="el-icon-user icon-size" style="padding-right: 0.5rem"></i
          ></span> -->

            <el-col :span="3">
              <img src="../assets/opendoor.svg" alt="" style="width: 13.5px;height: 13.5px;margin-left: 0; " />
            </el-col>
            <el-col :span="20">
              <p class="label">{{ task.order_placed_at }}</p>
            </el-col>
          </el-col>
        </el-tooltip>
      </el-row>
      <el-row class="spacing" >
        <el-tooltip class="item" effect="light"  :content="getTooltipContent(task.order_status)" placement="top-start">
          <el-col :span="20" class="first-content">
            <el-col :span="3">
              <img src="../assets/warning.svg" alt="" style="  width: 8px; height: 13px; margin-left: 0;" />
            </el-col>
            <el-col :span="20">
              <p v-if="task.order_status=='complete' ||task.order_status=='cancelled' ||task.order_status=='rejected' " class="label">
                {{ task.modified_at}}
              </p>
              <p v-else class="label" :style="{ color: task.due_date.color }">
                {{ task.due_date.label}}
              </p>
            </el-col>
          </el-col>
        </el-tooltip>

        <el-col :span="3" class="addition-section">
          <p v-if="task.due_date.color" :style="{ color: task.due_date.color }">
            &#9679;
          </p>
        </el-col>
      </el-row>
      
      <el-row class="spacing">
        <el-tooltip class="item" effect="light" :content="selectedColumnType === 'order_status'
          ? 'Delivery type'
          : 'Order type'
          " placement="top-start">
          <el-col :span="20" class="first-content">
            <el-col :span="3">
              <img src="../assets/info.svg" alt="" style=" width: 13.5px;height: 13.5px;margin-left: 0;" />
            </el-col>
            <el-col :span="20">
              <p v-if="selectedColumnType === 'order_status'" class="label">
                {{ task?.delivery_type || "NA" }}
              </p>

              <p v-else class="label">
                {{ task?.orderStatus || "NA" }}
              </p>
            </el-col>
          </el-col>
          <el-col :span="9" class="addition-section"> </el-col>
        </el-tooltip>
      </el-row>
      <el-row class="spacing">
        <el-tooltip class="item" effect="light" :content="'Assigned to'" placement="top-start">
          <el-col :span="15" class="first-content">
            <el-col :span="3">
              <img src="../assets/person.svg" alt="" style="width: 13.5px;height: 13.5px;margin-left: 0;" />
            </el-col>
            <el-col :span="24">
              <div class="engineer-details" style="">
                <p class="label overfolw-ellipsis">
                  {{ task.assigned_to }}
                </p>
                <div v-if="task.assigned_to !== 'NA' && task.assigned_to !== 'Unassigned'">
                  <el-badge is-dot :type="task.isOnline ? 'success' : 'info'" style="margin-left: 10px"></el-badge>
                  <!-- <span v-if="task.isOnline === true" style="margin-left: 10px; color: green">Online</span>
                  <span v-else style="margin-left: 10px; color: grey">Offline</span> -->
                </div>
              </div>
            </el-col>
          </el-col>
        </el-tooltip>
        <el-col :span="9" class="addition-section">
          <div v-for="(icon, inx) in buttonsData" :key="inx">
            <el-tooltip class="item" effect="light" :content="icon.tooltipContent" placement="top-start">
              <i v-if="(task.design && icon.exist === 'desgin') ||
                (task.additional_info?.path && icon.exist === 'survey')
                " :key="inx" :class="icon.icon" class="icon-size" style="padding-left: 1rem; color: #777"
                @click.native.stop="icon.callback(task)">
              </i>
            </el-tooltip>
          </div>


          <!--messages  -->
          <div style="display: flex;align-items: center;padding-left: 1rem;">
            <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" fill="#777" class="bi bi-chat-left"
              viewBox="0 0 16 16">
              <path
                d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
            </svg>
            <p
              style="padding-left: 0.5rem; font-weight: 600;font-size: 1rem; color:#777 ;display: flex;align-items: flex-start">
              {{ task.unread_message_count ? task.unread_message_count : 0 }}</p>
          </div>
          <!-- <i
            class="el-icon-document-checked"
            style="font-size: 1.2rem; padding-left: 0.5rem"
          ></i> -->
        </el-col>
      </el-row>
    </el-row>
  </div>
</template>
<script>
import AvatarHandler from "../../../../pages/dashboardCRM/avatars/AvatarHandler.vue";
import debounce from "debounce";
import API from "@/services/api/";
import {
  generateColorFromName,
  extractInitials as extractLetters,
} from "../../../../utils"
import { formatDateTime } from '../../../../utils/dateFormatter'
import { useUiStore } from "../../../../stores/ui";
import { mapState } from "pinia";
import { isSOMUsers } from "../../../../pages/utils/l2c2cRoles";
export default {
  emits: [
    "leadDetails",    
    "nudgeClicked",   
    "nudgeClicked",   
    "select-card",    
    "component-name", 
    "name-updated",   
    "handle-date",    
    "handleComplete", 
    "handlePriority", 
  ],
  components: {
    // Avatar,
    AvatarHandler
  },
  data() {
    return {
      manager: JSON.parse(localStorage.getItem("user")).is_manager,
      admin: JSON.parse(localStorage.getItem("user")).role,
      editedName: "",
      ownerId:JSON.parse(localStorage.getItem("user")).user_id,
      date: false,
      selectedDate: null,
      presentDate: null,
      cardSelected: "",
      pickerOptions: {
        disabledDate: (date) => {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return date < today;
        },
      },
    };
  },

  //RKChange2
  emits: ["onDesign", "onViewSurvey"],
  //end
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    buttonsData: {
      type: Array,
    },
    page: {
      type: String,
    },
    componentName: {
      type: String,
    },
    drawer: {
      type: Boolean,
    },
    selected: {
      type: String,
    },
    selectedColumnType: {
      type: String,
    },
    taskIndex: {
      type: Number
    },
  },
  methods: {
    isSOMUsers,
    getTooltipContent(orderStatus) {
      if (orderStatus === 'complete') {
        return 'Completed On';
      } else if (orderStatus === 'cancelled') {
        return 'Canceled On';
      } else if (orderStatus === 'rejected') {
        return 'Rejected On';
      } else {
        return 'Due On'; // Default or fallback value
      }
    },
    async handleClick(id) {
      
      this.$emit("leadDetails", id)
    },
    formatDate(isoDate) {
      if (!isoDate) return ""; // Handle the case when isoDate is undefined or null

      const date = new Date(isoDate);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      return date.toLocaleDateString("en-US", options);
    },
    isToday(date) {
      const today = new Date().toISOString();
      return date < today;
    },
    dateConvert(value) {
      const dateStr = value;
      const date = new Date(dateStr);
      const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
      
      return formattedDate;
    },
    getAvatarUrl(collaborator) {
      // Return the avatar URL based on collaborator's id or any other logic
      // Replace this with your actual avatar URL retrieval logic
      return `https://example.com/avatar/${collaborator.id}.jpg`;
    },
    getAvatarLabel(name, index) {
      
      if (index == 0) {
        return name ? this.extractLetters(name) : '';
      } else if (index == 1) {
        return name ? this.extractLetters(name) : '';
      } else if (index == 2) {
        return `+${this.task.collaborators.length - 2}`;
      }
    },
    async nudgeNotification(task){
      
      const {id} = task
      if(task.nudgeTooltipText !== 'Nudge') return
      
      const currentDate = new Date();
      this.presentDate = {
        sent_at: currentDate.toISOString(),
      }
      
      try {
        await API.TASKS.POST_NUDGE_NOTIFICATION(id,this.presentDate)
        this.$emit('nudgeClicked', task)
        
        this.$message({
            showClose: true,
            message: 'Nudge Notification has been sent successfully.',
            type: "success",
            center: true
          })
      } catch (err) {
        console.error(err)

        let errorMessage
        if (err?.response?.data?.available_in) {
          this.$emit('nudgeClicked', parseInt(err.response.data.available_in.match(/\d+/g).join('')))
          errorMessage = `Too many requests. Try again in ${err.response.data.available_in}.`
        } else {
          errorMessage = `There was an unknown error while sending a nudge request.`
        }
        this.$message({
          showClose: true,
          message: errorMessage,
          type: "error",
          center: true
        })
      }
      
    },
    getAvatarTooltipContent(name, index) {
      
      if (index === 2) {
        let str = '';
        let filteredArr = name.slice(2, name.length)
        filteredArr.forEach((e, index) => {
          
          str = str + e.name + `${filteredArr.length - 1 === index ? '' : ', '}`
        })
        return str
      }
      return name[index].name
    },
    extractLetters,
    dateTimeFormater(date) {
      const user = JSON.parse(localStorage.getItem('user')) || {};
      const isUsUser = user.isUSFlagEnabled

      const dateTime = formatDateTime(date, "Date Dash", isUsUser) + " | " + formatDateTime(date, "Short Time", isUsUser)
      return dateTime
    },
    selectRow() {
      if(this.isMobileView && this.page == 'Leads'){
        this.$router.push({
          name: 'leadSummary',
          params: { leadId: this.task?.lead_id },
        })
        return
      }
      this.cardSelected = true;
      

      this.$emit("select-card", this.$props.task);
    },
    handleReminder() {
      this.$emit("component-name", "setReminder", this.$props.task);
    },

    updateEditedName: debounce(function (event) {
      const editedName = event.target.innerText.trim()
      this.$props.task.name = editedName
      this.$emit('name-updated', this.$props.task);
    }, 500),



    emitUpdatedDate() {
      this.$emit("handle-date", this.$props.task);
    },
    handleComplete() {
      this.$emit("handleComplete", this.$props.task)
    },
    handlePriority() {
      this.$emit("handlePriority", this.$props.task);
    },
    openMap(address) {
      if (!address) { return }

      window.open(`http://maps.google.com/?q=${address}`);
    },
    isOrderPowerModel(task) {
      let powerModels = [
        "Power Gazebo",
        "Power Patio",
      ]
      return powerModels.find(model => model == task?.component_type)
    }
  },
  computed: {
    ...mapState(useUiStore, {
      isMobileView: state => state.isMobileView
    }),
    displayedProducts() {
      return this.task?.d2c_products?.slice(0, 3);
    },
    remainingProducts() {
      return this.task.d2c_products.slice(3);
    },
    remainingCount() {
      return this.task?.d2c_products?.length - 3;
    },
  },

};
</script>
<style scoped>
/* task-card.css 
.task-card {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px 16px 20px;
  border: 1px solid #fff;
}*/
.d2c-products-container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: flex-start;
  gap: 2px;
}

.d2c-product-pill {
  padding: 2px 8px 2px 8px;
  border: 1px solid grey;
  border-radius: 16px;
  background-color: #f0f0f0;
  font-size: 0.85rem !important;
  white-space: nowrap;
  margin-right: 4px;
}

.remaining-count {
  position: relative;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid grey;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.user-container {
  /* margin-left: 8px; */
  margin-bottom: 0.5rem;
}

.name-container {
  font-size: 1rem !important;
  font-weight: 600;
  padding-bottom: 0.2rem;
}

.spacing {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.first-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.engineer-details {
  display: flex;
  justify-content: flex-start;
}

.addition-section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.time {
  font-size: 0.75rem;
  font-weight: 500;
  color: #777;
  display: flex;
  gap: 8px;
  padding-top: 0.1rem;
  align-items: center;
}

.time::before {
  content: "";
  background: url("../assets/Access-time.svg");
  width: 16px;
  height: 16px;
  display: block;
  background-repeat: no-repeat;
}

.remainder {
  font-size: 0.85rem;
  font-weight: 600;
  color: #409eff;
  display: flex;
  gap: 8px;
}

.remainder::before {
  content: "";
  background: url("../assets/event.svg");
  width: 0.75rem;
  height: 0.85rem;
  display: block;
}

.personBadge {
  font-size: 0.85rem;
  color: #409eff;
  display: flex;
  gap: 6px;
  transition: 0.1s;
  border-radius: 2px;
  display: inline-block;
}
.ellipsisDiv p{
  max-height:50px
}
.personBadge:hover {
  background-color: rgb(236, 236, 244);
}

.personBadge::before {
  content: "";
  background: url("../assets/PersonBadge.svg");
  width: 1rem;
  margin-left: -2px;
  height: 1rem;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 6px;
}

.account {
  font-size: 0.85rem;
  font-weight: 600;
  color: #777777;
  display: flex;
  /* gap: 2px; */
}

.account::before {
  content: "";
  background: url("../assets/account_tree.svg");
  width: 1rem;
  height: 1rem;
  margin-right: 5px;
  display: block;
}

.accountComplete {
  font-size: 0.85rem;
  font-weight: 600;
  color: #CCCCCC;
  display: flex;
  /* gap: 2px; */
}

.accountComplete::before {
  content: "";
  background: url("../assets/accountTree.svg");
  width: .9rem;
  height: .8rem;
  margin-right: 5px;
  display: block;
}

.documents {
  font-size: 0.85rem;
  font-weight: 600;
  color: #777777;
  display: flex;
  margin-right: 5px;
  /* gap: 2px; */
}

.documents::before {
  content: "";
  background: url("../assets/paperclip 1.svg");
  width: 1rem;
  height: 1rem;
  display: block;
}

.documentsComplete {
  font-size: 0.85rem;
  font-weight: 600;
  color: #CCCCCC;
  display: flex;
  margin-right: 5px;
  /* gap: 2px; */
}

.documentsComplete::before {
  content: "";
  background: url("../assets/subTask.svg");
  width: .5rem;
  height: 1rem;
  margin-right: 4px;
  display: block;
}

.chat {
  font-size: 0.85rem;
  font-weight: 600;
  color: #777777;
  display: flex;
  /* gap: 2px; */
}

.chat::before {
  content: "";
  background: url("../assets/chat-left 1.svg");
  width: 1rem;
  height: 1rem;
  margin-right: 5px;
  display: block;
}

.chatComplete {
  font-size: 0.85rem;
  font-weight: 600;
  color: #CCCCCC;
  display: flex;
  /* gap: 2px; */
}

.chatComplete::before {
  content: "";
  background: url("../assets/chat_tree.svg");
  width: 1rem;
  height: 1rem;
  margin-right: 5px;
  display: block;
}

.phone {
  font-size: 0.85rem;
  font-weight: 500;
  /* color: #777; */
  display: flex;
  gap: 8px;
}

.phone::before {
  content: "";
  background: url("../assets/phone.svg");
  width: 13.5px;
  height: 13.5px;
  display: block;
}

.calender {
  font-size: 0.85rem;
  font-weight: 500;
  display: flex;
  gap: 8px;
}

.calender::before {
  content: "";
  background: url("../assets/CalendarEvent.svg");
  width: 0.95rem;
  height: 0.8rem;
  display: block;
}
.calenderComplete {
  font-size: 0.85rem;
  font-weight: 500;
  display: flex;
  gap: 8px;
}

.calenderComplete::before {
  content: "";
  background: url("../assets/dueComplete.svg");
  width: 0.95rem;
  height: 0.8rem;
  display: block;
}

.calenderComplete ::v-deep.el-input__prefix {
  display: none;
}

.calenderComplete ::v-deep.el-input__suffix {
  display: none;
}

.calenderComplete ::v-deep.el-input__inner {
  border: none;
  padding: 0;
  height: 16px;
  color: #CCCCCC;
}

.dueCalender {
  color: red;
  font-size: 0.85rem;
  font-weight: 500;
  /* color: #777; */
  display: flex;
  gap: 8px;
}

.dueCalender ::v-deep.el-input__prefix {
  display: none;
}

.dueCalender ::v-deep.el-input__suffix {
  display: none;
}

.dueCalender ::v-deep.el-input__inner {
  border: none;
  padding: 0;
  color: red;
  height: 16px;
}

.calender ::v-deep.el-input__prefix {
  display: none;
}

.calender ::v-deep.el-input__suffix {
  display: none;
}

.calender ::v-deep.el-input__inner {
  border: none;
  padding: 0;
  color: #777777;
  height: 16px;
}

.dueCalender::before {
  content: "";
  background: url("../assets/DueCalendarEvent.svg");
  width: 0.95rem;
  height: 0.8rem;
  display: block;
}

.email {
  font-size: 0.85rem;
  font-weight: 500;
  /* color: #777; */
  display: flex;
  gap: 8px;
}

.email::before {
  content: "";
  background: url("../assets/email.svg");
  width: 13.5px;
  height: 13.5px;
  display: block;
}

.location {
  font-size: 0.85rem;
  font-weight: 500;
  color: #777777;
  display: flex;
  align-items: center;
  gap: 4px;
}

.location::before {
  content: "";
  background: url("../assets/Group.svg");
  width: 16px;
  height: 16px;
  display: block;
}

.locationComplete {
  font-size: 0.85rem;
  font-weight: 500;
  color: #CCCCCC;
  display: flex;
  gap: 4px;
}

.locationComplete::before {
  content: "";
  background: url("../assets/locationComplete.svg");
  width: 16px;
  height: 16px;
  display: block;
}

.body-content {
  display: flex;
  justify-content: left;
}

.el-date-picker::v-deep.el-input__inner {
  padding: 0;
}
.disabled-content {
  pointer-events: none;
  opacity: 0.3; 
}
.leadDetcolumn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 3px;
  gap: 8px;
}

.task-card {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
    rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px; */
  overflow: hidden;
  /* margin-top: 2px; */
  /* margin: 0.6rem 0rem; */
  margin-bottom: 0;
  cursor: pointer;
  padding: 12px 12px 2px 12px;
}

.task-card h2 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.task-card img {
  width: 16px;
  height: 18px;
  border-radius: 50%;
  margin-left: 12px;
}

.task-card span {
  font-size: 12px;
  /* color: #666; */
}

.task-card .badge {
  font-size: 10px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 8px;
}

.task-card .badge.teal {
  background-color: #e6fffa;
  color: #2dcca7;
}

.task-card .badge.blue {
  background-color: #ebf8ff;
  color: #3182ce;
}
</style>
<style scoped>
.wrapper {
  /* margin-bottom: 0.5rem; */
  /* margin-left: 1rem; */
}

.container {
  margin-bottom: 0;
}

.label {
  font-size: 14px;
  font-weight: 600;
}

.label-name {
  color: #409eff;
}

.tag {
  border-radius: 20px;
}

.value {
  font-size: 14px;
}

.alignButtons {
  display: flex;
  width: 103px;
  margin: 1rem 0;
}

.assignee {
  font-size: 12px;
  color: #999;
  float: right;
}

.card-options {
  padding: 0.7rem 0rem;
}

.icon-size {
  font-size: 1.3rem;
}

.activeClass {
  background: red;
}

#task-crad>>>.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

#task-crad>>>.custom-tooltip::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  /* border-style: solid; */
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #000 transparent;
}

#task-crad>>>.el-tooltip__popper.is-dark {
  border: none;
}

.project-type {
  color: grey;
  font-weight: 500;
  text-transform: capitalize;
  padding-left: 0.2rem;
}

.overfolw-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-overfolw {
  white-space: nowrap;
  max-width: 0.5rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overfolw-texts {
  max-width: 12rem !important;
}

#task-card>>>.el-avatar {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-transform: uppercase;
}

.user-name {
  font-size: 1rem !important;
  font-weight: 600;
  padding-bottom: 0.2rem;
}

@media (min-width: 1200px) {
  .task-card {
    /* margin: 1rem 0rem; */
    margin-bottom: 0;
    padding: 12px 12px 4px 12px;
  }

  .user-name {
    font-size: 1rem !important;
    font-weight: 600;
    padding-bottom: 0.2rem;
  }

  .lead-name {
    font-size: 1rem !important;
  }
}</style>
