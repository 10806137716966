
<template>
    <div class="email-container-card">
        <EmailPopup
             v-if="openEmailPopup"
            :openDrawer="openEmailPopup"
            :emailData="mailDetails"
            :leadData="lead"
            :admin="admin"
            @onClickClose="handleOpenEmailPopup"
            @addEmailToListEmailView="addEmailToListEmail"
           :flag="emailOperationFlag"
           :popupHeader="popupHeader"
            :tabType="tab"
           />
    
           <GlobalDelete
            v-if="openDeleteDialogPopup"
             @confirm="handleDeleteEmail"
              @onClickClose="openDeleteDialog"
              :message="'Do you want to delete this email'"
              :openDrawer="openDeleteDialogPopup"
              />
    
           <div v-if="loading">
                <el-skeleton
                 :rows="3" 
                 animated
                  />
              </div>
    <div v-else>
        <div 
        class="head-container">
            <div
             class="icon-container" 
             @click="handleEmailVisibility"
             >
                <i 
                v-if="!isVisible" 
                class="el-icon-arrow-right"
                 />
                <i 
                v-else
                 class="el-icon-arrow-down"
                  />
            </div>
            <div class="heading-container">
                <p 
                v-if="isVisible"
                 class="email-description-content"
                 :style="emailStatusStyling()"
                 >
                    Email - {{visibleData.headers? visibleData.headers.subject?visibleData.headers.subject:visibleData.headers.Subject:visibleData?.subject||""  }}
                </p>
                <p 
                v-else
                :style="emailStatusStyling()"
                class="email-description-content"
                >
                    Email - {{ mailDetails.subject?mailDetails.subject:mailDetails.Subject }}
                </p>
            </div>
             <div class="action-container">
                <p @click="handleModalType('reply')">
                    Reply All
                </p>
                <p @click="handleModalType('forward')">
                    Forward
                </p>
                <p @click="openDeleteDialog">
                    Delete
                </p>
                <!-- <el-popconfirm
                    confirm-button-text='Yes'
                    cancel-button-text='No'
                    icon="el-icon-info"
                    icon-color="red"
                    title="Are you sure to delete this mail?"
                    @confirm="handleDeleteEmail"
                >
                    <p slot="reference">Delete</p>
                </el-popconfirm> -->
            </div>
        </div>
        <div class="email-info-container">
            <div 
            v-if="isVisible"
            class="profile-picture-container" 
            >
                <el-avatar icon="el-icon-user-solid" />
            </div>
    
            <div class="email-details-container">
                <div
                class="email-person-details"
                :style="{paddingLeft: isVisible ? '0px' : '20px'}"
                >
                  <div class="person-name">
                    <p v-if="isVisible" :style="emailStatusStyling()">
                        {{visibleData.headers? visibleData.headers.From:visibleData?.from||""}}
                    </p>
                    <p v-else :style="emailStatusStyling()">
                        {{ mailDetails.from}}
                    </p>
                  </div>
                  <div>
                    <p
                    v-if="isVisible"
                     class="person-info"
                      >
                      to {{ visibleData.headers["to"] ? visibleData.headers["to"] : visibleData.headers["To"] }}
                      {{ ccContent}}
                    </p>
                    <p 
                    v-else
                    class="person-info"
                     >
                           to {{ mailDetails.to !== 'Unknown' ? mailDetails.to :lead.lead_details.email ?lead.lead_details.email:lead.email }}
                    </p>
                  </div>
                    <div class="person-info">
                        <p v-if="isVisible">
                            {{formatDateString(visibleData?.headers?.Date||visibleData?.sentDateTime||visibleData?.Sent||"")}}
                        </p>
                        <p v-else>
                            {{mailDetails.date ? formatDateString(mailDetails.date) : "" }}
                        </p>
                    </div>
                </div>
                <div
                     v-if="isVisible"
                    v-html="htmlContent"
                />
                <div
                 v-else
                  class="email-content-details"
                  >
                   <p style="margin-left: 20px;"> 
                    Hey, this is a response...
                </p>
                </div>
                <img 
                v-if="isVisible"
                style="margin-top: 10px"
                src="./assets/Arka.png" 
                alt=""
                 >
            </div>
        </div>
    </div>

    </div>
    </template>
    
    <script>
    import API from "@/services/api/";
    import EmailPopup from "./emailPopup.vue";
    import  GlobalDelete from "./globalDelete.vue";
    import {formatDateString} from "./utils/utils"
    import { useEmailStore } from "../../stores/email";
import { mapState } from "pinia";
    export default {
        emits: ["removeEmail", "addEmailToListEmailView"],
        
        components: {
        EmailPopup,
        GlobalDelete,
        },
        props:{
        item:{
            type: Object,
            required: true,
        },
        lead:{
            type: Object,
            required: true,
        },
        admin:{
            type:String,
            default: "admin",
        },
        tab:{
            type: String,
            default: null,
        },
        noDataHeight:{
            type:String,
            default:'100%'
        }
    },
        data(){
            return{
                isVisible: false,
                visibleData:[],
                isLoading: false,
                openEmailPopup:false,
                openReplyPopup:false,
                emailOperationFlag:"forward",
                popupHeader:"",
                deleteLeadConfirmationDialog:false,
                loading:false,
                openDeleteDialogPopup:false,
            }
    },

    computed:{
        ...mapState(useEmailStore,{
            isMicrosoftAccount:state=>state.isMicrosoftAccount,
            isGoogleAccount:state=>state.isGoogleAccount,
                
        }),
        htmlContent(){
            const body=this.visibleData?.body||this.visibleData.Body
            return  body?.replace(/\n/g, '<br>')?.replace(/<([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})>/g, '$1')||body;
        },

        ccContent(){
            const ccData=this.visibleData?.headers?.cc || this.visibleData?.headers?.Cc||this.visibleData?.cc||this.visibleData?.header?.CC
            if (ccData) {
        let content =ccData;
        return ", " + content;
    } else {
        return ""; 
    }
        },
        mailDetails(){
            if(this.isMicrosoftAccount){
                let microsoftData=this.$props.item;
                microsoftData.to= Array.isArray( microsoftData.to)? microsoftData.to.join(", "):microsoftData.to;
                return  microsoftData
            }
            return this.$props.item
        }


    },
    
    methods:{
        formatDateString,
        async handleEmailVisibility(){
            this.isVisible = !this.isVisible;
            if(this.isVisible == false){
                return
            }
            try{
                const {data} =this.isGoogleAccount? await API.EXTERNAL_INTEGRATION.GET_EMAIL_INFO_BASED_ON_ID(this.item.id): await API.EXTERNAL_INTEGRATION.GET_MICROSOFT_EMAIL_INFO_BASED_ON_ID(this.item.id);
                this.visibleData = data.email;
                if(this.isMicrosoftAccount){
                   const headers={
                    to:this.visibleData.To,
                    from:this.visibleData.From,
                    cc:this.visibleData.CC,
                    subject:this.visibleData.Subject,
                   }
                   this.visibleData['headers']=headers;
                }
                this.handleEmailReadStatus();
                }catch(e){
                    this.$message({
                    showClose: true,
                    message: 'Cannot get email details',
                    type: "error",
                    center: true
                })
            }
        },

        async handleEmailReadStatus(){
            const body = {
                action: "read"
            }
            try{
                 this.isGoogleAccount?  await API.EXTERNAL_INTEGRATION.CHANGE_EMAIL_STATUS(this.item.id, body):await API.EXTERNAL_INTEGRATION.CHANGE_MICROSOFT_EMAIL_STATUS(this.item.id, body);
                  this.$props.item.status = "Read"
            }catch(err){
                this.$message({
                    showClose: true,
                    message: 'Cannot update email status',
                    type: "error",
                    center: true
                })
            }
        },
    
    
        handleModalType(type){
            switch(type){
            case "reply":
            this.emailOperationFlag = "reply"
            this.popupHeader = "Reply"
                break;
            case "forward":
                this.popupHeader = "Forward"
                this.emailOperationFlag = "forward"
                break;
            default:
                break;
          }
            this.handleOpenEmailPopup()
            if(this.isVisible){
                this.isVisible = false
            }
        },
        openDeletePopup(){
            this.deleteLeadConfirmationDialog = true;
        },
    
       async  handleDeleteEmail(){
        this.openDeleteDialogPopup = false;
        this.isVisible = false
        this.loading = true;
        try{
            const response =this.isMicrosoftAccount?await API.EXTERNAL_INTEGRATION.DELETE_MICROSOFT_MAIL(this.item.id) : await API.EXTERNAL_INTEGRATION.DELETE_EMAIL(this.item.id)
            this.$message({
                    showClose: true,
                    message: 'Email deleted successfully',
                    type: "success",
                    center: true
                 })
            this.$emit("removeEmail",this.item.id)
            }catch(e){
            this.$message({
                    showClose: true,
                    message: "You don't have enough permissions to create email, please disconnect and Integrate again with all the email related permissions",
                    type: "error",
                    center: true
                })
        }
        this.loading = false
        },
    
        handleOpenEmailPopup(){
         this.openEmailPopup = !this.openEmailPopup
        },
    
        addEmailToListEmail(data){
            this.$emit("addEmailToListEmailView", data)
        },
        openDeleteDialog(){
            this.openDeleteDialogPopup = !this.openDeleteDialogPopup
        },

        emailStatusStyling(){
              if(this.item.status == "Read"){
                return{
                    color:"#777",
                    fontWeight:"400"
                }
              }
        }
    
    },
    }
    </script>
    
    <style  scoped>
    .email-container-card{
        padding: 10px;
        border: 1px solid #CCCCCC;
        border-radius: 8px;
    }
    
    .head-container{
        display: flex;
        gap: 10px;
    }
    
    .icon-container{
        cursor: pointer;
    }
    
    .heading-container{
        font-size: 14px;
        font-weight: 600;
        width: 300px;
    }
    
    .email-description-content{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    
    .action-container{
        display: flex;
        gap: 10px;
        font-size: 14px;
        font-weight: 600;
        margin-left: auto;
        color:  #1C3366;
        cursor: pointer;
    }
    
    .email-info-container{
        display: flex;
        gap: 10px;
        margin-top: 10px;
    }
    
    .profile-picture-container ::v-deep .el-avatar{
     background-color: #1C3366;
    }
    
    .person-name{
        font-size: 14px;
        font-weight: 600;
    }
    
    .person-info{
        color: #777777;
        font-size: 12px;
        font-weight: 400;
    }
    
    .email-content-details{
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }
    
    .logo-image{
        margin-top: 10px;
    }
    </style>