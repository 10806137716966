import { three_b_org_id } from "../../../constants"
import defaultUserNote from "../reportGazebo/defaultUserNote.html?raw"
import { removeQueryParamsFromUrl } from "../../../utils"

export function getDefaultRoadmapSteps(options) {
    options ||= {}

    if (options.organisationId == three_b_org_id) {
        return [
            "Review and Approve the Proposed Design (Homeowner).",
            "Detailed System Design and Engineering (We'll Take Care of It).",
            "Utility 3rd Party Authorization  (We'll Take Care of It).",
            "Approvals for Development and Microgeneration( We'll Take Care of It).",
            "System Installation and Commissioning (We'll Take Care of It).",
            "Obtain Permission to Operate (PTO) from Utility (We'll Take Care of It).",
            "Wire Provider Installs Bi Directional Meter",
            "Turn system on, Setup Customer Solar System Monitoring, Start Saving Money",
            "Join Our Referral Program",
        ]
    }

    return [
        `Review and Confirm Proposed Design (${options.isCommercial ? 'Propertyowner' : 'Homeowner'})`,
        `Authorize agreement and pay deposit (${options.isCommercial ? 'Propertyowner' : 'Homeowner'}).`,
        "Detailed system design and engineering (we'll take care of it).",
        "Plans sent to HOA for review, if applicable. (We provide the information, you submit to the HOA)",
        "Approvals by Utility and Municipality - AHJ (we'll take care of it).",
        "System installation and commissioning (we'll take care of it).",
        "Obtain Permission to Operation (PTO) and turn on the system from the Utility (we'll take care of it).",
        "Join our referral program.",
    ]
}

export function getPowerModelImage(sku, side, model) {
    sku = sku?.trim()

    let availableFrontImages = {
        "gazebo_1": new URL(`../reportGazebo/assets/img/frontCovers/gazebo_1.jpg`, import.meta.url).href,
        "gazebo_2": new URL(`../reportGazebo/assets/img/frontCovers/gazebo_2.jpg`, import.meta.url).href,
        "gazebo_3": new URL(`../reportGazebo/assets/img/frontCovers/gazebo_3.png`, import.meta.url).href,
        "gazebo_4": new URL(`../reportGazebo/assets/img/frontCovers/gazebo_4.png`, import.meta.url).href,
        "patio_1": new URL(`../reportGazebo/assets/img/frontCovers/patio_1.jpg`, import.meta.url).href,
        "patio_2": new URL(`../reportGazebo/assets/img/frontCovers/patio_2.jpg`, import.meta.url).href,
        "patio_3": new URL(`../reportGazebo/assets/img/frontCovers/patio_3.jpg`, import.meta.url).href,
        "patio_4": new URL(`../reportGazebo/assets/img/frontCovers/patio_4.jpg`, import.meta.url).href,
    }

    let availableBackImages = {
        "gazebo_1": new URL(`../reportGazebo/assets/img/backCovers/gazebo_1.webp`, import.meta.url).href,
        "gazebo_2": new URL(`../reportGazebo/assets/img/backCovers/gazebo_2.webp`, import.meta.url).href,
        "gazebo_3": new URL(`../reportGazebo/assets/img/backCovers/gazebo_3.webp`, import.meta.url).href,
        "gazebo_4": new URL(`../reportGazebo/assets/img/backCovers/gazebo_4.webp`, import.meta.url).href,
        "patio_1": new URL(`../reportGazebo/assets/img/backCovers/patio_1.webp`, import.meta.url).href,
        "patio_2": new URL(`../reportGazebo/assets/img/backCovers/patio_2.webp`, import.meta.url).href,
        "patio_3": new URL(`../reportGazebo/assets/img/backCovers/patio_3.webp`, import.meta.url).href,
        "patio_4": new URL(`../reportGazebo/assets/img/backCovers/patio_4.webp`, import.meta.url).href,
    }

    let skuMapping = {
        "PGUS02-M1-90-348-43": "gazebo_4",
        "PGUS02-M1-90-303-36": "gazebo_3",
        "PGUS02-M1-90-252-30": "gazebo_2",
        "PGUS02-M1-90-202-24": "gazebo_1",
        "PGUS01-M1-77-320-43": "gazebo_4",
        "PGUS01-M1-77-275-36": "gazebo_3",
        "PGUS01-M1-77-230-30": "gazebo_2",
        "PGUS01-M1-77-185-24": "gazebo_1",
        "PPUS02-M1-90-352-43": "patio_1",
        "PPUS02-M1-90-303-36": "patio_2",
        "PPUS02-M1-90-252-30": "patio_3",
        "PPUS02-M1-90-204-24": "patio_4",
    }

    let imageName = skuMapping[sku]
    if (!imageName) {
        // For error handling, if the SKU is not found
        if (model == "patio") {
            imageName = "patio_1"
        } else {
            imageName = "gazebo_1"
        }
    }
    let frontImagePath = availableFrontImages[imageName]
    let backImagePath = availableBackImages[imageName]

    if (side == "front") {
        return frontImagePath
    } else {
        return backImagePath
    }
}

export function testimonialImage(testimonial) {
    let imageUrl = testimonial.image_url
    if (imageUrl) {
        imageUrl = removeQueryParamsFromUrl(imageUrl)
    } else {
        imageUrl = new URL("../../../assets/img/person-fill.svg", import.meta.url).href
    }
    return imageUrl
}

export function resolveImageUrl(input) {
    
    if (input === null) {
        // Return null if input is null
        return null;
    }

    const imageUrl = typeof input === "string"
        ? removeQueryParamsFromUrl(input) // Input is a direct URL
        : input?.image_url 
            ? removeQueryParamsFromUrl(input.image_url) // Input is an object with `image_url`
            : new URL("../../../assets/img/person-fill.svg", import.meta.url).href;
    return imageUrl;
}


export function getDefaultProposalDetails() {
    return {
        "title": {
            "title": `<span class="placeholder-span" contenteditable="false">[Default Proposal Title]</span>`,
            "titleDefault": `<span class="placeholder-span" contenteditable="false">[Default Proposal Title]</span>`,
            "prepared_for_text1": `<span class="placeholder-span" contenteditable="false">[Client Name]</span>`,
            "prepared_for_text2": `<p><span class="placeholder-span" contenteditable="false">[Client Address]</span></p><p><span class="placeholder-span" contenteditable="false">[Client Contact Number]</span></p><p><span class="placeholder-span" contenteditable="false">[Client Email]</span></p>`,
            "prepared_for_text3": `<p><span class="placeholder-span" contenteditable="false">[Client Address]</span></p>`,
            "cover_image_path": `[Default Front Cover Image]`,
            "prepared_by_text1": `<span class="placeholder-span" contenteditable="false">[Installer Name]</span>`,
            "prepared_by_text2": `<p><span class="placeholder-span" contenteditable="false">[Installer Phone No.]</span></p><p><span class="placeholder-span" contenteditable="false">[Installer Email]</span></p><p><span class="placeholder-span" contenteditable="false">[Installer Organisation]</span></p>`,
        },
        "user-note": {
            "body": defaultUserNote,
        },
        "solar-value-proposition-page": {
            "cover_image_path": `[Default Solar Value Proposition Image]`
        },
        "back-cover": {
            "cover_image_path": `[Default Back Cover Image]`,
            "prepared_by": `<span class="placeholder-span" contenteditable="false">[Installer Name]</span>`,
            "org_details_info": `<p><span class="placeholder-span" contenteditable="false">[Installer Phone No.]</span></p><p><span class="placeholder-span" contenteditable="false">[Installer Email]</span></p><p><span class="placeholder-span" contenteditable="false">[Installer Organisation]</span></p>`,
        },
        "image_template_1": {
            "title": "",
            "cover_image_path1": "",
            "cover_image_path2": "",
            "cover_image_path3": "",
            "cover_image_path4": "",
            "cover_image_path5": "",
            "cover_image_path6": "",
            "img_description1": "",
            "img_description2": "",
            "img_description3": "",
            "img_description4": "",
            "img_description5": "",
            "img_description6": "",
        },
        "image_template_1_landscape": {
            "title": "",
            "cover_image_path1": "",
            "cover_image_path2": "",
            "cover_image_path3": "",
            "img_description1": "",
            "img_description2": "",
            "img_description3": "",
        },
        "image_template_2_landscape": {
            "title1": "",
            "title2": "",
            "cover_image_path1": "",
            "cover_image_path2": "",
            "img_description1": "",
            "img_description2": "",
        },
        "image_template_2": {
            "title": "",
            "cover_image_path1": "",
            "cover_image_path2": "",
            "cover_image_path3": "",
            "cover_image_path4": "",
            "img_description1": "",
            "img_description2": "",
            "img_description3": "",
            "img_description4": "",
        },
        "image_template_3": {
            "title": "",
            "cover_image_path1": "",
        },
        "image_template_4": {
            "title": "",
            "cover_image_path1": "",
            "cover_image_path2": "",
            "cover_image_path3": "",
            "img_description1": "",
            "img_description2": "",
            "img_description3": "",
        },
        "image_template_5": {
            "title1": "",
            "title2": "",
            "cover_image_path1": "",
            "cover_image_path2": "",
            "cover_image_path_second1": "",
            "cover_image_path_second2": "",
            "img_description1": "",
            "img_description2": "",
            "img_description_secondary1": "",
            "img_description_secondary2": "",
        },
        "image_template_6": {
            "title1": "",
            "title2": "",
            "title3": "",
            "title4": "",
            "title5": "",
            "title6": "",
            "cover_image_path1": "",
            "cover_image_path2": "",
            "cover_image_path3": "",
            "cover_image_path4": "",
            "cover_image_path5": "",
            "cover_image_path6": "",
            "img_description1": "",
            "img_description2": "",
            "img_description3": "",
            "img_description4": "",
            "img_description5": "",
            "img_description6": "",
        },
        "text_template_1": {
            "title": "",
            "cover_image_path1": "",
            "cover_image_path2": "",
            "cover_image_path3": "",
            "cover_image_path4": "",
            "cover_image_path5": "",
            "cover_image_path6": "",
        },
        "text_template_2": {
            "title": "",
            "cover_image_path1": "",
            "img_description1": "",
            "cover_video1": {},
            "video_description1": "",
        },
        "text_template_3": {
            "title": "",
            "cover_video1": {},
            "video_description1": "",
        },
        "text_template_4": {
            "title": "",
            "cover_image_path1": "",
            "cover_image_path2": "",
            "cover_image_path3": "",
            "cover_image_path4": "",
            "cover_image_path5": "",
            "cover_image_path6": "",
        },
        "text_template_5": {
            "title": "",
            "cover_video1": {},
            "cover_video2": {},
            "cover_video3": {},
            "video_description1": "",
            "video_description2": "",
            "video_description3": "",
        },
        "text_template_6": {
            "title": "",
            "cover_video1": {},
            "cover_image_path1": "",
            "video_description1": "",
            "img_description1": "",
        },
        "video_template_1": {
            "title": "",
            // format is cover_video1 : {video_url: "", video_thumbnail: ""}
            "cover_video1": {},
            "cover_video2": {},
            "cover_video3": {},
            "cover_video4": {},
            "video_description1": "",
            "video_description2": "",
            "video_description3": "",
            "video_description4": "",
        },
        "video_template_1_landscape": {
            "title": "",
            // format is cover_video1 : {video_url: "", video_thumbnail: ""}
            "cover_video1": {},
            "cover_video2": {},
            "cover_video3": {},
            "video_description1": "",
            "video_description2": "",
            "video_description3": "",
        },
        "video_template_2_landscape": {
            "title": "",
            // format is cover_video1 : {video_url: "", video_thumbnail: ""}
            "cover_video1": {},
            "cover_video2": {},
            "video_description1": "",
            "video_description2": "",
        },
        "video_template_2": {
            "title": "",
            "cover_video1": {},
            "cover_video2": {},
            "video_description1": "",
            "video_description2": "",
        },
        "video_template_3": {
            "title": "",
            "cover_video1": {},
            "video_description1": "",
        },
        "video_template_4": {
            "title": "",
            "cover_video1": {},
            "cover_video2": {},
            "cover_video3": {},
            "video_description1": "",
            "video_description2": "",
            "video_description3": "",
        },
        "video_template_5": {
            "title": "",
            // format is cover_video1 : {video_url: "", video_thumbnail: ""}
            "cover_video1": {},
            "cover_video2": {},
            "cover_video3": {},
            "cover_video4": {},
            "cover_video5": {},
            "cover_video6": {},
            "video_description1": "",
            "video_description2": "",
            "video_description3": "",
            "video_description4": "",
            "video_description5": "",
            "video_description6": "",
        },
    }
}