
<div class="card" id="crmtable" >
<div class="card" id="crmtable" :style="{border:editMode? '2px solid blue':''}" v-if="tablesData">
  <CardHeader
    :heading="tableName"
    :editMode="editMode"
    @mode-open="handleOpenPopover"
  >
    <slot name="header">
      <DropdownElement
        @selectionMade="setTableData"
        :dataOptions="computedDropdown"
        v-if="dropDown"
      />
    </slot>
  </CardHeader>
  <div class="table_content"  :class="isGroupedTable||$props.editMode?'' :'table_pointer'" v-if="tablesData">
    <el-table
      :data="tablesData"
      :default-sort="{ prop: 'name', order: 'descending' }"
      :style="{ width: '100%', overflowY: 'scroll',overflowX: 'scroll' }"
      class="tableContainer"
      @row-click="handleRowClick"
      height='99%'
    >
      <el-table-column
        v-for="column in filteredColumnLabels"
        :key="column.prop"
        :label="column.label"
        :prop="column.prop"
        :min-width="column==='S. No.'?80:120"
        :width="handleMaxWidth(column.prop)"
        :header-align="getHeaderAlignment(column.prop)"
        :disabled="true"
      >
        <template v-slot="scope">
          <div>
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
              v-if="
                column.prop === 'Name - Leads' ||
                column.prop === 'Name - Tasks' ||
                column.prop === 'Name'
              "
            >
              <div
                style="width: 30px; margin-right: 0.5rem"
                v-if="chartSource === 'Leads'"
              >
                <el-avatar
                  :size="30"
                  :style="getAvatarStyle(scope.row[column.prop])"
                  >{{ generateInitials(scope.row[column.prop]) }}</el-avatar
                >
              </div>

              <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(scope.row[column.prop])">
                <template v-slot:content>
                <div>
                  {{ scope.row[column.prop] }}
                </div>
                </template>
                <div class="name-content">
                  {{ scope.row[column.prop] }}
                </div>
              </el-tooltip>
            </div>
            <div v-else>
              <div
                class="name-content alignment-dealsize"
                v-if="
                  column.prop === 'Deal Amount - Leads' ||
                  column.prop === 'Deal Amount - Tasks' ||
                  column.prop === 'Deal Amount'
                "
              >
                <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(scope.row[column.prop])">
                  <template v-slot:content>
                  <div>
                    {{
                      scope.row[column.prop] || scope.row[column.prop] == 0
                        ? handleAbbreviateNumber(currency, scope.row[column.prop])
                        : "-"
                    }}
                  </div>
                  </template>
                  <div>
                    {{
                      scope.row[column.prop] || scope.row[column.prop] == 0
                        ? handleAbbreviateNumber(currency, scope.row[column.prop])
                        : "-"
                    }}
                  </div>
                </el-tooltip>
              </div>
              <div
                class="name-content alignment-date"
                v-else-if="
                  column.prop === 'Target Closure Date - Leads' ||
                  column.prop === 'Target Closure Date - Tasks' ||
                  column.prop === 'Due date - Tasks' ||
                  column.prop === 'Due date - Leads' ||
                  column.prop === 'Due date' ||
                  column.prop=== 'Close Date'
                "
              >
                <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(scope.row[column.prop])">
                  <template v-slot:content>
                  <div>
                    {{
                      scope.row[column.prop]
                        ? handleFormatDate(
                            scope.row[column.prop],
                            typeConstants.monthDayYear,
                            isUSFlag()
                          )
                        : "-"
                    }}
                  </div>
                  </template>
                  <div>
                    {{
                      scope.row[column.prop]
                        ? handleFormatDate(
                            scope.row[column],
                            typeConstants.monthDayYear,
                            isUSFlag()
                          )
                        : ""
                    }}
                  </div>
                </el-tooltip>
              </div>
              <div
                @click.stop=""
                v-else-if="
                  column.prop === 'Email - Leads' ||
                  column.prop === 'Email - Tasks' ||
                  column.prop === 'Email'
                "
              >
                <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(scope.row[column.prop])">
                  <template v-slot:content>
                  <div>
                    {{
                      scope.row[column.prop] || scope.row[column.prop] == 0
                        ? scope.row[column.prop]
                        : "-"
                    }}
                  </div>
                  </template>
                  <div>
                    <a :href="`mailto: ${scope.row[column.prop]}`">
                      <i class="el-icon-message icons-container"></i>
                      {{
                        scope.row[column.prop] || scope.row[column.prop] == 0
                          ? scope.row[column.prop]
                          : "-"
                      }}</a
                    >
                  </div>
                </el-tooltip>
              </div>

              <div
                @click.stop=""
                v-else-if="
                  column.prop === 'Phone - Leads' ||
                  column.prop === 'Phone - Tasks' ||
                  column.prop=== 'Phone'
                "
              >
                <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(scope.row[column.prop])">
                  <template v-slot:content>
                  <div>
                    {{
                      scope.row[column.prop] || scope.row[column.prop] == 0
                        ? scope.row[column.prop]
                        : "-"
                    }}
                  </div>
                  </template>
                  <div>
                    <a :href="`tel: ${scope.row[column.prop]}`">
                      <i class="el-icon-phone icons-container"></i>
                      {{
                        scope.row[column.prop] || scope.row[column.prop] == 0
                          ? scope.row[column.prop]
                          : "-"
                      }}</a
                    >
                  </div>
                </el-tooltip>
              </div>

              <div v-else-if="column.prop !== 'id'" class="name-content">
                <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(scope.row[column.prop])">
                  <template v-slot:content>
                  <div>
                    {{
                      scope.row[column.prop] || scope.row[column.prop] == 0
                        ? scope.row[column.prop]
                        : "-"
                    }}
                  </div>
                  </template>
                  <div>
                    {{
                      scope.row[column.prop] || scope.row[column.prop] == 0
                        ? scope.row[column.prop]
                        : "-"
                    }}
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <slot></slot>
    </el-table>
  </div>

</div>
<div v-else class="card">
  <Skeleton    />
  </div>
</div>
