import API from "@/services/api/";
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import axios from "axios";
import {
  GOOGLE_API_KEY,
  GOOGLE_SIGNING_SECRET,
  COUNTRYSTATECITY_API_KEY,
  DATABASE_URL_FOR_WEBSOCKET,
  es_org_id,
  patriot_org_id,
  tata_org_id,
  havells_org_id,
  powerModelNameMapping,
  powerModelOldNewSKUMapping,
} from "./constants";
import { signRequest } from "./core/utils/utils";
import { modifyBackupTime } from "./pages/design/components/js/utils";
import { colors } from "../src/utils/colorPalettes";
import { formatNumber } from "./utils/numberFormat/currencyFormatter"
import { useDesignOrdersStore } from "./stores/designOrdersStore";

export function setCookie(name, data, expiryDate) {
  if (!expiryDate) {
    // If no expiry date, expire next year
    expiryDate = new Date(new Date().getTime() + 31536000000);
  }

  let cookieString = `${name}=${data}; expires=${expiryDate};`;
  if (location.hostname.includes("thesolarlabs.com")) {
    cookieString += " domain=thesolarlabs.com;";
  }
  document.cookie = cookieString;
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

// Setting the cookie to expire at a date in the past will delete the cookie
export function eraseCookie({ name, domain, path }) {
  let deleteCookieString = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  if (domain) {
    deleteCookieString += ` domain=${domain};`;
  }
  if (path) {
    deleteCookieString += ` path=${path};`;
  }
  document.cookie = deleteCookieString;
}

export function fetchOrganisationId() {
  return getOrganisationFromLocalStorage()?.id;
}

export function getOrganisationFromLocalStorage() {
  let org = JSON.parse(localStorage.getItem("organisation")) || {};
  return org
}

export function getUserFromLocalStorage() {
  let user = JSON.parse(localStorage.getItem("user")) || {};
  return user
}

export function getUserTokenFromLocalStorage(){
  return getUserFromLocalStorage().token
}

export function isPatriotOrg() {
  return getOrganisationFromLocalStorage().id == patriot_org_id
}

export function isAdmin() {
  const user = getUserFromLocalStorage();
  if (user.role == "ADMIN" ) {
    return true;
  }
  return false;
}

export function isCrmUser() {
  return getOrganisationFromLocalStorage()?.is_crm_enabled;
}

export function isExpertServicesOrg() {
  const org = getOrganisationFromLocalStorage() || {};
  if (org.id === es_org_id) {
    return true;
  }
  return false;
}

export function isAccountSubscribed() {
  let allServicesInfo = JSON.parse(localStorage.getItem("allServicesInfo"));
  let selfDesignId = allServicesInfo?.self_designing_info?.id;
  return !Boolean(selfDesignId);
}

// Possible roles: ADMIN, SALESPERSON, DESIGNER, ARKA_SALES_OP,ARKA_INTERNAL_SALES
export function isUserOfRole(role) {
  return getUserFromLocalStorage().role == role;
}

export function isUSFlagEnabled() {
  return getUserFromLocalStorage().isUSFlagEnabled;
}

export function isOrgOfCountryUsa() {
  return getOrganisationFromLocalStorage().country == 52;
}

export function isOrgOfCountryNorthAmerica() {
  let orgCountry = getOrganisationFromLocalStorage().country
  return orgCountry == 57 || orgCountry == 52;
}


export async function fetchOrganisationDetails() {
  const { organisation_id } = { ...getUserFromLocalStorage() };
  let orgResponse = await API.ORGANISATION.FETCH_ORGANISATION(organisation_id);
  localStorage.setItem("organisation", JSON.stringify(orgResponse.data));

  const uiData = JSON.parse(localStorage.getItem("ui"));
  if (uiData) {
    uiData.taskManagement = {};
    localStorage.setItem("ui", JSON.stringify(uiData));
  }
  return orgResponse.data;
}
export async function fetchUserDetails() {
  let userData = getUserFromLocalStorage();
  let userResponse = await API.USERS.FETCH_USER(userData["user_id"]);

  userData["logo"] = userResponse.data.logo;
  userData["administration_rights_enabled"] = userResponse.data.administration_rights_enabled;
  userData["enable_designer_view"] = userResponse.data.enable_designer_view
  userData["enable_org_profile_customization"] = userResponse.data.enable_org_profile_customization
  localStorage.setItem("user", JSON.stringify(userData));
  return userResponse.data;
}
export function formatNumberWithCommas(value, isIndianFormat, options) {
  // For options parameter, check here:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
  if (typeof value === "string") {
    value = value.replace(/,/g, "");
  }
  if (!options) {
    options = {};
  }
  // options.minimumFractionDigits = 2;
  options.maximumFractionDigits = 2;
  if (isIndianFormat) {
    return parseFloat(value).toLocaleString("en-IN", options);
  } else {
    return parseFloat(value).toLocaleString("en-US", options);
  }
}
export function checkCountryCodeForGenability(countryCode) {
  if (
    getOrganisationFromLocalStorage().is_genability_enabled &&
    ['US', 'CA', 'AU', 'MX'].includes(countryCode))
  {
    return true;
  } else {
    return false
  }
}

export async function paginationHelper(data) {
  let origArray = data.origArray;
  let paginationDict = data.paginationDict;

  let paginationUnavailable =
    paginationDict.copyUrl == paginationDict.nextUrl || !paginationDict.nextUrl;
  if (paginationUnavailable) {
    return;
  }

  paginationDict.copyUrl = paginationDict.nextUrl;
  paginationDict.busy = true;
  let response = await axios.get(paginationDict.nextUrl);
  let newArray = origArray.concat(response.data.results);

  paginationDict.nextUrl = response.data.next;
  paginationDict.busy = false;

  return newArray;
}

export function replaceGoogleApiKeyInUrl(url) {
  if (!url.includes("maps.googleapis")) {
    return url;
  }
  if (url && url.includes("&key=")) {
    let beforeAPIkey = url.split("&key=")[0];
    let apiKeyAndAfter = url.split("&key=")[1];
    let afterAPIkey = apiKeyAndAfter.split("&")[1];
    let newUrl = beforeAPIkey + "&key=" + GOOGLE_API_KEY;
    if (afterAPIkey) {
      newUrl += "&" + afterAPIkey;
    }
    return newUrl;
  }
  return url;
}

export function convertLossDataFromArrayToGraphFormat(lossData) {
  const lossDataGraphFormat = [
    {
      Irradiation: [
        {
          initial_name: "Horizontal Global Irradiation",
          initial_value: lossData[0].Irradiance[0].initial_value,
          units: "kWh/m²",
        },
        {
          loss: "Global Incidence on PV plane",
          percent: lossData[0].Irradiance[1].percent,
        },
        {
          loss: "Near Shading: irradiance loss",
          percent: lossData[0].Irradiance[2].percent,
        },
        {
          loss: "Irradiance after reflection",
          percent: lossData[0].Irradiance[3].percent,
        },
        {
          loss: "Soiling loss factor",
          percent: lossData[0].Irradiance[4].percent,
        },
      ],
    },
    {
      DC: [
        {
          initial_name: "Nominal Energy after PV conversion",
          initial_value: lossData[1].DC[0].initial_value,
          units: "kWh",
        },
        {
          loss: "PV loss due to environmental condition",
          percent: lossData[1].DC[1].percent,
        },
        {
          loss: "Light Induced Degradation",
          percent: lossData[1].DC[2].percent,
        },
        {
          loss: "Mismatch loss, modules and strings",
          percent: lossData[1].DC[3].percent,
        },
        {
          loss: "Ohmic wiring loss",
          percent: lossData[1].DC[4].percent,
        },
      ],
    },
    {
      AC: [
        {
          loss: "Clipping losses",
          percent: lossData[2].AC[0].percent,
        },
        {
          loss: "DC/AC conversion",
          percent: lossData[2].AC[1].percent,
        },
        {
          loss: "System Unavailability",
          percent: lossData[2].AC[2].percent,
        },
        {
          loss: "AC Ohmic loss",
          percent: lossData[2].AC[3].percent,
        },
      ],
    },
  ];
  return lossDataGraphFormat;
}

export function convertLossDataFromJsonToGraphFormat(lossData) {
  const lossDataGraphFormat = [
    {
      Irradiance: [
        {
          initial_name: "Horizontal Global Irradiation",
          initial_value: lossData.energy.irradiance.ghi,
          units: "kWh/m²",
        },
        {
          loss: "Global Incidence on PV plane",
          percent: lossData.losses.irradiance.epoa,
        },
        {
          loss: "Near Shading: irradiance loss",
          percent: lossData.losses.irradiance.shading,
        },
        {
          loss: "Soiling loss factor",
          percent: lossData.losses.irradiance.soiling,
        },
        {
          loss: "Irradiance after reflection",
          percent: lossData.losses.irradiance.iam,
        },
      ],
    },
    {
      DC: [
        {
          initial_name: "Nominal Energy after PV conversion",
          initial_value: lossData.energy.dc.nominal,
          units: "kWh",
        },
        {
          loss: "PV loss due to environmental condition",
          percent: lossData.losses.dc.environmental,
        },
        {
          loss: "Light Induced Degradation",
          percent: lossData.losses.dc.lid,
        },
        {
          loss: "Mismatch loss, modules and strings",
          percent: lossData.losses.dc.mismatch,
        },
        {
          loss: "Ohmic wiring loss",
          percent: lossData.losses.dc.dc_ohmic,
        },
      ],
    },
    {
      AC: [
        {
          loss: "Clipping losses",
          percent: lossData.losses.ac.clipping,
        },
        {
          loss: "DC/AC conversion",
          percent: lossData.losses.ac.inverter_conversion,
        },
        {
          loss: "System Unavailability",
          percent: lossData.losses.ac.unavailability,
        },
        {
          loss: "AC Ohmic loss",
          percent: lossData.losses.ac.ac_ohmic,
        },
      ],
    },
  ];
  return lossDataGraphFormat;
}

import { useCreditsStore } from "./stores/credits";
import { useOrgInventoryBOQStore } from "./stores/organisation-inventory-BOQ";
import { useOrganisationStore } from "./stores/organisation";
import { useMiscStore } from "./stores/misc";
import NotificationsManager from "./services/notifications";

export async function initializeStore() {
  let creditsStore = useCreditsStore();
  let orgInventoryBOQStore = useOrgInventoryBOQStore();
  let organisationStore = useOrganisationStore();

  // Warning/Note: This call to fetch credit balacnce is required here 
  // since the credit balance stored in the store is used throughout the app.
  creditsStore.FETCH_AND_UPDATE_CREDIT_BALANCE();
  orgInventoryBOQStore.SET_ORGANISATION_ID();
  await organisationStore.GET_ALL_AVAILABLE_PRICING_PLANS();
  organisationStore.SET_QUOTA_DETAILS_AND_DEFAULT_VALUES();
}

export function initializeNotifications() {
  let miscStore = useMiscStore();
  miscStore.notificationsManager = new NotificationsManager();
  let notificationState = miscStore.notificationState;
  let notificationList = notificationState.notificationList;
  let notificationsManager = miscStore.notificationsManager;
  notificationsManager.addEventListener("message", (obj) => {
    notificationState.tempMessage = obj;
    if (notificationList.length === 3) {
      notificationList[0].close();
      notificationList.shift();
    }
    miscStore.SET_NOTIFICATION_STATE(true);
  });

  notificationsManager.addEventListener("open", async (event) => {
    try {
      notificationState.isLive = true;
      let notificationStatus =
        await API.NOTIFICATIONS.GET_NEW_NOTIFICATION_STATUS();
      miscStore.SET_NOTIFICATION_STATE(notificationStatus.data);
    } catch (err) {
      console.error(err);
    }
  });

  notificationsManager.addEventListener("close", async (event) => {
    notificationState.isLive = false;
  });
}

export function deactivateNotifications() {
  let miscStore = useMiscStore();
  miscStore.notificationsManager?.closeConnection();
  miscStore.notificationState?.notificationList.forEach((obj) => obj.close());
  miscStore.notificationState.isLive = false;
}

export function getDetailsFromGoogleMapsResult(result, allResults = null) {
  let addressComponents = result?.address_components?.filter((component) => {
    return component.types[0] == "administrative_area_level_1";
  });
  let state = addressComponents?.[0]?.long_name;
  let countryCodeObj = {};
  let county = null;
  let city = null;
  let postalCode = null;
  if (allResults) {
    for (let eachResult of allResults) {
      countryCodeObj = eachResult?.address_components?.filter((component) => {
        return component.types.includes("country");
      });
      if (Object.keys(countryCodeObj).length > 0) break;
    }
    for (let eachResult of allResults) {
      county = eachResult?.address_components?.filter((component) => {
        return component.types.includes("administrative_area_level_2");
      });
      if (Object.keys(county).length > 0) break;
    }
    for (let eachResult of allResults) {
      city = eachResult?.address_components?.filter((component) => {
        return component.types.includes("locality");
      });
      if (Object.keys(city).length > 0) break;
    }
    for (let eachResult of allResults) {
      postalCode = eachResult?.address_components?.filter((component) => {
        return component.types.includes("postal_code");
      });
      if (Object.keys(postalCode).length > 0) break;
    }
  } else {
    countryCodeObj = result?.address_components?.filter((component) => {
      return component.types.includes("country");
    });
    county = result?.address_components?.filter((component) => {
      return component.types.includes("administrative_area_level_2");
    });
    city = result?.address_components?.filter((component) => {
      return component.types.includes("locality");
    });
    postalCode = result?.address_components?.filter((component) => {
      return component.types.includes("postal_code");
    });
  }
  let countryCode = countryCodeObj?.[0]?.short_name;
  return { state, countryCode, postalCode, county, city };
}

export function isTataOrg() {
  return getOrganisationFromLocalStorage().id == tata_org_id;
}

export function isHavellsOrg() {
  return getOrganisationFromLocalStorage().id == havells_org_id;
}

export function isRelianceOrg() {
  return getOrganisationFromLocalStorage().crm_settings_enabled;
}

export function getLeadPipelineStages() {
  return JSON.parse(localStorage.getItem("ui")).leadManagement.pipeline;
}

export function getLeadSourceOptions() {
  return [
    {
      value: "website",
      label: "Website",
    },
    {
      value: "referral",
      label: "Referral",
    },
    {
      value: "marketing",
      label: "Marketing Campaigns",
    },
    {
      value: "outbound",
      label: "Outbound",
    },
  ];
}

export function getExpertServicesList() {
  const allServicesInfo = JSON.parse(localStorage.getItem("allServicesInfo"));
  let services = [];
  if (allServicesInfo) {
    for (let template of allServicesInfo.service_templates) {
      services.push({
        id: template.id,
        serviceName: template.template_constant[0].name,
        description: template.template_constant[0].description,
        detailedService: template,
        basePrice: template.base_price,
        isLoading: false
      });
    }
  }

  return services;
}

export function getSelfDesignInfo() {
  const allServicesInfo = JSON.parse(localStorage.getItem("allServicesInfo"));
  return allServicesInfo?.self_designing_info;
}

export const orderStatusDict = {
  incomplete: "Incomplete",
  pending: "Payment Pending",
  order_placed: "Order Placed",
  in_process: "Active",
  on_hold: "On Hold",
  complete: "Complete",
  cancelled: "Cancelled",
  rejected: "Rejected",
  "under review": "Under Review",
};

export function getOrderStatusColor(status) {
  if (status == "complete") {
    return "#2cc21c";
  } else if (["incomplete", "rejected", "cancelled"].includes(status)) {
    return "#ff0404";
  }else if (status=="under review") {
    return "#8324B2";
  }else if (status=="pending") {
    return "#FF8F40";
  } else {
    return "#409eff";
  }
}

export function connectWebsocketUtil(url) {
  let chatSocket = new WebSocket(url);

  chatSocket.onopen = function (event) {
    console.log(event);
    console.log("!!Successfully connected to the websocket server...");
  };
  return chatSocket;
}

export function getMessagesFromWebsocketUtil(chatSocket) {
  console.log("getmessages");
  chatSocket.onmessage = function (event) {
    console.log("!!!Message from server ", event.data);
    window.dispatchEvent(
      new CustomEvent("chatSocket-onMessage-called", {
        detail: {
          storage: event.data,
        },
      })
    );
  };
}

export function chatSendUtil(data, id, chatSocket, PostObj) {
  let messageVmodal = data;
  console.log(messageVmodal);
  if (messageVmodal) {
    let jsonSt = JSON.stringify(PostObj);
    chatSocket.send(jsonSt);
    // this.scrollToBottom();
    messageVmodal = "";
    // this.getMessagesFromWebsocket();
  }
}

const reverseDisplay = (myList) => {
  let result = myList.slice().reverse();
  return result;
};

export async function getChatMessagesFromDbUtil(id) {
  const response = await API.CHAT_iNFO.FETCH_CHAT_MESSAGES(id);
  console.log("!! messages from rest api", response);
  let chatMessages = response.data.results;
  chatMessages = reverseDisplay(chatMessages);
}

export function generateAvatarColor(name, count) {
  if (name === count) {
    // Return gray color for "+x" element
    return "#CCCCCC";
  }
  if (name) {
    let hashCode = 0;
    for (let i = 0; i < name.length; i++) {
      hashCode = name.charCodeAt(i) + ((hashCode << 5) - hashCode);
    }

    const index = Math.abs(hashCode) % colors.length;
    return colors[index];
  }

  return "#466cbe"
}

// Unifying the avatar color generation functions
export const generateColorFromName = generateAvatarColor

export function getProjectImageUrl(lat, lng, zoom, boundEdgePixels = 512) {
  zoom ||= 19;
  const mapWidth = Math.round(boundEdgePixels);
  const mapHeight = Math.round(boundEdgePixels);
  let mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat.toString()},
  ${lng.toString()}&scale=2&zoom=${zoom.toString()}&maptype=satellite&size=${mapWidth}x${mapHeight}&key=${GOOGLE_API_KEY}`;
  if (zoom >= 21) mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${lat.toString()},
  ${lng.toString()}&scale=1&zoom=${zoom.toString()}&maptype=satellite&size=${mapWidth}x${mapHeight}&key=${GOOGLE_API_KEY}`;
  return signRequest(
    mapUrl,
    GOOGLE_SIGNING_SECRET
  );
}

export async function getThumbnailForDesignFromVersionRefId(refId, project) {
  // First try to get the image from lambda
  let data = await API.DESIGN_VERSIONS.FETCH_LAYOUT_IMAGE_FROM_LAMBDA(refId)
  let thumbUrl = data?.detailed_layout
  if (thumbUrl) { return thumbUrl }

  // Else use the default project (Google Maps) thumbnail
  thumbUrl = getProjectImageUrl(project.latitude, project.longitude, project.zoom)

  // Cache the Google Maps image on the backend
  API.DESIGN_VERSIONS.CACHE_REPORT_IMAGES(refId, {
    layoutImageUrl: thumbUrl
  })
  return thumbUrl
}

export function setThumbnailsForDesignsInProject(project) {
  let designList = project.designs
  designList.forEach(async (design) => {
    // If the thumbnail image is already there, do nothing
    if (design.versions.layout_image) { return }

    // Else fetch the image
    design.versions.layout_image = await getThumbnailForDesignFromVersionRefId(design.versions.reference_id, project)
  });
}

export function setUiInStorage(ui) {
  localStorage.setItem("ui", JSON.stringify(ui));
}

export function getUiFromStorage() {
  {
    let ui = JSON.parse(localStorage.getItem("ui")) || {};
    ui.leadManagement ||= {};
    ui.taskManagement ||= {};
    ui.leadSummary ||= {};
    ui.pipeline ||= {};
    ui.dashboard ||= {};
    return ui;
  }
}

export function isGenerationDisabled(design) {
  return modulesAndInvertersNotAddedToDesign(design);
}

function modulesAndInvertersNotAddedToDesign(design) {
  let nameplateDcSize = design?.versions?.summary?.nameplate_dc_size
  let acSize = design?.versions?.summary?.ac_size

  return !(nameplateDcSize && acSize);
}

export function batteryNotAddedToHybridInverterDesign(design) {
  let isHybridInverter = design?.has_hybrid_inv

  return isHybridInverter && !isBatteryPresent(design)
}

function isBatteryPresent(design) {
  return design?.total_battery_capacity || design?.battery_detail?.length
}

function isBatteryPresentSM(data) {
  return data?.battery_model || data?.battery_data?.batteries?.length
}

function pricingAndConsumptionNotAddedToDesign(design) {
  let basicCost = design?.versions?.basic_cost
  let financials = design?.pricing
  let isPaybackPresent = (financials && financials.length > 0 && financials[0].payback)

  let pricingAndConsumptionNotAdded = (
    !basicCost ||
    !isPaybackPresent
  )
  return pricingAndConsumptionNotAdded
}

export function isWebProposalDisabled(design) {

  let orderStatus = design?.request_expert_service?.order_status

  if (orderStatus == "cancelled" || orderStatus == "rejected") {
    return true;
  }

  let isDisabled = (
    modulesAndInvertersNotAddedToDesign(design) ||
    pricingAndConsumptionNotAddedToDesign(design)
  )

  return isDisabled;
}

export function isDocProposalDisabled(design) {

  let orderStatus = design?.request_expert_service?.order_status

  if (orderStatus == "cancelled" || orderStatus == "rejected") {
    return true;
  }

  let isDisabled = (
    modulesAndInvertersNotAddedToDesign(design)
  )

  // For US and Tata reports, a lot of pages depend on payback period.
  // Hence, there is a check here for payback as well.
  if (
    isOrgOfCountryNorthAmerica() ||
    isTataOrg()
  ) {
    let pricingAndCosumptionNotAdded = pricingAndConsumptionNotAddedToDesign(design)
    isDisabled = isDisabled || pricingAndCosumptionNotAdded
  }
  return isDisabled;
}

export function tooltipMessageForDisabledDocumentProposalButton(design) {
  if (modulesAndInvertersNotAddedToDesign(design)) {
    return "Please add modules and inverters to the design, and calculate generation.";
  } else if (pricingAndConsumptionNotAddedToDesign(design)) {
    return "Please add consumption and pricing to the project.";
  } else {
    return "Button is disabled for some reason."
  }
}

export function isESSProposalDisabled(design) {

  return !consumptionPresent(design.project) || !design?.versions?.basic_cost || !isBatteryPresent(design);
}

export function ESSProposalDisabledTooltip(design) {
  if (!consumptionPresent(design.project)) {
    return "Please add Consumption details to the system to generate the proposal.";
  }

  if (!isBatteryPresent(design)) {
    return "Please add a battery to the system to generate the proposal.";
  }

  if (!design?.versions?.basic_cost) {
    return "Please add a battery pricing to the system to generate the proposal.";
  }

  return "";
}


export function isSalesModeReportDisabled(info) {
  let { orderStatus, nameplateDcSize, acSize, financials, reportTemplate, basicCost, design , project} =
    info;

  if (orderStatus == "cancelled" || orderStatus == "rejected") {
    return true;
  }
  let isDisabled = !nameplateDcSize || !(acSize >= 0) || !consumptionPresent(design.project || project);
  // For US and Gazebo reports, a lot of pages depend on payback period. Hence, there is a check for payback as well.
  // PRICING NOT REQUIRED FOR SALES MODE REPORT V2
  // if ( 
  //   reportTemplate == "solar_labs_usa" ||
  //   reportTemplate == "report_gazebo" ||
  //   reportTemplate == "tata_power"
  // ) {
  //   isDisabled =
  //     isDisabled ||
  //     (financials.length > 0 && !financials[0].payback) || !basicCost ||
  //     financials.length == 0;
  // }
  return isDisabled;
}
export function batteryUsableCapacity(model, options) {
  options ||= {}
  let requireValue = options.requireValue
  let usableCapacity = 0
  let usableCapacityKey = 'usable_capacity_kWh'
  let possibleCapacityKeys = ['usable_capacity_Ah', 'usable_capacity_kWh']
  for (let key of possibleCapacityKeys) {
    if (model[key]) {
      // If the unit is usable_capacity_VA, you can go bang your head,
      // as we don't have a formula for it (according to Sneha Thumma).
      usableCapacityKey = key
      usableCapacity = model[key]
      break
    }
  }
  let usableCapacityUnit = usableCapacityKey.replace('usable_capacity_', '')
  if (requireValue) {
    if (usableCapacityKey == 'usable_capacity_Ah') {
      usableCapacity = (usableCapacity * model.voltage_V) / 1000
    }
    return usableCapacity
  } else {
    return `${usableCapacity}${usableCapacityUnit}`
  }
}

export function loadBatteryModels(bat) {
  let manufacturerIdToLoad = bat?.manufacturer?.id
  if (manufacturerIdToLoad == bat?.batteryModelsDict?.curManufacturerId) { return }
  else if (bat.batteryModelsDict.list.length > 1) { return }

  if (bat.batteryModelsDict.isLoading &&
    bat.batteryModelsDict.curManufacturerId == manufacturerIdToLoad
  ) {
    return
  }
  bat.batteryModelsDict.isLoading = true
  if (bat.battery.manufacturer != bat.manufacturer.id) {
    bat.batteryModelsDict.list = []
  }
  bat.curManufacturerId = manufacturerIdToLoad
  API.DESIGNS.GET_LIST_OF_BATTERIES_FROM_MANUFACTURER(manufacturerIdToLoad).then(resp => {
    if (manufacturerIdToLoad != bat.manufacturer.id) { return }
    resp.data.results.forEach(model => {
      let existingBattery = bat.batteryModelsDict.list.find(battery => battery.id == model.id)
      if (!existingBattery) { bat.batteryModelsDict.list.push(model) }
    })
    bat.batteryModelsDict.isLoading = false
    bat.batteryModelsDict.paginationDict.nextUrl = resp.data.next
  }).catch(err => {
    console.error(err)
    bat.batteryModelsDict.isLoading = false
    this.$message({
      showClose: true,
      message: 'Unable to load battery model details.',
      type: "error",
      center: true
    })
  })
}
export function isDesignExpertService(design) {
  return design?.request_expert_service?.id;
}

export function showProposalButtons(design) {
  if (!isDesignExpertService(design)) {
    return true;
  }

  if (
    isDesignExpertService(design) &&
    design?.request_expert_service?.order_status == "cancelled"
  ) {
    return false;
  }

  return true;
}

export const mapPinImageUrl =
  "https://marketing-portal-assets.s3.ap-south-1.amazonaws.com/generic/location-pin.png";

export const chatEvents = {
  ORDER_ASSIGNMENT: {
    message: "Assigned to user_name ",
    event: "Order Assignment",
  },
  DOCUMENT_UPLOAD: {
    message: "user_name uploaded a document",
    event: "document upload",
  },
  REVISION_REQUESTED: { message: "user_name requested a revision" },
  CONSUMPTION_UPDATED: { message: "user_name updated the consumption" },
  SITE_SURVEY_UPDATED: { message: "user_name updated the site survey" },
  NOTES_ADDED: {
    message: "user_name notes updated/added",
    event: "notes added",
  },
  AHJ_ADDED: { message: "user_name AHJ updated/added", event: "ahj added" },
  MODULES_ADDED: {
    message: "user_name Modules updated/added",
    event: "modules added",
  },
  INVERTER_ADDED: {
    message: "user_name Inverters updated/added",
    event: "Inverters added",
  },
  STORAGE_ADDED: {
    message: "user_name Storage updated/added",
    event: "Storage added",
  },
  PROPOSAL_REQUESTED: {
    message: "user_name Proposal Requested",
    event: "Proposal Requested",
  },
  COMPLETED_DESIGN: {
    message: "user_name completed the design",
    event: "design completed",
  },
  ORDER_ACCEPTED: {
    message: "user_name accepted the order",
    event: "order accepted",
  },
  REVISION_REQUEST_ACCEPTED: {
    message: "user_name accepted revesion request",
    event: "accepted revesion request",
  },
  ORDER_REJECTED: {
    message: "user_name rejected the order",
    event: "order rejected",
  },
  ORDER_CANCELLED: {
    message: "user_name cancelled the order",
    event: "order cancelled",
  },
  ORDER_PENDING: {
    message: "user_name moved the order on hold",
    event: "order pending",
  },
  PROPOSAL_OR_ORDER_REJECTED_CUSTOMER: {
    message: "user_name rejected the proposal/order",
    event: "rejected the proposal/order",
  },
};

export const sendEvent = async (eventName, orderId, assigneeId) => {
  const designOrdersStore = useDesignOrdersStore();
  const assignedUser = designOrdersStore.assignedUser;
  if (!eventName || !orderId) throw new Error();

  const url = `${DATABASE_URL_FOR_WEBSOCKET}order/${orderId}/`;
  const { user_id, first_name, last_name } = getUserFromLocalStorage();
  let assigneeName = "";
  if (eventName.event === 'Order Assignment') {
    assigneeName = `${assignedUser.first_name} ${assignedUser.last_name}`
  }
  const chatSocket = new WebSocket(url);
  chatSocket.onopen = function (event) {
    console.log(`Successfully connected for event ${eventName.event}`);

    const data = {
      order_id: orderId,
      message: eventName.message.replace(
        "user_name",
        assigneeName ? assigneeName : `${first_name} ${last_name}`
      ),
      user_id: assigneeId || user_id,
      message_type: "event",
    };
    chatSocket.send(JSON.stringify(data));

    chatSocket.close();
  };

  chatSocket.onerror = function (event) {
    console.log(`Error occured for event ${eventName.event}`);
    console.log(event);

    throw new Error(`Error occured for event ${eventName.event}`);
  };

  chatSocket.onclose = function (event) {
    console.log(`Closed for event ${eventName.event}`);

    return `Closed for event ${eventName.event}`;
  };
};

export const reportPagesListNonUs = [
  {
    label: "title",
    name: "Title",
  },
  {
    label: "company-overview",
    name: "Company Overview",
  },
  {
    label: "heat-map",
    name: "Irradiance Map",
  },
  {
    label: "shadow-analysis",
    name: "Shadow Analysis",
  },
  {
    label: "system-metrics",
    name: "System Metrics",
  },
  {
    label: "system-pricing",
    name: "System Pricing",
  },
  {
    label: "savings",
    name: "Savings",
  },
  {
    label: "battery-storage",
    name: "Battery Storage",
  },
  {
    label: "components",
    name: "Components",
  },
  {
    label: "monthly-production",
    name: "Monthly Production",
  },
  {
    label: "monthly-savings",
    name: "Monthly Savings",
  },
  {
    label: "monthly-table",
    name: "Monthly Table",
  },
  {
    label: "field-segments",
    name: "Field Segments",
  },
  {
    label: "losses",
    name: "Losses",
  },
  {
    label: "our-team",
    name: "Our Team",
  },
  {
    label: "frequently-asked-questions",
    name: "Frequently Asked Questions",
  },
  {
    label: "bill-with-without-solar",
    name: "Cost of Not Going Solar",
  },
  {
    label: "environmental-impact",
    name: "Environmental Impact",
  },
  {
    label: "thank-you",
    name: "Thank You",
  },
];

export const reportPagesPlainListNonUs = reportPagesListNonUs.map(
  (page) => page.label
);

export const shadeTogglePagesListUS = [
  'shadow-analysis',
  'field-segments'
];

export const financialTogglePagesListUS = [
  "battery-storage",
  "pricing-summary-approvals",
  "pv-as-an-asset",
  "cost-of-not-going-solar",
  "pricing-details",
  "financial-details",
];


export const shadeTogglePagesListNonUs = [
  'heat-map',
  'shadow-analysis',
  'field-segments'
];

export const financialTogglePagesListNonUs = [
  'savings',
  'system-pricing',
  'battery-storage',
  'monthly-savings',
  'bill-with-without-solar'
];


export const leadSummaryTabList = [
  {
    title: 'leadInfo',
    label: 'Lead Info',
    icon: 'card-text',
    onlyOnMobile: true,
  },
  {
    title: 'tasks',
    label: 'Tasks',
    icon: 'list-check',
    onlyOnMobile: true,
  },
  {
    title: 'timeline',
    label: 'Activity',
    mobileLabel: 'Timeline',
    imgUrl: new URL(
      "/src/pages/leadManagement/components/assets/timelineMobileNav.svg",
      import.meta.url
    ).href,
    activeImgUrl: new URL(
      "/src/pages/leadManagement/components/assets/timelineMobileNavActive.svg",
      import.meta.url
    ).href,
  },
  {
    title: 'projectInfo',
    label: 'Project Info',
    icon: "folder",
  },
  {
    title: 'design',
    label: 'Design',
    imgUrl: new URL(
      "/src/pages/leadManagement/components/assets/designMobileNav.svg",
      import.meta.url
    ).href,
    activeImgUrl: new URL(
      "/src/pages/leadManagement/components/assets/designMobileNavActive.svg",
      import.meta.url
    ).href,
  },
  // Will be added with L2C2C changes
  // {
  //   title: 'solutionOrders',
  //   label: 'Solution Orders',
  //   icon: "folder",
  //   onlySolutionOrders: true
  // },
]

export function getActiveTabName(tabTitle) {
  let activeTab = leadSummaryTabList.find(tab => tab.title == tabTitle)
  return activeTab.mobileLabel || activeTab.label
}

export function extractInitials(text) {
  if (text) {
    text = text.trim().toUpperCase()

    // Extract the last word of the text
    const words = text.split(" ");
    const firstWord = words[0]
    const firstLetter = firstWord[0];

    // Extract the last word of the text
    const lastWord = words[words.length - 1];
    const lastWordFirstLetter = lastWord && lastWord != firstWord ? lastWord[0] : "";

    return firstLetter + lastWordFirstLetter;
  }
}

export function getUpdatedGazeboName(originalName) {
  const nameMapping = {
    'PGUS01-01M1-77- 4x8': 'PGUS01-M1-77-180-24',
    'PGUS01-01M1-77- 5x8': 'PGUS01-M1-77-225-30',
    'PGUS01-01M1-77- 6x8': 'PGUS01-M1-77-268-36',
    'PGUS01-01M1-77- 7x8': 'PGUS01-M1-77-312-43',
  };
  return nameMapping[originalName] || originalName;
}

export function getUpdatedModelName(originalName) {
  const nameMapping = {...powerModelNameMapping};
  return nameMapping[originalName.trim()] || originalName;
}

export function getNewSKUid(originalSku, inverterType) {
  const nameMapping = {...powerModelOldNewSKUMapping};
  let updatedSKU = nameMapping[originalSku.trim()] || originalSku;
  if(inverterType === CUSTOM_INVERTER_PGPP) updatedSKU = updatedSKU.replace('M1', 'NP');
  else updatedSKU = updatedSKU.replace('NP', 'M1');
  return updatedSKU;
}

let energyOffsetWithBatteryDict = {
  label: "Energy Offset with Solar & Battery",
  label_key: "energy_offset_with_solar_battery",
  value: (data, countryCode) => {
    let value = 0;
    if(isBatteryPresentSM(data)) {
      value = (
        data.energy_offset_with_solar_and_battery ??
        data.battery_data?.energy_offset_with_solar_and_battery
      )
    }
    return formatNumber(value, "decimal", countryCode) + "%"
  }
}

export const batteryCards = {
  yearlyGridImport: {
    text: "Yearly Grid Import",
    text_key: "yearly_grid_import",
    value: (data, countryCode) => {
      let value = 0;
      if (isBatteryPresentSM(data)) {
        value = data.battery_data?.grid_metrics?.g_import ?? data?.grid_metrics?.g_import
      }

      if (isNaN(value)) {
        return "-"
      }

      return formatNumber(value, "decimal", countryCode, 2) + ' kWh';
    }
  },
  yearlyGridExport: {
    text: "Yearly Grid Export",
    text_key: "yearly_grid_export",
    value: (data, countryCode) => {
      let value = 0;
      if (isBatteryPresentSM(data)) {
        value = data.battery_data?.grid_metrics?.g_export ?? data?.grid_metrics?.g_export
      }

      if (isNaN(value)) {
        return "-"
      }

      return formatNumber(value, "decimal", countryCode, 2) + ' kWh';
    }
  },
  gridIndependence: {
    text: "On-site Grid Independence",
    text_key: "grid_independence",
    value: (data, countryCode) => {
      let value = 0;
      if (isBatteryPresentSM(data)) {
        value = data.battery_data?.grid_metrics?.grid_independence ?? data?.grid_metrics?.grid_independence
      }

      if (isNaN(value)) {
        return "-"
      }

      return formatNumber(value, "decimal", countryCode, 2) +' %';
    }
  },
  yearlyBillWithoutSolarbattery: {
    text: "Yearly Bill without Solar and Battery",
    text_key: "yearly_bill_without_solar_battery",
    value: (data, countryCode) => {
      let value = 0;
      if(isBatteryPresentSM(data)) {
        value = data.financial_data?.bill_without_solar[0] || 0
      }
      return formatNumber(value, "currency", countryCode)
    }
  },
  yearlyBillWithSolarbattery: {
    text: "Yearly Bill with Solar and Battery",
    text_key: "yearly_bill_with_solar_battery",
    value: (data, countryCode) => {
      let value = 0;
      if(isBatteryPresentSM(data)) {
        value = data.financial_data?.residual_energy_post_battery_bill[0] || 0
      }
      return formatNumber(value, "currency", countryCode)
    }
  },
  lifetimeSavingSolarbattery: {
    text: "Lifetime Savings from Battery",
    text_key: "lifetime_saving_solar_battery",
    value: (data, countryCode) => {
      let value = 0;
      if(isBatteryPresentSM(data)) {
        value = data?.financial_data?.additional_savings_post_battery || 0
      }
      return formatNumber(value, "currency", countryCode);
    }
  },
  yearlyBillWithoutBattery: {
    text: "Yearly Bill without Battery",
    text_key: "lifetime_saving_solar_battery",
    value: (data, countryCode) => {
      let value = 0;
      if(isBatteryPresentSM(data)) {
        value = data.financial_data?.bill_without_solar[0] || data.bills.bill_without_solar
      }
      return formatNumber(value, "currency", countryCode)
    }
  },
  yearlyBillWithBattery: {
    text: "Yearly Bill with Battery",
    text_key: "lifetime_saving_solar_battery",
    value: (data, countryCode) => {
      let value = 0;
      if(isBatteryPresentSM(data)) {
        value = data.financial_data?.residual_energy_post_battery_bill[0] || data.bills.residual_energy_post_battery_bill
      }
      return formatNumber(value, "currency", countryCode)
    }
  },
  lifetimeSavingBattery: {
    text: "Lifetime Savings from Battery",
    text_key: "lifetime_saving_solar_battery",
    value: (data, countryCode) => {
      let value = 0;
      if(isBatteryPresentSM(data)) {
        value = data.financial_data?.additional_savings_post_battery || data.additional_savings_post_battery;
      }
      return formatNumber(value, "currency", countryCode)
    }
  },
  energyOffsetWithSolar: {
    iconName: "solarAndStorageIcon",
    iconPath: new URL(
      "/src/assets/drop/brightness-high (2).svg",
      import.meta.url
    ).href,
    text: "Energy Offset with Solar",
    text_key: "energy_offset_with_solar",
    value: (data) => {
      let value = 0;
      if(isBatteryPresentSM(data)) {
        value = (
          ( data.battery_data?.energy_offset_with_solar ||
          data.energy_offset_with_solar ) ?? 0
        )
      }
      return value + '%'
    }
  },
  energyOffsetWithSolarBattery: {
    iconName: "offsetWithSolarAndBatteryIcon",
    iconPath: new URL(
      "/src/assets/drop/chargeBattery.svg",
      import.meta.url
    ).href,
    text: energyOffsetWithBatteryDict.label,
    text_key: energyOffsetWithBatteryDict.label_key,
    value: energyOffsetWithBatteryDict.value,
  },
  reducedPaybackPeriod: {
    iconName: "reducedPaybackIcon",
    iconPath: new URL(
      "/src/assets/drop/clock.svg",
      import.meta.url
    ).href,
    text: "Reduced Payback Period",
    text_key: "reduced_payback_period",
    value: (reportData) => {
      // TODO: Pending from backend
      return "0 years"
    }
  },
  storageWithLoad: {
    iconName: "storageAndLoadIcon",
    iconPath: new URL(
      "/src/assets/drop/cloud (2).svg",
      import.meta.url
    ).href,
    text: "on storage with essential load",
    text_key: "on_storage_with_essential_load",
    value: (data) => {
      let value = (
        ( data.battery_data?.battery_backup_on_storage_and_load ||
        data.battery_backup_on_storage_and_load ) ?? 0
      )
      return modifyBackupTime(value)
    }
  },
  storageOnly: {
    iconName: "storageOnlyIcon",
    iconPath: new URL(
      "/src/assets/drop/Group 1734.svg",
      import.meta.url
    ).href,
    text: "on storage only",
    text_key: "on_storage_only",
    value: (data) => {
      let value = (
        ( data.battery_data?.battery_backup_on_storage ||
        data.battery_backup_on_storage ) ?? 0
      )
      return modifyBackupTime(value)
    }
  },
  solarAndStorage: {
    iconName: "solarAndStorageIcon",
    iconPath: new URL(
      "/src/assets/drop/brightness-high (2).svg",
      import.meta.url
    ).href,
    text: "on solar & storage",
    text_key: "on_solar_storage",
    value: (data) => {
      let value = (
        ( data.battery_data?.battery_backup_on_storage_and_solar ||
        data.battery_backup_on_storage_and_solar ) ?? 0
      )
      return modifyBackupTime(value)
    }
  },
  percentageOfSelfConsumption: {
    iconName: "storageAndLoadIcon",
    iconPath: new URL(
      "/src/assets/drop/cloud (2).svg",
      import.meta.url
    ).href,
    text: "on % of self consumption",
    text_key: "on_percentage_of_self_consumption",
    value: (data) => {
      let value = (
        ( data.battery_data?.battery_backup_on_storage_and_load ||
        data.battery_backup_on_storage_and_load ) ?? 0
      )
      return modifyBackupTime(value)
    }
  },
}

export const batteryCardMapping = {
  bill_savings: {
    first: batteryCards.yearlyGridImport,
    second: batteryCards.yearlyGridExport,
    third: batteryCards.gridIndependence,
    fourth: batteryCards.yearlyBillWithoutSolarbattery,
    fifth: batteryCards.yearlyBillWithSolarbattery,
    sixth: batteryCards.lifetimeSavingSolarbattery,
    // first: batteryCards.energyOffsetWithSolar,
    // second: batteryCards.energyOffsetWithSolarBattery,
    // third: batteryCards.reducedPaybackPeriod,
  },
  back_up_home_essentials: {
    first: batteryCards.storageWithLoad,
    second: batteryCards.storageOnly,
    third: batteryCards.solarAndStorage,
  },
  full_energy_independence: {
    first: batteryCards.percentageOfSelfConsumption,
    second: batteryCards.storageOnly,
    third: batteryCards.solarAndStorage,
  },
}


export const batteryCardMappingEssOnly = {
  bill_savings: {
    first: batteryCards.yearlyBillWithoutBattery,
    second: batteryCards.yearlyBillWithBattery,
    third: batteryCards.lifetimeSavingBattery,
  },
  back_up_home_essentials: {
    first: batteryCards.storageWithLoad,
    second: batteryCards.storageOnly,
  },
  full_energy_independence: {
    first: batteryCards.percentageOfSelfConsumption,
    second: batteryCards.storageOnly,
  },
}

export const getBatteryCard = (choice, cardNumber, essOnlyDesign = false) => {
  const mapping = essOnlyDesign ? batteryCardMappingEssOnly : batteryCardMapping;

  // Check if the choice and cardNumber exist in the mapping
  if (mapping[choice] && mapping[choice][cardNumber]) {
    return mapping[choice][cardNumber];
  } else {
    // Handle the case where the choice or cardNumber is invalid
    return null;
  }
};

// This is just temporary, will be removed with coming battery2.0
export const batteryDetailsMapping = {
  bill_savings: energyOffsetWithBatteryDict,
  back_up_home_essentials: energyOffsetWithBatteryDict,
  full_energy_independence: energyOffsetWithBatteryDict,
}

export const batterySecondaryInfoMapping = {
  bill_savings: {
    first: batteryCards.yearlyGridImport,
    second: batteryCards.yearlyGridExport,
    third: batteryCards.gridIndependence,
    fourth: batteryCards.yearlyBillWithoutSolarbattery,
    fifth: batteryCards.yearlyBillWithSolarbattery,
    sixth: batteryCards.lifetimeSavingBattery,
  },
  back_up_home_essentials: energyOffsetWithBatteryDict,
  full_energy_independence: energyOffsetWithBatteryDict,
}

export const batterySecondaryInfoMappingEssOnly = {
  bill_savings : {
    first: batteryCards.yearlyBillWithoutBattery,
    second: batteryCards.yearlyBillWithBattery,
    third: batteryCards.lifetimeSavingBattery,
  },
  back_up_home_essentials: {},
  full_energy_independence: {},
}

export const getBatterySecondaryCard = (cardNumber, choice, essOnlyDesign = false) => {
  const mapping = essOnlyDesign ? batterySecondaryInfoMappingEssOnly : batterySecondaryInfoMapping;

  // Check if the choice and cardNumber exist in the mapping
  if (mapping[choice] && mapping[choice][cardNumber]) {
    return mapping[choice][cardNumber];
  } else {
    // Handle the case where the choice or cardNumber is invalid
    return null;
  }
};

import { useDesignStore } from "./stores/design"
import { CUSTOM_INVERTER_PGPP } from "./core/coreConstants";

export function updateDesignIfCrm(leadId) {
  let designStore = useDesignStore()
  if (isCrmUser() && designStore.id && leadId == designStore.project.lead) {
    designStore.SET_DESIGN(designStore.id);
  }
}

export function getDefaultCoordinates() {
  let country = getOrganisationFromLocalStorage().country
  if (country == 91) {
    return {
      lat: 28.5421285,
      lng: 77.3348087
    }
  }
  else {
    return {
      lat: 37.566947399999996549,
      lng: -122.071335300000001212
    }
  }
}


export function isBomComponentNonCountable(comp) {
  let nonCountableComps = [
    'Handrail',
    'Walkways',
    'Safetyline',
    'DC Cable',
    'AC Cable',
    'Railings',
    'Cable Conduits',
    'Earthing Strip',
    'Lifeline',
  ]
  return nonCountableComps.includes(comp)
}

export function isBomComponentOfTypeLength(component) {
  if (component.unit_type == 'length') {
    return true
  } else if (component.unit_type) {
    // This else condition is required to handle other non length units for some BOM components.
    return false
  } else if (isBomComponentNonCountable(component.category || component.component)) {
    return true
  }
  return false
}

export function isBomComponentStructureOrRackingSystem(component) {
  return (
    component.component === 'Structure' ||
    component.component === 'Racking System' ||
    component.category === 'Structure' ||
    component.category === 'Racking System'
  )
}
export async function fetchSasToken(containerName){
  const postData = {
    "container":containerName
  }
  const response_Azure_sas = await API.AZURE_SAS_TOKEN.FETCH_AND_SET_SAS_TOKEN(postData);
  const sasToken = response_Azure_sas.data['sas_token'];
  return sasToken
}
export async function uploadFileToBlob(file, currImageName, containerName) {
  let storageAccountName = `downloadstsl`;
  if (!file) return [];
  const postData = {
    "container": containerName
  }
  const response_Azure_sas = await API.AZURE_SAS_TOKEN.FETCH_AND_SET_SAS_TOKEN(postData);
  const sasToken = response_Azure_sas.data['sas_token'];
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient = blobService.getContainerClient(containerName);
  await createBlobInContainer(containerClient, file, currImageName);
}

async function createBlobInContainer(containerClient, file, currentImageName) {
  // create blobClient for container
  // generate a unique name of File for Upload
  const blobClient = containerClient.getBlockBlobClient(currentImageName);
  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file[0].type } };

  // upload file
  let response = await blobClient.uploadData(file[0], options);
}

export function titleCase(str) {
  var splitStr = str.split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export function financeSelection(selectedFinance) {
  const financeOptions = {
    "cash": "Cash",
    "loan": "Loan",
    "ppa": "PPA",
    "lease": "Lease"
  };

  return financeOptions[selectedFinance] || null;
}

export function returnInitials(name) {
  if (!name || name.trim().length === 0) {
    return "N/A"; // Return empty string for blank names
  }

  const names = name.trim().split(" ");
  const initials = names.map((n) => n.charAt(0).toUpperCase());

  if (initials.length === 1) {
    return initials[0]; // Return single initial if only one name is available
  } else {
    return initials[0] + initials[initials.length - 1]; // Return first and last initials for multiple names
  }
}

export function consumptionPresent(projectData) {
  let consumptionDetails = projectData.consumption_details
  if (!consumptionDetails?.average_monthly_consumption) {
    return false
  }

  if (!consumptionDetails.utility_tariff_details.source) {
    return false
  }

  if (projectData.type_of_rate == 'tou') {
    return consumptionDetails.utility_tariff_details.utility_rate_name
  } else if ((projectData.type_of_rate == 'flat')) {
    return consumptionDetails.average_price_per_unit
  }
};

export function isUtilityTarrifDetails(utilityTariffDetails) {
  if (utilityTariffDetails && utilityTariffDetails.source == 'genability' && utilityTariffDetails.utility_provider_name && utilityTariffDetails.utility_rate_name) {
    return true;
  } else {
    return false;
  }
};

export function isGenabilityTariff(tariffDetails) {

  // Checking 'pre_solar_new_genability_tariff_data' because for older projects this key is not present. So we need to follow 
  if(tariffDetails.source == 'genability' && tariffDetails.pre_solar_new_genability_tariff_data && tariffDetails.post_solar_new_genability_tariff_data){
    return true;
  } else{
    return false;
  }
}

export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};

export async function countryStates(countryCode) {
  const url = `https://api.countrystatecity.in/v1/countries/${countryCode}/states`;
  let statesList = [];

  try {
      const response = await fetch(url, {
          headers: {
              "X-CSCAPI-KEY": COUNTRYSTATECITY_API_KEY
          }
      });

      if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
      }

      const stateDetails = await response.json();
      
      statesList = stateDetails.map(state => state.name);
      statesList.sort();
  } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
  }

  return statesList;
}

export async function getOrgSettings () {
  try {
    const res = await API.EXTERNAL_INTEGRATION.GET_ORG_SETTINGS(getOrganisationFromLocalStorage().org_settings_id);
    const isDocuSignIntegrated = res.data?.is_docusign_intigrated || false;
    localStorage.setItem('isDocuSignIntegrated', JSON.stringify(isDocuSignIntegrated));
    return isDocuSignIntegrated;
  } catch (err) {
    console.error(err)
    this.$message({
      showClose: true,
      message: "Unable to get Organistaion settings.",
      type: "error",
      center: true,
    });
  } 
}

export async function isGoodleapConnected() {
  try {
    const response = await API.FINANACIALS_INFORMATION.GET_ALL_EXERNAL_INTEGRATIONS();
    return response;
    // const isConnected = response.data?.goodleap.is_connected || false;
    // localStorage.setItem('isGoodleapConnected', JSON.stringify(isConnected));
    // return isConnected;
  } catch (err) {
    console.error('Error fetching external loan details:', err);
  }
}

export async function getAllFinancialIntegrations() {
  try {
    const response = await API.FINANACIALS_INFORMATION.GET_ALL_EXERNAL_INTEGRATIONS_V2();
    return response;
  } catch (err) {
    console.error('Error fetching external loan details:', err);
  }
}

export async function getFinancialIntegrationState() {
  try {
    const response = await API.FINANACIALS_INFORMATION.GET_ALL_EXERNAL_INTEGRATIONS_STATUS();
    return response;
  } catch (err) {
    console.error('Error fetching external loan details:', err);
  }
}

export async function base64ToBlob(url) {
  return await (await fetch(url)).blob()
}

export function resizeImageToMaxDim(imgUrl, maxDim, options) {
  options ||= {}
  let fileType = options.fileType || "image/jpeg"
  let quality = options.quality || 0.9

  return new Promise((resolve, reject) => {
    let image = document.createElement("img");
    image.onload = () => {
      if (image.height > maxDim || image.width > maxDim) {
        let newW, newH;
        if (image.height > image.width) {
          newH = maxDim;
          newW = image.width * (newH / image.height);
        } else {
          newW = maxDim;
          newH = image.height * (newW / image.width);
        }
        let canvas = document.createElement("canvas");
        canvas.width = newW;
        canvas.height = newH;
        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        let newImageUrl = canvas.toDataURL(fileType, quality);
        resolve(newImageUrl)
      } else {
        resolve(imgUrl)
      }
    };
    image.onerror = () => { reject() }
    image.src = imgUrl;
  })
}

export function zeroPad(num, places) {
  return String(num).padStart(places, '0')
}

export async function waitForTimeout(duration) {
  await new Promise(resolve => setTimeout(resolve, duration * 1000))
}

export function removeQueryParamsFromUrl(url) {
  try {
    let urlObj = new URL(url);
    urlObj.search = '';
    return urlObj.toString();
  } catch {
    // Return the original URL if not a valid URL
    return url
  }
}

// SOM roles check

export function isVIP(){
 return getOrganisationFromLocalStorage().vip_for_power_gazebo;
}

export function isProjectManager(){
  return getUserFromLocalStorage().is_project_manager
}

export function isInternalSales(){
  return getUserFromLocalStorage().role=="ARKA_INTERNAL_SALES"
}

export function isSalesAdmin(){
return getUserFromLocalStorage().role=="ADMIN"&&getOrganisationFromLocalStorage().is_vip_aggregator
}

export function isSalesOps(){
  return getUserFromLocalStorage().role=="ARKA_SALES_OP"
}
export function leadDeleteToolTip(isProposalGenerated,siteSurveyGenerated,isDesignCheck,isSignedContract){
  let conditionStr=[]
  if(!isProposalGenerated&&!siteSurveyGenerated&&!isDesignCheck&&!isSignedContract){
   conditionStr.push('an order has been placed, proposal, contains contract, site survey has been generated/shared.')
 }
else if(!isProposalGenerated&&!siteSurveyGenerated&&!isDesignCheck){
   conditionStr.push('an order has been placed, proposal, site survey has been generated/shared.')
 }  else if(!siteSurveyGenerated&&!isDesignCheck&&!isSignedContract){
   conditionStr.push('an order has been placed, contains contract, site survey has been generated/shared.')
 }
 else if(!isProposalGenerated&&!siteSurveyGenerated&&!isSignedContract){
   conditionStr.push('it contains contract, proposal, site survey has been generated/shared.')
 }
 else if(!isProposalGenerated&&!isDesignCheck&&!isSignedContract){
   conditionStr.push('an order has been placed, contains contract, proposal has been generated/shared.')
 }
 else if(!isProposalGenerated&&!siteSurveyGenerated){
   conditionStr.push('a proposal, site survey has been generated/shared.')
 }else if(!isProposalGenerated&&!isDesignCheck){
   conditionStr.push('an order has been placed, proposal has been generated/shared.')
 }
 else if(!isDesignCheck&&!siteSurveyGenerated){
   conditionStr.push('an order has been placed, site survey has been generated/shared.')
 }

 else if(!isDesignCheck&&!isSignedContract){
   conditionStr.push('an order has been placed,contains contract.')
 }
 else if(!siteSurveyGenerated&&!isSignedContract){
   conditionStr.push('it contains contract, site survey has been generated/shared.')
 }
 else if(!isProposalGenerated&&!isSignedContract){
   conditionStr.push('it contains contract, proposal has been generated/shared.')
 }
 else{
   if(!isDesignCheck){
   conditionStr.push('an order has been placed under it.')
 }
   if(!isProposalGenerated){
   conditionStr.push('a proposal has been generated/shared.')
 }
   if(!siteSurveyGenerated){
  conditionStr.push('a site survey has been generated for it.')
 }
 if(!isSignedContract){
   conditionStr.push('it contains contract.')
 }
 }
return conditionStr;
}
