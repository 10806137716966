
    <div class="main_div">
<img 
src="./assets/integration.png"
 alt="" 
>
<p class="info">
    Looks like you have not integrated your Email Client with the CRM.
</p>
    <p class="info">
        Click below to get started on the Integration.
    </p>
<el-button 
type="primary" 
@click="handleIntegrateButton"
>
Integrate With your Google/Outlook account.
</el-button>
    </div>
