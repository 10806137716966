import Vue from "vue";
import Router from "vue-router";
import home from "./pages/home/home.vue";
import dashboardCrm from "./pages/dashboardCRM/components/dashboardV2/index.vue";
import projectListViewHome from "./pages/home/components/projectListViewHome.vue";
import { isTataOrg } from './utils.js';

import { isCrmUser ,isUserOfRole,isExpertServicesOrg} from "./utils";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("./pages/login/login.vue"),
    },
    {
      path: "/pdfPuppeteer/:contractId/:token/:designId",
      name: "pdfPuppeteer",
      component: () => import("./pages/documentManagement/components/textEditor/pdfPuppeteer.vue"),
    },
    {
      path: "/paginatedContentForPuppeteer/:contractId/:token/:designId/:isUSFlagEnabled",
      name: "paginatedContentForPuppeteer",
      component: () => import("./pages/documentManagement/components/textEditor/paginatedContentForPuppeteer.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/auth",
      name: "senseHawkLogin",
      component: () => import("./pages/login/loginForSenseHawk.vue"),
    },
    {
      path: "/",
      name: "home",
      component: () => {
        if(isUserOfRole('ARKA_SALES_OP')){
          return import("./pages/arkaOrders/index.vue");
        }
       else if (isCrmUser()) {
          return import("./pages/dashboardCRM/components/dashboardV2/index.vue");
        } else {
          return import("./pages/home/home.vue");
        }
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/db",
      name: "db",
      component: () => {
          return import("./pages/dashboardCRM/components/dashboardV2/index2.vue");     
      },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/projects",
      name: "projectListViewHome",
      component: projectListViewHome,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/leadmanagement",
      name: "leadManagement",
      component: () => import("./pages/leadManagement/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/arkaorders",
      name: "arkaorders",
      component: () => import("./pages/arkaOrders/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/createOrder",
      name: "createOrder",
      component: () => import("./pages/arkaOrders/placeOrdersIndex.vue"),
      meta: {
        requiresAuth: true,
      },
    },
// catalog router donot remove the commented code
    // {
    //   path: "/catalog",
    //   name: "catalog",
    //   component: () => import("./pages/arkaOrders/catalogOrders/index.vue"),
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },

    {
      path: "/createBundle",
      name: "createBundle",
      component: () => import("./pages/arkaOrders/createBundle/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/taskmanagement",
      name: "taskManagement",
      component: () => import("./pages/taskManagement/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/taskmanagement?selectedTask=:id",
      name: "taskManagement:task",
      component: () => import("./pages/taskManagement/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/pipelines",
      name: "pipelines",
      component: () => import("./pages/pipeLines/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/custom-task-list",
      name: "custom-task-list",
      component: () => import("./pages/taskList/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/crmsettings",
      name: "crmsettings",
      component: () => import("./pages/crmSettings/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/integrations/oauth2callback",
      name: "redirect:google",
      component: () => import("./pages/integrations/components/redirect.vue"),
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: "/integrations/microsoft_auth/callback",
      name: "integrations:microsoft_auth",
      component: () => import("./pages/integrations/components/redirect.vue"),
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: "/integrations",
      name: "integrations",
      component: () => import("./pages/integrations/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/leadSummary/:leadId",
      name: "leadSummary",
      component: () => import("./pages/designSummaryCRM/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/leadSummary/:leadId/design/:designId",
      name: "leadSummary:design",
      component: () => import("./pages/designSummaryCRM/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/leadSummary/:leadId/design/:designId",
      name: "pricingFinancials",
      component: () => import("./pages/designSummaryCRM/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin",
      name: "admin",
      component: () => import("./pages/admin/admin.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/wireSizeCalculator/:designId",
      name: "wireSizeCalculator",
      component: () =>
        import("./pages/wireSizeCalculator/wireSizeCalculator.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/media/:surveyId",
      name: "mediaBox",
      component: () => import("./pages/media/mediaBox.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/salesStudio/:designId",
      name: "salesStudioMode",
      component: () => import("./pages/studio/stage/SaleStage.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/salesModeReport/:designId",
      name: "salesModeReport",
      component: () =>
        import(
          "./components/ui/saleStudio/saleStudioReport/salesModeReport.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/salesModeReportRef/:referenceId",
      name: "salesModeReportRef",
      component: () =>
        import(
          "./components/ui/saleStudio/saleStudioReport/salesModeReport.vue"
        ),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/wireSizeCalculatorDownload/:designId",
      name: "wireSizeCalculatorDownload",
      component: () =>
        import("./pages/wireSizeCalculatorDownload/wireSizeCalculator.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/detailedBOM/:designId",
      name: "detailedBOM",
      component: () => import("./pages/detailedBOM/detailedBOM.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/detailedBOMDownload/:designId",
      name: "detailedBOMDownload",
      component: () =>
        import("./pages/detailedBOMDownload/detailedBOMDownload.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/organisationSummary",
      name: "organisationSummary",
      component: () =>
        import(
          "./pages/organisation/organisationProfile/organisationSummary.vue"
        ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/organisationDefaults",
      name: "organisationDefaults",
      component: () =>
        import("./pages/organisation/organisationDefaults_new/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/inventory",
      name: "inventory",
      component: () =>
        import("./pages/organisation/organisationInventory/inventory.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/userManagement",
      name: "userManagement",
      component: () => import("./pages/userManagement/index.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "profile",
          name: "profile",
          component: () =>
            import("./pages/userManagement/components/myProfile.vue"),
        },
        {
          path: "team",
          name: "team",
          component: () =>
            import("./pages/userManagement/components/myTeam.vue"),
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/userManagement/team/:userId",
      name: "teamMember",
      component: () => import("./pages/userProfile/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //     path: "/userProfile",
    //     name: "userProfile",
    //     component: () => import ("./pages/userProfile/userProfile.vue"),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    {
      path: "/studio/:designId",
      name: "studio",
      component: () => import("./pages/studio/studio.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/stage/:designUUID",
      name: "DesignOverview",
      component: () => import("./pages/designOverview/DesignOverview.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/stage-report/:designUUID",
      name: "ReportStage",
      component: () => import("./pages/studio/stage/ReportStage.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/projectSummary/:projectId",
      name: "projectSummary",
      component: () => import("./pages/project/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/projectSummary/:projectId/orderService/:serviceId",
      name: "orderService",
      component: () => import("./pages/project/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/designSummary/:designId",
      name: "designSummary",
      component: () => import("./pages/design/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/designSummary/datasheet/:designId",
      name: "datasheet",
      component: () => import("./pages/datasheet/datasheet.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/pricing",
      name: "pricing",
      component: () => import("./pages/pricing/pricing.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/order-summary",
      name: "orderSummary",
      component: () => import("./pages/pricing/orderSummary.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/payment-response",
      name: "response",
      component: () => import("./pages/pricing/payment/response.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/error/",
      name: "error",
      component: () => import("./pages/error/index.vue"),
    },
    {
      path: "/resetPassword",
      name: "resetPassword",
      component: () => import("./pages/login/resetPassword.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //     path: "/signup",
    //     name: "signup",
    //     component: () => import ("./pages/login/signup.vue"),
    // },
    {
      path: "/activate/:activationId/:timeStamp",
      name: "activate",
      component: () => import("./pages/verifyUser/activate.vue"),
    },
    {
      path: "/customTariff",
      name: "customTariff",
      component: () => import("./pages/customTariff/customTariff.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/siteSurveyForm",
      name: "siteSurveyForm",
      component: () => import("./pages/siteSurveyForm/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/incentives",
      name: "incentives",
      component: () => import("./pages/incentives/incentives.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/consumptionProfile/:projectId",
      name: "consumptionProfileEnergy",
      component: () => import("./pages/consumptionProfile/index.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/financials",
      name: "financials",
      component: () => import("./pages/financials/financials.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/dailyConsumptionProfile",
      name: "dailyConsumptionProfile",
      component: () => import("./pages/dailyConsumptionProfile/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/credits",
      name: "credits",
      component: () => import("./pages/credits/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/project",
      name: "project",
      component: () => import("./pages/project/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/dashboard/success/:paymentId",
      name: "dashboard",
      component: () => import("./pages/dashboard/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/dashboard/failed",
      name: "dashboard",
      component: () => import("./pages/dashboard/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/orders",
      name: "orders",
      component: () => import("./pages/orders/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/order/:orderId",
      name: "orderCreation",
      component: () => import("./pages/orderCreation/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    // for redirection of unknown routes and calling parameterized routes without parameters
    {
      path: "*",
      redirect: "/",
    },
    // Web Proposal
    {
      path: "/webProposal/:designUUID",
      name: "webProposal",
      // component: webProposal,
      component: () => import("./pages/webProposal/webProposal.vue"),
      meta: {
        requiresAuth: false,
      },
    },

    // Document Proposal
    {
      path: "/documentProposal/:designId",
      name: "documentProposal",
      // component: webProposal,
      component: () => import("./pages/webProposal/documentProposal.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    // Document Proposal for end user:
    {
      path: "/documentProposalRef/:referenceId",
      name: "documentProposalRef",
      // component: webProposal,
      component: () => import("./pages/webProposal/documentProposal.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/documentProposalRef/:referenceId/puppeteer/:date/:token",
      name: "documentProposalPuppeteer",
      // component: webProposal,
      component: () => import("./pages/webProposal/documentProposal.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/editProposalTemplate/:templateId",
      name: "editProposalTemplate",
      component: () => import("./pages/webProposal/proposalEditor.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/editProposal/:designId",
      name: "editProposal",
      component: () => import("./pages/webProposal/proposalEditor.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/?dashboardId=:id",
      name: "dashboardCRM",
      component: () => import("./pages/dashboardCRM/components/dashboardV2/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/dashboard/:id",
      name: "dashboardEditMode",
      component: () =>
        import("./pages/dashboardCRM/components/dashboardV2/editDashboard.vue"),
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/addersAndDiscounts",
      name: "addersAndDiscounts",
      component: () => import("./pages/AND/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/designOrders",
      name: "designOrders",
      component: () => import("./pages/designOrders/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/esUsers",
      name: "esUsers",
      component: () =>
        import("./pages/designOrders/manageUsersAndShiftsIndex.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () => import("./pages/notifications/notifications.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/siteSurveyResponsePage/:siteSurveyTokenId/:templateId",
      name: "siteSurveyReviewPage",
      component: () =>
        import("./pages/editableSiteSurvey/index.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/documentManagement",
      name: "documentManagement",
      component: () => import("./pages/documentManagement/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/contractEditor/:contractId",
      name: "contractEditor",
      component: () => import("./pages/documentManagement/components/textEditor/contractTemplateEditor.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/paymentSchedulePuppeteer/:designId/:token/:isUSFlagEnabled",
      name: "paymentSchedulePuppeteer",
      component: () => import("./pages/documentManagement/components/textEditor/paymentSchedulePage.vue"),
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: "/projectTimeline",
      name: "projectTimeline",
      component: () => import("./pages/projectTimeline/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/userManagement2",
      name: "userManagement2",
      component: () =>
        import("./pages/userManagement2/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/authorization-code/callback",
      name: "redirect",
      component: () => import("./pages/integrations/components/redirect.vue"),
      meta: {
        requiresAuth: true,
      }
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

// Documentation: https://v3.router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, from, next) => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const { token } = user;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  router.fromPage = from;
  router.toPage = to;

  // Redirect user to login page if they're trying to navigate to a page that requires authentication
  if (requiresAuth && !token) {
    next("/login");
  } else if (requiresAuth && token) {
    next();
  } else {
    next();
  }

  if (isCrmUser() && to.path === '/projects' || isCrmUser() && to.path === '/projects/') {
    next('/'); // Redirect to home or any other route
  } else {
    next(); // Proceed to the route
  }

  const projectSummaryRegex = /^\/projectSummary\/\d+$/;
  if (isCrmUser() && projectSummaryRegex.test(to.path)) {
    next('/'); // Redirect to home or any other route
  } else {
    next(); // Proceed to the route
  }

  const designSummaryRegex = /^\/designSummary\/\d+$/;
  if (isCrmUser() && designSummaryRegex.test(to.path)) {
    next('/'); // Redirect to home or any other route
  } else {
    next(); // Proceed to the route
  }

  // Route to configurable dashboard for CRM users, and static dashboard for non-CRM users
  if (to.name == "home") {

    // Redirect to the projects page if the organization is Tata & hide dashboard and orders page.
    if(isTataOrg()) {
      to.matched[0].components.default = projectListViewHome;
      next('/projects');
    } else if (isCrmUser()) {
      to.matched[0].components.default = dashboardCrm;
    } else {
      to.matched[0].components.default = home;
    }
  }
// Route restriction for /designorders route to for users other than Expert Services Org
  if (!isExpertServicesOrg() && to.path.toLowerCase().includes('designorders')) {
    next('/'); // Redirect to home or any other route
  } else {
    next(); // Proceed to the route
  }

  // Token check for each page before route change
  if (to.name === "admin" && (user.role === "ADMIN" )) {
    next();
  } else if (to.name === "admin" && user.role !== "ADMIN") {
    next("/");
  }
  if (to.name === "organisationDefaults" &&  (user.role === "ADMIN" )) {
    next();
  } else if (to.name === "organisationDefaults" && user.role !== "ADMIN") {
    next("/");
  }
  if (to.name === "pricing" && user.role === "ADMIN") {
    next();
  } else if (to.name === "pricing" && user.role !== "ADMIN") {
    next("/");
  } else if (to.name === "integration" && Object.keys(to.query).length > 0) {
    // saving projectData in sessionstorage coming from salesforce.
    sessionStorage.setItem("projectData", JSON.stringify(to.query));
  }
});

router.goBackOrGoHome = () => {
  if (router.fromPage.name) {
    router.back();
  } else {
    router.push({ name: "home" });
  }
};

// Error handling for when page routing fails, due to new builds
router.onError((error) => {
  console.error(error);
  let reloadCondition =
    !window.location.origin.includes("localhost") &&
    error.message.includes("imported module");
  if (reloadCondition) {
    window.location = router.toPage.fullPath;
  }
});

export default router;
