<template>
  <div class="inputview-container">
    <p class="title">
      {{ inputDetails.title }}
      <span v-if="inputDetails.isStar" class="astrisk"> *</span>
    </p>
    <el-input
      :placeholder="inputDetails.placeholder"
      v-model="inputData"
    ></el-input>
  </div>
</template>

<script>
export default {
  emits: ["item-selected"],
  name: "inputView",
  data() {
    return {
      inputData: "",
      inputDetails: "",
    };
  },
  props: {
    itemValue: {
      type: Object,
      default: () => {},
    },
    selectedValue: {
      type: [String,Number],
      default:  "",
    },
    indexOfOptions: {
      type: Number,
      default: null,
    },
    limitData: {
      type: [String ,Number],
      default: "",
    },
  },
  mounted() {
    this.inputData = this.$props.selectedValue?this.$props.selectedValue:this.$props.itemValue?.selectedValue;
    this.inputDetails = this.$props.itemValue;
  },
  methods: {
    validateNumber() {
      const numericValue = parseFloat(this.numberValue);

      if (isNaN(numericValue)) {
        // Reset the input if it's not a valid number
        this.numberValue = "";
      } else {
        // Update the input with the validated number
        this.numberValue = numericValue;
      }
    },
  },

  watch: {
    selectedValue:{
      immediate:true,
      handler(val){
        if(!val){
          this.inputData='';

        }
      }
    },
    limitData: {
      immediate: true,
      handler(val) {
        this.inputData = val;
      },
    },
    // selectedValue(val) {
    //   this.inputData = val;
    // },
    items:{
      immediate:true,
      handler(val){
        
      }

    },
    inputData: {
      handler(value) {

        if(value){
          const numericValue = parseFloat(value ? value : this.$props.limitData);

if (isNaN(numericValue)) {
  // Reset the input if it's not a valid number
  this.inputData = '';
} else {

  const numericValueStr = numericValue.toString();
  if (numericValueStr.length <= 16) {
    // Update the input with the validated number
    this.inputData = numericValue;
  } else {
    // If it exceeds 18 characters, truncate or handle the error as needed
    // For example, you can limit it to 18 characters
    this.inputData = numericValueStr.substring(0, 16);
  }
}
this.$emit(
  "item-selected",
  this.$props.itemValue.prop,
  value,
  value,
  this.$props.itemValue,
  this.$props.indexOfOptions,
  false
);
        }else{
          this.$emit(
  "item-selected",
  this.$props.itemValue.prop,
  '',
  '',
  this.$props.itemValue,
  this.$props.indexOfOptions,
  false)
        }
      
      },
    },
  },
};
</script>

<style scoped>
.inputview-container {
}
.title {
  padding-bottom: 0.5rem;
  font-size: 12px;
  color: #777;
  font-weight: 600;
}

.inputview-container >>> .el-input__inner {
  background: #e8edf2;
  color:black;
}
.inputview-container >>> .el-input__inner::placeholder {
  font-size: 14px;
  color: #777;
}
.astrisk {
  font-size: 12px;
  font-weight: 600;
  color: #f00;
}
</style>
