
<div id="parentContainer">
  <el-dialog
    :visible="isSelfDesignPopupVisible"
    :close-on-click-modal="false"
    title="Order Details"
    :width="essOnlyDesign ? '576px' : '976px'"
    @close="onDialogClose"
  >
    <div class="container">
      <div v-if="!essOnlyDesign" class="leftContainer">
        <div class="headerContainer">
          <h3 class="headingOne">{{$props.buttonclickedNEW.name}}</h3>
          <p class="headContent">
            {{$props.buttonclickedNEW.description}}
          </p>
        </div>
        <div class="drpDownContainer" v-if="dropdownOptionsAvailable">
        <el-tooltip effect="dark" placement="top-start" content="You can upgrade your designs using the Project Upgrade option." :disabled="!isDropdownDisabled">
          <el-select v-model="selectedOption" :disabled="isDropdownDisabled" :placeholder="selectedOption.type">
            <el-option
              v-for="option in allDropdownFeatures"
              :key="option.id"
              :value="option"
              class="loanDropdown"
            >
              <div class="flexContainer">
                <div class="">
                  <p class="flexContainer">
                    {{option.type }}
                  </p>
                  <p class="uptoKW">{{option.description}}</p>
                </div>                                    
                <p class="droDwnCredits">{{formatNumberWithCommas(option.credits)}} Credits</p>
              </div>
            </el-option>
          </el-select>
        </el-tooltip>
        </div>
        <div class="prjtSize" v-if="dropdownOptionsAvailable">
          <p class="detailsValue" v-if="selectedOption">
            <span class="tickSign">✓</span>Project size {{selectedOption.description}}
          </p>
        </div>
        <div>
          <el-checkbox-group class="checkBoxContainer" v-model="selectedAddOnChecked" @change="addonsChange" v-if="checkboxOptionsAvailable">
            <el-checkbox  v-for="feature in filteredAvailableFeatures" :key="feature.features" :label="feature" :disabled="availedAddOn(feature.id)" :checked="availedAddOn(feature.id)">
              {{feature.name}} ({{formatNumberWithCommas(feature.credits)}} Credits)
              <div v-if="feature.description" class="hover_information">
                  <i class="fas fa-info-circle"></i>
                  <div class="tooltip">
                    <p>
                      {{feature.description}}
                    </p>
                  </div>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="rightContainer">
        <div class="headerContainer">
          <h3 class="headingTwo">Payment Summary</h3>
          <div class="flexColumn">
            <div class="packageDetails">
              <p class="font14Bold">Purchased credits</p>
              <p class="font14Normal">{{formatNumberWithCommas(credits.purchased_credits)}} credits</p>
            </div>
            <div class="packageDetails">
              <p class="font14Bold">Promotional credits</p>
              <p class="font14Normal">{{formatNumberWithCommas(credits.promotional_credits)}} credits</p>
            </div>
          </div>
        </div>
        <div class="selectedPaymentsHeight">
          <div class="selectedPayments" v-if="manuallySelectedAddons.length == [] ? false : true">
            <div class="packageDetails marginLeft" v-for="addon in manuallySelectedAddons" :key="addon.id">
              <p class="detailsSmall">{{(addon.name)}}</p>
              <p class="font14RedNormal">{{(addon.credits)}}</p>
            </div>
          </div>
          <div class="packageDetails" style="padding: 10px 0px; border-bottom: 1px solid #ccc;">
            <p class="font14Bold">Total payable credits</p>
            <p class="font14RedNormal">-{{formatNumberWithCommas(totalPayableCredits)}} credits</p>
          </div>
          <div class="packageDetails" style="padding: 10px 0px;">
            <div class="balanceCont">
              <p class="font14Bold">Balance credits</p>
              <div class="creditCont">
                <el-tooltip placement="bottom" popper-class="designStudioToolTip" content="Purchased Credits+Promotional credits-Total payable credits">
                  <div>
                      <button class="iBtn button-dark-theme-icons el-icon-info icons-circle'"/>
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div>
              <p class="font14Normal">{{formatNumberWithCommas(totalCredits - totalPayableCredits)}} credits​</p>
            </div>
          </div>
        </div>
        <div class="footerBtnContainer">
          <div class="packageDetails">
            <div class="crdtBalChkBoxContainer">
              <el-checkbox v-model="usePromotionalCredits">Promotional Credits</el-checkbox>
            </div>
            <p class="font14Normal">{{formatNumberWithCommas(credits.promotional_credits)}}</p>
          </div>
          <el-button class="footerBtn" type="primary" @click="selfDesignPopup" 
            >{{dynamicButtonName}}</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
  <CreateNewProjectPopup
    v-if="isNewProjectFormVisible"
    :isNewProjectFormVisible.sync="isNewProjectFormVisible"
    :isSelfDesignPopup="isSelfDesignPopup"
    @onNewProjectDialogClose="onNewProjectDialogClose"
    :totalPayableCreditsNonUS="selectedOption"
    :totalPayableCreditsUS="totalAddonsPrice + $props.buttonclickedNEW.base_price"
    :selfDesigningInfo="$props.buttonclickedNEW"
    :selectedAddOnChecked="manuallySelectedAddons"
    :avilFeaturesIds="avilFeaturesIds"
    :iSPromotionalChecked="iSPromotionalChecked"
  />
  <add-credit-popup v-if="isAddCreditPopupVisible"
    :isAddCreditPopupVisible.sync="isAddCreditPopupVisible" :preFilledCreditCount ="insufficientCredits"/>
</div>
