<template>
	<div id="pdfEditorContainer" :class="[{customPlaceholderMode:customPlaceholderMode}]">
    <div id="parent-container">
			<DetailsComponent v-if="showDetailsCont" 
				:styleValues="detailsComponentStyle"
				:activeSpanFontSize = "activeSpanFontSize"
				@fontSize="increaseFont"
                @deletePH="deletePlaceholder"
			/>
		<div id="pdfContainer">
		</div>
  </div>
	<el-checkbox v-if="!customPlaceholderMode"
	 	class="addPSClass"
		size="medium"
		v-model="includePaymentScheduleData"
	>
		Add project schedule page in contract
	</el-checkbox>
	</div>
</template>


<script>
import { uploadFileToBlob } from "@/utils.js";
import { containerNameBulkImport } from "@/constants.js";
import { v4 } from "uuid";
import DetailsComponent from './detailsComponent.vue';
import * as pdfjs from 'pdfjs-dist/build/pdf.min.js'
import 'pdfjs-dist/build/pdf.worker.min.js'
import API from "@/services/api/";
import {mapState, mapActions} from "pinia";
import {useContractManagementStore} from '../../../../stores/contractManagement'

export default {
	emits: ["totalPages", "clearPlaceholder", "clearCustomPlaceholder"],
    data(){
			return{
				showDetailsCont: false,
				pdf: null,
				pdfSrc: null,
				pdfContainer: null,
				allPDFCanvas:[],
				currentCanvasIndex:0,
				pos1:0,
				pos2:0,
				pos3:0,
				pos4:0,
				elmnt:'',
				activeSpanId:null,
				activeSpanFontSize: 14,
				detailsComponentStyle: {}, 
				currentFontSize: 14,
				contractId : this.$route.params.contractId,
				includePaymentScheduleData: this.includePaymentSchedule,
			}
    },
    props:{
			pdfPageNo:{
				type: Number,
				default:1,
			},
			selectedProjectPlaceholder:{
				type:String,
				default:''
			},
			selectedDocusignPlaceholder:{
				type:String,
				default:''
			},
			selectedGoodleapPlaceholder:{
				type:String,
				default:''
			},
			selectedCustomPlaceholder:{
				type:String,
				default:''
			},
			fileLink:{
				type:String,
				default:null,
			},
			saveTemplateCounter:{
				type:Number,
				default:0
			},
			includePaymentSchedule:{
				type:Boolean,
				default:false
			},
			customPlaceholderMode:{
					type:Boolean,
					default:false
			},
    },
    mounted(){
        let pdfUrl = this.fileLink; 
        pdfjs.getDocument(pdfUrl).promise.then((pdf) => {
            this.pdf = pdf;
            this.renderPages();
        });
				window.addEventListener('click', this.handleClickOutside);
    },
    components: {
        DetailsComponent,
    },
		computed:{
			...mapState(useContractManagementStore, {
				CMStore: (state) => state
			})
		},
    methods:{
				handleClickOutside(event) {
					if(event.target.localName == 'canvas'){
						this.showDetailsCont = false;
						return;
					}
					event.target.classList.forEach((classes) => {
						if(!(classes == "arrow" || classes ==  "font-cont"|| classes ==  "fontButton"|| 
							classes ==  "details-component" || classes == "bi-plus-circle" || classes == "b-icon" || classes == "bi" || classes=='bi-dash-circle')) {
							this.showDetailsCont = false;
						} 
					})
        },
        increaseFont(val) {
					this.currentFontSize = val;
					let activeSpanElement = document.getElementById(this.activeSpanId);
					if (activeSpanElement) {
						activeSpanElement.style.fontSize = val + 'px';
						this.activeSpanFontSize = val;
						this.resizeDetailsContainer(activeSpanElement);
            const indexOfCurrentSpan = this.CMStore.placeholdersArray.findIndex(placeholder=> placeholder.id== this.activeSpanId)
            if(indexOfCurrentSpan>=0){
							this.updatePlaceholdersArray(activeSpanElement,'edit',indexOfCurrentSpan)
            }
					}
        },
        resizeDetailsContainer(spanElement) {
					const spanRect = spanElement.getBoundingClientRect();
					const containerRect = this.pdfContainer.getBoundingClientRect();
                    const scrollHeight = this.getScrollHeight();
					this.detailsComponentStyle = {
						position: 'absolute',
						left: `${spanRect.left - containerRect.left}px`,
						top: `${(spanRect.bottom - containerRect.top + 105) - scrollHeight}px`,
					};
        },
        async onFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.renderPDF(file);
            }
        },
        async uploadFiles(file){
            let files=[];
            files.push(file)
            const uuid =  v4();
            const imageInfo = await uploadFileToBlob(files,uuid,containerNameBulkImport);
        },
        renderPDF(file) {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                const typedArray = new Uint8Array(event.target.result);
                pdfjs.getDocument(typedArray).promise.then((pdf) => {
                this.pdf = pdf;
                this.renderPages();
                });
            };
            fileReader.readAsArrayBuffer(file);
        },
        async  renderPages() {
            this.pdfContainer = document.getElementById('pdfContainer');
            for (let i = 1; i <= this.pdf.numPages; i++) {
                const page = await this.pdf.getPage(i);
                const viewport = page.getViewport({ scale: 1 });
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                this.pdfContainer.style.height = canvas.height + 'px';
                this.pdfContainer.style.width = canvas.width + 'px';
                this.pdfContainer.style.maxWidth = '900px';
                this.pdfContainer.style.overflowY = 'hidden';
                this.pdfContainer.style.overflowX = 'auto';
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };
                await page.render(renderContext).promise;
                this.allPDFCanvas.push(canvas)
            }
            this.pdfContainer.appendChild(this.allPDFCanvas[0]);
            this.initializeSavedSpans();
            this.$emit('totalPages',this.allPDFCanvas.length);
        },
        next(){
            this.pdfContainer = document.getElementById('pdfContainer');
            const canvasToRemove = this.pdfContainer.querySelector('canvas');// there will be just 1 canvas at a time
            this.currentCanvasIndex++;
            if(this.currentCanvasIndex>this.allPDFCanvas.length-1){
                this.currentCanvasIndex = this.allPDFCanvas.length-1;
                return;
            }
            let nextCanvas = this.allPDFCanvas[this.currentCanvasIndex];
            this.showSpansOfCurrentPage();
            this.pdfContainer.replaceChild(nextCanvas, canvasToRemove);
        },
        back(){
            this.pdfContainer = document.getElementById('pdfContainer');
            const canvasToRemove = this.pdfContainer.querySelector('canvas');// there will be just 1 canvas at a time
            this.currentCanvasIndex--;
            if(this.currentCanvasIndex<0){
                this.currentCanvasIndex = 0;
                return;
            }
            let previousCanvas = this.allPDFCanvas[this.currentCanvasIndex];
            this.showSpansOfCurrentPage();
            this.pdfContainer.replaceChild(previousCanvas, canvasToRemove);
        },
        showSpansOfCurrentPage(){
            let pageClass = 'page-' + (this.currentCanvasIndex+1);
            this.pdfContainer = document.getElementById('pdfContainer');
            let spans = this.pdfContainer.getElementsByTagName('span');
            let arraySpans =  Array.from(spans);
            arraySpans.forEach(function(span) {
								if(!span.classList.contains('arrow'))
                	span.style.display = 'none';
            });
            let matchingSpans = this.getAllElementsWithClass(this.pdfContainer,pageClass);
            let matchingSpansArray =  Array.from(matchingSpans);
            matchingSpansArray.forEach(function(span) {
                span.style.display = 'inline';
            });
        },
        getAllElementsWithClass(parentElement, className) {
            let allElements = parentElement.getElementsByTagName('span');
            let matchingElements = [];
            for (let i = 0; i < allElements.length; i++) {
                let element = allElements[i];
                if (element.classList.contains(className)) {
                    matchingElements.push(element);
                }
            }
            return matchingElements;
        },
        giveStyleToSpan(span){
            span.style.position = "absolute";
            span.style.borderRadius = "4px"
            span.style.backgroundColor = "#E8EDF2";
            span.style.cursor = "pointer";
            span.style.userSelect = "none";
            span.style.padding = "5px";
						span.style.fontSize="14px"
        },
        initializeSavedSpans(){
						this.CMStore.placeholdersArray.forEach((placeholder)=>{
							const span = document.createElement('span');
							this.giveStyleToSpan(span);
							span.classList.add('page-' + (placeholder.page_no));
							span.innerHTML = `${placeholder.placeholder}<span class="arrow">▼</span>`;
							span.style.left = placeholder.x_position;
            	span.style.top =  placeholder.y_position;
							span.style.fontSize = placeholder.font_size
							span.id = placeholder.id
							const arrow = span.querySelector('.arrow');
							arrow.style.marginLeft = '10px';
							arrow.style.cursor = 'pointer';
							this.pdfContainer.appendChild(span);
							this.enableDrag(span);
							arrow.addEventListener('click', (event) => {
								this.activeSpanId = span.id;
								this.activeSpanFontSize = Number(span.style.fontSize.replace("px",""))
                if (!this.showDetailsCont) {
                                    const scrollHeight = this.getScrollHeight();
									const spanRect = span.getBoundingClientRect();
									const containerRect = this.pdfContainer.getBoundingClientRect();
									this.detailsComponentStyle = {
										position: 'absolute',
										left: `${spanRect.left - containerRect.left}px`,
										top: `${(spanRect.bottom - containerRect.top + 105 - scrollHeight)}px`,
									};
									this.showDetailsCont = true;
                } else {
									this.showDetailsCont = false;
                }
            	});
						})
            this.showSpansOfCurrentPage()
        },
        getScrollHeight() {
            const scrollableContainer = document.querySelector('.mainbody');
            const scrollPositionFromTop = scrollableContainer.scrollTop;
            return scrollPositionFromTop;
        },
        addSpan(placeholderJson) {
            this.CMStore.isEditorContentChanged = true;
            let placeholderName = placeholderJson.name || placeholderJson.placeholder_key //placeholder_key in case of custom placeholder
            let placeholderIdentifier = placeholderJson.identifier  || placeholderJson.placeholder_key //placeholder_key in case of custom placeholder
            const span = document.createElement('span');
            this.giveStyleToSpan(span);
            span.classList.add('page-' + (this.currentCanvasIndex + 1));
            span.innerHTML = `${placeholderName}<span class="arrow">▼</span>`;
            span.style.left = '50px';
            span.style.top = '50px';
            const uuid =  v4();
            // We are maintaining the id in the format like - "placeholder-uuid" so that, later on very easily we can split it and get the placeholderIdentifier
            // which could be used as placeholder_key inside updatePlaceholdersArray method. Have discussed with backend to not to add '-' from their end.
            span.id = `${placeholderIdentifier}-${uuid}`; 
            const arrow = span.querySelector('.arrow');
            arrow.style.marginLeft = '10px';
            arrow.style.cursor = 'pointer';
            this.pdfContainer.appendChild(span);

            arrow.addEventListener('click', (event) => {
							this.activeSpanFontSize = Number(span.style.fontSize.replace("px",""))
                if (!this.showDetailsCont) {
                    const scrollHeight = this.getScrollHeight();
                    const spanRect = span.getBoundingClientRect();
                    const containerRect = this.pdfContainer.getBoundingClientRect();
                    this.detailsComponentStyle = {
                        position: 'absolute',
                        left: `${spanRect.left - containerRect.left}px`,
                        top: `${(spanRect.bottom - containerRect.top + 105) - scrollHeight}px`,
                    };
                    this.showDetailsCont = true;
                } else {
                    this.showDetailsCont = false;
                }
            });
						this.updatePlaceholdersArray(span,'add')
            this.enableDrag(span);
        },
        deletePlaceholder(){
            this.CMStore.isEditorContentChanged = true;
            let span = document.getElementById(this.activeSpanId)
            let placeholderId = this.activeSpanId
            const indexOfCurrentSpan = this.CMStore.placeholdersArray.findIndex(placeholder=> placeholder.id== placeholderId)
            this.CMStore.placeholdersArray.splice(indexOfCurrentSpan,1)
            span.remove()
            this.showDetailsCont = false;
        },
        enableDrag(span) {
            span.addEventListener('mousedown', (e) => {
                this.activeSpanId = span.id;
                if (e.target.classList.contains('arrow')) return;
                this.elmnt = e.target;
                this.pos3 = e.clientX;
                this.pos4 = e.clientY;
                document.onmouseup = this.closeDragElement;
                document.onmousemove = this.elementDrag;
            });
        },
        elementDrag(e) {
            this.CMStore.isEditorContentChanged = true;
            this.showDetailsCont = false
            e = e || window.event;
            e.preventDefault();
            let currentSpan = document.getElementById(this.activeSpanId)
            // calculate the new cursor position:
            this.pos1 = this.pos3 - e.clientX;
            this.pos2 = this.pos4 - e.clientY;
            this.pos3 = e.clientX;
            this.pos4 = e.clientY;
            // set the element's new position:
            this.elmnt.style.top = (this.elmnt.offsetTop - this.pos2) + "px";
            this.elmnt.style.left = (this.elmnt.offsetLeft - this.pos1) + "px";

            //-------------------- to handle the corners--------------------//
            let container = document.getElementById('pdfContainer')
            let containerStyle = window.getComputedStyle(container);
            let containerWidth = Number(containerStyle.width.replace("px",""))
            let containerHeight = Number(containerStyle.height.replace("px",""))

            let left = Number(this.elmnt.style.left.replace("px",""))
            let top = Number(this.elmnt.style.top.replace("px",""))
            let placeholderStyle =  window.getComputedStyle(currentSpan);
            let placeholderWidth = Number(placeholderStyle.width.replace("px",""))
            let placeholderHeight = Number(placeholderStyle.height.replace("px",""))
            if( left + placeholderWidth > containerWidth ){
                this.elmnt.style.left = (containerWidth - placeholderWidth)+ "px" ;
            }
            else if(left<0){
                this.elmnt.style.left = "0px";
            }
            if(top + placeholderHeight > containerHeight){
                this.elmnt.style.top = (containerHeight - placeholderHeight)+ "px" ;
            }
            else if(top<0){
                this.elmnt.style.top = "0px" ;
            }
            //--------------------------------------END------------------------//
        },
        closeDragElement() {
            // stop moving when mouse button is released:
            let currentSpan = document.getElementById(this.activeSpanId)
            currentSpan.style.top = this.elmnt.style.top;
            currentSpan.style.left = this.elmnt.style.left;
            const indexOfCurrentSpan = this.CMStore.placeholdersArray.findIndex(placeholder=> placeholder.id== this.activeSpanId)
            if(indexOfCurrentSpan>=0){
								this.updatePlaceholdersArray(currentSpan,'edit',indexOfCurrentSpan)
            }
            document.onmouseup = null;
            document.onmousemove = null;
        },
        findSpanIndexById(spans, id) {
            const parser = new DOMParser();
            // Use findIndex to get the index of the span with the specific id
            const index = spans.findIndex(outerHTML => {
                const doc = parser.parseFromString(outerHTML, 'text/html');
                const span = doc.body.firstChild;
                return span.id === id;
            });
            return index;
        },
        printAllSpansCoordinates(){
            // contains page number, text content and x,y coordinate of each span
            this.pdfContainer = document.getElementById('pdfContainer');
            let spans = this.pdfContainer.getElementsByTagName('span');
            let spanObjWithPageNo = {}
            let arraySpans =  Array.from(spans);
            arraySpans.forEach(function(span) {
                let className = span.className
                if(!spanObjWithPageNo[className]){
                    spanObjWithPageNo[className]=[]
                    spanObjWithPageNo[className].push({
                        "x": span.style.left,
                        "y": span.style.top,
                        "text": span.textContent
                    })
                }
                else{
                    spanObjWithPageNo[className].push({
                        "x": span.style.left,
                        "y": span.style.top,
                        "text": span.textContent
                    })
                }
            });
        },
				updatePlaceholdersArray(span,addOrEdit,index){
					let placeholderKey= span.id.split('-')[0] // already have span in the format - "placeholderKey-uuid"
					let spanJson = {
						"page_no": this.currentCanvasIndex + 1,
						'placeholder':  span.innerText.substring(0,  span.innerText.length-1 ),
						"placeholder_key": placeholderKey,
						"x_position":span.style.left,
            "y_position":span.style.top,
            "font_size": span.style.fontSize || "14px",
						"id": span.id,
						"additional_details": this.CMStore.keyValuePlaceholders[placeholderKey].additional_details,
						"placeholder_type": this.CMStore.keyValuePlaceholders[placeholderKey].placeholder_type,
						"default_value" : this.CMStore.keyValuePlaceholders[placeholderKey].default_value || "", // in case of custom placehoder we have default_value
						"value" : this.CMStore.keyValuePlaceholders[placeholderKey].value || "", // in case of custom placehoder we have default_value
					};
					if(addOrEdit=='add')
						this.CMStore.placeholdersArray.push(spanJson)
					else if(addOrEdit=='edit')
						this.CMStore.placeholdersArray[index] = spanJson
				},
				async handleSave(){
					try{
						let patchData = {
							"additional_details":{
								"placeholders":[... this.CMStore.placeholdersArray]
							},
							"include_payment_schedule": this.includePaymentScheduleData
						}
						await API.CONTRACT_TEMPLATES.UPDATE_CONTRACT_TEMPLATE(this.contractId,patchData)
                        this.CMStore.isEditorSavingInProgress = false;
                        this.CMStore.isEditorContentChanged = false;
						this.$message({
							showClose: true,
							message: "Updated Successfully!",
							type: "success",
							center: true
						});
					}
					catch(e){
						console.error(e);
                        this.CMStore.isEditorSavingInProgress = false;
						this.$message({
							showClose: true,
							message: "Something failed! Can you try once again?",
							type: "error",
							center: true
						});
					}
				}
    },
    watch:{
        pdfPageNo:{
            handler(val,preVal){
                if(this.pdfPageNo>this.allPDFCanvas.length){
                    this.pdfPageNo = this.allPDFCanvas.length;
                    return;
                }
                else if(this.pdfPageNo<1){
                    this.pdfPageNo = 1;
                    return;
                }
                else if(val>preVal){
                    this.next();
                }
                else{
                    this.back()
                }
            }
        },
        selectedProjectPlaceholder:{
            handler(val){
                if(val){
                    this.addSpan(val)
                    this.$emit("clearPlaceholder")  // so that watch can be triggered whenever the same placeholder is added
                }
            }
        },
        selectedDocusignPlaceholder:{
            handler(val){
                if(val){
                    this.addSpan(val)
                    this.$emit("clearPlaceholder")  // so that watch can be triggered whenever the same placeholder is added
                }
            }
        },
        selectedGoodleapPlaceholder:{
            handler(val){
                if(val){
                    this.addSpan(val)
                    this.$emit("clearPlaceholder")  // so that watch can be triggered whenever the same placeholder is added
                }
            }
        },
        selectedCustomPlaceholder:{
            handler(val){
                if(val){
                    this.addSpan(val)
                    this.$emit("clearCustomPlaceholder")  // so that watch can be triggered whenever the same placeholder is added
                }
            }
        },
        saveTemplateCounter:{
            handler(val){
                if(val>0){
                    this.handleSave()
                }
            }
        },
    }
}
</script>
<style scoped>
#pdfEditorContainer >>> .el-checkbox__inner {
  width: 20px;
  height: 20px;
}

#pdfEditorContainer >>> .el-checkbox__label {
  color: #222;
  font-size: 16px;
  white-space: initial;
  padding-left: 12px;
}
#pdfEditorContainer >>> .el-checkbox__inner::after {
  top: 3px;
  left: 7px;
  border-width: 2px;
}
.addPSClass{
	margin-top: 10px;
	width: 612px;
	font-weight: bold;
}

.customPlaceholderMode{
	pointer-events: none;
	cursor: not-allowed;
}
</style>

<style scoped>
.draggable-span {
  position: absolute;
  border: 1px solid black;
  background-color: lightgrey;
  cursor: pointer;
  user-select: none;
  padding: 5px;
}
#parent-container{
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 20px; */
}
#parent-container ::v-deep #pdfContainer {
  position: relative;
  background-color: #fff;
}
#parent-container ::v-deep #pdfCanvas {
  border: 1px solid black;
}
</style>
