<template>
  <div id="table">
    <div class="headerContainer" style="">
      <el-table
        :data="table"
        :border="true"
        class="tableTop"
        :header-cell-style="{
          color: '#1C3366',
          paddingLeft: '16px',
          fontWeight: '600',
          fontSize: '16px',
        }"
      >
        <el-table-column
          v-for="(column , index) in checkedDropDownHeaderList"
          :key="index"
          v-bind:label="column.title"
          :show-header="false"
          :min-width="column.width"
          :resizable="false"
        >
        </el-table-column>
      </el-table>
    </div>

    <!-- dropdownTable -->

    <hr class="hrClass" />
    <div
      class="bodyContainer"
      :style="{
        cursor: 'pointer',
        width: '100%',
        maxHeight: `calc(100vh - ${'38vh'}) !important`,
        minHeight: `calc(100vh - ${'38vh'}) !important`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        background: 'white',
      }"
      v-infinite-scroll="loadMoreLeadData"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="10"
      @scroll="syncScroll"
    >
      <div>
        <el-collapse
          v-for="(item , index) in leadsNames"
          :key="index"
          style="width: 100%"
          v-model="activeNames"
          @change="handleArrow(item)"
        >
          <el-collapse-item :name="item.lead_id">
            <template v-slot:title>
              <div class="leadName">
                <div>
                  <i
                    class="el-icon-arrow-down"
                    v-if="item.showArrow === false"
                    style="margin-left: 16px; color: #777; font-weight: 700"
                  ></i>
                  <i
                    class="el-icon-arrow-up"
                    v-else
                    style="margin-left: 18px; color: #777; font-weight: 700"
                  ></i>
                  <span
                    style="margin-left: 12px; font-size: 14px; color: #777"
                    >{{ item.lead__name }}</span
                  >
                  <span
                    style="
                      font-weight: 500;
                      font-size: 11px;
                      /* background: #ffffff; */
                      border-radius: 5px;
                      color: #777;
                      padding: 3px;
                      border: 1px solid #999999;
                      margin-left: 10px;
                    "
                    >{{ item.task_count }}
                    {{ spellTask(item.task_count) }}</span
                  >
                </div>
              </div>

              <span
                style="
                  margin-left: auto;
                  display: flex;
                  align-items: center;
                  color: #777;
                "
                @click.stop="openMap(item.lead__address)"
                v-if="item.lead__address !== '' && item.lead__address"
              >
                <img
                  src="./assets/Group.svg"
                  alt=""
                  style="margin-right: 10px"
                />
                <!-- <el-tooltip
                  :content="item.lead__address"
                  effect="dark"
                  placement="top"
                > -->
                <p
                  style="
                    white-space: nowrap;
                    text-decoration: underline;
                    margin-right: 10px;
                  "
                >
                  {{ item.address }}
                </p>
                <!-- </el-tooltip> -->
              </span>
            </template>
            <div @mouseover="handleMouse(item)">
              <ListViewTable
                :tableData="item.tasks[0]"
                :tableColumns="checkedDropDownTableList"
                :getCellStyles="getCellStyles"
                :getHeaderStyles="getHeaderStyles"
                :weight="600"
                :size="'16px'"
                :color="'#1C3366'"
                :showHeader="false"
                :tableHeightOffset="'40vh'"
                :cellHeight="'60px'"
                :paddingLeft="'16px'"
                @updateValues="updateValues"
                @updateStatus="updateStatus"
                @updatePriority="updatePriority"
                @nudgeClicked="handleNudgeClick"
                @updateDateValues="updateDateValues"
                @loadLead="loadLead"
                :loadMore="() => loadMore"
                :handleRowClick="onRowClick"
                :showTableHeight="false"
              />
            </div>
          </el-collapse-item>
          <hr class="hrClass" />
        </el-collapse>
      </div>
      <div
        style="display: flex; align-items: center; justify-content: center"
        v-if="showGroupLoader"
      >
        <i class="el-icon-loading"></i>
      </div>
    </div>
  </div>
</template>
l

<script>
import ListViewTable from "../../designOrders/components/ListViewTable.vue";
import infiniteScroll from "vue-infinite-scroll";
import debounce from "debounce";

export default {
  emits: [
    "update",               
    "updateStatus",         
    "updatePriority",       
    "updateDate",           
    "loadInner",            
    "leadDetails",          
    "update:selectedRowId", 
    "handleRowClick",       
    "getLeadsData",         
    "loadMoreLeadName",     
  ],
  props: {
    leadsNames: {
      type: Array,
      required: true,
    },
    selectedRowId: {
      type: Number,
    },

    scrollStart: {
      type: Boolean,
    },
    showGroupLoader: {
      type: Boolean,
      default: false,
    },
    activeNames: {
      type: Array,
      default() {
        return []
      }
    },
    checkedDropDownTableList: {
      type: Array,
      default() {
        return []
      }
    },
    checkedDropDownHeaderList: {
      type: Array,
      default() {
        return []
      }
    },
  },
  directives: {
    infiniteScroll,
  },

  data() {
    return {
      hoveredItemID: 0,
      table: [
        {
          id: 1,
          leadName: "Ashok",
        },
      ],
    };
  },
  mounted() {
    this.handleArrow(this.$props.leadsNames[0]);
  },
  methods: {
    getCellStyles(row, column) {
      if (column.id === "dueDate" && row.status === "Completed") {
        return {
          color: "#999999",
        };
      }
      if (column.id === "homeOwner") {
        return {
          color: "#409eff",
        };
      }

      if (column.id === "dueDate" && row.dueDate === "OverDue") {
        return {
          color: "#FF0000",
        };
      }

      if (column.id === "priority" && row.status === "Completed") {
        return {
          color: "#999999",
        };
      }
      if (column.id === "lead_stage" && row.status === "Completed") {
        return {
          color: "#999999",
        };
      }
      if (column.id === "location" && row.status === "Completed") {
        return {
          color: "#999999",
        };
      }
    },
    getHeaderStyles() {
      const left = "left";
      return left;
    },

    syncScroll(event) {
      const scrollTop = event.target.scrollTop;

      // Apply the same scrolling position to the first table's container
      const firstTableContainer = document.querySelector(".headerContainer");
      if (firstTableContainer) {
        firstTableContainer.scrollTop = scrollTop;
      }
    },

    updateValues: debounce(function (values) {
      this.$emit("update", values);
    }, 500),

    handleNudgeClick(task) {
      let count = 60;
      if (task) task.nudgeTooltipText = `Available in ${count} second(s)`;

      const intervals = setInterval(() => {
        count -= 1;
        if (task) task.nudgeTooltipText = `Available in ${count} second(s)`;
      }, 1000);

      setTimeout(() => {
        clearInterval(intervals);
        task.nudgeTooltipText = "Nudge";
      }, 60000);
    },

    updateStatus(values) {
      this.$emit("updateStatus", values);
    },
    updatePriority(values) {
      this.$emit("updatePriority", values);
    },

    updateDateValues(values) {
      this.$emit("updateDate", values);
    },

    loadMore() {
      if (this.scrollStart === true) {
        this.$emit("loadInner", this.hoveredItemID);
      }
    },

    spellTask(count) {
      if (count === 1) {
        return "Task";
      } else {
        return "Tasks";
      }
    },

    loadLead(id) {
      this.$emit("leadDetails", id);
    },

    onRowClick(value) {
      const { lead } = value;
      this.$emit("update:selectedRowId", lead);
      this.$emit("handleRowClick", value);
    },
    handleArrow(row) {
      row.showArrow = !row.showArrow;
      row.showArrow && this.$emit("getLeadsData", row.lead_id);
    },

    handleMouse(item) {
      const id = item.lead_id;
      this.hoveredItemID = id;
    },
    openMap(address) {
      window.open(`http://maps.google.com/?q=${address}`);
    },
    loadMoreLeadData() {
      this.scrollStart && this.$emit("loadMoreLeadName");
    },
  },
  components: { ListViewTable },
};
</script>

<style scoped>
.column-data {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
}

.headerContainer
  >>> .el-table--enable-row-transition
  .el-table__body
  td.el-table__cell {
  display: none;
}

.bodyContainer {
  border: 1px solid #cccccc;
  border-radius: 0 0 5px 5px;
  max-height: 85vh;
}
.bodyContainer >>> .el-table__header-wrapper,
.el-table__footer-wrapper {
  display: none;
}

.bodyContainer
  >>> .el-table--enable-row-transition
  .el-table__body
  td.el-table__cell {
  padding: 0;
}

.bodyContainer >>> .el-collapse-item__content {
  padding: 0px;
}

.bodyContainer >>> .el-collapse-item__header {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #e8edf2;
  height: 34px;
}

.bodyContainer >>> .el-collapse-item__arrow {
  display: none;
}
.leadName {
  font-weight: 600;
  font-size: 14px;
  color: "#777777";
  margin-left: 20%;
  line-height: 0;
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hrClass {
  border: 0;
  height: 1px;
  background-color: rgb(221, 217, 217);
}

.el-collapse >>> div[role="tab"] {
  position: sticky;
  top: 0;
  z-index: 10;
}

.mainContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  width: 100%;
}
.left {
  display: flex;
  align-items: center;
}
.right {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.textColor {
  margin-left: 10px;
  color: #1c3366;
  font-weight: 500;
  font-size: 16px;
}
.headerContainer >>> .el-table--border .el-table__cell {
  border-right: 1px solid #ccc;
}

.tableTop {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}
</style>
