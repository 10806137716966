<template>
  <div
    :class="isDrilldown&&selectedSort.length > 0 ? 'container-dash-sort' :isDrilldown? 'container-dash': 'container'"
  >
    <div class="navContainer" @click="handleCardSelect" >
      <div class="gridContainer">
        <div class="firstContainer">
          <MenuView
            :status.sync="status"
            :homeOwn.sync="homeOwn"
            :prior.sync="prior"
            :colloabo.sync="colloabo"
            :dueDateClear.sync="dueDateClear"
            :startDateClear.sync="startDateClear"
            :clearall.sync="clearall"
            :menuItems="menuData"
            @statusCheckbox="statusCheckbox"
            @start-Date="startDateRange"
            @due-Date="dueDateRange"
            @loadMore="debounceHomeownerLoadMore"
            @loadMore2="debounceCollaboratorLoadMore"
            @collaboratorCheckbox="collaboratorCheckbox"
            @homeownerCheckbox="homeownerCheckbox"
            @priorityCheck="priorityCheck"
            @searchLeads="searchLeads"
            @searchUsers="searchUsers"
            :isDrillDown="isDrilldown"
          />
         
          <div
            class="relativeContCustom"
            v-if="isDrilldown || isTableOptClicked"
          >
            <p class="customize" v-if="isTableOptClicked || isDrilldown">
              Customize Table
            </p>
            <div :class="customClassNames" v-if="showCustomizeTable">
              <p class="drpDwnHeading">Select columns to view</p>
              <div class="drpdwnFlex" id="checkColor">
                <el-checkbox-group v-model="checkedColumnsList">
                  <el-checkbox
                    v-for="(column, index) in customColumnsListData"
                    :key="index"
                    :label="column.id"
                  >
                    {{ column.title }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="applyBtnContainer" @click="applySelectedColumns()">
                <el-button type="primary" class="applyBtn">Apply</el-button>
              </div>
            </div>
          </div>
          <div
            style="margin-bottom: 35px"
            v-if="
              (admin == 'ADMIN' || manager == true) &&
              !isDrilldown &&
              selectedTaskValue !== 'my'
            "
          >
            <AvatarHandler
              v-if="avatars.length > 0 && (admin == 'ADMIN' || manager == true )"
              :avatars="avatars"
              :overlap="true"
              :limit="3"
              :imgWidth="35"
              :removable="true"
              :editable="false"
              :fontSize="'14px'"
              :hover="true"
              :count="count"
              :gap="5"
              :isColumn="true"
              :isSelectable="true"
              @loadMore="debounceCollaboratorLoadMore"
              @searchAssignee="searchAssignee"
              @handleSelectedChange="handleAvatarSelectedChange"
            />
          </div>
        </div>

        <div class="secContainer">
          <div
            style="display: flex; align-items: center; margin: 0 10px"
            v-if="isTableOptClicked && !isDrilldown"
          >
            <span style="font-weight: 400; font-size: 16px">
              Group by lead
            </span>
            <el-switch
              v-model="groupByLead"
              style="margin-left: 5px; width: 30px"
              @change="handleGroupByLead"
            >
            </el-switch>
          </div>
          <div class="searchInpCont" v-if="!isDrilldown">
            <img src="./assets/search.svg" class="searchIcon" />
            <input
              type="text"
              class="searchInput"
              placeholder="Search"
              :style="{cursor : !scrollStart ?'not-allowed':''}"
              v-model="search"
              @input="updateGroup()"
              :disabled="!scrollStart "
            />
          </div>
          <div class="tableTypeCont">
            <el-tooltip
              class="item"
              effect="dark"
              content="Board View"
              placement="top"
            >
              <div
                v-if="!isDrilldown"
                :class="['tableOpt', !isTableOptClicked ? 'activeClass' : '']"
                @click="setLeadListingView(false)"
              >
                <b-icon 
                  icon="kanban"
                  class="typeIcon"
                />
              </div>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="List View"
              placement="top"
            >
              <div
                v-if="!isDrilldown"
                :class="['cardOpt', isTableOptClicked ? 'activeClass' : '']"
                @click="setLeadListingView(true)"
              >
                <b-icon 
                  icon="list-ul"
                  class="typeIcon"
                />
              </div>
            </el-tooltip>
            <el-button
              v-if="!isDrilldown"
              type="primary"
              class="createLeadBtn"
              @click="handleShowOrHideDrawer"
              >Create Task</el-button
            >
            <el-button
              v-if="isDrilldown"
              type="primary"
              class="createLeadBtn"
              @click="downloadCsv"
              v-loading="downlodLoading"
              >Export CSV</el-button
            >
            <div
              v-if="isDrilldown"
              style="padding-left: 1rem; font-size: 1rem; cursor: pointer"
              @click="handleClose"
            >
              <img src="../../../assets/img/close.svg" alt="" />
            </div>
            <GlobalDrawer
              :isOpen="showCreateDrawer"
              :title="createTaskHeading"
              :handleCloseDialog="handleShowOrHideDrawer"
              :drawer-size="750"
              class="task-drawer-dialog"
            >
              <!-- <template v-if="taskMode === 'view'" v-slot:header>
                <button>Mark Complete</button>
              </template> -->
              <template v-if="showCreateDrawer" v-slot:body>
                <TaskDrawerDynamic
                  :addingCollab.sync="addingCollab"
                  :deletingCollab.sync="deletingCollab"
                  :reminderData.sync="reminderDetails"
                  :mode="taskMode"
                  :taskData="taskValue"
                  @createTask="createTask"
                  @createTaskClicked="createTaskClicked"
                  :isCreateLoading="isCreateLoading"
                  @updateTask="updateTask"
                  :activityTimeline="activityTimeline"
                  @deleteTask="deleteTask"
                  @allowDocumentUpload="handleAllowDocUpload"
                  :documentUploaded="documentUploaded"
                  @documentUploading="documentUploaded = false"
                  :showCloseDialog="showCloseDialog"
                  @setUnsavedChanges="setUnsavedChanges"
                  @closeDrawer="closeDrawer"
                  @handleCloseWarningDialog="showCloseDialog = false"
                />
              </template>
            </GlobalDrawer>
            <setReminder
              :key="setKey"
              :showCreateDrawer="showSetReminderDrawer"
              @close="showSetReminderDrawer = false"
              @save="onSave"
              :drawerSize="400"
              :page="'task'"
              :lead="taskValue"
            />
          </div>
        </div>
      </div>
      <div class="activeFilters">
        <div>
          <div class="tagsContainer" v-if="selectedSort.length > 0">
            <div>
              <img src="./assets/sort.svg" alt="alter" />
            </div>
            <div>
              <el-tag
                class="tag"
                size="medium"
                style="margin-right: 15px"
                closable
                @close="handleRemoveSort()"
              >
                {{ handleDisplaySort(selectedSort) }}
              </el-tag>
            </div>
          </div>
        </div>
        <div
          class="tagsContainer"
          v-if="
            selectedFilter.length > 0 ||
            (statusSources.value && statusSources.value.length>0) ||
            (collaboratorSources.value && collaboratorSources.value.length>0) ||
            (homeownerSources.value && homeownerSources.value.length>0) ||
            startDateClosure ||
            dueDateClosure ||
            priorityValue
          "
        >
          <div>
            <img src="./assets/filter_alt.svg" alt="alter" />
          </div>
          <div>
            <el-tag
              v-for="item in selectedFilter"
              :key="item"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleRemoveFilter(item)"
            >
              {{ handleDisplayFilters(item) }} : {{ item[2] }}
            </el-tag>
            <el-tooltip v-if="statusSources.value  && statusSources.value.length > 0" placement="bottom" effect="dark">
              <template v-slot:content>
                <div> <p class="filterTag" v-for="statusSource in statusSources.value" :key="statusSource.value">{{ statusSource }}</p></div>
              </template>
            <el-tag
              class="tag"
              size="medium"
              closable
              @close="handleStatusremove()"
            >
              {{ statusSources.label }} <span v-if="statusSources.value.length==1">: {{ statusSources.value[0] }}</span> <span v-else class="tag-value">{{ statusSources.value.length }}</span>
            </el-tag>
          </el-tooltip>
            <el-tooltip v-if="collaboratorSources.value  && collaboratorSources.value.length>0" placement="bottom" effect="dark">
              <template v-slot:content>
                <div> <p class="filterTag" v-for="collaboratorSource in collaboratorSources.value" :key="collaboratorSource.value">{{ collaboratorSource }}</p></div>
              </template>
            <el-tag
              class="tag"
              closable
              @close="handleCollaboratorsremove()"
            >
              {{ collaboratorSources.label }} <span v-if="collaboratorSources.value.length==1">: {{ collaboratorSources.value[0] }}</span> <span v-else class="tag-value">{{ collaboratorSources.value.length }}</span>
            </el-tag>
          </el-tooltip>
          <el-tooltip v-if="homeownerSources.value && homeownerSources.value.length>0 " placement="bottom" effect="dark">
              <template v-slot:content>
<div > <p class="filterTag" v-for="homeownerSource in homeownerSources.value" :key="homeownerSource.value">{{ homeownerSource }}</p></div>
</template>
            <el-tag
              class="tag"
              closable
              @close="handleHomeownerremove()"
            >
              {{ homeownerSources.label }} <span v-if="homeownerSources.value.length==1">: {{ homeownerSources.value[0] }}</span>  <span v-else class="tag-value">{{ homeownerSources.value.length }}</span>
            </el-tag>
          </el-tooltip>
            <el-tag
              v-if="startDateClosure"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleRemovestartClosure()"
            >
              {{ startDateClosure.label }} :
              {{ formattedDate(startFrom, startTo) }}
            </el-tag>
            <el-tag
              v-if="dueDateClosure"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleRemoveEndClosure()"
            >
              {{ dueDateClosure.label }} : {{ formattedDate(dueFrom, dueTo) }}
            </el-tag>
            <el-tag
              v-if="priorityValue"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleRemovePriority()"
            >
              {{ priorityValue.label }} : {{ priorityValue.value }}
            </el-tag>
          </div>
        </div>
        <p
          class="clrAll"
          v-if="
            selectedFilter.length > 0 ||
            (statusSources.value && statusSources.value.length>0) ||
            startDateClosure ||
            (collaboratorSources.value && collaboratorSources.value.length>0) ||
            (homeownerSources.value && homeownerSources.value.length>0) ||
            dueDateClosure ||
            priorityValue ||
            selectedSort.length > 0
          "
          @click="handleClear"
        >
          Clear all
        </p>
      </div>
    </div>
    <div style="height: 60vh; margin-top: 10px">
      <KanbanView
        v-if="!isTableOptClicked && !isDrilldown"
        :kanBanColumns="kanbanColumnsData"
        :dataTable="kanbanDataValue"
        :selectedCard="selectedCard"
        :isLoading="isKanbanLoading"
        :page="'Task'"
        @updateDate="updateDate"
        :loadMoreData="debounceLoadMore"
        @handleColumnNumber="handleColumnNumber"
        :handleOrderClick="handleOnClick"
        :offSetHeight="'20.3rem'"
        @handlePriority="handlePriority"
        @updateStatus="updateStatus"
        @leadDetails="leadDetails"
        @editableName="editableName"
        :showKanbanLoader="showKanbanLoader"
        :showKanbanLoading="showKanbanLoading"
        :selectedTask="true"
        :activeKanbanColumn="activeKanbanColumn"
      />

      <div v-else>
        <TableView
          v-loading="dataLoading"
          :tableData="tableColumnData"
          @update="editableName"
          @updateStatus="updateTableStatus"
          @updatePriority="handleTablePriority"
          @updateDate="updateTableDate"
          @loadMoreTableData="loadMoreTableData"
          @handleRowClick="handleOnClick"
          @leadDetails="leadDetails"
          v-if="!groupByLead"
          :isDrilldown="isDrilldown"
          :taskCount="taskCount"
          :showHeaderSortable="isDrilldown"
          :showTableLoader="showTableLoader"
          :checkedTableColumns="checkedTableColumns"
          :checkedTableViewColumn="checkedTableViewColumn"
        />
        <DropDownTable
          :leadsNames="leadsNames"
          @update="editableName"
          @updateStatus="updateTableStatus"
          @updatePriority="handleTablePriority"
          @updateDate="updateTableDate"
          @handleRowClick="handleOnClick"
          @getLeadsData="getLeadsData"
          @leadDetails="leadDetails"
          :activeNames="activeNames"
          @loadMoreLeadName="loadMoreTaskNames"
          :selectedRowId.sync="selectedRowId"
          :isDrilldown="isDrilldown"
          :selectedSort="selectedSort"
          :selectedTaskValue="selectedTaskValue"
          :selectedFilter="selectedFilter"
          :scrollStart="scrollStart"
          :showGroupLoader="showGroupLoader"
          :checkedDropDownTableList="checkedDropDownTableList"
          :checkedDropDownHeaderList="checkedDropDownHeaderList"
          v-else
        />
      </div>
      <LeadsDrawer
        v-if="showLeadQuickViewPane"
        :drawer="showLeadQuickViewPane"
        :lead="lead"
        :activeTab="'first'"
        @close="[(showLeadQuickViewPane = false), (lead = null)]"
      />
    </div>
  </div>
</template>
<script>
import TableView from "./tableView.vue";
import KanbanView from "../../../components/ui/kanban/components/kanbanView.vue";
import MenuView from "../../designOrders/components/header/menuView.vue";
import LeadsDrawer from "../../leadManagement/components/leadsDrawer.vue";
import GlobalDrawer from "../../commonComponents/allDrawer/globalDrawer.vue";
import TaskDrawerDynamic from "./taskDrawerDynamic.vue";
import setReminder from "./../../setReminder/setReminder.vue";
import API from "../../../services/api";
import DropDownTable from "./DropDownTable.vue";
import { getUiFromStorage, setUiInStorage } from "../../../utils";
import debounce from "debounce";
import { generateColorFromName } from "../../../utils";
import { DateTime } from "luxon";
import AvatarHandler from "../../dashboardCRM/avatars/AvatarTest.vue";
import { mapState, mapActions } from "pinia";
import { useDashboardStore } from "../../../stores/dashboard";

export default {
  emits: ["handleClose", "csv-download", "updateWidget", "filterView"],
  data() {
    return {
      allUserData:[],
      showCloseDialog: false,
      isUnsavedChanges: false,
      activeNames: [],
      documentUploaded: false,
      allowUpload: false,
      showGroupLoader: false,
      showTableLoader: false,
      showKanbanLoader: false,
      showKanbanLoading:false,
      idx: 0,
      taskCount: 0,
      scrollStart: true,
      addingCollab: [],
      deletingCollab: [],
      activityTimeline: false,
      isCreateLoading: false,
      groupByLead: JSON.parse(localStorage?.getItem("groupByLead"))
        ? JSON.parse(localStorage?.getItem("groupByLead"))
        : true,
      count: 0,
      debounceTimer: null,
      taskMode: "create",
      reminderDetails: {},
      nextTableUrl: "",
      nextCollaboratorUrl: "",
      homeOwnerResponse: {},
      homeOwnerScroll: {},
      showLeadQuickViewPane: false,
      priorityValue: "",
      priority: "",
      lead: null,
      manager: JSON.parse(localStorage.getItem("user")).is_manager,
      admin: JSON.parse(localStorage.getItem("user")).role,
      selectedTaskValue:
        JSON.parse(localStorage?.getItem("ui"))?.taskManagement?.viewTask ||
        "my",
        selecetedTaskType:'My Tasks',
      showCreateDrawer: false,
      showSetReminderDrawer: false,
      createTaskHeading: "Create Task",
      selectedCard: false,
      isSearchedLeadEmpty: false,
      searchVal: "",
      status: "",
      collaboratorsNames: [],
      homeownerNames: [],
      startDateClosure: "",
      dueDateClosure: "",
      statusSeperator: "",
      statusSources: "",
      collaboratorSources: "",
      collaboratorSeperator: "",
      homeownerSources: "",
      homeownerSeperator: "",
      search: "",
      avtarSelect: [],
      startDate: [],
      startFrom: "",
      sortStrings: "",
      filtersString: "",
      startTo: "",
      selectedSort: [],
      setKey: 0,
      isKanbanLoading: true,
      tableColumnData: [],
      scrollData: [],
      homeOwn: false,
      prior: false,
      colloabo: false,
      dueDateClear:false,
      startDateClear:false,
      clearall: false,
      selectedFilter: [],
      dueDate: [],
      allLeads: [],
      allUsers: [],
      dueFrom: "",
      dueTo: "",
      selectedRowId: 0,
      isTableOptClicked: false,
      taskValue: {},
      leadsNames: [],
      groupByNextUrl: "",
      downlodLoading: false,
      dataLoading: false,
      activeKanbanColumn: "",
      // manager: JSON.parse(localStorage.getItem("user")).is_manager,
      // admin: JSON.parse(localStorage.getItem("user")).role,
      avatars: [],
      kanbanColumnsData: [
        {
          label: "to_be_scheduled",
          value: "To Be Scheduled",
        },
        {
          label: "due_today",
          value: "Due Today",
        },
        {
          label: "overdue",
          value: "Overdue",
        },
        {
          label: "due_this_week",
          value: "Due This Week",
        },
        {
          label: "due_later",
          value: "Due Later",
        },
        {
          label: "completed",
          value: "Completed in the Last 2 Weeks",
        },
      ],
      kanbanData: [],
      reminderData: null,
      showCustomizeTable: false,
      allColumnsList: [
        {
          id: "slNo",
          title: "S. No.",
          width: "100",
        },
        {
          id: "name",
          title: "Task Details",
          width: "340",
          type: "taskName",
          headerTask: true,
        },
        // { id: "name", title: "Name", width: "140" },
        {
          id: "status",
          title: "Status",
          width: "180",
          type: "status_dropdown",
        },

        { id: "priority", title: "Priority", width: "120", type: "priority" },
        { id: "dueDate", title: "Due Date", width: "120", type: "dateDueBy" },
        {
          id: "assignees",
          title: "Assign To",
          width: "150",
        },
        {
          id: "homeOwner",
          title: "Homeowner",
          width: "140",
          type: "homeOwner",
        },

        {
          id: "lead_stage",
          title: "Stage",
          width: "140",
        },
      ],

      allTableViewColumnList: [
        {
          id: "name",
          title: "Task Details",
          width: "360",
          type: "taskName",
        },
        {
          id: "status",
          title: "Status",
          width: "180",
          type: "status_dropdown",
        },

        { id: "priority", title: "Priority", width: "120", type: "priority" },
        { id: "dueDate", title: "Due Date", width: "120", type: "dateDueBy" },
        {
          id: "assignees",
          title: "Assign  To",
          width: "150",
        },
        {
          id: "homeOwner",
          title: "Homeowner",
          width: "140",
          type: "homeOwner",
        },

        {
          id: "lead_stage",
          title: "Stage",
          width: "140",
        },
      ],

      ////Drop Down Table View Customizeable Table/////////////////////////////////////////////////
      allDropDownHeaderList: [
        {
          id: "name",
          title: "Task Details",
          width: "340",
          type: "taskName",
        },
        // { id: "name", title: "Name", width: "140" },
        {
          id: "status",
          title: "Status",
          width: "180",
          type: "status_dropdown",
        },
        { id: "priority", title: "Priority", width: "140", type: "priority" },
        { id: "dueDate", title: "Due Date", width: "140", type: "dateDueBy" },
        {
          id: "homeOwner",
          title: "Homeowner",
          width: "140",
          type: "homeOwner",
        },
        {
          id: "lead_stage",
          title: "Stage",
          width: "140",
        },
        {
          id: "assignees",
          title: "Assignee",
          width: "149.5",
        },
      ],
      allDropDownTableList: [
        {
          id: "name",
          title: "Task Details",
          width: "338",
          type: "taskName",
        },
        // { id: "name", title: "Name", width: "140" },
        {
          id: "status",
          title: "Status",
          width: "180",
          type: "status_dropdown",
        },
        { id: "priority", title: "Priority", width: "140", type: "priority" },
        { id: "dueDate", title: "Due Date", width: "140", type: "dateDueBy" },
        {
          id: "homeOwner",
          title: "Homeowner",
          width: "140",
          type: "homeOwner",
        },
        {
          id: "lead_stage",
          title: "Stage",
          width: "140",
        },
        {
          id: "assignees",
          title: "Assignee",
          width: "140",
        },
      ],

      ////////////////////////////////////////////////////////end//////////////////////////////////////////

      checkedColumnsList: [
        "slNo",
        "name",
        "status",

        "priority",
        "dueDate",
        "assignees",
        "homeOwner",
        "location",

        "lead_stage",
      ],
      checkedTableColumns: [
        {
          id: "slNo",
          title: "S. No.",
          width: "100",
        },
        {
          id: "name",
          title: "Task Details",
          width: "340",
          type: "taskName",
          headerTask: true,
        },
        // { id: "name", title: "Name", width: "140" },
        {
          id: "status",
          title: "Status",
          width: "180",
          type: "status_dropdown",
        },

        { id: "priority", title: "Priority", width: "120", type: "priority" },
        { id: "dueDate", title: "Due Date", width: "120", type: "dateDueBy" },
        {
          id: "assignees",
          title: "Assign To",
          width: "150",
        },
        {
          id: "homeOwner",
          title: "Homeowner",
          width: "140",
          type: "homeOwner",
        },

        {
          id: "lead_stage",
          title: "Stage",
          width: "140",
        },
      ],
      checkedTableViewColumn: [
        {
          id: "name",
          title: "Task Details",
          width: "360",
          type: "taskName",
        },
        // { id: "name", title: "Name", width: "140" },
        {
          id: "status",
          title: "Status",
          width: "180",
          type: "status_dropdown",
        },

        { id: "priority", title: "Priority", width: "120", type: "priority" },
        { id: "dueDate", title: "Due Date", width: "120", type: "dateDueBy" },
        {
          id: "assignees",
          title: "Assign  To",
          width: "150",
        },
        {
          id: "homeOwner",
          title: "Homeowner",
          width: "140",
          type: "homeOwner",
        },
        {
          id: "lead_stage",
          title: "Stage",
          width: "140",
        },
      ],

      ///////////////////Checked Drop Down?????????????????????
      checkedDropDownHeaderList: [
        {
          id: "name",
          title: "Task Details",
          width: "340",
          type: "taskName",
        },
        // { id: "name", title: "Name", width: "140" },
        {
          id: "status",
          title: "Status",
          width: "180",
          type: "status_dropdown",
        },
        { id: "priority", title: "Priority", width: "140", type: "priority" },
        { id: "dueDate", title: "Due Date", width: "140", type: "dateDueBy" },
        {
          id: "homeOwner",
          title: "Homeowner",
          width: "140",
          type: "homeOwner",
        },
        {
          id: "lead_stage",
          title: "Stage",
          width: "140",
        },
        {
          id: "assignees",
          title: "Assignee",
          width: "149.5",
        },
      ],
      checkedDropDownTableList: [
        {
          id: "name",
          title: "Task Details",
          width: "338",
          type: "taskName",
        },
        // { id: "name", title: "Name", width: "140" },
        {
          id: "status",
          title: "Status",
          width: "180",
          type: "status_dropdown",
        },
        { id: "priority", title: "Priority", width: "140", type: "priority" },
        { id: "dueDate", title: "Due Date", width: "140", type: "dateDueBy" },
        {
          id: "homeOwner",
          title: "Homeowner",
          width: "140",
          type: "homeOwner",
        },
        {
          id: "lead_stage",
          title: "Stage",
          width: "140",
        },
        {
          id: "assignees",
          title: "Assignee",
          width: "140",
        },
      ],
      ///////////////////////////////////////////////////////////////////////////////////
      drillDownColumns: [],
      listQueryParams:''
    };
  },
  components: {
    MenuView,
    KanbanView,
    LeadsDrawer,
    AvatarHandler,
    GlobalDrawer,
    TaskDrawerDynamic,
    TableView,
    setReminder,
    DropDownTable,
  },
  props: {
    isDrilldown: {
      type: Boolean,
      default: false,
    },
    drillDownUrl: {
      type: Object,
      default: () => {},
    },
    widgetId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    isDrilldown: {
      immediate: true,
      handler(val) {
        this.showKanbanLoading = false;
        if (val === true) {
          this.getListView();
        }
      },
    },


    widgetId: {
      immediate: true,
      handler(val) {
        if (val) {
          // this.getListView();
        }
      },
    },
    selectedSort(val) {
      this.sortStrings = val ? val : "";
      if (!this.$props.isDrilldown) {
        this.getemptyData();
      }
      this.getTasksData();
      this.getLead();
    },
    selectedTaskValue() {
      this.getData();
      this.getLead();
    },
    selectedFilter(val) {
      let reqObj = "";
      val.map((k, inx) => {
        reqObj = reqObj + `${k[1]}=${k[2]}`;
        if (inx < val.length - 1) {
          reqObj += "&";
        }
      });
      this.filtersString = reqObj ? reqObj : "";
      this.getemptyData();
      this.getTasksData();
      this.getLead();
    },
    search(val) {
      this.searchAllLeads(val);
      // this.searchVal = val;
      // if (!this.isTableOptClicked) {
      //   this.getKanbanData();
      // }
    },
    isTableOptClicked(val) {
      if(!this.$props.isDrilldown){
      this.getListView();
      }
    },
  },
  created() {
    this.getemptyData();
    this.debounceLoadMore = debounce(this.loadMoreData, 300);
    this.debounceHomeownerLoadMore = debounce(this.loadMoreHomeowners, 300);
    this.debounceCollaboratorLoadMore = debounce(
      this.loadMoreCollaborators,
      300
    );
    this.searchAllLeads = debounce(this.searchAllLeadsHelper, 300);
  },
  methods: {
    ...mapActions(useDashboardStore, {
      setDashboard: "SET_DASHBOARD_DETAILS",
    }),
    closeDrawer(){
      this.isUnsavedChanges = false
      this.showCloseDialog = false
      this.handleShowOrHideDrawer()
    },
    setUnsavedChanges(bool){
      this.isUnsavedChanges = bool
    },
    handleAllowDocUpload() {
      this.allowUpload = true;
    },
    handleClose() {
      this.$emit("handleClose");
    },
    async downloadCsv() {
      this.drillDownColumns.length = 0;
      this.allColumnsList.filter((column) => {
        if (this.checkedColumnsList.includes(column.id)) {
          this.drillDownColumns.push(column.title);
        }
      });
      this.$emit("csv-download", this.drillDownColumns, "Task",this.listQueryParams);
    },
    async deleteTask(id) {
      this.showCreateDrawer = false;
      try {
        this.kanbanData = this.kanbanData.map((e) => {
          return {
            ...e,
            tasks:
              e.tasks.length > 0
                ? e.tasks.filter((innerElement) => innerElement.id !== id)
                : e.tasks,
          };
        });
        this.$router.replace({ query: {} });
        this.getLead();
      } catch (err) {
        console.error(err.message);
        this.$message({
          showClose: true,
          message: "Problem in deleting data",
          type: "error",
          center: true,
        });
      }
    },
    async onSave(reminderDetails) {
      this.reminderDetails = {
        reminder_sent_at: reminderDetails.reminder_sent_at,
        notes: reminderDetails.notes,
      };
      if (this.taskValue.id) {
        this.setReminder(this.taskValue.id);
      }
      this.showSetReminderDrawer = false;
    },
    async setReminder(id) {
      let response = await API.TASKS.POST_SETREMINDER_TASK(
        id,
        this.reminderDetails
      );
      this.loadingStateButton = false;
      this.$message({
        showClose: true,
        message: "Reminder has been set successfully.",
        type: "success",
        center: true,
      });
    },
    async leadDetails(id) {
      try {
        let res = await API.LEADS.FETCH_LEAD(id);

        let filteredData = {
          ...res.data,
          color: generateColorFromName(res.data.name),
          country: null,
          created_at: this.formateDateTime(res.data.created_at),
          lead_details: {
            created_at: res.data.created_at,
            deal_value: res.data.deal_value,
            email: res.data.email,
            estimated_system_size: res.data.estimated_system_size,
            id: res.data.id,
            last_contacted: res.data.last_contacted,
            latest_notes: res.data.latest_notes,
            lead_source_object: res.data.lead_source_object,
            name: res.data.name,
            owner: res.data.owner,
            phone: res.data.phone,
            stage_object: res.data.stage_object,
            pipeline: res.data.pipeline,
            stage: res.data.stage,
            target_closure_date: res.data.target_closure_date || null,
          },
          ...res.data.project_details,
        };
        this.showLeadQuickViewPane = true;
        this.lead = filteredData;
        // this.$router.replace({ ...this.$route.query, selectedTask: id });
      } catch (err) {
        console.error(err.message);
        this.$message({
          showClose: true,
          message:
            "There was an error while getting the lead data. Please try again.",
          type: "error",
          center: true,
        });
      }
    },

    updateGroup: debounce(function () {
      this.getLead();
    }, 500),

    formateDateTime(dateTime) {
      if (
        DateTime.fromISO(dateTime).startOf("day").toISO() ===
        DateTime.local().startOf("day").toISO()
      ) {
        const date = DateTime.fromISO(dateTime);
        const time = date.toLocaleString(DateTime.TIME_SIMPLE);
        return `Today at ${time}`;
      }

      if (
        DateTime.fromISO(dateTime).startOf("day").toISO() ===
        DateTime.local().startOf("day").minus({ days: 1 }).toISO()
      ) {
        const date = DateTime.fromISO(dateTime);
        const time = date.toLocaleString(DateTime.TIME_SIMPLE);
        return `Yesterday at ${time}`;
      }

      return DateTime.fromISO(dateTime).toFormat("dd/MM/y  hh:mm a");
    },
    handleGroupByLead(e) {
      localStorage.setItem("groupByLead", this.groupByLead);

      if (!this.groupByLead) {
        this.getListView();
      }
    },

    handleAvatarSelectedChange(id) {
      let isExist = this.avtarSelect.find((e) => e === id);
      if (isExist) {
        this.avtarSelect = this.avtarSelect.filter((e) => e !== id);
      } else {
        this.avtarSelect.push(id);
      }
      this.getemptyData();
      this.getTasksData();
    },
    handleColumnNumber(column) {
      const newData = this.loadMoreData(column);
    },
    async loadMoreData(col) {
      if (col.next) {
        this.showKanbanLoader = true;
        this.activeKanbanColumn = col.stage;
      }
      const response = await API.DESIGN_ORDERS.LOAD_MORE_Leads(col.next);
      response.data.results.map((item) => {
        item.assignees = [
          {
            id: item.assignee,
            name: item.assigned_to,
          },
        ];
        item.nudgeTooltipText = "Nudge";
        item.stage = col.stage;
      });
      this.kanbanData.map((dt) => {
        if (dt.title === col.title) {
          dt.tasks.push(...response.data.results);
          dt.next = response.data.next;
        }
      });
      this.showKanbanLoader = false;
    },
    async loadMoreHomeowners() {
      if (this.homeOwnerScroll.next) {
        const response = await API.DESIGN_ORDERS.LOAD_MORE_Leads(
          this.homeOwnerScroll.next
        );
        const allLead = response.data.results;
        allLead?.map((t) => {
          t.label = t.name;
          t.value = t.id;
          t.location = t.address;
        });
        this.allLeads.push(...allLead);
        this.homeOwnerScroll = response.data;
      }
    },
    async loadMoreCollaborators(currentAssignee, assigneeField, collaborators) {
      if (this.nextCollaboratorUrl) {
        const response = await API.DESIGN_ORDERS.LOAD_MORE_Leads(
          this.nextCollaboratorUrl
        );
        response.data.results.forEach((item) => {
          item.name = `${item.first_name} ${item.last_name}`;
          item.label = `${item.first_name} ${item.last_name}`;
          delete item.last_name;
          delete item.first_name;
          delete item.competence;
          delete item.shifts;
          delete item.email;
        });

        const allUsers = response.data.results;
        let isAlreadyExist = allUsers.find((e) => e.id === currentAssignee);
        if (isAlreadyExist) assigneeField.options.shift();

        this.allUsers.push(...allUsers);
        this.avatars.push(...allUsers);
        this.nextCollaboratorUrl = response.data.next;
      }
    },
    async searchLeads(query) {
      let responseLeads = await API.TASKS.SEARCH_LEADS(query);

      const allLead = responseLeads.data.results;
      allLead?.map((t) => {
        t.label = t.name;
        t.value = t.id;
        t.location = t.address;
      });

      this.allLeads = allLead;
      this.homeOwnerScroll = responseLeads.data;
    },
    async searchAssignee(query) {
      let responseLeads = await API.USERS.SEARCH_USERS(query);
      responseLeads.data.results.forEach((item) => {
        item.name = `${item.first_name} ${item.last_name}`;
        item.label = `${item.first_name} ${item.last_name}`;
        delete item.last_name;
        delete item.first_name;
        delete item.competence;
        delete item.shifts;
        delete item.email;
      });
      this.avatars = responseLeads.data.results;
      this.nextCollaboratorUrl = responseLeads.data.next;
    },
    async searchUsers(query) {
      let response = await API.USERS.SEARCH_USERS(query);
      response.data.results.forEach((item) => {
        item.name = `${item.first_name} ${item.last_name}`;
        item.label = `${item.first_name} ${item.last_name}`;
        delete item.last_name;
        delete item.first_name;
        delete item.competence;
        delete item.shifts;
        delete item.email;
      });
      this.allUsers = response.data.results;
      this.nextCollaboratorUrl = response.data.next;
    },
    async loadMoreTableData() {
      if (this.nextTableUrl) {
        this.showTableLoader = true;
        const { data } = await API.DESIGN_ORDERS.LOAD_MORE_Leads(
          this.nextTableUrl
        );
        this.nextTableUrl = data.next;
        data.results.map((item) => {
          item.slNo = this.idx += 1;
          item.dueBy = item.due_by;
          item.dueDate = item.due_by ? this.formatDate(item.due_by) : "N/A";
          item.location = item.lead_details.address
            ? item.lead_details.address
            : "N/A";
          item.homeOwner = item.lead_details.name;
          item.assignees = item.assigned_to !== " " ? item.assigned_to : "N/A";
          item.flag = false;
          item.showInput = false;
          item.showDropdown = false;
          item.showDatePicker = false;
          item.nudgeTooltipText = "Nudge";
          switch (item.lead_stage) {
            case "":
              item.lead_stage = "N/A";
              break;
            default:
              item.lead_stage = item.lead_stage ? item.lead_stage : "N/A";
          }

          switch (item.status) {
            case "in_progress":
              item.status = "In-Progress";
              break;
            case "completed":
              item.status = "Completed";
              break;
            case "new":
              item.status = "New";
              break;
            default:
              item.status = "New";
          }

          item.statusOption = [
            {
              id: 1,
              value: "new",
              label: "New",
            },
            {
              id: 2,
              value: "in_progress",
              label: "In-Progress",
            },
            {
              id: 3,
              value: "completed",
              label: "Completed",
            },
          ];
        });
        this.scrollData = data.results;
        this.tableColumnData = [...this.tableColumnData, ...this.scrollData];
      }
      this.showTableLoader = false;
    },

    handleShowOrHideDrawer() {
      if(this.isUnsavedChanges){
        this.showCloseDialog = true;
        return
      }
      this.$router.replace({ query: {} });
      this.taskMode = "create";
      this.createTaskHeading = "Create Task";
      this.showCreateDrawer = !this.showCreateDrawer;
      this.reminderDetails = {};
      //Reload the updated data

      if (!this.showCreateDrawer) {
        this.getemptyData();
        this.getTasksData();
        this.taskValue = {}
      }

      if (!this.showCreateDrawer) {
        this.getLead();
      }

      if (this.selectedRowId) this.getLeadsData(this.selectedRowId);
      return (this.selectedRowId = 0);
    },
    createTaskClicked() {
      this.isCreateLoading = true;
    },
    createTask() {
      this.createTaskData().then(() => this.getLead());
    },

    dateHandler(dateString) {
      const originalDate = new Date(dateString);

      const utcDate = new Date(
        originalDate.getUTCFullYear(),
        originalDate.getUTCMonth(),
        originalDate.getUTCDate(),
        originalDate.getUTCHours(),
        originalDate.getUTCMinutes(),
        originalDate.getUTCSeconds()
      );

      const iso8601Format = utcDate.toISOString();
      return iso8601Format;
    },

    async createTaskData() {
      try {
        this.getemptyData();
        this.getTasksData();
        this.showCreateDrawer = !this.showCreateDrawer;
      } catch (err) {
        console.error(err);
        this.$message({
          showClose: true,
          message: "There was an unknown error while creating the task",
          type: "error",
          center: true,
        });
      }
      this.isCreateLoading = false;
    },

    async updateTask(taskObj) {
      try {
        if (this.allowUpload) {
          this.documentUploaded = true;
          this.allowUpload = false;
        }
      } catch (err) {
        console.error(err);
        this.$message({
          showClose: true,
          message: "Unable to update task",
          type: "error",
          center: true,
        });
      }
      if (this.$props.isDrilldown) {
        let updatewidgersData = {
          id: this.$props.widgetId,
          flag: !this.updateWidgets,
        };
        this.updateWidgets=!this.updateWidgets
        this.setDashboard(updatewidgersData);
        this.getListView();
      }
      this.activityTimeline = true;
      this.addingCollab = [];
      this.deletingCollab = [];
    },
    handleCardSelect() {
      this.selectedCard = !this.selectedCard;
    },
    async getemptyData() {
      let kanbanColumns = this.kanbanColumnsData;
      kanbanColumns.forEach(async (dt, index) => {
        let newData = {
          sequence: index,
          count: 0,
          title: dt.value,
          stage: dt.label,
          tasks: [],
          next: "",
          previous: "",
          loading: true,
        };
        this.kanbanData.push(newData);
      });
    },
    async getData() {
      let kanbanColumns = this.kanbanColumnsData;
      let reqobj = "";
      let tableData = "";
      let ui = getUiFromStorage();

      kanbanColumns.forEach(async (dt, index) => {
        const queryParams = {
          order_by: this.sortStrings
            ? this.sortStrings[2] % 2 == 0
              ? "desc"
              : "asc"
            : "",
          sort_by: this.sortStrings ? this.sortStrings[1] : "",
          status: this.statusSeperator,
          priority: this.priority,
          start_date_before: this.startTo,
          start_date_after: this.startFrom,
          due_date_before: this.dueTo,
          due_date_after: this.dueFrom,
          tasks: this.selectedTaskValue,
          collaborators: this.collaboratorSeperator,
          homeowners: this.homeownerSeperator,
          assigned_to: this.avtarSelect,
          offset: new Date().getTimezoneOffset(),
        };

        reqobj = `stage=${dt.label}&view=kanban&order_by=${
          this.sortStrings
            ? this.sortStrings[2] % 2 == 0
              ? "desc"
              : "asc"
            : ""
        }&sort_by=${this.sortStrings ? this.sortStrings[1] : ""}&status=${
          this.statusSeperator
        }&priority=${this.priority}&start_date_before=${
          this.startTo
        }&start_date_after=${this.startFrom}&due_date_before=${
          this.dueTo
        }&due_date_after=${this.dueFrom}&tasks=${
          this.selectedTaskValue
        }&collaborators=${this.collaboratorSeperator}&homeowners=${
          this.homeownerSeperator
        }&assigned_to=${
          this.avtarSelect
        }&offset=${new Date().getTimezoneOffset()}`;
        if (this.filtersString) {
          reqobj += `&${this.filtersString}`;
          queryParams.time_period = this.selectedFilter[0]
            ? this.selectedFilter[0][2]
            : "";
        }
        if (this.searchVal) {
          queryParams.query = this.searchVal;
          reqobj += `&query=${this.searchVal}`;
        }
        const kanbanQueryParams = { ...queryParams };
        kanbanQueryParams.view = "kanban";
        kanbanQueryParams.stage = dt.label;
        const qParams = new URLSearchParams(kanbanQueryParams);
        let result = await API.TASKS.FETCH_TASK_LIST(qParams);
        let dataValue = result.data.results ? result.data.results : [];
        let count = result?.data.count || 0;
        const newKData = dataValue.map((item) => {
          let assignees = [
            {
              id: item.assignee,
              name: item.assigned_to,
            },
          ];
          let nudgeTooltipText = "Nudge";
          return {
            ...item,
            assignees,
            nudgeTooltipText,
            stage: dt.label,
          };
        });
        let newData = {
          sequence: index,
          count: count,
          title: dt.value,
          stage: dt.label,
          tasks: newKData.length > 0 ? newKData : [],
          next: result ? result.data.next : "",
          previous: result ? result.data.previous : "",
          loading: false,
        };
        this.kanbanData.push(newData);
      });
      this.isKanbanLoading = false;
    },

    async getListView() {
      this.dataLoading = true;
      let kanbanColumns = this.kanbanColumnsData;
      this.tableColumnData =[]
      this.nextTableUrl=''
      let reqobj = "";
      let tableData = "";
      let ui = getUiFromStorage();
      const queryParams = {
        order_by: this.sortStrings
          ? this.sortStrings[2] % 2 == 0
            ? "desc"
            : "asc"
          : "",
        sort_by: this.sortStrings ? this.sortStrings[1] : "",
        status: this.statusSeperator,
        priority: this.priority,
        start_date_before: this.startTo,
        start_date_after: this.startFrom,
        due_date_before: this.dueTo,
        due_date_after: this.dueFrom,
        tasks:this.$props.isDrilldown?'all': this.selectedTaskValue,
        collaborators: this.collaboratorSeperator,
        homeowners: this.homeownerSeperator,
        assigned_to: this.avtarSelect,
        offset: new Date().getTimezoneOffset(),
      };

      if (this.searchVal) {
        tableData += `&query=${this.searchVal}`;
        queryParams.query = this.searchVal;
      }

      if (this.$props.drillDownUrl?.param) {
        queryParams[this.$props.drillDownUrl.param] = this.selectedFilter[0]
          ? this.selectedFilter[0][2]
          : this.$props.drillDownUrl.value;
      }
      if (this.$props.widgetId) {
        queryParams.widget = this.$props.widgetId;
      }
      const qParams = new URLSearchParams(queryParams);
      this.listQueryParams=qParams;

      try {
        const { data } = await API.TASKS.FETCH_TASK_LIST(qParams);
        this.taskCount = data.count;
        data.results.map((item, idx) => {
          item.slNo = idx += 1;
          this.idx = item.slNo;
          item.dueBy = item.due_by;
          item.dueDate = this.formatDate(item.due_by);
          item.location = item.lead_details.address
            ? item.lead_details.address
            : "N/A";
          item.homeOwner = item.lead_details.name;
          item.assignees = item.assigned_to !== " " ? item.assigned_to : "N/A";
          item.flag = false;
          item.showInput = false;
          item.showDropdown = false;
          item.showDatePicker = false;
          item.nudgeTooltipText = "Nudge";
          item.checkBox = item.status;
          switch (item.lead_stage) {
            case "":
              item.lead_stage = "N/A";
              break;
            default:
              item.lead_stage = item.lead_stage ? item.lead_stage : "N/A";
          }
          switch (item.status) {
            case "new":
              item.status = "New";
              break;
            case "in_progress":
              item.status = "In-Progress";
              break;
            case "completed":
              item.status = "Completed";
              break;

            default:
              item.status = item.status;
          }
          item.due_by = this.dateHandler(item.due_by);
          item.statusOption = [
            {
              id: 1,
              value: "new",
              label: "New",
            },
            {
              id: 2,
              value: "in_progress",
              label: "In-Progress",
            },
            {
              id: 3,
              value: "completed",
              label: "Completed",
            },
          ];
        });

        this.tableColumnData = data.results;
        this.nextTableUrl = data.next;
        // if(this.$props.isDrilldown){
        this.loadMoreTableData()
        // }
        this.dataLoading = false;
      } catch (err) {
        console.error(err);
        this.$message({
          showClose: true,
          message: this.$props.isDrilldown
            ? "Error in fetching Data. Please try again later."
            : "There was an while Fetching Data. Please try again later.",
          type: "error",
          center: true,
        });
      }
    },

    getTasksData() {
      if (this.isTableOptClicked || this.$props.isDrilldown) {
        this.getListView();
      } else {
        this.getData();
      }
    },

    async getLead() {
      this.showKanbanLoading = this.$props.widgetId ? false : true;
      try {
        let ui = getUiFromStorage();

        let tableData = "";

        tableData = `order_by=${
          this.sortStrings
            ? this.sortStrings[2] % 2 == 0
              ? "desc"
              : "asc"
            : ""
        }&sort_by=${this.sortStrings ? this.sortStrings[1] : ""}&status=${
          this.statusSeperator
        }&priority=${this.priority}&start_date_before=${
          this.startTo
        }&start_date_after=${this.startFrom}&due_date_before=${
          this.dueTo
        }&due_date_after=${this.dueFrom}&tasks=${
         this.selectedTaskValue
        }&collaborators=${this.collaboratorSeperator}&homeowners=${
          this.homeownerSeperator
        }&offset=${new Date().getTimezoneOffset()}`;
        if (this.searchVal) {
          tableData += `&query=${this.searchVal}`;
        }
        const { data } = await API.TASKS.GROUP_BY_LEAD(tableData);

        const newData = data.results.map((item) => {
          let tasks = [];
          return {
            ...item,
            tasks,
            address: this.limitWords(item.lead__address, 12),
            showArrow: false,
          };
        });
        this.groupByNextUrl = data.next;
        this.leadsNames = newData;
        this.loadMoreTaskNames();
        this.isTableOptClicked = JSON.parse(localStorage?.getItem("isTable"))
          ? JSON.parse(localStorage?.getItem("isTable"))
          : false;
          this.showKanbanLoading = false;
        if(this.leadsNames.length <= 0 )return;
        this.getLeadsData(this.leadsNames[0].lead_id);
        this.leadsNames.map((item, index) =>{
          if(index == 0 ){
            item.showArrow = true
          }else{
            item.showArrow = false
          }
        })
        this.activeNames = this.leadsNames[0].lead_id;
      } catch (err) {
        console.error(err);
        this.showKanbanLoading = false;
      }
    },

    limitWords(input, maxWords) {
      if (input === null || input === "") return "N|A";
      const words = input.trim().split(/\s+/);

      if (words.length <= maxWords) {
        return input;
      }

      const truncatedWords = words.slice(0, maxWords);
      const truncatedText = truncatedWords.join(" ");

      return truncatedText + " ...";
    },

    async loadMoreTaskNames() {
      this.scrollStart = false;
      if (this.groupByNextUrl !== null) {
        this.showGroupLoader = true;
        const { data } = await API.TASKS.GET_MORE_GROUP_BY_LEAD(
          this.groupByNextUrl
        );
        const newData = data.results.map((item) => {
          let tasks = [];
          let loc =
            item.lead__address && item.lead__address !== null
              ? item.lead__address
              : "N/A";
          return {
            ...item,
            tasks,
            address: this.limitWords(loc, 12),
            showArrow: false,
          };
        });

        this.leadsNames.push(...newData);
        this.groupByNextUrl = data.next;
           if(this.leadsNames.length <= 0 )return;
        this.leadsNames.map((item, index) =>{
          if(index == 0 ){
            item.showArrow = true
          }else{
            item.showArrow = false
          }
        })
        this.activeNames = this.leadsNames[0]?.lead_id;


       
      }
      this.showGroupLoader = false;
      this.scrollStart = true;
    },

    async getLeadsData(id) {
      let ui = getUiFromStorage();
      let tableData = "";

      tableData = `order_by=${
        this.sortStrings ? (this.sortStrings[2] % 2 == 0 ? "desc" : "asc") : ""
      }&sort_by=${this.sortStrings ? this.sortStrings[1] : ""}&status=${
        this.statusSeperator
      }&priority=${this.priority}&start_date_before=${
        this.startTo
      }&start_date_after=${this.startFrom}&due_date_before=${
        this.dueTo
      }&due_date_after=${this.dueFrom}&tasks=${
        this.selectedTaskValue
      }&offset=${new Date().getTimezoneOffset()}`;

      const { data } = await API.TASKS.LEAD_DATA_BY_ID(id, tableData);
      data.results.map((item) => {
        item.dueBy = item.due_by;
        item.dueDate = item.due_by ? this.formatDate(item.due_by) : "N/A";
        item.location = item.lead_details.address;
        item.homeOwner = item.lead_details.name;
        item.name = item.name !== "" ? item.name : "N/A";
        item.assignees = item.assigned_to !== " " ? item.assigned_to : "N/A";
        item.flag = false;
        item.showInput = false;
        item.showDropdown = false;
        item.showDatePicker = false;
        item.nudgeTooltipText = "Nudge";
        switch (item.lead_stage) {
          case "":
            item.lead_stage = "N/A";
            break;
          default:
            item.lead_stage = item.lead_stage ? item.lead_stage : "N/A";
        }

        switch (item.status) {
          case "in_progress":
            item.status = "In-Progress";
            break;
          case "completed":
            item.status = "Completed";
            break;
          case "new":
            item.status = "New";
            break;
          default:
            item.status = item.status ? item.status : "New";
        }
        item.statusOption = [
          {
            id: 1,
            value: "new",
            label: "New",
          },
          {
            id: 2,
            value: "in_progress",
            label: "In-Progress",
          },
          {
            id: 3,
            value: "completed",
            label: "Completed",
          },
        ];
      });
      const newData = data.results;

      const validLead = this.leadsNames.find((item) => item.lead_id === id);
      validLead.tasks = [];
      validLead.tasks = [newData];
      if (data.next !== null) this.loadInnerTableData(data.next, id);
    },

    async loadInnerTableData(url, id) {
      try {
        if (url !== null || url !== undefined) {
          const { data } = await API.TASKS.GET_MORE_GROUP_BY_LEAD_ITEMS(url);
          data.results.map((item) => {
            item.dueBy = item.due_by;
            item.dueDate = item.due_by ? this.formatDate(item.due_by) : "N/A";
            item.location = item.lead_details.address;
            item.homeOwner = item.lead_details.name;
            item.name = item.name !== "" ? item.name : "N/A";
            item.assignees = item.assigned_to !== "" ? item.assigned_to : "N/A";
            item.flag = false;
            item.showInput = false;
            item.showDropdown = false;
            item.showDatePicker = false;
            item.nudgeTooltipText = "Nudge";
            switch (item.lead_stage) {
              case "":
                item.lead_stage = "N/A";
                break;
              default:
                item.lead_stage = item.lead_stage ? item.lead_stage : "N/A";
            }

            switch (item.status) {
              case "in_progress":
                item.status = "In-Progress";
                break;
              case "completed":
                item.status = "Completed";
                break;
              case "new":
                item.status = "New";
                break;
              default:
                item.status = item.status ? item.status : "New";
            }
            item.statusOption = [
              {
                id: 1,
                value: "in_progress",
                label: "In-Progress",
              },
              {
                id: 2,
                value: "completed",
                label: "Completed",
              },
              {
                id: 3,
                value: "new",
                label: "New",
              },
            ];
          });
          const newDropData = data.results;
          const validLead = this.leadsNames.find((item) => item.lead_id === id);
          validLead.tasks[0].push(...newDropData);
          if (data.next !== null) {
            this.nextUrl(data.next, id);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    nextUrl(url, id) {
      this.loadInnerTableData(url, id);
    },

    formatDate(inputDate) {
      const date = new Date(inputDate);
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();
      const formattedDate = `${day} ${month}, ${year}`;

      return formattedDate;
    },

    getFormatedData(data) {
      let lead_stage = "";
      let status = "";
      switch (data.lead_stage) {
        case "":
          lead_stage = "N/A";
          break;
        default:
          lead_stage = data.lead_stage ? data.lead_stage : "N/A";
      }
      switch (data.status) {
        case "in_progress":
          status = "In-Progress";
          break;
        case "completed":
          status = "Completed";
          break;
        case "new":
          status = "New";
          break;
        default:
          status = data.status;
      }
      const newData = {
        ...data,
        dueBy: data.due_by,
        dueDate: this.formatDate(data.due_by),
        location: data.lead_details.address ? data.lead_details.address : "N/A",
        homeOwner: data.lead_details.name,
        assignees: data.assigned_to !== " " ? data.assigned_to : "N/A",
        flag: false,
        showInput: false,
        showDropdown: false,
        showDatePicker: false,
        nudgeTooltipText: "Nudge",
        checkBox: data.status,
        lead_stage,
        due_by: this.dateHandler(data.due_by),
        status,
        statusOption: [
          {
            id: 1,
            value: "new",
            label: "New",
          },
          {
            id: 2,
            value: "in_progress",
            label: "In-Progress",
          },
          {
            id: 3,
            value: "completed",
            label: "Completed",
          },
        ],
      };

      return newData;
    },

    async patchData(id, data) {
      try {
        const formData = new FormData();

        formData.append("offset", new Date().getTimezoneOffset());

        data.map((i) =>
          formData.append(
            i.isArray ? i.propertyName + "[]" : i.propertyName,
            i.value
          )
        );
        let result = await API.TASKS.UPDATE_TASK_LIST(id, formData);

        const placeToReplace = this.kanbanDataValue.find(
          (item) => item.stage === data[0].property?.stage
        );

        if (placeToReplace) {
          const newData = placeToReplace.tasks.filter(
            (item) => item.id !== result.data.id
          );

          this.kanbanDataValue.map((item) => {
            if (item.stage === data[0].property.stage) {
              item.tasks = newData;
              item.count -= 1;
            }
          });
        }

        const newKData = {
          ...result.data,
          assignees: [
            {
              id: result.data.assignee,
              name: result.data.assigned_to,
            },
          ],
          nudgeTooltipText: "Nudge",
          stage: result.data.task_stage,
        };

        this.kanbanDataValue.map((item) => {
          if (item.stage === result.data.task_stage) {
            item.tasks.unshift(newKData);
            item.count += 1;
          }
        });

        const tableData = this.tableColumnData.filter(
          (item) => item.id !== result.data.id
        );

        this.tableColumnData = tableData;
        let newDataTable = this.getFormatedData(result.data);

        this.tableColumnData = [newDataTable, ...this.tableColumnData];

        if (this.$props.isDrilldown) {
          if (this.$props.isDrilldown) {
            let updatewidgersData = {
              id: this.$props.widgetId,
              flag: !this.updateWidgets,
            };
            this.setDashboard(updatewidgersData);
          }
          this.$emit("updateWidget");
        }
        // this.getListView();
        // this.getemptyData();
        // this.getTasksData();
      } catch (err) {
        console.error(err);
        this.$message({
          showClose: true,
          message: "Error in updating the task",
          type: "error",
          center: true,
        });
        this.getemptyData();
        this.getTasksData();
      }
    },
    searchAllLeads(query) {
      if (query !== "") {
        this.isLoading = true;
        setTimeout(() => {
          this.searchAllLeadsHelper(query);
        }, 200);
      } else {
        // fetching all leads
        this.allLeadsList = [];
        this.isLoading = true;
        this.searchVal = "";
        this.getemptyData();
        this.getTasksData();
      }
    },
    async searchAllLeadsHelper(query) {
      try {
        this.isSearchedLeadEmpty = true;
        this.isSearchingLeads = true;
        this.searchVal = query;
        this.getemptyData();
        this.getTasksData();
      } catch (error) {
        console.error(error);
      }
    },
    handleDisplaySort(data) {
      const SortData = this.menuData.find((d) => d.title === "Sort");
      const DrillSortData = this.menuData.find((d) => d.title == "Sort by");

      if (SortData || DrillSortData) {
        const SortOptions = SortData ? SortData.options : DrillSortData.options;
        const options = SortOptions.find((f) => f.label === data[1]);
        const subOptions = options?.subOptions.find(
          (op) => op.index === data[2]
        );
        return `${options.value} : ${subOptions.value}`;
      }
    },
    handleRemoveFilter(item) {
      this.selectedFilter = this.selectedFilter.filter((e) => {
        if (e !== item) return e;
      });
    },
    handleDisplayFilters(data) {
      const FilterData = this.menuData.find((d) => d.title === "Filter");

      if (FilterData) {
        const FilterOptions = FilterData.options;
        const options = FilterOptions.find((f) => f.label === data[1]);
        return options.value;
      }
    },
    handleRemoveSort() {
      this.selectedSort = "";
      this.sortStrings = "";
    },
    handleStatusremove() {
      this.statusSeperator = "";
      this.statusSources = "";
      this.getemptyData();
      this.getTasksData();
      this.status = true;
    },
    handleCollaboratorsremove() {
      this.collaboratorSeperator = "";
      this.collaboratorSources = "";
      this.getemptyData();
      this.getTasksData();
      this.colloabo = true;
    },
    handleHomeownerremove() {
      this.homeownerSeperator = "";
      this.homeownerSources = "";
      this.getemptyData();
      this.getTasksData();
      this.homeOwn = true;
    },
    handleRemovestartClosure() {
      this.startDateClosure = "";
      this.startFrom = "";
      this.startTo = "";
      this.startDate = "";
      this.startDateClear=true
      this.getemptyData();
      this.getTasksData();
    },
    handleRemoveEndClosure() {
      this.dueDateClosure = "";
      this.dueFrom = "";
      this.dueTo = "";
      this.dueDate = "";
      this.dueDateClear=true
      this.getemptyData();
      this.getTasksData();
    },
    handleRemovePriority() {
      this.priority = "";
      this.priorityValue = "";
      this.getemptyData();
      this.getTasksData();
      this.prior = true;
    },
    handleClear() {
      this.priority = "";
      this.priorityValue = "";
      this.selectedFilter = [];
      this.selectedSort = "";
      this.startDateClosure = "";
      this.startFrom = "";
      this.startTo = "";
      this.startDate = "";
      this.dueDateClosure = "";
      this.dueFrom = "";
      this.dueTo = "";
      this.dueDate = "";
      this.sortStrings = "";
      this.statusSeperator = "";
      this.statusSources = "";
      this.collaboratorSeperator = "";
      this.collaboratorSources = "";
      this.homeownerSeperator = "";
      this.homeownerSources = "";
      this.clearall = true;
    },
    statusCheckbox(value) {
      this.statusSeperator = value ? value.join(",") : "";
      this.status = value[0];
      let data = value ? { label: "Status", value: value} : "";
      this.statusSources = data;
      this.getemptyData();
      this.getTasksData();
      this.getLead();
    },
    collaboratorCheckbox(value) {
      value.forEach((childId) => {
        let element = this.allUserData.find((e) => e.id === childId);
        if (element.id) {
          this.collaboratorsNames.push(element.name);
        }
      });
      this.collaboratorSeperator = value ? value.join(",") : "";
      // this.status = value[0];
      let data = value
        ? { label: "Collaborators", value: this.collaboratorsNames }
        : "";
      this.collaboratorsNames = [];
      this.collaboratorSources = data;
      this.getemptyData();
      this.getTasksData();
      this.getLead();
    },
    homeownerCheckbox(value) {
      value.forEach((childId) => {
        let element = this.allLeads.find((e) => e.id === childId);
        if (element.id) {
          this.homeownerNames.push(element.name);
        }
      });
      this.homeownerSeperator = value ? value.join(",") : "";
      // this.status = value[0];
      let data = value
        ? { label: "Homeowner", value: this.homeownerNames }
        : "";
      this.homeownerNames = [];
      this.homeownerSources = data;
      this.getemptyData();
      this.getTasksData();
      this.getLead();
    },
    priorityCheck(value) {
      this.priority = value;
      let data = value
        ? {
            label: "Priority",
            value: value == "default" ? "Default" : "Urgent",
          }
        : "";
      this.priorityValue = data ? data : "";
      this.getemptyData();
      this.getTasksData();
      this.getLead();
    },
    startDateRange(value) {
      this.startDate = value;
      this.startFrom = value ? value[0].toISOString() : "";
      this.startTo = value ? value[1].toISOString() : "";
      let data = value
        ? {
            label: "Start Date",
            value: value,
          }
        : "";
      this.startDateClosure = data;
      this.getemptyData();
      this.getTasksData();
      this.getLead();
    },
    dueDateRange(value) {
      this.dueDate = value;
      this.dueFrom = value ? value[0].toISOString() : "";
      this.dueTo = value ? value[1].toISOString() : "";
      let data = value
        ? {
            label: "Due Date",
            value: value,
          }
        : "";
      this.dueDateClosure = data;
      this.getemptyData();
      this.getTasksData();
      this.getLead();
    },
    updateDate(value) {
      value.due_by = value.due_by.toISOString();
      const { id } = value;
      this.patchData(id, [
        {
          propertyName: "due_by",
          value: value.due_by,
          isArray: false,
          property: value,
        },
      ]);
    },

    updateTableDate(value) {
      const { id } = value;
      this.patchData(id, [
        {
          propertyName: "due_by",
          value: value.due_by,
        },
      ]);
    },

    handlePriority(value) {
      value.priority = value.priority == "default" ? "high" : "default";
      const { id } = value;
      this.patchData(id, [
        {
          propertyName: "priority",
          value: value.priority,
          isArray: false,
          property: value,
        },
      ]);
      this.groupByLead && this.getLeadsData(value.lead);
    },

    async handleTablePriority(value) {
      const { id } = value;

      const { priority } = value;
      let prevState = priority;
      try {
        await this.patchData(id, [
          {
            propertyName: "priority",
            value: priority,
            isArray: false,
            property: value,
          },
        ]);
      } catch (err) {
        console.error(err);
        if (err.response.status == 403) {
          this.$message({
            showClose: true,
            message: "You don't have the permission to perform this action",
            type: "error",
            center: true,
          });
        }
      }
      this.groupByLead && this.getLeadsData(value.lead);
    },

    async updateStatus(value) {
      let prevStatus = value.status;
      value.status = value.status == "completed" ? "in_progress" : "completed";
      // value.is_completed=value.is_completed==false ? true: false
      const { id } = value;
      try {
        await this.patchData(id, [
          {
            propertyName: "status",
            value: value.status,
            isArray: false,
            property: value,
          },
          {
            propertyName: "is_completed",
            value: value.status === "completed" ? true : false,
            isArray: false,
            property: value,
          },
        ]);
      } catch (err) {
        value.status = prevStatus;
        console.error(err);
        if (err.response.status == 403) {
          this.$message({
            showClose: true,
            message: "You don't have the permission to perform this action",
            type: "error",
            center: true,
          });
        }
      }
    },

    async mountedActions() {
      if (this.$route.query.selectedTask) {
        const { data } = await API.TASKS.FETCH_TASK_BY_ID(
          this.$route.query.selectedTask
        );
        this.handleOnClick(data);
      } else {
        // this.$message({
        //   showClose: true,
        //   message: this.$props.isDrilldown
        //     ? "Error in fetching Data. Please try again later."
        //     : "There was an while Fetching Data. Please try again later.",
        //   type: "error",
        //   center: true,
        // });
      }
    },

    async updateTableStatus(value) {
      const { id } = value;
      let prevState = value.status;
      try {
        await this.patchData(id, [
          {
            propertyName: "status",
            value: value.status,
            isArray: false,
          },
        ]);
      } catch (err) {
        value.status = prevState;
        console.error(err);
        if (err.response.status == 403) {
          this.$message({
            showClose: true,
            message: "You dont have the permission to perform this action",
            type: "error",
            center: true,
          });
        }
      }
      this.groupByLead && this.getLeadsData(value.lead);
    },

    editableName(value) {
      const { id } = value;
      this.patchData(id, [
        {
          propertyName: "name",
          value: value.name,
          isArray: false,
          property: value,
        },
      ]);
    },
    handleOnClick(data) {
      if (this.$props.isDrilldown) {
        this.$router.push({
          name: "taskManagement:task",
          query: { selectedTask: data.id },
        });
      } else {
        !this.$route.query.selectedTask
          ? this.$router.replace({
              query: { ...this.$route.query, selectedTask: data.id },
            })
          : "";
        this.taskValue = data;
        this.taskValue.isTask = true;
        this.taskValue.reminder_details = data?.reminder_details
          ?.reminder_sent_at
          ? data?.reminder_details
          : null;
        this.reminderDetails = data.reminder_details;
        //open task drawer and pass the task value
        this.showCreateDrawer = true;
        this.createTaskHeading = " ";
        this.taskMode = "view";
      }
    },
    setLeadListingView(isTable) {
      localStorage.setItem("isTable", isTable);

      this.isTableOptClicked = isTable;
      if (isTable === false && this.kanbanDataValue[0].tasks.length === 0) {
        this.getData();
      }
    },
    formattedDate(start, end) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const date = new Date(start);
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
      const month = months[monthIndex];

      const enddate = new Date(end);
      const endday = enddate.getDate();
      const endmonthIndex = enddate.getMonth();
      const endyear = date.getFullYear();
      const endmonth = months[endmonthIndex];

      return `${day} ${month} ${year} - ${endday} ${endmonth} ${endyear}`;
    },
    async getAllLeads() {
      try {
        const response = await API.TASKS.FETCH_ALL_LEAD_LIST();
        const allLead = response.data.results;
        this.homeOwnerResponse = response.data;
        this.homeOwnerScroll = response.data;
        allLead?.map((t) => {
          t.label = t.name;
          t.value = t.id;
          t.location = t.address;
        });
        this.allLeads = allLead ? allLead : [];
      } catch (e) {
        this.$message({
          showClose: true,
          message: "There was an unknown error while fetching the tags",
          type: "error",
          center: true,
        });
      }
    },
    async getAllUsers() {
      try {
        const response = await API.USERS.FETCH_ALL_USERS();
        this.count = response.data.count;
        this.avatars = response.data.results.map((e) => {
          return {
            ...e,
            name: `${e.first_name} ${e.last_name}`,
            isSelected: false,
          };
        });
        response.data.results.forEach((item) => {
          item.name = `${item.first_name} ${item.last_name}`;
          item.label = `${item.first_name} ${item.last_name}`;
          delete item.last_name;
          delete item.first_name;
          delete item.competence;
          delete item.shifts;
          delete item.email;
        });
        this.allUsers = response.data.results;
        this.nextCollaboratorUrl = response.data.next;
      } catch (e) {
        this.$message({
          showClose: true,
          message: "There was an unknown error while fetching the tags",
          type: "error",
          center: true,
        });
      }
    },
    async getAllUserData(){
      const param= 'view=tree&only_internal=true'
      let response=  await API.USER_MANAGEMENT.FETCH_USERS_TREE(param)
      response.data.forEach((item) => {
          item.name = `${item.first_name} ${item.last_name}`;
          item.label = `${item.first_name} ${item.last_name}`;
          delete item.last_name;
          delete item.first_name;
          delete item.competence;
          delete item.shifts;
          delete item.email;
        });
      this.allUserData= response.data
    },
    applySelectedColumns() {
      let ui = getUiFromStorage();
      this.showCustomizeTable = false;
      this.checkedTableColumns = this.allColumnsList.filter((column) =>
        this.checkedColumnsList.includes(column.id)
      );

      if (!this.$props.isDrilldown && !this.groupByLead) {
        this.checkedTableViewColumn = this.allTableViewColumnList.filter(
          (column) => this.checkedColumnsList.includes(column.id)
        );
      }

      if (!this.$props.isDrilldown && this.groupByLead) {
        this.checkedDropDownTableList = this.allDropDownTableList.filter(
          (column) => this.checkedColumnsList.includes(column.id)
        );
        this.checkedDropDownHeaderList = this.allDropDownHeaderList.filter(
          (column) => this.checkedColumnsList.includes(column.id)
        );
      }

      if (this.$props.isDrilldown) {
        ui.dashboard.drillDownColumnList = this.checkedColumnsList;
      }

      if (!this.groupByLead && !this.$props.isDrilldown) {
        ui.dashboard.taskColumnList = this.checkedColumnsList;
      } else {
        ui.dashboard.dropDownList = this.checkedColumnsList;
      }

      setUiInStorage(ui);
    },
  },
  async mounted() {
    let ui = getUiFromStorage();
    this.mountedActions();
    this.getLead();
    this.nextTableUrl=''

    const vm = this;
    window.addEventListener(
      "click",
      function (e) {
        let customizeTableButton = document.querySelector(
          ".relativeContCustom"
        );
        if (!customizeTableButton) {
          return;
        }

        if (customizeTableButton?.contains(e.target)) {
          this.showCustomizeTable = true;
        } else {
          this.showCustomizeTable = false;
        }
      }.bind(vm)
    );

    if (!this.$props.widgetId) {
      this.getTasksData();
    }

    //something was wrong with this so commenting
    //this.getTableData();
    this.getAllLeads();
    this.getAllUsers();
    this.getAllUserData();
    let org = JSON.parse(localStorage.getItem("user"));
    if (this.$props.isDrilldown) {
      this.groupByLead = false;
    }
    if (JSON.parse(localStorage.getItem("groupByLead")) === false) {
      this.groupByLead = false;
    }
    if (this.$props.isDrilldown) {
      this.checkedColumnsList = ui.dashboard.drillDownColumnList
        ? ui.dashboard.drillDownColumnList
        : this.checkedColumnsList;
    }
    if (!this.groupByLead && !this.$props.isDrilldown) {
      this.checkedColumnsList = ui.dashboard.taskColumnList
        ? ui.dashboard.taskColumnList
        : this.checkedColumnsList;
    } else {
      this.checkedColumnsList = ui.dashboard.dropDownList
        ? ui.dashboard.dropDownList
        : this.checkedColumnsList;
    }

    setUiInStorage(ui);
    this.checkedTableColumns = this.allColumnsList.filter((column) =>
      this.checkedColumnsList.includes(column.id)
    );
  },
  computed: {
    customColumnsListData() {
      return this.allColumnsList.filter(
        (d) => d.id !== "slNo" && d.id !== "name"
      );
    },

    customClassNames() {
      if (this.$props.isDrilldown) {
        return "customizeDropdown";
      } else {
        return "customizeTableDropdown";
      }
    },

    taskViewOptions() {
      let options = [
        {
          value: "my",
          title: "My Tasks",
          label:"My Tasks"
        },
        {
          value: "collaborated",
          title: "Collaborations",
          label:"Collaborations"
        },
      ];

      if (this.admin === "ADMIN") {
        options.push({
          value: "all",
          title: "All Tasks",
          label:"All Tasks"
        });
      }

      if (this.manager) {
        options.push({
          value: "team",
          title: "My Team's Tasks",
          label:"My Team's Tasks"
        });
      }

      return options;
    },
    kanbanDataValue() {
      const kanbanSortedData = this.kanbanData.sort(
        (a, b) => a.sequence - b.sequence
      );
      let key = "sequence";
      const arrayUniqueByKey = [
        ...new Map(kanbanSortedData.map((item) => [item[key], item])).values(),
      ];
      return arrayUniqueByKey;
    },
    menuData() {
      const menuItems = [
        {
          title: "Sort",
          icon: "sort",
          callback: (key, keypoints) => {
            this.selectedSort = keypoints;
          },
          options: [
            {
              value: "Task Name",
              label: "task_name",
              subOptions: [
                {
                  value: "Ascending",
                  label: "asc",
                  index: 1,
                },
                {
                  value: "Descending",
                  label: "desc",
                  index: 2,
                },
              ],
            },
            {
              value: "Status",
              label: "status",
              subOptions: [
                {
                  value: "Ascending",
                  label: "asc",
                  index: 3,
                },
                {
                  value: "Descending",
                  label: "desc",
                  index: 4,
                },
              ],
            },
            {
              value: "Priority",
              label: "priority",
              subOptions: [
                {
                  value: "Ascending",
                  label: "asc",
                  index: 5,
                },
                {
                  value: "Descending",
                  label: "desc",
                  index: 6,
                },
              ],
            },
            {
              value: "Due Date",
              label: "due_date",
              subOptions: [
                {
                  value: "Ascending",
                  label: "asc",
                  index: 7,
                },
                {
                  value: "Descending",
                  label: "desc",
                  index: 8,
                },
              ],
            },
            {
              value: "Start Date",
              label: "start_date",
              subOptions: [
                {
                  value: "Ascending",
                  label: "asc",
                  index: 9,
                },
                {
                  value: "Descending",
                  label: "desc",
                  index: 10,
                },
              ],
            },
            {
              value: "Project Name",
              label: "project_name",
              subOptions: [
                {
                  value: "Ascending",
                  label: "asc",
                  index: 11,
                },
                {
                  value: "Descending",
                  label: "desc",
                  index: 12,
                },
              ],
            },
            {
              value: "Stage",
              label: "stage",
              subOptions: [
                {
                  value: "Oldest To Newest",
                  label: "asc",
                  index: 13,
                },
                {
                  value: "Newest To Oldest",
                  label: "desc",
                  index: 14,
                },
              ],
            },
          ],
        },
        {
          title: "Filter",
          icon: "filter",
          // callback: (key, keypoints) => {
          //   this.selectedFilter = keypoints;
          // },

          callback: (key, keypoints) => {
            const isExist = this.selectedFilter.find(
              (e) => e[1] === keypoints[1]
            );
            if (isExist) {
              if (keypoints[1] === "Created at" && keypoints[2] === "Custom")
                this.show = !this.show;
              if (keypoints[1] === "Due Date" && keypoints[2] === "Custom")
                this.dueshow = !this.dueshow;
              this.selectedFilter = this.selectedFilter.filter((e) => {
                if (e[1] !== keypoints[1]) return e;
              });
              this.selectedFilter.push(keypoints);
            } else {
              // this.ExpectedClosure()
              this.selectedFilter.push(keypoints);
            }

            this.$emit("filterView", this.selectedFilter);
          },
          options: [
            {
              value: "Status",
              label: "status",
              type: "checkbox",
              subOptions: [
                {
                  value: "New",
                  label: "new",
                },
                // {
                //     value: "Overdue",
                //     label: "overdue",
                // },
                {
                  value: "In Progress",
                  label: "in_progress",
                },
                {
                  value: "Completed",
                  label: "completed",
                },
              ],
            },
            {
              value: "Priority",
              label: "priority",
              type: "radio",
              subOptions: [
                {
                  value: "Default",
                  label: "default",
                },

                {
                  value: "Urgent",
                  label: "high",
                },
              ],
            },
            {
              value: "Due Date",
              label: "due_by",
              type: "date",
            },
            {
              value: "Start Date",
              label: "started_on",
              type: "date",
            },
            // {
            //     value: "Involvement",
            //     label: "involvement",
            //     type: "text",
            //     subOptions: [
            //         {
            //             value: "Assignee",
            //             label: "assignee",

            //         },
            //         {
            //             value: "Collaborator",
            //             label: "collaborator",

            //         },
            //     ],
            // },
            {
              value: "Collaborators",
              label: "collaborator",
              type: "collaborators",
              subOptions: this.allUsers,
            },
            {
              value: "Homeowners",
              label: "homeowners",
              type: "homeowner",
              subOptions: this.allLeads,
            },
          ],
          expected_closure: "",
        },
        {
          title:this.selecetedTaskType,
          icon: "",
          callback: (key, keypoints) => {
            this.selectedTaskValue = key;
            this.selecetedTaskType=this.taskViewOptions.find((d) => d.value === key).label
            
          },
          singleLevel: true,
          options: this.taskViewOptions,
        },
      ];
      let menu = [];
      if (this.isDrilldown) {
        menu = menuItems.filter((d) => d.title === "Sort");
        menu.map((d) => {
          if (d.title === "Sort") {
            d.title = "Sort by";
          }
        });
      }
      return menu.length ? menu : menuItems;
    },
  },
};
</script>

<style scoped>
.checkBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 2%;
}

.checkBox .el-checkbox {
  padding: 5%;
}

.updateButton {
  background-color: #1c191c2e;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  float: right;
  margin-right: 12px;
}

.labelText {
  margin-right: 10px;
}

@media (min-width: 1281px) {
  .container {
    width: calc(100% - 74px);
    margin-left: auto;
    padding: 24px 30px;
    height: calc(100vh - 101px);
    background-color: #e8edf2;
  }
}

@media (max-width: 1280px) {
  .container {
    padding: 24px;
    background-color: #e8edf2;
    height: 94vh;
    overflow: hidden;
    overflow-y: scroll;
  }
}

@media (max-width: 650px) {
  .container {
    padding: 24px;
    background-color: #e8edf2;
    height: 94vh;
    overflow: hidden;
    overflow-y: scroll;
  }
}
</style>

<style scoped>
.cardOpt .typeIcon, .tableOpt{
  font-size: 20px;
}
.activeClass{
  color: white !important;
  background-color: #1C3366 !important;
}
.gridContainer {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.firstContainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  gap: 14px;
}

.secContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  gap: 0;
}

.commonPtag {
  font-size: 16px;
  font-weight: 600;
  color: #222;
  margin-left: -8px;
}

.filterCont {
  display: flex;
  align-items: center;
  gap: 12px;
}

.relativeCont {
  position: relative;
}

.customize {
  font-size: 16px;
  font-weight: 600;
  color: #409eff;
  cursor: pointer;
}

.customizeDropdown {
  position: absolute;
  top: 24px;
  width: 300px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  z-index: 999;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}
.customizeTableDropdown {
  position: absolute;
  top: 165px;
  width: 300px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  z-index: 999;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.drpDwnHeading {
  font-size: 16px;
  font-weight: 600;
  color: #1c3366;
}

.drpdwnFlex >>> .el-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  overflow: hidden;
  overflow-y: scroll;
  height: 250px;
}

.applyBtnContainer {
  text-align: center;
  margin-top: 8px;
}

.searchInpCont {
  position: relative;
  max-width: 320px;
  margin-right: 10px;
  background-color: white;
}

.searchInput {
  width: 100%;
  font-size: 18px;
  padding-left: 36px;
  height: 42px;
  border-radius: 4px;
  border: none;
}

.searchIcon {
  position: absolute;
  top: 12px;
  left: 8px;
}

.tableTypeCont {
  display: flex;
  align-items: center;
}

.tableOpt,
.cardOpt {
  display: grid;
  place-items: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-style: solid;
  border-color: #999999;
}
.cardOpt{
  border-width: 1px 1px 1px 0px;
  border-radius: 0px 4px 4px 0px;
}

.tableOpt {
  border-width: 1px 0px 1px 1px;
  border-radius: 4px 0px 0px 4px;
}

.createLeadBtn {
  width: 123px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  padding: 0px;
  margin-left: 30px;
}

.relativeCont >>> .el-checkbox {
  display: flex;
  align-items: center;
  gap: 6px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.firstContainer >>> .el-submenu__title i {
  color: #000000;
}

.relativeCont >>> .el-checkbox__label {
  font-size: 16px;
  color: #222;
}

.relativeCont >>> .el-checkbox__inner {
  width: 18px;
  height: 18px;
  border: 1px solid #777;
}

.filterCont >>> .el-select {
  width: 100%;
  max-width: 150px;
}
.el-select-dropdown__item {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #222;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
}
.relativeCont >>> .el-input__inner {
  border: none !important;
  font-weight: 600;
  font-size: 16px;
  color: #222 !important;
}
.relativeCont >>> .el-input__inner::placeholder {
  font-size: 16px;

  color: #222222;
}
.filterCont >>> .el-input__inner::placeholder {
  font-size: 16px;
  font-weight: 600;
  color: #222;
}

.relativeCont >>> .el-select .el-input .el-select__caret {
  font-size: 18px;
  font-weight: bold;
  color: #222222;
}

.filterCont >>> .el-select .el-input .el-select__caret {
  color: #222;
  font-weight: bold;
  position: relative;
  font-size: 18px;
}

.filterCont >>> .thirdSelect {
  width: 100%;
  max-width: 190px;
}

.navContainer >>> .el-checkbox__inner::after {
  border: 2px solid #ffffff;
  border-left: 0;
  border-top: 0;
  height: 8px;
  left: 5px;
}

.tagsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.activeFilters {
  display: flex;
  margin: 15px auto;
}

.actFil,
.clrAll {
  font-size: 16px;
  color: #777;
  padding-right: 8px;
  margin: auto 0;
}

.clrAll {
  text-align: center;
  padding-left: 8px;
  cursor: pointer;
}

.tags {
  padding: 4px 12px;
  border-radius: 50px;
  background-color: #1c3366;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.crossIcon {
  cursor: pointer;
}

.el-tag {
  color: #ffffff;
  background-color: #1c3366;
  height: 35px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 50px;
  text-align: center;
  position: relative;
  padding-right: 33px;
}
.tag-value{
    background-color: white;
    margin-top: 4px;
    color: black;
    border-radius: 50%;
    display: inherit;
    line-height: 18px;
    width: 18px;
    font-size: small;
    margin-left: 2px;
}
.filterTag{
  padding: 2px 0px;
}
.el-tag--medium {
    line-height: 30px;
}
.el-tag >>> .el-icon-close {
  position: absolute;
  right: 13px;
  top: 8.5px;
  color: #ffffff;
  font-size: 16px;
}

.el-tag >>> .el-icon-close::before {
  color: #ffffff;
  font-size: 16px;
}
.container-dash {
  margin-left: auto;
  height: calc(100vh - 170px);
  background-color: #fff;
  padding: 5px 12px;
}
.container-dash-sort{
  margin-left: auto;
  height: calc(100vh - 145px);
  background-color: #fff;
  padding: 5px 12px;
}

.el-checkbox >>> .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #222;
  border-radius: 2px;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background-color: #ffffff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}

.relativeContCustom >>> .el-checkbox {
  display: flex;
  align-items: center;
  gap: 6px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.relativeContCustom >>> .el-checkbox__label {
  font-size: 16px;
  color: #222;
}

.relativeContCustom >>> .el-checkbox__inner {
  width: 18px;
  height: 18px;
  border: 1px solid #777;
}
</style>

<style>
::-webkit-scrollbar {
  width: 5px;
  border-radius: 4px;
  height: 6px;
}
</style>
