
<div
  class="task-drawer-container"
  :class="[{ taskViewMode: mode === 'view' }]"
  v-loading="isTaskLoading"
>

  <div class="taskCard">
  <!-- Update Status Icon -->
  <div
    class="update-task-status"
    :class="shouldShowTaskStatus()"
    v-if="mode == 'view'"
  >
    <b-icon
      icon="arrow-clockwise"
      animation="spin"
      font-scale="2"
      v-if="taskUpdateStatus == 'updating'"
    ></b-icon>
    <b-icon
      icon="check-circle"
      font-scale="2"
      v-if="taskUpdateStatus == 'complete'"
      style="color: #28a745"
    ></b-icon>
    <span
      v-if="taskUpdateStatus == 'updating'"
    >
      Updating Task..
    </span>
    <span
      v-if="taskUpdateStatus == 'complete'"
    >
      Task Updated.
    </span>
  </div>

  <el-form class="task-form" :label-position="'top'" label-width="100px" :model="task" @keypress.enter.native="onClickCreate">
    <!-- Task Name -->
    <div class="task-name-container">
      <img
        v-if="mode !== 'create'"
        class="task-status-icon"
        :src="taskStatusIcon"
        @click.stop="onClickTaskStatusIcon"
      />
      <el-form-item style="flex: 1; margin-top: 0;" v-if="showEditableField">
        <el-input
          placeholder="Task Name"
          v-model="task.taskName"
          maxlength="50"
          :autofocus="true"
          @input="taskFieldUpdated('name')"
        ></el-input>
        <span class="validation-text home-owner">
          {{ validationText.taskName }}
        </span>
      </el-form-item>
      <span v-else class="task-name">
        {{ task.taskName }}
      </span>
      <div
        class="icons-container"
        v-if="mode !== 'create'"
        >
        <b-icon
          v-if="isMobileView"
          @click="isEditMode = !isEditMode"
          :icon="'pencil'"
          class="task-icon"
        ></b-icon>
        <b-icon
          :icon="'trash'"
          @click="handleDeleteTask"
          class="task-icon"
        ></b-icon>
      </div>
    </div>

    <!-- Assignee -->
    <el-col :span="fieldsColWidthComputed">
      <el-form-item label="Assign To" style="position: relative">
        <div class="dropdown-field-with-2-icons">
          <el-select
            :clearable="false"
            v-model="task.assignee"
            :placeholder="'Select Assignee'"
            @visible-change="onDropdownVisibleChange()"
            @change="onChangeAssignee(); taskFieldUpdated('assignee_id')"
            :filterable="true"
            class="select-with-prefix assignee-selection"
          >
            <template #prefix>
              <img
                src="/src/pages/taskManagement/components/assets/Person.svg"
                v-show="!task.assignee"
                class="icon-style-left-dropdown"
              />
              <el-avatar
                v-show="task.assignee"
                :size="32"
                class="selected-avatar"
                :style="
                  getAvatarStyle(
                    findLabelById(task.assignee, allUsers)
                  )
                "
                >{{
                  extractLetters(
                    findLabelById(task.assignee, allUsers)
                  )
                }}</el-avatar
              >
            </template>
            <el-option
              class="dropdown-option"
              v-for="(option, index) in allUsers"
              :label="option.label"
              :value="option.id"
              :key="index"
            >
              <template #default>
                <el-avatar
                  :size="32"
                  class="option-avatar"
                  :style="getAvatarStyle(option.label)"
                  >{{ extractLetters(option.label) }}</el-avatar
                >
                {{ option.label }}
                <!-- TODO: Check if this icon code is working correctly -->
                <b-icon style="padding-right: inherit;margin-left:auto;" :color="iconColor(option)" :icon="showIcon(option)"></b-icon>
              </template>
            </el-option>
            <div
              v-infinite-scroll="loadMore2"
              infinite-scroll-disabled="isLoadingUsers"
              infinite-scroll-distance="10"
              class="loading-icon"
            >
              <i v-if="isLoadingUsers" class="el-icon-loading infiniteScrollLoader"/>
            </div>
          </el-select>
        </div>
      </el-form-item>
    </el-col>

    <!-- Start Date -->
    <el-col :span="fieldsColWidthComputed">
      <el-form-item label="Start Date" style="position: relative">
        <el-date-picker
          v-if="showEditableField"
          class="date-picker"
          v-model="task.startDate"
          type="date"
          placeholder="Select a date"
          prefix-icon="''"
          :picker-options="pickerOptions"
          :clearable="false"
          :ref="'startDate'"
          @change="handleDateChange('startDate', task.startDate); taskFieldUpdated('started_on')"
        >
        </el-date-picker>
        <span v-else>
          {{ task.startDate ? formatDateForDrawer(task.startDate) : 'None' }}
        </span>
        <img
          v-if="showEditableField"
          src="./assets/CalendarEvent.svg"
          class="icon-style-left date-icon"
          @click="openDatePicker('startDate')"
        />
      </el-form-item>
    </el-col>

    <!-- Due Date -->
    <el-col :span="fieldsColWidthComputed">
      <el-form-item label="Due Date" style="position: relative">
        <el-date-picker
          v-if="showEditableField"
          class="date-picker"
          v-model="task.dueDate"
          type="date"
          placeholder="Select a date"
          prefix-icon="''"
          :picker-options="computePickerOptionsDue"
          :clearable="false"
          :ref="'dueDate'"
          @change="taskFieldUpdated('due_by')"
        >
        </el-date-picker>
        <span v-else>
          {{ task.dueDate ? formatDateForDrawer(task.dueDate) : 'None' }}
        </span>
        <img
          v-if="showEditableField"
          src="./assets/CalendarEvent.svg"
          class="icon-style-left date-icon"
          @click="openDatePicker('dueDate')"
        />
      </el-form-item>
    </el-col>

    <!-- Priority -->
    <el-col :span="fieldsColWidthComputed">
      <el-form-item label="Priority" style="position: relative">
        <div
          class="toggle-icon-parent"
          @click="togglePriority(); taskFieldUpdated('priority')"
        >
          <!-- <img :src="field.leftIcon" class="icon-style-left" /> -->
          <img :src="curPriorityIcon" class="icon-style-left" />
          <span
            :style="{ color: task.priority == 'high' ? 'red' : '#999999' }"
          >
            {{ task.priority == 'high' ? 'Urgent' : 'Default' }}
          </span>
        </div>
      </el-form-item>
    </el-col>

    <!-- Set Reminder -->
    <el-col :span="fieldsColWidthComputed">
      <el-form-item label="Reminder" style="position: relative">
        <div
          v-if="showEditableField"
          @click="onSetReminder"
        >
          <div class="text-field-with-left-icon">
            <img
              v-if="!reminderData?.reminder_sent_at && showEditableField"
              src="./assets/CalendarEvent.svg"
              class="icon-style-left"
            />
            <span class="text-style">{{
              extractDateTimeFromSetReminderDetails()
            }}</span>
          </div>
        </div>
        <p v-else class="reminder-text" @click="onSetReminder">
          {{ reminderData?.reminder_sent_at ? extractDateTimeFromSetReminderDetails(reminderData?.reminder_sent_at) : 'Set Reminder' }}
        </p>
      </el-form-item>
    </el-col>

    <!-- Status -->
    <el-col :span="fieldsColWidthComputed">
      <el-form-item label="Status" style="position: relative">
        <el-select
          v-if="showEditableField"
          @change="taskFieldUpdated('status')"
          v-model="task.status"
          placeholder="Select a status"
        >
          <el-option
            v-for="option in taskStatusOptions"
            :label="option.label"
            :value="option.id"
            :key="option.id"
            :disabled="option.disabled ? true : false"
          ></el-option>
        </el-select>
        <span v-else>
          {{ taskStatusOptions.find(option => option.id == task.status)?.label }}
        </span>
      </el-form-item>
    </el-col>

    <!-- Homeowner -->
    <el-col :span="fieldsColWidthComputed">
      <el-form-item style="position: relative">
        <template v-slot:label>
        <div>
          Homeowner
          <span v-if="showEditableField && canHomeownerBeChanged" class="required-field">*</span>
        </div>
        </template>
        <span class="validation-text home-owner">{{
          validationText.homeOwner
        }}</span>
        <el-tooltip
          v-if="showEditableField && canHomeownerBeChanged"
          class="item"
          effect="dark"
          content="Homeowner cannot be changed after the task has been created."
          placement="top-start"
          popper-class="task-drawer-homeowner-tooltip"
          :disabled="!isHomeownerDisabled()"
        >
          <el-select
            class="infinite-dropdown"
            v-model="task.homeOwner"
            placeholder="Enter a homeowner"
            @change="onChangeHomeowner(); taskFieldUpdated('lead_id');"
            :filterable="true"
            :filter-method="customFilterMethodForLeads"
            :disabled="isHomeownerDisabled()"
          >
            <el-option
              v-for="option in allLeads"
              :label="option.label"
              :value="option.id"
              :key="option.id"
              :disabled="option.disabled ? true : false"
            ></el-option>
            <div
              v-infinite-scroll="loadMoreLeads"
              infinite-scroll-disabled="isLoadingLeads"
              infinite-scroll-distance="10"
              class="loading-icon"
            >
              <i v-if="isLoadingLeads" class="el-icon-loading infiniteScrollLoader"/>
            </div>
          </el-select>
        </el-tooltip>
        <router-link
        v-else
            :to="{
              name: 'leadSummary',
              params: { leadId: allLeads.find(lead => lead.id == task.homeOwner)?.id },
            }"
           @click="onClickToRedirectToLeadSummary"
          >
        <p class="homeowner-name">
          <span><img class="icon-style-left" src="../../../assets/img/PersonBadge.svg" alt=""></span>
          <span style="color:#409EFF">{{ allLeads.find(lead => lead.id == task.homeOwner)?.name }}</span>
        </p>
        </router-link>
      </el-form-item>
    </el-col>
    <!-- Lead Stage -->
    <el-col :span="fieldsColWidthComputed">
      <el-form-item label="Perform Task at" style="position: relative">
        <el-select
          v-if="showEditableField"
          v-model="task.leadStage"
          @change="taskFieldUpdated('lead_stage');"
          placeholder="Select a stage"
          :class="[task.homeOwner== '' ? 'disabled-lead-stage' : '']"
          :disabled="task.homeOwner== '' ? true : false"
          v-loading="isLoadingLeadStages"
        >
          <el-option
            v-for="option in leadStageOptions"
            :label="option.label"
            :value="option.id"
            :key="option.id"
            :disabled="option.disabled ? true : false"
          ></el-option>
        </el-select>
        <span v-else>
          {{ leadStageOptions.find(stage => stage.id == task.leadStage)?.name }}
        </span>
      </el-form-item>
    </el-col>

    <!-- Collaborators -->
    <el-col :span="fieldsColWidthComputed">
      <el-form-item label="Collaborators" style="position: relative">
        <div class="collaborators" :class="!showEditableField && 'non-editable-collaborators'">
          <AvatarHandler
            v-if="task?.collaborators?.length || showEditableField"
            :overlap="true"
            :limit="isMobileView ? 2 : 3"
            :imgWidth="30"
            :fontSize="'14px'"
            :avatars="task.collaborators"
            :selectionAvatars="avatars"
            :removable="true"
            :editable="showEditableField"
            :gap="5"
            @handleRemove="handleCollaboratorRemove"
            @handleAdd="handleCollaboratorAdd"
            @loadMore="loadMore2"
            :mode="mode"
          />
          <p v-else>None</p>
        </div>
      </el-form-item>
    </el-col>

    <!-- Location -->
    <el-col :span="16">
      <el-form-item label="Location" class="task-lead-location">
        <p class="task-lead-location-value">
          {{ task.location || 'No address' }}
        </p>
      </el-form-item>
    </el-col>
    <el-col :span="8">
      <el-form-item label="Nudge" class="task-lead-location">
        <div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="nudgeTooltipContent"
            placement="top"
            popper-class="task-drawer-homeowner-tooltip"
            >
            <b-icon @click="nudgeNotification" icon="bell" />
          </el-tooltip>
        </div>
      </el-form-item>
    </el-col>

    <!-- Description -->
    <el-col :span="24">
      <el-form-item label="Description" class="task-description">
        <el-input
          v-if="showEditableField"
          class="largeText"
          @input="taskFieldUpdated('description');"
          maxlength="1024"
          type="textarea"
          :rows="3"
          placeholder="What is this task about?"
          v-model="task.description"
        ></el-input>
        <p v-else class="task-description-value">
          {{ task.description || 'No description' }}
        </p>
      </el-form-item>
    </el-col>
  </el-form>

  <!-- Documents upload -->
  <div
    class="grid-document-container"
    :style="{ marginBottom: mode == 'create' ? '74px' : '' }"
    v-loading="documentLoader"
  >
    <div
      class="PDcard"
      v-for="(file, i) in task.documentInfo.files"
      :key="i"
    >
      <img
        :src="
          fileUploadAssets[
            `/src/assets/drop/fileUploadImages/${findFileTypeIcon(file)}`
          ]
        "
      />
      <p class="contentOne">
        <abbr :title="file.display_name" style="text-decoration: none;" class="abbrTag">{{
          file.name
        }}</abbr>
      </p>
      <div class="icons">
        <img
          v-if="mode !== 'create'"
          src="./assets/Download.svg"
          class="dwnldIcon"
          target="_blank"
          @click="downloadClick(file)"
        />
        <img
          v-if="mode !== 'create'"
          src="./assets/delete.svg"
          class="delBtn"
          @click="deleteClick(file.id)"
        />
        <img
          v-if="mode == 'create'"
          src="./assets/delete.svg"
          class="delBtn"
          @click="removeClick(i)"
        />
      </div>
    </div>
    <div
      class="PDcard lastPDcard"
      @click="isShareDocumentPopupVisible = true"
      ref="scrollRef"
    >
      <img src="./assets/add.svg" class="addIcon" />
      <p class="addDocCon">Upload a document</p>
    </div>
  </div>
  <div class="activity-timeline" v-if="mode === 'view'">
    <TaskActivityTimeline :taskList="taskList" />
  </div>
  </div>
  <div
    class="footer"
    style="background-color: white"
    v-show="mode === 'view' && !isTaskLoading"
  >
    <div>
      <el-avatar
        :size="32"
        class="option-avatar"
        :style="getFooterAvatarStyle(computeUserName)"
        ><span class="option-avatar-text">{{
          extractLetters(computeUserName)
        }}</span></el-avatar
      >
    </div>
    <div class="footer-comment" v-loading="commentLoader">
      <el-input
        :autosize="{ minRows: 3, maxRows: 3 }"
        type="textarea"
        placeholder="Ask a question or post an update..."
        v-model="commentText"
        @keydown.native.enter.prevent="handleCommentChange"
      >
      </el-input>
    </div>
  </div>
  <ShareDocumentPopup
    :isShareDocumentPopupVisible.sync="isShareDocumentPopupVisible"
    @confirmUploaded="confirmUploaded"
    :isTaskMode="true"
    @documentData="onSaveDocuments"
  />
  <DeleteProjectDocument
    v-if="isDeleteDocumentPopupOpen"
    :isDeleteProjectDocumentPopupOpen="isDeleteDocumentPopupOpen"
    @cancelDelete="isDeleteDocumentPopupOpen = false"
    @confirmDelete="confirmDelete"
  />
  <!-- End documents upload -->
  <div class="create-btn-container" v-show="mode === 'create'">
    <el-button
      class="create-button"
      type="primary"
      @click="onClickCreate"
      :loading="isCreatingTask"
      >{{ computeCreateBtnText }}</el-button
    >
  </div>
  <DeleteProjectDocument
    v-if="isDeleteDialogVisible"
    :isDeleteProjectDocumentPopupOpen="isDeleteDialogVisible"
    :message="'Are you sure you want to delete the task?'"
    @cancelDelete="isDeleteDialogVisible = false"
    @confirmDelete="confirmDeleteTask"
  />
  
  <setReminder
    :key="setKey"
    :showCreateDrawer="showSetReminderDrawer"
    @close="showSetReminderDrawer = false"
    @save="onSaveReminder"
    :drawerSize="400"
    :page="'task'"
    :lead="taskData"
  />
  <CloseDialog
    v-if="mode=='create'"
    :isDeleteProjectDocumentPopupOpen="showCloseDialog"
    @cancelDelete="$emit('handleCloseWarningDialog', false)"
    @confirmDelete="confirmClose"
    message="Are you sure you want to discard your changes "
  />
</div>
