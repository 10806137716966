<template>
    <div class="btnsContainerFlex">
      <div class="flexOne"  v-if="isProposalGenration">
        <div class="propsal-btn"  v-if="!isGenerationAlreadyCalculated && !essOnlyDesign">
            <span> <img v-if="isGenerationDisabledForDesign(design)" style="display: flex;"  src="./assets/fanDisabled.svg" alt="">
                <img v-else src="./assets/fan.svg" alt=""> 
                
            </span> 
        <ReusableButton 
          :loading="isLoading"
          :button="generationButtonData()"
          @click="calculateGeneration()"
        />
  </div>
  <div class="proposal-btn-container"> 

  <div class="propsal-btn"  v-if="isGenerationAlreadyCalculated && !essOnlyDesign">
    <span> <b-icon style="transform: scale(1.5)" :class="[isWebProposalDisabledForDesign(design)?'disabled-btn' :'enabled-btn']" :icon="'link45deg'"></b-icon> </span> 
        <ReusableButton
         
          :button="webProposalButtonData()"
          @click="navigateToWebProposal()"
        />
  </div>
  <div class="propsal-btn"    v-if="isGenerationAlreadyCalculated">
    <span> <b-icon style="transform: scale(1.5)" :class="[isDocumentProposalDisabledForDesign(design)?'disabled-btn' :'enabled-btn']" :icon="'files'"></b-icon> </span> 
        <ReusableButton
       
          :button="documentProposalButtonData()"
          @click="navigateToDocumentProposal()"
        />
  </div>
  <div class="propsal-btn"  v-if="essOnlyDesign">
    <span> <b-icon style="transform: scale(1.5)" :class="[essOnlyProposalButtonData?.disableCondition?'disabled-btn' :'enabled-btn']" :icon="'badge3d'"></b-icon> </span> 
        <ReusableButton
         
          :button="essOnlyProposalButtonData()"
          @click="navigateToDocumentProposal()"
        />
  </div>
  <div class="propsal-btn"   v-if="isGenerationAlreadyCalculated && !essOnlyDesign">
    <span> <b-icon style="transform: scale(1.5)" :class="[is3DLinkDisabledForDesign(design)?'disabled-btn' :'enabled-btn']" :icon="'badge3d'"></b-icon> </span> 
        <ReusableButton
        
          :button="designOverviewButtonData()"
          @click="navigateToDesignOverview()"
        />
        </div>
      </div>

      </div>
      <div v-if="!essOnlyDesign&&isSalesModeBtns" class="flexTwo">
        <div class="sales-mode-btn"   v-if="isSalesModeAvailable && !isMobileView">
      <span> <b-icon style="transform: scale(1.5);" :class="[!isSalesModeEnabled||isSalesModeDisabled?'disabled-btn' :'enabled-salesmode-btn']" :icon="'bounding-box'"></b-icon> </span>     
            <ReusableButton
        
          :loading="isStudioLoading"
          :button="generationSaleStudioButtonData()"
          @click="saleStudioButton()"
        />
        </div>
    <div>
        <div class="sales-mode-btn" v-if="isSalesModeAvailable">
            <span> <b-icon style="transform: scale(1.5)" :class="[isSalesModeReportDisabledForDesign(design)?'disabled-btn' :'enabled-btn']" :icon="'clipboard-data'"></b-icon> </span>     
        <ReusableButton
          
          :loading="isReportLoading"
          :button="generationSaleReportButtonData()"
          @click="salesModeReportBtn()"
        />
        </div>
    </div>
      </div>
  
      
    </div>
  </template>
  
  <script>
  import ReusableButton from "../../designSummaryCRM/components/ReusableButton.vue";
  import {
    isWebProposalDisabled,
    isDocProposalDisabled,
    isGenerationDisabled,
    isSalesModeReportDisabled,
    tooltipMessageForDisabledDocumentProposalButton,
    isExpertServicesOrg,
    consumptionPresent,
  } from "../../../utils";
  import { mapState, mapActions } from "pinia";
  import { useDesignStore } from "../../../stores/design";
  import { useProjectStore } from "../../../stores/project";
  import { useUiStore } from "../../../stores/ui";
  import API from '../../../services/api';
  
  export default {
    props: {
      design: {
        type: Object,
        required: true,
      },
      isProposalGenration:{
        type:Boolean,
        default:true
      },
      isSalesModeBtns:{
        type:Boolean,
        default:true
    },
    isSalesModeDisabled:{
      type:Boolean,
      default:false
    }
    },
    components: {
      ReusableButton,
    },
    data() {
      return {
        reusableButtonStyles: {
          fontSize: "16px",
          fontWeight: "600",
          height: "28px",
          padding: "6px 10px",
          color:'#ccc'
        },
        salesModeBtnStyle: {
          fontSize: "16px",
          fontWeight: "600",
          height: "28px",
          padding: "6px 10px",
        //   background: '#1C3366',
          border: 'none',
          color:'#1C3366'
        },
        openSalesModeBtnStyle: {
          fontSize: "16px",
          fontWeight: "600",
          height: "28px",
          padding: "6px 10px",
        //   background: '#1C3366',
          border: 'none',
          color:'#409EFF'
        },
        isLoading: false,
        isStudioLoading: false,
        isReportLoading: false,
        tooltipMessageForNoModules:
          "Please add modules and inverters to the design, and calculate generation.",
        tooltipMessageForSalesModeStudio : "Contact your administrator to get access to Sales Mode."
      };
    },
    computed: {
      ...mapState(useProjectStore, {
        project: (state) => state
      }),
      ...mapState(useUiStore, {
        isMobileView: state => state.isMobileView
      }),
      ...mapState(useProjectStore, {
        projectPermissionObject: 'GET_PERMISISON_OBJECT',
      }),
      essOnlyDesign() {
        return this.design.system_type == 'ess_only_design'
      },
      consumptionData() {
        return this.design?.project || this.project;
      },
      isGenerationAlreadyCalculated() {
        let design = this.$props.design;
        let nameplateDcSize = design?.versions?.summary?.nameplate_dc_size;
        let acSize = design?.versions?.summary?.ac_size;
        let annualGeneration = design?.annual_generation;
        if (nameplateDcSize && acSize && annualGeneration) {
          return true;
        } else return false;
      },
      isSalesModeAvailable() {
        const user = JSON.parse(localStorage.getItem("user")) || {};
        return user.sales_mode_enabled;
      },
      isSalesModeEnabled() {
        if (isExpertServicesOrg()) {
          return true
        }
  
        return this.projectPermissionObject.edit_design
      }
    },
    methods: {
      ...mapActions(useDesignStore, {
        SET_DESIGN: "SET_DESIGN",
        STORE_DESIGN_VERSION: "STORE_DESIGN_VERSION",
      }),
      async calculateGeneration() {
        try {
          this.isLoading = true;
          await this.SET_DESIGN(this.$props.design.id);
          this.isLoading = false;
        } catch (e) {
          this.isLoading = false;
          console.error(e);
        }
      },
      async saleStudioButton() {
        // console.log('this.$route.params.leadID: ', this.$route.params);
        // localStorage.setItem('SaleStudioConstants', JSON.stringify({
        //   leadID: this.$route.params.leadId,
        // }))
        // const routeData = this.$router.resolve({ name: 'salesStudioMode', params: { designId: this.$props.design.id } });
        // window.open(routeData.href, '_blank');
        this.isStudioLoading = true;
        try {
          let designId = this.$props.design.id;
          const response = await API.DESIGNS.FETCH_DESIGN(designId);
          await this.STORE_DESIGN_VERSION(response);
          this.$router.push({
            name: "salesStudioMode",
            params: { designId: designId },
          });
          this.isStudioLoading = false;
        } catch (e) {
          console.error(e);
          this.isStudioLoading = false;
        }
      },
  
      generationButtonData() {
        let user = JSON.parse(localStorage.getItem('user'))
        let isUserAllowed = user.design_studio_enabled || user.sales_mode_enabled;
        let designObj  = this.$props.design;
        let tooltipMsg = "";
        if(!isUserAllowed) {
          tooltipMsg = "Connect with the designer to add inverter for detailed modelling";
        } else {
          if(!designObj?.versions?.summary?.nameplate_dc_size){
            tooltipMsg = "Please add modules and inverters to the design to calculate generation.";
          } else if(!designObj?.versions?.summary?.ac_size) {
            tooltipMsg = "Please add inverters in design studio for detailed modelling";
          }
        }
        return {
          label: "Calculate Generation",
          type: "text",
          styleEnabled: { ...this.openSalesModeBtnStyle },
          styleDisabled: { ...this.reusableButtonStyles },
          disableCondition: this.isGenerationDisabledForDesign(
            this.$props.design
          ),
          tooltip: tooltipMsg,
        };
      },
      generationSaleStudioButtonData() {
        return {
          label: "Open in Sales Mode",
          type : "text",
          styleEnabled: { ...this.openSalesModeBtnStyle },
          styleDisabled: { ...this.reusableButtonStyles },
          disableCondition: !this.isSalesModeEnabled||this.$props.isSalesModeDisabled,
          tooltip: this.tooltipMessageForSalesModeStudio
        }
      },
  
      async salesModeReportBtn() {
        this.isReportLoading = true;
        let designId = this.$props.design.id || null;
        const routeData = this.$router.resolve({
          name: "salesModeReport",
          params: { designId: designId },
        });
        window.open(routeData.href, "_blank");
        this.isReportLoading = false;
      },
  
      generationSaleReportButtonData(){
         return {
            label: "Sales Mode Report",
            type : "text",
            styleEnabled: { ...this.salesModeBtnStyle },
            styleDisabled: { ...this.reusableButtonStyles },
            disableCondition: this.isSalesModeReportDisabledForDesign(
              this.$props.design
            ),
            tooltip: this.tooltipMessageForSalesModeReport(this.$props.design),
         }
      },
      
  
      webProposalButtonData() {
        return {
          label: "Web proposal",
          type: "text",
          styleEnabled: { ...this.salesModeBtnStyle },
          styleDisabled: { ...this.reusableButtonStyles },
          disableCondition: this.isWebProposalDisabledForDesign(
            this.$props.design
          ),
          tooltip: this.tooltipMessageForProposal(this.$props.design),
        };
      },
  
      documentProposalButtonData() {
        return {
          label: "Document proposal",
          type: "text",
          styleEnabled: { ...this.salesModeBtnStyle },
          styleDisabled: { ...this.reusableButtonStyles },
          disableCondition: this.isDocumentProposalDisabledForDesign(
            this.$props.design
          ),
          tooltip: this.tooltipMessageForProposal(this.$props.design),
        };
      },
  
      essOnlyProposalButtonData() {
        return {
          label: "ESS Proposal",
          type: "text",
          styleEnabled: { ...this.salesModeBtnStyle },
          styleDisabled: { ...this.reusableButtonStyles },
          disableCondition: !this.$props.design?.versions?.basic_cost || !this.consumptionPresent(this.consumptionData),
          tooltip: "Add consumption, pricing and battery",
        };
      },
  
      designOverviewButtonData() {
        return {
          label: "3D model",
          type: "text",
          styleEnabled: { ...this.salesModeBtnStyle },
          styleDisabled: { ...this.reusableButtonStyles },
          disableCondition: this.is3DLinkDisabledForDesign(this.$props.design),
          tooltip: this.tooltipMessageForNoModules,
          //why is this returning false even though the value exists?
        };
      },
      navigateToWebProposal() {
        const designUUID = this.$props.design?.versions?.reference_id;
        const route = { name: "webProposal", params: { designUUID } };
  
        // Generate the absolute URL for the route
        const url = this.$router.resolve(route).href;
        // Open the URL in a new tab
        window.open(url, "_blank");
      },
      navigateToDocumentProposal() {
        const designId = this.$props.design?.id;
        const route = { name: "documentProposal", params: { designId } };
        // Generate the absolute URL for the route
        const url = this.$router.resolve(route).href;
        // Open the URL in a new tab
        window.open(url, "_blank");
      },
      navigateToDesignOverview() {
        const designUUID = this.$props.design?.versions?.reference_id;
        const route = { name: "DesignOverview", params: { designUUID } };
        // Generate the absolute URL for the route
        const url = this.$router.resolve(route).href;
        // Open the URL in a new tab
        window.open(url, "_blank");
      },
      isGenerationDisabledForDesign(design) {
        return isGenerationDisabled(design);
      },
      isWebProposalDisabledForDesign(design) {
        return isWebProposalDisabled(design);
      },
      tooltipMessageForProposal(design) {
        return tooltipMessageForDisabledDocumentProposalButton(design)
      },
      tooltipMessageForSalesModeReport(design) {
        let summary = design?.versions?.summary;
        let financials = design?.pricing;
        if (summary?.nameplate_dc_size == null || !(summary?.ac_size >=0)) {  //here the difference is, it will work even for Ac Size 0
          return this.tooltipMessageForNoModules;
        } else if (
          (financials?.length > 0 && !financials?.[0]?.payback) || !design?.versions?.basic_cost ||
          financials?.length == 0
        ) {
          return "Please add consumption and pricing to the project.";
        }
      },
      isDocumentProposalDisabledForDesign(design) {
        return isDocProposalDisabled(design);
      },
      isSalesModeReportDisabledForDesign(design) {
        return isSalesModeReportDisabled({
          orderStatus: design?.request_expert_service?.order_status,
          nameplateDcSize: design?.versions?.summary?.nameplate_dc_size,
          acSize: design?.versions?.summary?.ac_size,
          reportTemplate:
            design?.versions?.setting?.report_defaults?.template_name,
          financials: design?.pricing,
          basicCost: design?.versions?.basic_cost
        });
      },
      is3DLinkDisabledForDesign(design) {
        // Disable 3D link if generation is not calculated
        let annualGeneration = design?.annual_generation;
        let acSize = design?.versions?.summary?.ac_size;
        let dcSize = design?.versions?.summary?.nameplate_dc_size;
  
        if (annualGeneration || (acSize && dcSize)) {
          return false;
        }
  
        return true;
      },
      consumptionPresent,
    },
  };
  </script>
  
  <style scoped>
  .btnsContainerFlex {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  @media (max-width: 768px) {
    .btnsContainerFlex {
      flex-direction: row;
      gap: 24px;
    }
    .flexTwo{
      flex-direction: row;
    }
    .flexTwo > div >>> button{
      width: 100%;
      text-align: left;
      max-width: 135px;
    }
  }
  .flexOne{
    padding: 8px 16px;
  }
  .proposal-btn-container{
    display: flex;
    justify-content: space-around;
  }

  .flexTwo {
    display: flex;
    flex-wrap: wrap;
  }
  .sales-mode-btn {
    display: flex;
    width:100%;
    align-items: center;
    gap:8px;
    padding:16px 8px;
   
  }
  .propsal-btn{
    display: flex;
    align-items: center;
    gap:8px;
    width:240px;
  }
  .enabled-btn{
    color: #1C3366;
    display: flex;
  }
  .disabled-btn{
    color: #ccc;
    display: flex;
  }
  .enabled-salesmode-btn{
    color:#409EFF;
    display: flex;
  }

  </style>
  