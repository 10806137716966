import Vue from "vue";
import InfiniteLoading from "vue-infinite-loading";
import VueStatic from "vue-static";
import Vue2TouchEvents from "vue2-touch-events";
import "./stores/index";
import { store } from "./store";
import router from "./router";
import Vuebar from "vuebar";
import InputLength from "./components/ui/length/inputLength.vue";
import InputLengthOS from "./components/ui/length/inputLengthOutsideStudio.vue";
import DisplayLength from "./components/ui/length/displayLength.vue";
import VueConfirmDialog from "vue-confirm-dialog";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import VueToastr from "vue-toastr";
import "./styles/main.css";
import "./styles/variables.css";
import AddCreditPopup from "./components/ui/AddCreditPopup.vue";
import GenericPayNowPopup from "./components/ui/GenericPayNowPopup.vue";
import ProjectUpgradePopup from "./components/ui/ProjectUpgradePopup.vue";
import SelfDesignPopup from "./components/ui/SelfDesignPopup.vue";
import App from "./App.vue";
import AllDrawer from "./pages/commonComponents/allDrawer/allDrawer.vue";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import * as Sentry from "@sentry/vue";
import {VITE_APP_ENVIRONMENT} from "./constants.js"
import { decodeHtml,decodeHtml2 } from "./utils/stringFormatters";
import emitter from 'tiny-emitter/instance'
import Mousetrap from './plugins/mousetrap';

import _ from "lodash";
window._ = _;

window.global ||= window;

let isSentryInitialized = false;


// Modified modules
import "./plugins/element-ui/element";
import "./plugins/vee-validate";


// Using external library tiny-emitter for Vue3 future proofing
export const serverBus = {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
};
if (!Vue.prototype.$eventBus) {
  Object.defineProperty(Vue.prototype, "$eventBus", { value: serverBus });
}

// Common methods that are used globally
if (!Vue.prototype.$globalMethods) {
  Object.defineProperty(Vue.prototype, "$globalMethods", {
    value: {
      htmlDecode: decodeHtml,
      htmlDecode2: decodeHtml2,
    }
  });
}

// Mousetrap
if (!Vue.prototype.$mousetrap) {
  Object.defineProperty(Vue.prototype, '$mousetrap', { value: Mousetrap });
}


Vue.config.productionTip = false;
// Vue.config.silent = true;
// Making modules available to vue
Vue.use(Vuebar);
Vue.use(VueToastr);
Vue.use(VueConfirmDialog);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
Vue.use(InfiniteLoading, {
  system: {
    throttleLimit: 500,
  },
});
Vue.use(VueStatic, {
  name: "nonReactiveData",
});

Vue.use(Vue2TouchEvents);

Vue.component("input-length", InputLength);
Vue.component("input-length-os", InputLengthOS);
Vue.component("display-length", DisplayLength);
Vue.component("add-credit-popup", AddCreditPopup);
Vue.component("generic-pay-now-popup", GenericPayNowPopup);
Vue.component("project-upgrade-popup", ProjectUpgradePopup);
Vue.component("self-design-popup", SelfDesignPopup);
Vue.component("all-drawer", AllDrawer);


// Disabling sentry for now, since it's causing performance issues in studio.
let enableSentry = true;
try{
  const allowedHosts = ["app.arka360.com","d2c.arka360.com"];
  const currentHost = window.location.hostname;
  if(enableSentry && !isSentryInitialized&&allowedHosts.includes(currentHost)){
  Sentry.init({
    Vue,
    dsn: "https://2b52abb7a79dda75ce6e9c3ae42c83c5@o4507464086650880.ingest.us.sentry.io/4507542284402688",
    environment: VITE_APP_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [ 
      /^https:\/\/dev\.arka360\.com\//,
      /^https:\/\/beta\.arka360\.com\//,
      /^https:\/\/app\.arka360\.com\//,
    ],
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event) {
      // Check if the event contains an exception
      if (event.exception) {
        const exception = event.exception.values?.[0];
        // Check if the exception matches the "Event" type and specific value
        if (
          exception &&
          exception.type === "Event" &&
          exception.value === "Event `Event` (type=error) captured as promise rejection"
        ) {
          // Ignore this event
          return null;
        }
      }
      // Allow all other events to be sent
      return event;
    },
  });
  }
  isSentryInitialized = true;
} catch(error) {
  console.error(error, 'Error in setting up Sentry');
  isSentryInitialized = false;
} finally {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}

