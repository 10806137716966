<template>
  <div class="card" id="crmtable" >
  <div class="card" id="crmtable" :style="{border:editMode? '2px solid blue':''}" v-if="tablesData">
    <CardHeader
      :heading="tableName"
      :editMode="editMode"
      @mode-open="handleOpenPopover"
    >
      <slot name="header">
        <DropdownElement
          @selectionMade="setTableData"
          :dataOptions="computedDropdown"
          v-if="dropDown"
        />
      </slot>
    </CardHeader>
    <div class="table_content"  :class="isGroupedTable||$props.editMode?'' :'table_pointer'" v-if="tablesData">
      <el-table
        :data="tablesData"
        :default-sort="{ prop: 'name', order: 'descending' }"
        :style="{ width: '100%', overflowY: 'scroll',overflowX: 'scroll' }"
        class="tableContainer"
        @row-click="handleRowClick"
        height='99%'
      >
        <el-table-column
          v-for="column in filteredColumnLabels"
          :key="column.prop"
          :label="column.label"
          :prop="column.prop"
          :min-width="column==='S. No.'?80:120"
          :width="handleMaxWidth(column.prop)"
          :header-align="getHeaderAlignment(column.prop)"
          :disabled="true"
        >
          <template v-slot="scope">
            <div>
              <div
                style="
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                "
                v-if="
                  column.prop === 'Name - Leads' ||
                  column.prop === 'Name - Tasks' ||
                  column.prop === 'Name'
                "
              >
                <div
                  style="width: 30px; margin-right: 0.5rem"
                  v-if="chartSource === 'Leads'"
                >
                  <el-avatar
                    :size="30"
                    :style="getAvatarStyle(scope.row[column.prop])"
                    >{{ generateInitials(scope.row[column.prop]) }}</el-avatar
                  >
                </div>

                <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(scope.row[column.prop])">
                  <template v-slot:content>
                  <div>
                    {{ scope.row[column.prop] }}
                  </div>
                  </template>
                  <div class="name-content">
                    {{ scope.row[column.prop] }}
                  </div>
                </el-tooltip>
              </div>
              <div v-else>
                <div
                  class="name-content alignment-dealsize"
                  v-if="
                    column.prop === 'Deal Amount - Leads' ||
                    column.prop === 'Deal Amount - Tasks' ||
                    column.prop === 'Deal Amount'
                  "
                >
                  <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(scope.row[column.prop])">
                    <template v-slot:content>
                    <div>
                      {{
                        scope.row[column.prop] || scope.row[column.prop] == 0
                          ? handleAbbreviateNumber(currency, scope.row[column.prop])
                          : "-"
                      }}
                    </div>
                    </template>
                    <div>
                      {{
                        scope.row[column.prop] || scope.row[column.prop] == 0
                          ? handleAbbreviateNumber(currency, scope.row[column.prop])
                          : "-"
                      }}
                    </div>
                  </el-tooltip>
                </div>
                <div
                  class="name-content alignment-date"
                  v-else-if="
                    column.prop === 'Target Closure Date - Leads' ||
                    column.prop === 'Target Closure Date - Tasks' ||
                    column.prop === 'Due date - Tasks' ||
                    column.prop === 'Due date - Leads' ||
                    column.prop === 'Due date' ||
                    column.prop=== 'Close Date'
                  "
                >
                  <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(scope.row[column.prop])">
                    <template v-slot:content>
                    <div>
                      {{
                        scope.row[column.prop]
                          ? handleFormatDate(
                              scope.row[column.prop],
                              typeConstants.monthDayYear,
                              isUSFlag()
                            )
                          : "-"
                      }}
                    </div>
                    </template>
                    <div>
                      {{
                        scope.row[column.prop]
                          ? handleFormatDate(
                              scope.row[column],
                              typeConstants.monthDayYear,
                              isUSFlag()
                            )
                          : ""
                      }}
                    </div>
                  </el-tooltip>
                </div>
                <div
                  @click.stop=""
                  v-else-if="
                    column.prop === 'Email - Leads' ||
                    column.prop === 'Email - Tasks' ||
                    column.prop === 'Email'
                  "
                >
                  <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(scope.row[column.prop])">
                    <template v-slot:content>
                    <div>
                      {{
                        scope.row[column.prop] || scope.row[column.prop] == 0
                          ? scope.row[column.prop]
                          : "-"
                      }}
                    </div>
                    </template>
                    <div>
                      <a :href="`mailto: ${scope.row[column.prop]}`">
                        <i class="el-icon-message icons-container"></i>
                        {{
                          scope.row[column.prop] || scope.row[column.prop] == 0
                            ? scope.row[column.prop]
                            : "-"
                        }}</a
                      >
                    </div>
                  </el-tooltip>
                </div>

                <div
                  @click.stop=""
                  v-else-if="
                    column.prop === 'Phone - Leads' ||
                    column.prop === 'Phone - Tasks' ||
                    column.prop=== 'Phone'
                  "
                >
                  <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(scope.row[column.prop])">
                    <template v-slot:content>
                    <div>
                      {{
                        scope.row[column.prop] || scope.row[column.prop] == 0
                          ? scope.row[column.prop]
                          : "-"
                      }}
                    </div>
                    </template>
                    <div>
                      <a :href="`tel: ${scope.row[column.prop]}`">
                        <i class="el-icon-phone icons-container"></i>
                        {{
                          scope.row[column.prop] || scope.row[column.prop] == 0
                            ? scope.row[column.prop]
                            : "-"
                        }}</a
                      >
                    </div>
                  </el-tooltip>
                </div>

                <div v-else-if="column.prop !== 'id'" class="name-content">
                  <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(scope.row[column.prop])">
                    <template v-slot:content>
                    <div>
                      {{
                        scope.row[column.prop] || scope.row[column.prop] == 0
                          ? scope.row[column.prop]
                          : "-"
                      }}
                    </div>
                    </template>
                    <div>
                      {{
                        scope.row[column.prop] || scope.row[column.prop] == 0
                          ? scope.row[column.prop]
                          : "-"
                      }}
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <slot></slot>
      </el-table>
    </div>
 
  </div>
  <div v-else class="card">
    <Skeleton    />
    </div>
  </div>
</template>

<script>
import CardHeader from "./models/Card/CardHeader.vue";
import DropdownElement from "./models/DropdownElement.vue";
import {
  generateAvatarColor,
  isUSFlagEnabled,
  generateColorFromName,
  formatNumberWithCommas

} from "../../../utils";
import {
  getLocaleAbbreviations,
  getFormattedCurrencyComas,
  getFormattedComas,
  getFormattedComasWithMaxDecimal,
  getCurrencySymbol,
} from "../../../utils/numberFormat/currencyFormatter";
import { typeConstants, formatDateTime } from "../../../utils/dateFormatter";
import { DateTime } from "luxon";
import API from "../../../services/api";
import { mapState, mapActions } from "pinia";
import { useDashboardStore } from "../../../stores/dashboard";
import Skeleton from "./models/Card/Skeleton.vue";


export default {
  emits: ["update:updateCheck", "mode-open", "showDrawer", "handleRowClick"],
  name: "leadsTaleCrm",
  components: {
    CardHeader,
    DropdownElement,
    Skeleton
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    headingText: {
      type: String,
      default: "",
    },
    data: {
      required: true,
      type: [Object,Array],
    },
    dropDown: {
      type: Boolean,
      default: true,
    },
    pageRedirectFlag: {
      type: String,
    },
    id: {
      type: Number,
      default: 0,
    },
    maxHeight: {
      type: String,
      default:'90%',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    updateCheck: {
      type: Boolean,
      default: false,
    },
    activeWidgetId:{
      type:[Number,String],
      default:null
    }
  },
  data() {
    return {
      tableType: "amount",
      dropdownSelection: "",
      currency: "",
      tableData: [],
      typeConstants: typeConstants,
      tableName: "",
      tableDetails: "",
      tablesData: null,
      columnLabels: [],
      chartSource: "",
      isGroupedTable:false,
      orgData:JSON.parse(localStorage.getItem("organisation")),
    };
  },

  computed: {
    ...mapState(useDashboardStore, {
      dashboardData: (state) => state.dashboardupdate,
    }),

    filteredColumnLabels() {
      return this.columnLabels.filter(
        (column) => !['id', 'object_id', 'additional_details'].includes(column.prop)
      );
    },
  },
  methods: {
    showTooltip(value) {
      // Check the length of the value and decide whether to show the tooltip
      return this.columnLabels.length>3&& value && value.toString().length > 14;
    },
    getFormatedData(entry,data,allData,index){
      const countryCode=allData.find(cr=>cr.column_label==='additional_details')
      const currencyCode=countryCode?.data[index]?.country;
if(data||data==0){if(entry.metric_data_type==='currency'){
  if(this.isGroupedTable){
    return  this.handleCurrency(this.orgData,data)

  }else{
    return this.handleCurrency(currencyCode?currencyCode:this.orgData,data)

  }

//  return this.handleAbbreviateNumber(this.currency,data)
}else if(entry.metric_data_type==='number'){

    return getFormattedComasWithMaxDecimal(this.orgData?.currency_code,data,0,2)

}else if(entry.metric_data_type==='datetime'&&this.isGroupedTable){
  return this.formateDateTime(data)
}

else{
  return data

}}else{
  data
}


    },
    formateDateTime(dateTime) {
      if (
        DateTime.fromISO(dateTime).startOf("day").toISO() ===
        DateTime.local().startOf("day").toISO()
      ) {
        const date = DateTime.fromISO(dateTime);
        const time = date.toLocaleString(DateTime.TIME_SIMPLE);
        return `Today at ${time}`;
      }

      if (
        DateTime.fromISO(dateTime).startOf("day").toISO() ===
        DateTime.local().startOf("day").minus({ days: 1 }).toISO()
      ) {
        const date = DateTime.fromISO(dateTime);
        const time = date.toLocaleString(DateTime.TIME_SIMPLE);
        return `Yesterday at ${time}`;
      }

      return DateTime.fromISO(dateTime).toFormat("dd/MM/y  hh:mm a");
    },

    handleMaxWidth(columnProp) {
      if (
        columnProp === "Deal Amount - Leads" ||
        columnProp === "Deal Amount - Tasks" ||
        columnProp === "Target Closure Date - Leads" ||
        columnProp === "Target Closure Date - Tasks" ||
        columnProp === "Due date - Tasks" ||
        columnProp === "Due date - Leads" ||
        columnProp === "Due date" ||
        columnProp === "Target Closure Date" ||
        columnProp === "Deal Amount" ||
        columnProp === "Close Date" ||
        columnProp === "Priority"
      ) {
        return 120;
      }else if( columnProp === "S. No."){
return 80
      } else {
        return "auto";
      }
    },
    getHeaderAlignment(columnProp) {
      if (
        columnProp === "Deal Amount - Leads" ||
        columnProp === "Deal Amount - Tasks" ||
        columnProp === "Deal Amount"
      ) {
        return "right";
      } else {
        return "left";
      }
    },
    getTableData(input, measureDetails) {
      let deepCopyArray = JSON.parse(JSON.stringify(input));
      // deepCopyArray=  deepCopyArray.filter(cp=>cp.column_label!=='additional_details'&&cp.column_label!=='object_id')
      const result = [];
      this.columnLabels=[];
      this.tablesData = [];
      deepCopyArray.map((label, indx) => {
        this.columnLabels[indx] = {label:label.unit?label.column_label+" ("+label.unit +")":label.column_label
          ,prop:label.column_label};
      });
      for (let i = 0; i < deepCopyArray[0].data.length; i++) {
        const newObj = {};
        for (const entry of deepCopyArray) {
          newObj[entry.column_label] =this.getFormatedData(entry,entry.data[i],input,i) ;
        }

        this.tablesData.push(newObj);


      }

      return result;
    },
    handleTotal(numberData) {
      const total = numberData.reduce(
        (acc, currentValue) => acc + currentValue,
        0
      );
      return total !== 0 ? total : null;
    },
    handlePercentage(value, total, totalIndex) {
      const tempArr = { column_label: "Percentage(%)", data: [] };
      value.data.map((d) => {
        let perc = 0;
        if (d && d !== total) {
          perc = (d / total) * 100;
          if(perc>0){
            perc=perc.toFixed(2)
          }
          tempArr.data.push(perc);
        }
        if (d === total) {
          if (d && d !== 0) {
            tempArr.data[totalIndex] = 100;
          } else {
            tempArr.data[totalIndex] = null;
          }
        }
      });

      return tempArr;
    },
    async getWidgets() {
      if (this.$props.id) {
        try {
          let { data } = await API.CRM_DASHBOARD.GET_WIDGET_BY_ID(
            this.$props.id
          );
          this.isGroupedTable=false;
          this.tableName = data.name;
          this.chartSource = data.source
            ? data.source
            : data.additional_details.source;
          this.tableDetails = data.result.current_time_range_resp.dataset;
          let mapingData = data.result.current_time_range_resp.dataset;
          let measureDetails = data.additional_details.measure_details;
          let totalLength = "";
          let newData=[];
          if (data.additional_details.table_type === "grouped") {
            this.isGroupedTable=true;

    if(mapingData[0]?.measure)    
    {    mapingData=[]
            measureDetails.map((mes,index)=>{
              const refData=data.result.current_time_range_resp.dataset
             const filterdMeasure=refData.find(f=>f.measure===mes.id);
             newData[index]=filterdMeasure
              
            })


            newData.map((d, index) => {
              d.metric_data_type=d.metric_data_type;
              d.column_label =
                data.additional_details.measure_details[index].label;
              d.isPercentage =
                data.additional_details.measure_details[index].percentage;
                mapingData[index]=d
            });}else{
              mapingData.map((d, index) => {
              d.metric_data_type=d.metric_data_type;
              d.column_label =
                data.additional_details.measure_details[index].label;
              d.isPercentage =
                data.additional_details.measure_details[index].percentage;
            });
            }
    

        

            let tempData = { column_label: {}, data: [] };
          
            tempData.column_label = "Labels";
            data.result.current_time_range_resp.labels.map((d,index) => {
              tempData.data.push(d.label);
            });

            totalLength = tempData.data.length;

            if (data.additional_details.show_total === true) {
              mapingData.map((dt) => {
                dt.data[totalLength] = this.handleTotal(dt.data);
              });
              tempData.data.push("Total");
            }

            mapingData.unshift(tempData);
           
          }
          const columnData = [];
          if(mapingData.length>0){
            const checkData=mapingData.filter(md=>md.column_label!=='object_id'&&md.column_label!=='additional_details')
            if(checkData.length>0)  { let slnoData={ column_label: "S. No.", data: [] };
          const refdata=checkData[0]?.data?.length
          for(let i=0;i<refdata;i++){
            slnoData.data.push(i+1)
          }
        
          mapingData.unshift(slnoData);}
          }
          mapingData.map((t) => {
            columnData.push(t);
            const total = t.data[totalLength];

            if (t.isPercentage) {
              let temp = this.handlePercentage(t, total, totalLength);
              columnData.push(temp);
            }
          });
          const columnNames = [];

          this.getTableData(columnData, measureDetails);
        } catch (err) {
          this.tablesData = null;
        }
      }
      this.$emit('update:updateCheck',false)
    },
    handleOpenPopover(value) {
      this.$emit("mode-open", value, this.$props.id);
    },

    async handleRowClick(data) {
      if (this.pageRedirectFlag == "dashboard" && !this.$props.editMode&&! this.isGroupedTable) {
        let id = data.object_id;
        if (this.chartSource === "Leads"||this.chartSource==="Activities") {
          this.$emit("showDrawer", "lead");
        }
        if (this.chartSource === "Tasks") {
          this.$emit("showDrawer", "task");
        }
        try {
          let filteredData = {};
          if (this.chartSource === "Leads"||this.chartSource==="Activities") {
            let res = await API.LEADS.FETCH_LEAD(id);
            filteredData = {
              ...res.data,
              type: "lead",
              color: generateColorFromName(res.data.name),
              country: null,
              created_at: this.formateDateTime(res.data.created_at),
              lead_details: {
                pipeline: res.data.pipeline,
                stage_object: res.data.stage_object,
                lead_source_object: res.data.lead_source_object,
                region:res.data.region,
                probability:res.data.probability,
                created_at: res.data.created_at,
                deal_value: res.data.deal_value,
                email: res.data.email,
                estimated_system_size: res.data.estimated_system_size,
                id: res.data.id,
                last_contacted: res.data.last_contacted,
                latest_notes: res.data.latest_notes,
                lead_source: res.data.lead_source,
                name: res.data.name,
                owner: res.data.owner,
                phone: res.data.phone,
                stage: res.data.stage,
                target_closure_date: res.data.target_closure_date || null,
              },
              ...res.data.project_details,
            };
          } else if (this.chartSource === "Tasks") {
            let res = await API.TASKS.FETCH_TASK_BY_ID(id);
            filteredData = res.data;
            filteredData.type = "task";
            filteredData.isTask = true;
          }
          // setTimeout(() => {
          this.$emit("handleRowClick", filteredData);
          // }, 3000);
        } catch (err) {
          this.$message({
            showClose: true,
            message:
              "There was an error while getting the lead data. Please try again.",
            type: "error",
            center: true,
          });
        }
      }
    },
    isUSFlag() {
      return isUSFlagEnabled();
    },
    handleFormatDate(isoDateTime, type, isUS) {
      return formatDateTime(isoDateTime, type, isUS);
    },
    handleCurrency(country,data){
  
if(isNaN(formatNumberWithCommas(data,country?.country_code==="IN"))){
  return '-'

}else{
  const currencyformate= getCurrencySymbol(country?.country_code)+ formatNumberWithCommas(data,country?.country_code==="IN");

return currencyformate
}
 

    },
    handleAbbreviateNumber(currencyCode, value) {
      return getFormattedCurrencyComas(currencyCode, value);
    },
    formatter(row, column) {
      return row.address;
    },
    setTableData(data) {
      switch (data) {
        case "Weekly":
          this.tableData = this.$props.data.this_week.map((item) => ({
            ...item,
            abbreviation: this.generateInitials(item.name),
          }));
          break;
        case "Monthly":
          this.tableData = this.$props.data.this_month.map((item) => ({
            ...item,
            abbreviation: this.generateInitials(item.name),
          }));
          break;
        case "Quarterly":
          this.tableData = this.$props.data.this_quarter.map((item) => ({
            ...item,
            abbreviation: this.generateInitials(item.name),
          }));
          break;
        case "Yearly":
          this.tableData = this.$props.data.this_year.map((item) => ({
            ...item,
            abbreviation: this.generateInitials(item.name),
          }));
          break;
        default:
          this.tableData = this.$props.data.map((item) => ({
            ...item,
            abbreviation: this.generateInitials(item.name),
          }));
          break;
      }
    },
    convertDateFormat(dateString) {
      let date = DateTime.fromISO(dateString);
      const newDateString = `${date.day}-${date.month}-${date.year}`;
      return newDateString;
    },
    generateInitials(name) {
      if (!name || name.trim().length === 0) {
        return "N/A"; // Return empty string for blank names
      }

      const names = name.trim().split(" ");
      const initials = names.map((n) => n.charAt(0).toUpperCase());

      if (initials.length === 1) {
        return initials[0]; // Return single initial if only one name is available
      } else {
        return initials[0] + initials[initials.length - 1]; // Return first and last initials for multiple names
      }
    },
  },
  computed: {
    // computedData(data) {
    //     let td = []
    //     if (data) {
    //         td = data.map(item => ({ ...item, abbreviation: this.generateInitials(item.name) }))
    //     }
    //     return td
    // },
    computedDropdown() {
      return [
        { value: "Weekly", label: "This Week" },
        { value: "Monthly", label: "This Month" },
        { value: "Quarterly", label: "This Quarter" },
        { value: "Yearly", label: "This Year" },
      ];
    },
    getAvatarStyle() {
      return (value) => {
        const backgroundColor = generateAvatarColor(value);
        return {
          marginRight: "0.5rem",
          marginTop: "5px",
          backgroundColor: backgroundColor,
        };
      };
    },
  },
  mounted() {
    // this.getWidgets();
    this.dropdownSelection = this.computedDropdown[0].value;
    this.setTableData(this.dropDown ? "Weekly" : "default");
    this.currency = JSON.parse(
      localStorage.getItem("organisation")
    ).currency_code;

    // Add dummy data to the table
    // this.tableData = [
    //   {
    //     name: "John Doe",
    //     contact_details: {
    //       phone: "1234567890",
    //       email: "john.doe@example.com",
    //     },
    //     close_date: "2023-05-30",
    //     deal_amount: 50000,
    //     abbreviation: "JD",
    //   },
    //   {
    //     name: "Jane Smith",
    //     contact_details: {
    //       phone: "9876543210",
    //       email: "jane.smith@example.com",
    //     },
    //     close_date: "2023-12-01",
    //     deal_amount: 75000,
    //     abbreviation: "JS",
    //   },
    //   // Add more dummy data objects as needed
    // ];
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        this.getWidgets();
      },
    },
    dashboardData: {
      deep: true,
      handler(val) {
        this.getWidgets();
      },
    },
    updateCheck: {
      immediate: true,
      handler(val) {
        if(val===true){
          if(this.$props.activeWidgetId===this.$props.id||!this.$props.editMode){
            this.getWidgets();

          }
        }
      },
    },
  },
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  height: 100%;
}
.el-table::v-deep.el-table--scrollable-x .el-table__body-wrapper {
  overflow: unset;
}

.el-table-column:nth-child(1),
.el-table-column:nth-child(2) {
  min-width: 30%;
}
.table_pointer >>> .el-table .el-table__row:hover {
  cursor: pointer;
}

.el-table-column:nth-child(3),
.el-table-column:nth-child(4) {
  min-width: 20%;
}
.el-table ::v-deep thead {
  font-family: "Switzer";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #222222;
}
.table_content {
  padding-left: 10px;
  height: 100% !important;
}
.el-table {
  font-family: "Switzer";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #222222;
}

.el-table::v-deep th.el-table__cell .cell {
  word-break: break-word;
  padding-left: 8px;
  padding-right: 8px;
}
.name-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-table::before,
.el-table--group::after,
.el-table--border::after {
  background-color: transparent !important;
}
.alignment-dealsize {
  display: flex;
  justify-content: end;
  align-items: center;
}
.alignment-date {
  display: flex;
  justify-content: start;
  align-items: center;
}
.icons-container {
  padding-right: 0.5rem;
}
.el-tooltip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 100%;
}
#crmtable>>>.el-table__header-wrapper{
overflow :visible  !important;

}
</style>
