<template>
  <div class="card" @click="handleDrildown">
    <div style="width: 100%" v-if="kpiData">
      <div class="card-header">
        <div class="title">
          <p>{{ kpiData.name }}</p>

          <p v-if="editMode" style="cursor: pointer">
            <CommonPopover @open-options="handleOpenMode" />
          </p>
        </div>
      </div>
      <div class="card-content" style="margin-top: -0.8rem">
        <div class="values">
          <div class="value">
            <div
              style="
                font-family: 'Switzer';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 24px;
              "
            >
              <h3 v-if="metricType === 'currency'">{{ formatNumber }}</h3>
              <h3 v-else-if="metricType === 'number'">{{ formatCount }}</h3>
             
            </div>
            <div :style="greenRedText">
              <h5
                style="
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 14px;
                  display: flex;
                "
                v-if="kpiPercentage||kpiPercentage===0"
              >
                <span style="display: flex; justify-content: end">
                  <b-icon :style="greenRedText" :icon="trendIconClass"></b-icon
                ></span>
              
                {{ kpiPercentage&&kpiPercentage!==0? kpiPercentage.toFixed(2) : "0" }}%
                {{ comparisionText }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Skeleton  v-else />
  </div>
</template>

<script>
import API from "../../../services/api";
import {
  getCurrencySymbol,
  getLocaleAbbreviations,
  getFormattedComas,
} from "../../../utils/numberFormat/currencyFormatter";
import Skeleton from "./models/Card/Skeleton.vue";
import { mapState, mapActions } from "pinia";
import { useDashboardStore } from "../../../stores/dashboard";
import CommonPopover from "./dashboardV2/commonPopover.vue";

export default {
  emits: ["mode-open", "update:chartSource", "update:updateCheck", "drilldown-kpi"],
  name: "CardComponentCRM",
  components: {
    Skeleton,
    CommonPopover,
  },
  data() {
    return {
      kpiData: null,
      kpiCardData: "",
      kpiDataSet: "",
      kpiPercentage: "",
      kpiTimeRange: "",
      metricType: "",
      isPopoverOpen: false,
      comparisionText:""
    };
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
   default:''
    },
    icon: {
      type: String,
      
    },
    value1: {
      type: Number,
     
    },
    value2: {
      type: Number,
     
    },
    themeColor: {
      type: String,
     
    },
    secondaryColor: {
      type: String,
     
    },
    percentage: {
      type: Number,
    },
    percentageChange: {
      type: Number,
     
    },
    unit: {
      type: String,
     
    },
    iconUrl: {
      type: String,
    },
    id: {
      type:[ Number , String],
      default: 0,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    updateCheck: {
      type: Boolean,
      default: false,
    },
    activeWidgetId:{
      type:[Number,String],
      default:null
    }
  },
  computed: {
    ...mapState(useDashboardStore, {
      dashboardData: (state) => state.dashboardupdate,
    }),

    trendIconClass() {
      if (this.kpiPercentage >= 0) {
        return "arrow-up-right";
      } else {
        return "arrow-down-left";
      }
    },

    formatNumber() {
      let countryCode = JSON.parse(
        localStorage.getItem("organisation")
      ).country_code;

      //currencyCode = "INR"
      return `${getCurrencySymbol(countryCode)}${getLocaleAbbreviations(
        countryCode,
        // this.$props.value1,
        this.kpiDataSet,
        true
      )}`;
    },
    formatCount() {
      let currencyCode = JSON.parse(
        localStorage.getItem("organisation")
      ).currency_code;
      return getLocaleAbbreviations(currencyCode, this.kpiDataSet, true);
    },
    greenRedText() {
      let style = "margin-left:5px;";
      if (this.kpiPercentage >= 0)
        style += `color:#2CC21C;font-weight: 400;font-size: 14px;line-height: 18px;`;
      else
        style += `color:#FF0000;font-weight: 400;font-size: 14px;line-height: 18px;`;
      return style;
    },
  },

  mounted() {
    this.getWidgets();
  },
  methods: {
    handleOpenMode(value) {
      this.$emit("mode-open", value, this.$props.id);
    },
    getTimeRageLabels(label) {
      switch (label) {
        case "this_week":
          this.kpiTimeRange = "this week";
          break;
        case "this_month":
          this.kpiTimeRange = "this month";
          break;
        case "this_year":
          this.kpiTimeRange = "this year";
          break;
        default:
          this.kpiTimeRange = "this week";
          break;
      }
    },
    async getWidgets() {
      if (this.$props.id) {
        try {
          let { data } = await API.CRM_DASHBOARD.GET_WIDGET_BY_ID(
            this.$props.id
          );
          this.kpiData = data;
          this.$emit("update:chartSource", data.source);
          this.kpiCardData = data.result.current_time_range_resp;
          this.metricType = this.kpiCardData?.dataset[0]?.metric_data_type;
          this.kpiDataSet = this.kpiCardData?.dataset[0]?.data[0] || 0;
          this.kpiPercentage =
            this.kpiCardData?.additional_info?.percentage_change || 0;

            this.comparisionText= this.kpiCardData?.additional_info?.text || "";
          this.getTimeRageLabels(data?.time_range);
        } catch (err) {
          this.kpiData = {};
          // this.$message({
          //   showClose: true,
          //   dangerouslyUseHTMLString: true,
          //   message: "There was an error while fetching the KPI data.",
          //   type: "error",
          //   center: true,
          //   duration: 10000,
          // });
        }
      }
      this.$emit('update:updateCheck',false)
    },

    percentageColor(percentage) {
      if (this.title === "Uncontacted leads" && percentage >= 0) {
        return "#409EFF";
      }
      if (this.title === "Contacted Leads" && percentage >= 0) {
        return "#F88227";
      }
      if (this.title === "Open Deals" && percentage >= 0) {
        return "#2CC21C";
      }
      if (this.title === "Closed Sales" && percentage >= 0) {
        return "#7C1EA4";
      }
    },
    handleDrildown() {
      if (!this.$props.editMode) {
        const queryParamsKey = this.kpiCardData.drill_down;
        const queryParamsValue = {
          data_value: this.kpiCardData.additional_info.query_param_value,
        };
        this.$emit(
          "drilldown-kpi",
          queryParamsKey,
          queryParamsValue,
          this.kpiData
        );
      }
    },
  },
  watch: {
    dashboardData: {
      deep: true,
      handler(val) {
        this.getWidgets();
      },
    },
    updateCheck: {
      immediate: true,
      handler(val) {
        if(val===true){
          if(this.$props.activeWidgetId===this.$props.id||!this.$props.editMode){
            this.getWidgets();

          }
        }
      },
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  padding: 14px;
  background-color: #fff;
  transition: box-shadow 0.3s ease-in-out;
  width: 100%;
  height: 100px;
  display: flex;
  justify-self: start;
  align-items: center;
}

.card:hover {
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); */
  cursor: pointer;
}

.icon-box {
  color: var(--theme-color);
  background-color: var(--secondary-color);
  padding: 5px;
}

.icon-container {
  position: absolute;
  top: 16px;
  right: 16px;
}

.title {
  color: grey;
  display: flex;
  justify-content: space-between;
  /* height: 40px; */
  /* font-style: normal; */
  /* font-weight: 400;
    font-size: 18px; */
  margin-bottom: 0.5rem;
  padding-bottom: 8px;
}

.content_section .title {
  font-weight: 400;
  font-size: 18px;
  color: #777777;
  flex-flow: 1;
  padding-right: 0px;
  line-height: 24px;
  width: 100%;
}

.card-content {
  /* margin-bottom: 8px; */
}

.values {
  display: flex;
  /* margin-bottom: 8px; */
}
.el-progress {
  position: relative;
  line-height: 1;
  display: flex;
  justify-content: space-between;
}
.value {
  display: flex;
  font-size: 24px;
  font-weight: 600;
  align-items: end;
  /* height: 40px; */
}
h3 {
  font-weight: 600;
}
@media only screen and (max-width: 1500px) {
  .value {
    display: flex;
    font-size: 0.8rem;
    /* align-items: end; */
  }
}
@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .card {
    margin-bottom: 1rem;
  }
}

.progress-bar {
  margin-bottom: 6px;
  white-space: nowrap;
  /* Prevent text from wrapping */
}
</style>
