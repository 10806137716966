<template>
  <div
    class="task-drawer-container"
    :class="[{ taskViewMode: mode === 'view' }]"
    v-loading="isTaskLoading"
  >

    <div class="taskCard">
    <!-- Update Status Icon -->
    <div
      class="update-task-status"
      :class="shouldShowTaskStatus()"
      v-if="mode == 'view'"
    >
      <b-icon
        icon="arrow-clockwise"
        animation="spin"
        font-scale="2"
        v-if="taskUpdateStatus == 'updating'"
      ></b-icon>
      <b-icon
        icon="check-circle"
        font-scale="2"
        v-if="taskUpdateStatus == 'complete'"
        style="color: #28a745"
      ></b-icon>
      <span
        v-if="taskUpdateStatus == 'updating'"
      >
        Updating Task..
      </span>
      <span
        v-if="taskUpdateStatus == 'complete'"
      >
        Task Updated.
      </span>
    </div>

    <el-form class="task-form" :label-position="'top'" label-width="100px" :model="task" @keypress.enter.native="onClickCreate">
      <!-- Task Name -->
      <div class="task-name-container">
        <img
          v-if="mode !== 'create'"
          class="task-status-icon"
          :src="taskStatusIcon"
          @click.stop="onClickTaskStatusIcon"
        />
        <el-form-item style="flex: 1; margin-top: 0;" v-if="showEditableField">
          <el-input
            placeholder="Task Name"
            v-model="task.taskName"
            maxlength="50"
            :autofocus="true"
            @input="taskFieldUpdated('name')"
          ></el-input>
          <span class="validation-text home-owner">
            {{ validationText.taskName }}
          </span>
        </el-form-item>
        <span v-else class="task-name">
          {{ task.taskName }}
        </span>
        <div
          class="icons-container"
          v-if="mode !== 'create'"
          >
          <b-icon
            v-if="isMobileView"
            @click="isEditMode = !isEditMode"
            :icon="'pencil'"
            class="task-icon"
          ></b-icon>
          <b-icon
            :icon="'trash'"
            @click="handleDeleteTask"
            class="task-icon"
          ></b-icon>
        </div>
      </div>

      <!-- Assignee -->
      <el-col :span="fieldsColWidthComputed">
        <el-form-item label="Assign To" style="position: relative">
          <div class="dropdown-field-with-2-icons">
            <el-select
              :clearable="false"
              v-model="task.assignee"
              :placeholder="'Select Assignee'"
              @visible-change="onDropdownVisibleChange()"
              @change="onChangeAssignee(); taskFieldUpdated('assignee_id')"
              :filterable="true"
              class="select-with-prefix assignee-selection"
            >
              <template #prefix>
                <img
                  src="/src/pages/taskManagement/components/assets/Person.svg"
                  v-show="!task.assignee"
                  class="icon-style-left-dropdown"
                />
                <el-avatar
                  v-show="task.assignee"
                  :size="32"
                  class="selected-avatar"
                  :style="
                    getAvatarStyle(
                      findLabelById(task.assignee, allUsers)
                    )
                  "
                  >{{
                    extractLetters(
                      findLabelById(task.assignee, allUsers)
                    )
                  }}</el-avatar
                >
              </template>
              <el-option
                class="dropdown-option"
                v-for="(option, index) in allUsers"
                :label="option.label"
                :value="option.id"
                :key="index"
              >
                <template #default>
                  <el-avatar
                    :size="32"
                    class="option-avatar"
                    :style="getAvatarStyle(option.label)"
                    >{{ extractLetters(option.label) }}</el-avatar
                  >
                  {{ option.label }}
                  <!-- TODO: Check if this icon code is working correctly -->
                  <b-icon style="padding-right: inherit;margin-left:auto;" :color="iconColor(option)" :icon="showIcon(option)"></b-icon>
                </template>
              </el-option>
              <div
                v-infinite-scroll="loadMore2"
                infinite-scroll-disabled="isLoadingUsers"
                infinite-scroll-distance="10"
                class="loading-icon"
              >
                <i v-if="isLoadingUsers" class="el-icon-loading infiniteScrollLoader"/>
              </div>
            </el-select>
          </div>
        </el-form-item>
      </el-col>

      <!-- Start Date -->
      <el-col :span="fieldsColWidthComputed">
        <el-form-item label="Start Date" style="position: relative">
          <el-date-picker
            v-if="showEditableField"
            class="date-picker"
            v-model="task.startDate"
            type="date"
            placeholder="Select a date"
            prefix-icon="''"
            :picker-options="pickerOptions"
            :clearable="false"
            :ref="'startDate'"
            @change="handleDateChange('startDate', task.startDate); taskFieldUpdated('started_on')"
          >
          </el-date-picker>
          <span v-else>
            {{ task.startDate ? formatDateForDrawer(task.startDate) : 'None' }}
          </span>
          <img
            v-if="showEditableField"
            src="./assets/CalendarEvent.svg"
            class="icon-style-left date-icon"
            @click="openDatePicker('startDate')"
          />
        </el-form-item>
      </el-col>

      <!-- Due Date -->
      <el-col :span="fieldsColWidthComputed">
        <el-form-item label="Due Date" style="position: relative">
          <el-date-picker
            v-if="showEditableField"
            class="date-picker"
            v-model="task.dueDate"
            type="date"
            placeholder="Select a date"
            prefix-icon="''"
            :picker-options="computePickerOptionsDue"
            :clearable="false"
            :ref="'dueDate'"
            @change="taskFieldUpdated('due_by')"
          >
          </el-date-picker>
          <span v-else>
            {{ task.dueDate ? formatDateForDrawer(task.dueDate) : 'None' }}
          </span>
          <img
            v-if="showEditableField"
            src="./assets/CalendarEvent.svg"
            class="icon-style-left date-icon"
            @click="openDatePicker('dueDate')"
          />
        </el-form-item>
      </el-col>

      <!-- Priority -->
      <el-col :span="fieldsColWidthComputed">
        <el-form-item label="Priority" style="position: relative">
          <div
            class="toggle-icon-parent"
            @click="togglePriority(); taskFieldUpdated('priority')"
          >
            <!-- <img :src="field.leftIcon" class="icon-style-left" /> -->
            <img :src="curPriorityIcon" class="icon-style-left" />
            <span
              :style="{ color: task.priority == 'high' ? 'red' : '#999999' }"
            >
              {{ task.priority == 'high' ? 'Urgent' : 'Default' }}
            </span>
          </div>
        </el-form-item>
      </el-col>

      <!-- Set Reminder -->
      <el-col :span="fieldsColWidthComputed">
        <el-form-item label="Reminder" style="position: relative">
          <div
            v-if="showEditableField"
            @click="onSetReminder"
          >
            <div class="text-field-with-left-icon">
              <img
                v-if="!reminderData?.reminder_sent_at && showEditableField"
                src="./assets/CalendarEvent.svg"
                class="icon-style-left"
              />
              <span class="text-style">{{
                extractDateTimeFromSetReminderDetails()
              }}</span>
            </div>
          </div>
          <p v-else class="reminder-text" @click="onSetReminder">
            {{ reminderData?.reminder_sent_at ? extractDateTimeFromSetReminderDetails(reminderData?.reminder_sent_at) : 'Set Reminder' }}
          </p>
        </el-form-item>
      </el-col>

      <!-- Status -->
      <el-col :span="fieldsColWidthComputed">
        <el-form-item label="Status" style="position: relative">
          <el-select
            v-if="showEditableField"
            @change="taskFieldUpdated('status')"
            v-model="task.status"
            placeholder="Select a status"
          >
            <el-option
              v-for="option in taskStatusOptions"
              :label="option.label"
              :value="option.id"
              :key="option.id"
              :disabled="option.disabled ? true : false"
            ></el-option>
          </el-select>
          <span v-else>
            {{ taskStatusOptions.find(option => option.id == task.status)?.label }}
          </span>
        </el-form-item>
      </el-col>

      <!-- Homeowner -->
      <el-col :span="fieldsColWidthComputed">
        <el-form-item style="position: relative">
          <template v-slot:label>
          <div>
            Homeowner
            <span v-if="showEditableField && canHomeownerBeChanged" class="required-field">*</span>
          </div>
          </template>
          <span class="validation-text home-owner">{{
            validationText.homeOwner
          }}</span>
          <el-tooltip
            v-if="showEditableField && canHomeownerBeChanged"
            class="item"
            effect="dark"
            content="Homeowner cannot be changed after the task has been created."
            placement="top-start"
            popper-class="task-drawer-homeowner-tooltip"
            :disabled="!isHomeownerDisabled()"
          >
            <el-select
              class="infinite-dropdown"
              v-model="task.homeOwner"
              placeholder="Enter a homeowner"
              @change="onChangeHomeowner(); taskFieldUpdated('lead_id');"
              :filterable="true"
              :filter-method="customFilterMethodForLeads"
              :disabled="isHomeownerDisabled()"
            >
              <el-option
                v-for="option in allLeads"
                :label="option.label"
                :value="option.id"
                :key="option.id"
                :disabled="option.disabled ? true : false"
              ></el-option>
              <div
                v-infinite-scroll="loadMoreLeads"
                infinite-scroll-disabled="isLoadingLeads"
                infinite-scroll-distance="10"
                class="loading-icon"
              >
                <i v-if="isLoadingLeads" class="el-icon-loading infiniteScrollLoader"/>
              </div>
            </el-select>
          </el-tooltip>
          <router-link
          v-else
              :to="{
                name: 'leadSummary',
                params: { leadId: allLeads.find(lead => lead.id == task.homeOwner)?.id },
              }"
             @click="onClickToRedirectToLeadSummary"
            >
          <p class="homeowner-name">
            <span><img class="icon-style-left" src="../../../assets/img/PersonBadge.svg" alt=""></span>
            <span style="color:#409EFF">{{ allLeads.find(lead => lead.id == task.homeOwner)?.name }}</span>
          </p>
          </router-link>
        </el-form-item>
      </el-col>
      <!-- Lead Stage -->
      <el-col :span="fieldsColWidthComputed">
        <el-form-item label="Perform Task at" style="position: relative">
          <el-select
            v-if="showEditableField"
            v-model="task.leadStage"
            @change="taskFieldUpdated('lead_stage');"
            placeholder="Select a stage"
            :class="[task.homeOwner== '' ? 'disabled-lead-stage' : '']"
            :disabled="task.homeOwner== '' ? true : false"
            v-loading="isLoadingLeadStages"
          >
            <el-option
              v-for="option in leadStageOptions"
              :label="option.label"
              :value="option.id"
              :key="option.id"
              :disabled="option.disabled ? true : false"
            ></el-option>
          </el-select>
          <span v-else>
            {{ leadStageOptions.find(stage => stage.id == task.leadStage)?.name }}
          </span>
        </el-form-item>
      </el-col>

      <!-- Collaborators -->
      <el-col :span="fieldsColWidthComputed">
        <el-form-item label="Collaborators" style="position: relative">
          <div class="collaborators" :class="!showEditableField && 'non-editable-collaborators'">
            <AvatarHandler
              v-if="task?.collaborators?.length || showEditableField"
              :overlap="true"
              :limit="isMobileView ? 2 : 3"
              :imgWidth="30"
              :fontSize="'14px'"
              :avatars="task.collaborators"
              :selectionAvatars="avatars"
              :removable="true"
              :editable="showEditableField"
              :gap="5"
              @handleRemove="handleCollaboratorRemove"
              @handleAdd="handleCollaboratorAdd"
              @loadMore="loadMore2"
              :mode="mode"
            />
            <p v-else>None</p>
          </div>
        </el-form-item>
      </el-col>

      <!-- Location -->
      <el-col :span="16">
        <el-form-item label="Location" class="task-lead-location">
          <p class="task-lead-location-value">
            {{ task.location || 'No address' }}
          </p>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Nudge" class="task-lead-location">
          <div>
            <el-tooltip
              class="item"
              effect="dark"
              :content="nudgeTooltipContent"
              placement="top"
              popper-class="task-drawer-homeowner-tooltip"
              >
              <b-icon @click="nudgeNotification" icon="bell" />
            </el-tooltip>
          </div>
        </el-form-item>
      </el-col>

      <!-- Description -->
      <el-col :span="24">
        <el-form-item label="Description" class="task-description">
          <el-input
            v-if="showEditableField"
            class="largeText"
            @input="taskFieldUpdated('description');"
            maxlength="1024"
            type="textarea"
            :rows="3"
            placeholder="What is this task about?"
            v-model="task.description"
          ></el-input>
          <p v-else class="task-description-value">
            {{ task.description || 'No description' }}
          </p>
        </el-form-item>
      </el-col>
    </el-form>

    <!-- Documents upload -->
    <div
      class="grid-document-container"
      :style="{ marginBottom: mode == 'create' ? '74px' : '' }"
      v-loading="documentLoader"
    >
      <div
        class="PDcard"
        v-for="(file, i) in task.documentInfo.files"
        :key="i"
      >
        <img
          :src="
            fileUploadAssets[
              `/src/assets/drop/fileUploadImages/${findFileTypeIcon(file)}`
            ]
          "
        />
        <p class="contentOne">
          <abbr :title="file.display_name" style="text-decoration: none;" class="abbrTag">{{
            file.name
          }}</abbr>
        </p>
        <div class="icons">
          <img
            v-if="mode !== 'create'"
            src="./assets/Download.svg"
            class="dwnldIcon"
            target="_blank"
            @click="downloadClick(file)"
          />
          <img
            v-if="mode !== 'create'"
            src="./assets/delete.svg"
            class="delBtn"
            @click="deleteClick(file.id)"
          />
          <img
            v-if="mode == 'create'"
            src="./assets/delete.svg"
            class="delBtn"
            @click="removeClick(i)"
          />
        </div>
      </div>
      <div
        class="PDcard lastPDcard"
        @click="isShareDocumentPopupVisible = true"
        ref="scrollRef"
      >
        <img src="./assets/add.svg" class="addIcon" />
        <p class="addDocCon">Upload a document</p>
      </div>
    </div>
    <div class="activity-timeline" v-if="mode === 'view'">
      <TaskActivityTimeline :taskList="taskList" />
    </div>
    </div>
    <div
      class="footer"
      style="background-color: white"
      v-show="mode === 'view' && !isTaskLoading"
    >
      <div>
        <el-avatar
          :size="32"
          class="option-avatar"
          :style="getFooterAvatarStyle(computeUserName)"
          ><span class="option-avatar-text">{{
            extractLetters(computeUserName)
          }}</span></el-avatar
        >
      </div>
      <div class="footer-comment" v-loading="commentLoader">
        <el-input
          :autosize="{ minRows: 3, maxRows: 3 }"
          type="textarea"
          placeholder="Ask a question or post an update..."
          v-model="commentText"
          @keydown.native.enter.prevent="handleCommentChange"
        >
        </el-input>
      </div>
    </div>
    <ShareDocumentPopup
      :isShareDocumentPopupVisible.sync="isShareDocumentPopupVisible"
      @confirmUploaded="confirmUploaded"
      :isTaskMode="true"
      @documentData="onSaveDocuments"
    />
    <DeleteProjectDocument
      v-if="isDeleteDocumentPopupOpen"
      :isDeleteProjectDocumentPopupOpen="isDeleteDocumentPopupOpen"
      @cancelDelete="isDeleteDocumentPopupOpen = false"
      @confirmDelete="confirmDelete"
    />
    <!-- End documents upload -->
    <div class="create-btn-container" v-show="mode === 'create'">
      <el-button
        class="create-button"
        type="primary"
        @click="onClickCreate"
        :loading="isCreatingTask"
        >{{ computeCreateBtnText }}</el-button
      >
    </div>
    <DeleteProjectDocument
      v-if="isDeleteDialogVisible"
      :isDeleteProjectDocumentPopupOpen="isDeleteDialogVisible"
      :message="'Are you sure you want to delete the task?'"
      @cancelDelete="isDeleteDialogVisible = false"
      @confirmDelete="confirmDeleteTask"
    />
    
    <setReminder
      :key="setKey"
      :showCreateDrawer="showSetReminderDrawer"
      @close="showSetReminderDrawer = false"
      @save="onSaveReminder"
      :drawerSize="400"
      :page="'task'"
      :lead="taskData"
    />
    <CloseDialog
      v-if="mode=='create'"
      :isDeleteProjectDocumentPopupOpen="showCloseDialog"
      @cancelDelete="$emit('handleCloseWarningDialog', false)"
      @confirmDelete="confirmClose"
      message="Are you sure you want to discard your changes "
    />
  </div>
</template>

<script>
import {
  generateAvatarColor,
  extractInitials as extractLetters,
  getDefaultCoordinates
} from "../../../utils";
import { formatDateTime } from "../../../utils/dateFormatter";
import AvatarHandler from "../../dashboardCRM/avatars/AvatarHandler.vue";
import ShareDocumentPopup from "../../dashboard/components/shareDocumentPopup.vue";
import DeleteProjectDocument from "../../project/components/projectDocuments/deleteProjectDocument.vue";
import TaskActivityTimeline from "./activityTimeline/TaskActivityTimeline.vue";
import setReminder from "./../../setReminder/setReminder.vue";
import fileType from "@/pages/utils/fileType";
import API from "../../../services/api";
import debounce from "debounce";
import CloseDialog from "../../project/components/projectDocuments/deleteProjectDocument.vue";
import { useUiStore } from "../../../stores/ui";
import { mapState } from "pinia";
import { isNumber } from "lodash";


const fileUploadAssets = import.meta.glob(
  "/src/assets/drop/fileUploadImages/*",
  { eager: true, as: "url" }
);
export default {
  emits: [
    "handleCloseWarningDialog",
    "documentUploading",
    "activityTimeline",
    "closeDrawer",
    "deleteTask",
    "allowDocumentUpload",
    "updateTask",
    "createTask",
    "setUnsavedChanges",
    "createTaskClicked",
  ],

  props: {
    activityTimeline: {
      type: Boolean,
      default: false,
    },
    taskData: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    isLeadSummary: {
      type: String,
      default: "",
    },
    documentUploaded:{
      type: Boolean,
      default: false,
    },
    showCloseDialog:{
      type: Boolean,
      default: false,
    }
  },
  components: {
    AvatarHandler,
    ShareDocumentPopup,
    TaskActivityTimeline,
    DeleteProjectDocument,
    setReminder,
    CloseDialog,
  },
  data() {
    return {
      prevAssignee: null,
      documentUploadingFlag: false,
      documentLoader: false,
      fileIdToBeDeleted: null,
      startDate: null,
      isDeleteDialogVisible: false,
      validationText: {
        taskName: "",
        homeOwner: "",
      },
      isTaskLoading: true,
      taskUpdateStatus: "idle",
      pickerOptions: {
        disabledDate: (date) => {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return date < today;
        },
      },
      isDisable:false,
      isCreatingTask: false,
      taskList: [],
      tempUploadDocuments: {},
      commentLoader: false,
      commentText: "",
      watchTaskProperties: false,
      isDeleteDocumentPopupOpen: false,
      isShareDocumentPopupVisible: false,
      fileUploadAssets,
      dropdownVisible: false,
      task: {
        taskName: "",
        assignee: "",
        startDate: "",
        dueDate: "",
        priority: "default",
        reminder_details: this.$props.reminderData,
        status: "",
        homeOwner: "",
        location: "",
        leadStage: "",
        collaborators: [],
        location: "",
        description: "",
        documentInfo: {},
      },
      taskStatusOptions: [
        { id: 'new', label: 'New' },
        { id: 'in_progress', label: 'In-Progress' },
        { id: 'completed', label: 'Completed' },
      ],
      fields: [],
      allUsers: [],
      allLeads: [],
      addingCollab: [],
      deletingCollab: [],
      avatars: [],
      leadStageOptions: [],
      isLoadingUsers: false,
      isLoadingLeads: false,
      isLoadingLeadStages: false,
      showSetReminderDrawer: false,
      reminderData: {},
      setKey: 0,
      updatedTaskFields: [],
      pipeLinesList: [],
      updateCollaboratorsPromise: null,
      updateTaskPromise: null,
      isEditMode: false,
      presentDate: null,
      nudgeTooltipText: 'Nudge',
    };
  },

  watch: {
    documentUploaded:{
      deep: true,
      async handler(newVal){
        if(newVal){
          this.tempUploadDocuments.files = [];
          this.$emit('documentUploading')
          let res = await API.DOCUMENT_INFO.FETCH_DOCUMENTS_FOR_TASKS(
                this.$props.taskData.id
              );
              res.data = res.data.map((e) => {
                return { ...e, name: e.display_name, size: 0 };
              });
              this.task.documentInfo = {
                uploaded_by: "installer",
                files: res.data,
                document_container: "other",
              };
              await this.setTask(this.taskData);
              if (this.mode == "view") this.getComments();
              this.documentLoader = false;
        }

      }
    },  
    activityTimeline: {
      deep: true,
      async handler(newValue) {
        if (newValue) {
          try {
            let res = await API.TASKS.FETCH_INDIVIDUAL_TASK(
              this.$props.taskData.id
            );
            this.taskList = res.data;
            res = await API.DOCUMENT_INFO.FETCH_DOCUMENTS_FOR_TASKS(
              this.$props.taskData.id
            );
            res.data = res.data.map((e) => {
              return { ...e, name: e.display_name, size: 0 };
            });
            this.task.documentInfo = {
              uploaded_by: "installer",
              files: res.data,
            };
            this.isTaskLoading = false;
          } catch (err) {}
          this.$emit("activityTimeline", false);
        }
      },
    },
    reminderData: {
      deep: true,
      handler(newvalue) {
        this.task.reminder_details = newvalue;
      },
    },
    taskData: {
      handler() {
        // Once taskData has been updated from the parent component, do the setup
        this.setupForTaskDrawer()
      }
    },
    task: {
      immediate: true,
       handler(newTask, oldTask) {
        if(this.mode !== 'create') return
        if(newTask.taskName){
          this.$emit('setUnsavedChanges', true)
        }else{
          this.$emit('setUnsavedChanges', false)
          return
        }

        if(newTask.assignee == JSON.parse(localStorage.getItem("user")).user_id){
          this.isDisable=true
        }else{
          this.isDisable=false
        }
        if (newTask.collaborators.find((e) => e.id == newTask.assignee)) {
          // if(newTask.assignee == JSON.parse(localStorage.getItem("user")).user_id){
          //   this.task.collaborators = newTask.collaborators.filter(e => e.id != newTask.assignee)
          // }
          this.task.collaborators = newTask.collaborators.filter(
            (e) => e.id != newTask.assignee
          );
        }
        
        const collaborators = this.fields.filter(
          (f) => f.title === "collaborators"
        )[0];
        
        let limit = 10;
        if (newTask.collaborators.length === limit) {
          collaborators.selectionAvatars = collaborators.selectionAvatars.map(
            (e) => {
              if (
                newTask.collaborators.find((element) => element.id === e.id)
              ) {
                return e;
              }
              return { ...e, disabled: true };
            }
          );
        }
        if (newTask.homeOwner) this.validationText.homeOwner = "";
        if (newTask.taskName) this.validationText.taskName = "";
        if (newTask.taskName.length > 50)
          this.task.taskName = this.task.taskName.slice(0, 50);
        //working for everything except doc upload

        if (this.mode === "view" && this.watchTaskProperties) {
          debounce(this.updateTask(), 500);
        }
      },
      deep: true, // Enable deep watching to detect changes in nested properties
    },
  },
  async created() {
    await this.setupForTaskDrawer()
    
    if (this.isLeadSummary) {
      this.setStartDate()
      this.task.homeOwner = +this.$props.isLeadSummary;
      let res = await this.getLeadDetails(this.$props.isLeadSummary);
      this.allLeads.unshift({ ...res.data, label: res.data.name })
      this.setLeadStages(res.data)
    }
    let user = JSON.parse(localStorage.getItem("user"));
    this.task.assignee = user.user_id;
    if (this.mode == "create") {
      this.taskData.lead = null;
      this.task.status = "new";
      this.setStartDate()
    }
    this.reminder_details = this.$props.reminderData;

    // Uncommenting this scroll as its purpose is unknown.
    // this.scrollToEnd();

    this.debouncedUpdateTask = debounce(this.updateTaskNew, 1000)
    this.debouncedUpdateCollaborators = debounce(this.updateCollaborators, 1000)
  },
  computed: {
    ...mapState(useUiStore, {
      isMobileView: state => state.isMobileView,
    }),
    nudgeTooltipContent(){
      return this.mode == 'create' ? 'Unable to perform this action while creating a task.' : this.nudgeTooltipText
    },

    fieldsColWidthComputed() {
      if(this.isMobileView) {
        return 12;
      } else {
        return 8;
      }
    },
    computePickerOptionsDue() {
      return {
        disabledDate: (date) => {
          let today = new Date()
          today.setHours(0,0,0,0)
          let condition = this.startDate < today ? today : this.startDate
          return date < condition;
        },
      };
    },

    computeCreateBtnText() {
      return this.isCreatingTask ? "" : "Create";
    },
    computeUserName() {
      let user = JSON.parse(localStorage.getItem("user"));
      return `${user.first_name} ${user.last_name}`;
    },
    curPriorityIcon() {
      if (this.task.priority == "high") {
        return new URL(
          "/src/pages/taskManagement/components/assets/FlagFill.svg",
          import.meta.url
          ).href
        } else {
          return new URL(
          "/src/pages/taskManagement/components/assets/Priority.svg",
          import.meta.url
        ).href
      }
    },
    taskStatusIcon() {
      let newIconPath = new URL(
        "/src/pages/designOrders/components/assets/checkCircle.svg",
        import.meta.url
      ).href
      let inProgressIconPath = new URL(
        "/src/pages/designOrders/components/assets/In_progress.svg",
        import.meta.url
      ).href
      let completedIconPath = new URL(
          "/src/pages/designOrders/components/assets/completeCheckBox.svg",
          import.meta.url
        ).href

      let iconDict = {
        "New": newIconPath,
        "new": newIconPath,
        "In-Progress": inProgressIconPath,
        "in_progress": inProgressIconPath,
        "Completed": completedIconPath,
        "completed": completedIconPath,
      }

      let taskStatus = this.task.status
      return iconDict[taskStatus] || iconDict["New"]
    },
    showEditableField() {
      return !this.isMobileView || this.mode == 'create' || (this.isMobileView && this.isEditMode)
    },
    canHomeownerBeChanged() {
      return !(this.mode == 'view' || this.isLeadSummary)
    },
  },
  methods: {
    async nudgeNotification(){
      if (this.mode == 'create') return
      const {id} = this.taskData
      if(this.nudgeTooltipText !== 'Nudge') return
      
      const currentDate = new Date();
      this.presentDate = {
        sent_at: currentDate.toISOString(),
      }
      
      try {
        await API.TASKS.POST_NUDGE_NOTIFICATION(id,this.presentDate)
        this.handleNudgeClick(this.taskData)
        
        this.$message({
            showClose: true,
            message: 'Nudge Notification has been sent successfully.',
            type: "success",
            center: true
          })
      } catch (err) {
        console.error(err)

        let errorMessage
        if (err?.response?.data?.available_in) {
          this.handleNudgeClick(parseInt(err.response.data.available_in.match(/\d+/g).join('')))
          errorMessage = `Too many requests. Try again in ${err.response.data.available_in}.`
        } else {
          errorMessage = `There was an unknown error while sending a nudge request.`
        }
        this.$message({
          showClose: true,
          message: errorMessage,
          type: "error",
          center: true
        })
      }
      
    },
    handleNudgeClick(task) {
      let count = isNumber(task) ? task : 60
      if (task) this.nudgeTooltipText = `Available in ${count} second(s)`;

      const intervals = setInterval(() => {
        count -= 1;
        if (task) this.nudgeTooltipText = `Available in ${count} second(s)`;
      }, 1000);

      setTimeout(() => {
        clearInterval(intervals);
        this.nudgeTooltipText = "Nudge";
      }, 60000);
    },
    confirmClose(){
      this.$emit('closeDrawer')
    },
    setStartDate(){
      this.task.startDate = new Date()
      this.task.startDate.setHours(0, 0, 0, 0);
    },
    showIcon(id){
      if(id.stage_type=='close'){
        if(id.close_stage_type=='won'){
          return 'hand-thumbs-up'
        }
        else if(id.close_stage_type=='lost'){
          return 'hand-thumbs-down'
        }else{
          return 'dash-square'
        }
      }
    },
    iconColor(id){
      if(id.stage_type=='close'){
        if(id.close_stage_type=='won'){
          return 'green'
        }
        else if(id.close_stage_type=='lost'){
          return 'red'
        }else{
          return 'grey'
        }
      }
    },
    isHomeownerDisabled() {
      if (this.mode == "view") {
        return true;
      } else if (this.isLeadSummary) {
        return true;
      } else {
        return false;
      }
    },
    setLeadStages(leadObj) {
      let pipeline = this.pipeLinesList.find((e) => e.id == leadObj.pipeline);
      if (!pipeline) {
        this.leadStageOptions = []
        this.task.lead_stage = null
        return this.$message({
          showClose: true,
          message: "The stages for this lead could not be found.",
          type: "error",
          center: true,
        })
      }
      
      this.leadStageOptions = pipeline.pipeline_stages.map(e => ({...e, label: e.name}))
      if (this.mode == 'create') {
        let leadStage = this.leadStageOptions.find(e => e.id == leadObj.stage_object)
        this.task.leadStage = leadStage?.id || null
        this.taskFieldUpdated('lead_stage')
      }
    },
    async getPipelines() {
      let response = await API.LEADS.GET_PIPELINES();
      this.pipeLinesList = response.data.pipelines;
      return response.data.pipelines;
    },
    handleDateChange(type, date) {
      if (type === "startDate") {
        this.startDate = date;
        if (this.startDate > this.task.dueDate) this.task.dueDate = null;
      }
    },
    async confirmDeleteTask(bool) {
      this.isDeleteDialogVisible = false;
      this.isTaskLoading = true
      try {
        await API.TASKS.DELETE_TASK(this.taskData.id);
        this.$message({
          showClose: true,
          message: "The task has been deleted successfully.",
          type: "success",
          center: true,
        })
        this.$emit("deleteTask", this.taskData.id);
      } catch (err) {
        console.error(err)
        this.$message({
          showClose: true,
          message: 'There was an error while deleting the task.',
          type: "error",
          center: true,
        })
      }
    },
    handleDeleteTask() {
      this.isDeleteDialogVisible = true;
    },
    loadMore2() {
      this.loadMoreUsers()
    },
    async handleCommentChange(e) {
      if (e.shiftKey) {
        this.commentText += "\n";
      } else {
        if (this.commentText.replace(/\s/g, "") === "") return;
        this.commentLoader = true;
        try {
          let payload = {
            activity_type: "general",
            name: this.commentText,
            task: this.taskData.id,
            document_container: "other",
          };
          let formData = new FormData();
          for (let key in payload) {
            formData.append(key, payload[key]);
          }
          let res = await API.TASKS.POST_ACTIVITY_COMMENT(formData);
          this.taskList.push(res.data);
          this.commentText = "";
          this.commentLoader = false;
        } catch (err) {
          console.error(err.message);
          this.commentLoader = false;
        }
      }
    },

    async getComments() {
      try {
        let res = await API.TASKS.FETCH_ACTIVITY_LOG(
          this.$props.taskData.id
        );
        this.taskList = res.data;
      } catch (err) {
        console.error(err);
      }
    },

    async handleCollaboratorRemove(id) {
      // Handling edge cases
      let collabInAddingInd = this.addingCollab.findIndex(collab => collab.id == id)
      if (collabInAddingInd != -1) {
        this.addingCollab.splice(collabInAddingInd, 1)
      } else {
        this.deletingCollab.push(id);
      }

      let filteredArr = this.task.collaborators.filter((e) => e.id !== id);
      this.task.collaborators = filteredArr;
      if (this.mode == "view") {
        this.debouncedUpdateCollaborators()
      }
    },
    async handleCollaboratorAdd(ele) {
      // Handling edge cases
      let collabInDeletingInd = this.deletingCollab.findIndex(id => ele.id == id)
      if (collabInDeletingInd != -1) {
        this.deletingCollab.splice(collabInDeletingInd, 1)
      } else {
        this.addingCollab.push(ele);
      }

      this.task.collaborators.push(ele);
      if (this.mode == "view") {
        this.debouncedUpdateCollaborators()
      }
    },
    async updateCollaborators() {
      let callTime = new Date()
      this.lastCollaboratorUpdateCallTime = callTime
      // Wait for previous update request to finish, if any.
      try {
        await this.updateCollaboratorsPromise
      } catch (err) {
        console.error(err)
        this.$message({
          showClose: true,
          message: 'There was an error while updating the task.',
          type: "error",
          center: true,
        })
        return
      }
      // Only the latest update request will be sent, the ones
      // previously in the queue will be returned here.
      if (this.lastCollaboratorUpdateCallTime > callTime) { return }

      let addingCollabIds = this.addingCollab.map(collab => collab.id)
      const addingCollabIdsSet = new Set(addingCollabIds)
      const deletingCollabIdsSet = new Set(this.deletingCollab)
       
      let formData = new FormData();
      addingCollabIdsSet.forEach(id => {
        formData.append("collaborators[]", id);
      });
      deletingCollabIdsSet.forEach(id => {
        formData.append("deleted_collaborators[]", id);
      });
      
      this.taskUpdateStatus = 'updating'
      this.updateCollaboratorsPromise = API.TASKS.UPDATE_TASK(this.taskData.id, formData).then(() => {
        this.taskUpdateStatus = 'complete'
        setTimeout(() => {
          this.taskUpdateStatus = 'idle'
        }, 2000);
        this.getComments();
      })
      this.addingCollab = []
      this.deletingCollab = []
    },
    async onSaveDocuments(documentObj) {
      this.isShareDocumentPopupVisible = false
      if (this.mode == "create") {
        if (!this.tempUploadDocuments.files) {
          this.tempUploadDocuments.files = []
        }
        this.tempUploadDocuments.files = [
          ...this.tempUploadDocuments.files,
          ...documentObj.files
        ]
        this.task.documentInfo.files = this.tempUploadDocuments.files
      } else {
        this.documentLoader = true
  
        let formData = new FormData()
        
        // TODO: Check if these other params are required
        // this.tempUploadDocuments.document_container = "other";
        // formData.append(
        //   "document_container",
        //   this.tempUploadDocuments.document_container
        // );
  
        formData.append("uploaded_by", this.tempUploadDocuments.document_container);
        
        for (let file of documentObj.files) {
          if (file.name) {
            formData.append(
              "file[]",
              file,
              file?.name?.replace(/[<>$:@"\/%|\?\#*!]/g, "_")
            );
          }
        }
  
        try {
          await API.TASKS.UPDATE_TASK_LIST(
            this.taskData.id,
            formData
          );
          await Promise.all([
            API.DOCUMENT_INFO.FETCH_DOCUMENTS_FOR_TASKS(this.taskData.id).then(res => {
              res.data.forEach(file => file.name = file.display_name)
              this.task.documentInfo.files = res.data
            }),
            this.getComments()
          ])
        } catch(err) {
          console.error(err)
          this.$message({
            showClose: true,
            message: 'There was an error while uploading documents.',
            type: "error",
            center: true,
          })
        }
  
        this.documentLoader = false
      }

    },
    confirmUploaded() {
      console.log("confirm");
    },
    findFileTypeIcon(file) {
      let iconName = fileType["defaultFile"];
      if (fileType[file.file_type]) {
        iconName = fileType[file.file_type];
      } else {
        if (
          file.name.split(".").pop() === "dxf" ||
          file.name.split(".").pop() === "dwg"
        ) {
          iconName = fileType["image/x-dxf"];
        }
      }
      return iconName;
    },
    async downloadClick(file) {
      let fileID = file.id;
      let arrayIds = [];
      arrayIds.push(fileID);
      let postData = {
        document_ids: arrayIds,
      };
      //?????
      let response = await API.DOCUMENT_INFO.DOWNLOAD_DOCUMENTS(postData);
      let url = response.data[0];
      saveAs(url, file.display_name);
    },
    async deleteClick(fileID) {
      this.isDeleteDocumentPopupOpen = true;
      this.fileIdToBeDeleted = fileID;
    },
    async removeClick(i) {
      if (this.mode == "view") {
        let res = await API.DOCUMENT_INFO.FETCH_DOCUMENTS_FOR_TASKS(
          this.$props.taskData.id
        );
        res.data = res.data.map((e) => {
          return { ...e, name: e.display_name, size: 0 };
        });
        this.task.documentInfo = { uploaded_by: "installer", files: res.data };
      }
      this.tempUploadDocuments.files = this.tempUploadDocuments.files.filter(
        (e, index) => index !== i
      );
      let filteredArr = this.task.documentInfo.files.filter(
        (e, index) => index !== i
      );
      this.task.documentInfo = {
        files: filteredArr,
        document_container: null,
        uploaded_by: "installer",
      };
    },
    async confirmDelete(isDelete) {
      if (this.fileIdToBeDeleted) {
        if (isDelete) {
          let errorMessage = null;
          try {
            let response = await API.DOCUMENT_INFO.DELETE_DOCUMENTS(
              this.fileIdToBeDeleted
            );
            this.isDeleteDocumentPopupOpen = false;
          } catch (error) {
            errorMessage =
              error.message.status === 403
                ? "You don't have permission to edit this task."
                : "error";
            this.$message({
              showClose: true,
              message: errorMessage,
              type: "error",
              center: true,
            });
            this.isDeleteDocumentPopupOpen = false;
          }
          if (errorMessage == null) {
            this.removeClick(this.fileIdToBeDeleted);
            this.fileIdToBeDeleted = "";
          }
        }
      } else {
        if (isDelete) {
          let response = await API.DOCUMENT_INFO.DELETE_DOCUMENTS(
            this.fileIdToBeDeletedForDesigner
          );
          this.fileIdToBeDeletedForDesigner = "";
          this.isDeleteDocumentPopupOpen = false;
          this.getFilesForDesigner();
        }
      }
    },
    async searchLeads(query) {
      let responseLeads = await API.TASKS.SEARCH_LEADS(query);
      return responseLeads;
    },
    async getLeadDetails(lead_id) {
      const responseLead = await API.LEADS.FETCH_LEAD(lead_id, null);
      this.task.location = responseLead.data.address;
      return responseLead;
    },
    async getUserDetails(user_id) {
      const responseUser = await API.USERS.FETCH_USER(user_id);
      return responseUser;
    },
    async setTask(taskData) {
      let leadObj
      if (this.mode == "view") {
        let res = await this.getLeadDetails(this.taskData.lead);
        leadObj = res.data
        this.setLeadStages(leadObj);
      }
      this.task.taskName = taskData.name;
      // Assignee
      // const assigneeOptionData = await this.getUserDetails(taskData.assignee);
      // const assigneeOption = {
      //   id: assigneeOptionData.data.id,
      //   label:
      //     assigneeOptionData.data.first_name +
      //     " " +
      //     assigneeOptionData.data.last_name,
      //   name:
      //     assigneeOptionData.data.first_name +
      //     " " +
      //     assigneeOptionData.data.last_name,
      // };
      // const assigneeField = this.fields.filter(
      //   (f) => f.title === "assignee"
      // )[0];
      // if (!assigneeField.options.find((e) => e.id == assigneeOption.id))
      //   assigneeField.options.unshift(assigneeOption);
      // // const assignee = this.findLabelById(taskData.assignee, assigneeOptions);
      this.task.assignee = taskData.assignee;
      // Assignee end

      this.task.startDate = taskData.started_on;
      if(this.mode == 'view'){
         this.prevAssignee = this.task.assignee
         this.startDate = new Date(this.task.startDate ? this.task.startDate : null)
         this.startDate.setHours(0, 0, 0, 0);
        }

      this.task.dueDate = taskData.due_by;

      // Priority
      this.task.priority = taskData.priority;

      this.task.reminder_details = {};
      if (taskData.reminder_details) {
        this.reminderData = {
          reminder_sent_at: taskData.reminder_details.reminder_sent_at,
          notes: taskData.reminder_details.notes,
        };
      }
      this.task.status = taskData.status;

      // Homeowner
      // Add this option to the list of options
      if (!leadObj) {
        let res = await this.getLeadDetails(this.taskData.lead);
        leadObj = res.data
      }
      const leadOption = {
        address: leadObj.address,
        id: leadObj.id,
        label: leadObj.name,
        location: leadObj.address,
        name: leadObj.name,
        value: leadObj.id,
      };
      this.allLeads.push(leadOption)
      this.task.homeOwner = taskData.lead;
      // end Homeowner

      this.task.location = leadObj.address;

      // Lead Stage
      // As of 3 Sep 2023, backend sends the stage name (string) in taskData.lead_stage 
      // So we need to find the corresponding ID for the stage.
      let stageId = this.leadStageOptions.find(stageObj => stageObj.name == taskData.lead_stage)?.id
      this.task.leadStage = stageId
      // end  Lead Stage

      this.task.collaborators = taskData.collaborators;
      this.task.description = taskData.description;
      this.task.documentInfo = {};
      try {
        let res = await API.TASKS.FETCH_INDIVIDUAL_TASK(
          this.$props.taskData.id
        );
        this.taskList = res.data;
        res = await API.DOCUMENT_INFO.FETCH_DOCUMENTS_FOR_TASKS(
          this.$props.taskData.id
        );
        res.data = res.data.map((e) => {
          return { ...e, name: e.display_name, size: 0 };
        });
        this.task.documentInfo = { uploaded_by: "installer", files: res.data };
        this.isTaskLoading = false;
      } catch (err) {
        console.error(err.message);
      }
    },
    async updateTaskNew() {
      
      let callTime = new Date()
      this.lastUpdateCallTime = callTime
      // Wait for previous update request to finish, if any.
      try {
        await this.updateTaskPromise
      } catch (err) {
        console.error(err)
        this.$message({
          showClose: true,
          message: 'There was an error while updating the task.',
          type: "error",
          center: true,
        })
        return
      }
      // Only the latest update request will be sent, the ones
      // previously in the queue will be returned here.
      if (this.lastUpdateCallTime > callTime) { return }


      let formData = new FormData();
      
      // These if conditions exist below because we only want to send the fields
      // that have been updated, and not all the fields (in the request payload).
      if (this.updatedTaskFields.includes("name")) {
        formData.append("name", this.task.taskName);
      }

      if (this.updatedTaskFields.includes("assignee_id")) {
        formData.append("assignee_id", this.task.assignee);
      }

      if (this.updatedTaskFields.includes("started_on")) {
        let startDate = this.task.startDate ? new Date(this.task.startDate).toISOString() : ""
        formData.append("started_on", startDate);
        formData.append("offset", new Date().getTimezoneOffset());
      }

      if (this.updatedTaskFields.includes("due_by")) {
        let dueDate = this.task.dueDate ? new Date(this.task.dueDate).toISOString() : ""
        formData.append("due_by", dueDate);
        formData.append("offset", new Date().getTimezoneOffset());
      }

      if (this.updatedTaskFields.includes("priority")) {
        formData.append("priority", this.task.priority);
      }

      if (this.updatedTaskFields.includes("status")) {
        formData.append("status", this.task.status);
        formData.append("offset", new Date().getTimezoneOffset());
      }

      if (this.updatedTaskFields.includes("lead_id")) {
        formData.append("lead_id", this.task.homeOwner);
      }

      if (this.updatedTaskFields.includes("lead_stage")) {
        formData.append("lead_stage", this.task.leadStage);
      }

      // TODO: Collaborators pending
      // if (this.updatedTaskFields.includes("collaborators")) {
      //   formData.append("collaborators[]", id);
      // }

      if (this.updatedTaskFields.includes("description")) {
        formData.append("description", this.task.description);
      }
      
      this.taskUpdateStatus = 'updating'
      this.updateTaskPromise = API.TASKS.UPDATE_TASK(this.taskData.id, formData)
      let resp = await this.updateTaskPromise
      this.$emit("updateTask", resp.data);
      this.taskUpdateStatus = 'complete'
      setTimeout(() => {
        this.taskUpdateStatus = 'idle'
      }, 2000);
      // this.getComments();
      
      this.updatedTaskFields = []
    },
    async updateTask() {
      const formData = new FormData();
      formData.append("name", this.task.taskName);
      formData.append("description", this.task.description);
      formData.append("assignee_id", this.task.assignee);
      // formData.append("lead_stage", isNaN(this.task.leadStage) ? this.task.leadStage.id : this.task.leadStage);
      if (this.task.leadStage)
        formData.append("lead_stage", this.task.leadStage);

      const startDate =
        this.task.startDate !== "" && this.task.startDate !== null
          ? new Date(this.task.startDate).toISOString()
          : "";
      formData.append("started_on", startDate);
      formData.append(
        "reminder_details",
        JSON.stringify(this.task.reminder_details)
      );
      const dueDate =
        this.task.dueDate !== "" && this.task.dueDate !== null
          ? new Date(this.task.dueDate).toISOString()
          : "";
      formData.append("due_by", dueDate);
      // formData.append("lead_id", this.task.homeOwner);
      formData.append("priority", this.task.priority);
      formData.append("lead_id", this.task.homeOwner);
      switch (this.task.status) {
        case "In-Progress":
          this.task.status = "in_progress";
          break;
        case "Completed":
          this.task.status = "completed";
          break;
        case "New":
          this.task.status = "new";
          break;
        default:
          this.task.status = this.task.status;
      }

      formData.append("status", this.task.status);
      formData.append(
        "reminder_details",
        JSON.stringify(this.task.reminder_details)
      );
      formData.append("offset", new Date().getTimezoneOffset());
      // formData.append("status", this.task.status);
      let addingCollabIds = this.addingCollab.map(collab => collab.id)
      const addingCollabIdsSet = new Set(addingCollabIds)
      const deletingCollabIdsSet = new Set(this.$props.deletingCollab)
       
      addingCollabIdsSet.forEach(id => {
        formData.append("collaborators[]", id);
      });
      deletingCollabIdsSet.forEach(id => {
        formData.append("deleted_collaborators[]", id);
      });

      // if (Object.keys(this.tempUploadDocuments).length) {
      //   this.tempUploadDocuments.document_container = "other";
      //   formData.append(
      //     "document_container",
      //     this.tempUploadDocuments.document_container
      //   );

        // formData.append("uploaded_by", this.tempUploadDocuments.document_container);
      //   //files
      //   for (let file of this.tempUploadDocuments.files) {
      //     if (file.name) {
      //       formData.append(
      //         "file[]",
      //         file,
      //         file?.name?.replace(/[<>$:@"\/%|\?\#*!]/g, "_")
      //       );
      //     }
      //   }
      // }
      this.$emit("updateTask", formData);
      this.$props.addingCollab = [];
      this.$props.deletingCollab = [];
      this.tempUploadDocuments.files = [];
    },
    scrollToEnd() {
      const endMarker = this.$refs.scrollRef;
      if (endMarker) {
        endMarker.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    },
    extractLetters,
    getAvatarStyle(value) {
      const backgroundColor = generateAvatarColor(value);
      return {
        backgroundColor: backgroundColor,
      };
    },
    getFooterAvatarStyle(value) {
      const backgroundColor = generateAvatarColor(value);
      return {
        backgroundColor: backgroundColor,
        position: "relative",
      };
    },
    findLabelById(id, options) {
      const option = options.find((option) => option.id === id);
      if (option) {
        return option.label;
      }
      return ""; // Return "" if no corresponding label is found
    },
    async onChangeHomeowner() {
      let leadObj = this.allLeads.find(lead => lead.id == this.task.homeOwner)
      if (!leadObj || !leadObj.pipeline || !leadObj.stage_object) {
        this.isLoadingLeadStages = true
        let res = await this.getLeadDetails(this.task.homeOwner)
        leadObj = res.data
      }
      this.task.location = leadObj.address || "";
      this.setLeadStages(leadObj) 
      this.isLoadingLeadStages = false
    },
    onChangeAssignee() {
      if (this.mode == 'view') {
        // The past assignees need to be added as collaborators
        // when updating assignee for an existing task
        this.selectPreviousAssigneeInCollaborators()
      }
      
      this.disableAndDeselectAssigneeInCollaborators()
      this.disableAssigneeAndCreatorInCollaborators()

      // If assignee is the task creator, remove from collaborators, else add
      let creatorId = this.getTaskCreatorId()
      let assigneeId = this.task.assignee
      if (assigneeId == creatorId) {
        this.deselectTaskCreatorInCollaborators()
      } else {
        this.selectTaskCreatorInCollaborators()
      }
      this.taskFieldUpdated("collaborators")
    },
    onDropdownVisibleChange(field) {
      this.dropdownVisible = !this.dropdownVisible;
    },
    // clearInput() {
    //   this.task.assigneeName = "";
    // },
    // handleReminderClick() {
    //   this.$emit("openSetReminder");
    // },
    extractDateTimeFromSetReminderDetails() {
      if (
        this.reminderData &&
        this.reminderData.reminder_sent_at
      ) {
        const dateObj = new Date(this.reminderData.reminder_sent_at);
        const formattedDate = dateObj.toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
        const formattedTime = dateObj.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });

        return `${formattedDate} | ${formattedTime}`;
      } else {
        return "Set Reminder";
      }
    },
    togglePriority() {
      if (this.task.priority == "high") {
        this.task.priority = "default";
      } else {
        this.task.priority = "high";
      }
    },
    async onClickCreate(e) {
      if (e?.target.type == "textarea") { return }
      
      this.isCreatingTask = true
      let taskFormData = this.createTaskData()

      if (!taskFormData) {
        // Validation failed
        this.isCreatingTask = false
        return
      }

      try {
        await API.TASKS.CREATE_TASK(taskFormData);
        this.$emit("createTask");
        this.$emit('setUnsavedChanges', false)
      } catch (err) {
        console.error(err)
        this.$message({
          showClose: true,
          message: 'There was an error while creating the task.',
          type: "error",
          center: true,
        })
      }
      this.isCreatingTask = false
    },
    createTaskData() {
      if (!this.task.taskName.trim().length) this.task.taskName = "";
      if (!this.task.taskName || !this.task.homeOwner) {
        if (!this.task.taskName)
          this.validationText.taskName = "This is a required field";
        if (!this.task.homeOwner)
          this.validationText.homeOwner = "This is a required field";
        return;
      }
      this.$emit("createTaskClicked");
      const formData = new FormData();
      formData.append("name", this.task.taskName);
      formData.append("description", this.task.description);
      formData.append("assignee_id", this.task.assignee);
      const startDate =
        this.task.startDate !== "" && this.task.startDate !== null
          ? this.task.startDate?.toISOString()
          : "";
      formData.append("started_on", startDate);
      formData.append("lead_id", this.task.homeOwner);
      const dueDate =
        this.task.dueDate !== "" && this.task.dueDate !== null
          ? this.task.dueDate?.toISOString()
          : "";
      formData.append("due_by", dueDate);
      formData.append("priority", this.task.priority);
      formData.append(
        "reminder_details",
        JSON.stringify(this.task.reminder_details || {})
      );
      formData.append("status", this.task.status);
      // formData.append("lead_stage", isNaN(this.task.leadStage) ? this.task.leadStage.id : this.task.leadStage);
      if (this.task.leadStage)
        formData.append("lead_stage", this.task.leadStage);

      // formData.append("lead_stage", this.task.leadStage);
      this.task.collaborators.forEach((e) => {
        if (e.id !== JSON.parse(localStorage.getItem("user")).user_id) {
          formData.append("collaborators[]", e.id);
        }
      });
      // formData.append(
      //   "collaborators[]",
      //   this.task.collaborators.map((e) => e.id)
      // );
      //document upload
      if (Object.keys(this.tempUploadDocuments).length) {
        this.tempUploadDocuments.document_container = "other";
        formData.append(
          "document_container",
          this.tempUploadDocuments.document_container
        );
        formData.append("uploaded_by", this.tempUploadDocuments.document_container);
        //files
        for (let file of this.tempUploadDocuments.files) {
          if (file.name) {
            formData.append(
              "file[]",
              file,
              file?.name?.replace(/[<>$:@"\/%|\?\#*!]/g, "_")
            );
          }
        }
      }
      this.addingCollab = [];
      this.deletingCollab = [];

      return formData
    },
    
    async getAllLeads() {
      try {
        const response = await API.TASKS.FETCH_ALL_LEAD_LIST();
        const allLeads = response.data.results;
        this.nextLeadPageUrl = response.data.next;
        allLeads?.forEach((lead) => {
          lead.label = lead.name;
          lead.value = lead.id;
          lead.location = lead.address;
        });
        this.allLeads = allLeads || [];
      } catch (e) {
        this.$message({
          showClose: true,
          message: "There was an unknown error while fetching leads.",
          type: "error",
          center: true,
        });
      }
    },

    async loadMoreLeads() {
      if (!this.nextLeadPageUrl || this.isLoadingLeads) { return }
      
      this.isLoadingLeads = true
      const response = await API.TASKS.LOAD_MORE_LEADS(
        this.nextLeadPageUrl
      );

      const allLeads = response.data.results;
      allLeads?.map((t) => {
        t.label = t.name;
        t.value = t.id;
        t.location = t.address;
      });

      this.allLeads.push(...allLeads);
      this.nextLeadPageUrl = response.data.next;
      this.isLoadingLeads = false
    },

    async customFilterMethodForLeads(query, option) {
      const responseLead = await this.searchLeads(query);
      const filteredOptions = responseLead.data.results;
      //add to options
      filteredOptions.map((t) => {
        t.label = t.name;
        t.value = t.id;
        t.location = t.address;
      });
      const options = filteredOptions ? filteredOptions : [];
      this.allLeads = options
      //set nextURL
      this.nextLeadPageUrl = responseLead.data.next;
    },
    
    async getAllUsers() {
      try {
        const response = await API.USERS.FETCH_ALL_INTERNAL_USERS();
        this.count = response.data.count;
        this.avatars = response.data.results.map((e) => {
          return {
            ...e,
            name: `${e.first_name} ${e.last_name}`,
            isSelected: false,
            disabled: false,
          };
        });
        
        this.disableAssigneeAndCreatorInCollaborators()

        response.data.results.forEach((item) => {
          item.name = `${item.first_name} ${item.last_name}`;
          item.label = `${item.first_name} ${item.last_name}`;
          delete item.last_name;
          delete item.first_name;
          delete item.competence;
          delete item.shifts;
          delete item.email;
        });
        this.allUsers = response.data.results;
        this.nextCollaboratorUrl = response.data.next;
        this.loadMoreUsers()
      } catch (e) {
        console.error(e)
        this.$message({
          showClose: true,
          message: "There was an unknown error while fetching users from your organization.",
          type: "error",
          center: true,
        });
      }
    },

    async loadMoreUsers() {
      if (!this.nextCollaboratorUrl || this.isLoadingUsers) { return }
      this.isLoadingUsers = true
      const response = await API.DESIGN_ORDERS.LOAD_MORE_Leads(
        this.nextCollaboratorUrl
      );
      response.data.results.map((item) => {
        item.name = `${item.first_name} ${item.last_name}`;
        item.label = `${item.first_name} ${item.last_name}`;
        delete item.last_name;
        delete item.first_name;
        delete item.competence;
        delete item.shifts;
        delete item.email;
      });

      const allUsers = response.data.results;

      this.allUsers.push(...allUsers);
      this.avatars.push(...allUsers);

      // For removing the user object that was manually added #pseudoUserObject
      let idsSet = new Set(allUsers.map(user => user.id))
      if (idsSet.size != this.allUsers.length) {
        this.allUsers.shift();
      }

      // this.disableAssigneeAndCreatorInCollaborators()
      this.nextCollaboratorUrl = response.data.next;
      this.isLoadingUsers = false
      this.loadMoreUsers()
    },

    getTaskCreatorId() {
      let creatorId
      if (this.mode == 'create') {
        let userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
        creatorId = userFromLocalStorage.user_id
      } else {
        creatorId = this.taskData.created_by
      }
      return creatorId
    },

    disableAssigneeAndCreatorInCollaborators() {
      let curAssignee = this.avatars.find(user => user.id == this.task.assignee)
      if (curAssignee) {
        curAssignee.disabled = true;
      }
      
      let creatorId = this.getTaskCreatorId()
      let creator = this.avatars.find(user => user.id == creatorId)
      if (creator) {
        creator.disabled = true;
      }
    },

    selectTaskCreatorInCollaborators() {
      let creatorId = this.getTaskCreatorId()
      this.selectUserInCollaborators(creatorId)
    },

    deselectTaskCreatorInCollaborators() {
      let creatorId = this.getTaskCreatorId()
      let creator = this.avatars.find(user => user.id == creatorId)
      if (creator) {
        creator.isSelected = false
      }

      let creatorInd = this.task.collaborators.findIndex(user => user.id == creatorId)
      if (creatorInd != -1) {
        this.task.collaborators.splice(creatorInd, 1)
      }
    },

    disableAndDeselectAssigneeInCollaborators() {
      this.avatars.forEach(user => user.disabled = false)
      
      let assigneeId = this.task.assignee
      let assigneeObj = this.avatars.find(user => user.id == assigneeId)
      if (assigneeObj) {
        assigneeObj.isSelected = false
        assigneeObj.disabled = true
      }

      let assigneeIndex = this.task.collaborators.findIndex(user => user.id == assigneeId)
      if (assigneeIndex != -1) {
        this.task.collaborators.splice(assigneeIndex, 1)
      }
    },

    selectPreviousAssigneeInCollaborators() {
      let prevAssigneeId = this.prevAssignee
      if (prevAssigneeId) {
        this.selectUserInCollaborators(prevAssigneeId)
      }
      this.prevAssignee = this.task.assignee
    },

    selectUserInCollaborators(userId) {
      let user = this.avatars.find(user => user.id == userId)
      if (user) {
        user.isSelected = true
        let doesIconExist = this.task.collaborators.find(user => user.id == userId)
        if (!doesIconExist) {
          this.task.collaborators.push(user)
        }
      }
    },
    
    onSetReminder() {
      this.setKey++;
      this.showSetReminderDrawer = true;
    },
    
    onSaveReminder(reminderDetails) {
      this.reminderData = {
        reminder_sent_at: reminderDetails.reminder_sent_at,
        notes: reminderDetails.notes,
      };
      if (this.mode == 'view') {
        this.setReminder(this.taskData.id);
      }
      this.showSetReminderDrawer = false;
    },

    async setReminder(id) {
      await API.TASKS.POST_SETREMINDER_TASK(
        id,
        this.reminderData
      );
      this.$message({
        showClose: true,
        message: "Reminder has been set successfully.",
        type: "success",
        center: true,
      });
    },

    taskFieldUpdated(fieldName) {
      if (fieldName == 'name') {
        this.validationText.taskName = "";
      }
      if (fieldName == 'lead_id') {
        this.validationText.homeOwner = "";
      }

      if (!this.updatedTaskFields.includes(fieldName)) {
        this.updatedTaskFields.push(fieldName)
      }
      if (this.mode == 'view') {
        this.debouncedUpdateTask()
      }
    },

    onClickTaskStatusIcon() {
      let taskStatus = this.task.status

      let inCompleteStatuses = [
        "New", "new",
        "In-Progress", "in_progress"
      ]
      if (inCompleteStatuses.includes(taskStatus)) {
        this.task.status = 'completed'
      } else {
        this.task.status = 'new'
      }

      this.taskFieldUpdated('status')
    },

    openDatePicker(refName) {
      this.$refs[refName].focus()
    },

    async setupForTaskDrawer() {
      // This means the task has not loaded yet. So just return.
      if (this.mode == 'view' && !this.taskData?.id) { return }


      let requiredApiCalls = [
        this.getAllUsers(),
        this.getPipelines(),
      ]

      // Lead List API call only required in Create Task mode since lead can't be changed
      // for an already existing task.
      if (this.mode == "create") {
        requiredApiCalls.push(this.getAllLeads())
      }

      await Promise.all(requiredApiCalls)
      
      // Adding pseudo user obj to the all users list so that the user is visible
      // in case it's not there in the original results #pseudoUserObject
      let userObj
      if (this.mode == "create") {
        let user = JSON.parse(localStorage.getItem("user"));
        userObj = {
          id: user.user_id,
          label: `${user.first_name} ${user.last_name}`,
          name: `${user.first_name} ${user.last_name}`,
        };
      } else {
        let resp = await this.getUserDetails(this.taskData.assignee);
        let user = resp.data
        userObj = {
          id: user.id,
          label: user.first_name + " " + user.last_name,
          name: user.first_name + " " + user.last_name,
        };
      }
      let curUserExists = this.allUsers.find((e) => e.id === userObj.id);
      if (!curUserExists) this.allUsers.unshift(userObj);

      if (this.mode === "create") {
        this.isTaskLoading = false;
        this.task.status = 'new'
      }
      
      // If mode is view, prepopulate task object with taskData
      if (this.mode === "view") {
        this.prevAssignee = this.taskData.assignee
        this.setTask(this.taskData);
        setTimeout(() => {
          this.watchTaskProperties = true;
        }, 2000);
      }
    },
    formatDateForDrawer(date) {
      // In case "date" is not in the ISO format
      date = new Date(date)
      let isoDate = date.toISOString()

      return formatDateTime(isoDate, "Short Date", true)
    },
    shouldShowTaskStatus() {
      if (this.taskUpdateStatus == 'updating' || this.taskUpdateStatus == 'complete') {
        return 'show'
      }
    },
    onClickToRedirectToLeadSummary(){
      this.isTaskLoading=true
    },
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus();
      },
    },
  },
};
</script>

<style scoped>
.b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin>g {
  transform-origin: center;
  animation: b-icon-animation-spin 2s linear infinite normal;
}

@keyframes b-icon-animation-spin {
  0% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(359deg)
  }
}

.task-drawer-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.taskCard {
  padding: 0 10px 0 10px;
  display: flex;
  flex-flow: column;
  overflow: auto;
}

.taskCard::v-deep .el-select .el-input .el-select__caret {
  color: black;
  font-weight: bold;
}

.el-form-item {
  padding: 0 10px;
  margin-bottom: 0;
  margin-top: 10px;
}

.dropdown-option {
  display: flex;
  align-items: center;
}

.dropdown-option .el-avatar {
  padding-right: 20px;
}

.dropdown-field-with-2-icons::v-deep .el-input__prefix {
  left: 0x;
  top: 4px;
}

.dropdown-field-with-2-icons .icon-style-left-dropdown {
  border: black 1px dashed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 3px;
  margin-top: 4px;
}

.dropdown-field-with-2-icons .selected-avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.dropdown-option .el-avatar {
  padding-right: 0;
  margin-right: 10px;
}

/*.dropdown-field-with-2-icons:hover {
  border-radius: 8px;
  background: #e8edf2;
}*/

.dropdown-field-with-2-icons {
  background: #e8edf2;
  border-radius: 8px;
}

.select-with-prefix::v-deep .el-input {
  padding-left: 30px; /* Adjust the value as needed */
}

.text-field-with-2-icons,
.dropdown-field-with-2-icons {
  display: flex;
  flex-flow: row nowrap;
}

.dropdown-field-with-2-icons::v-deep .el-input__inner {
  padding-left: 10px;
  padding-right: 10px;
}

/*.text-field-with-2-icons:hover {
  border-radius: 8px;
  background: #e8edf2;
}*/

.text-field-with-2-icons ::v-deep .el-input__inner {
  border-radius: 8px;
  background: #e8edf2;
}

.text-field-with-left-icon {
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  background: #e8edf2;
  padding-left: 15px;
}

.text-style, .reminder-text {
  cursor: pointer;
  color: #409EFF;
  font-size: 14px;
  font-weight: 600;
}

/*.text-field-with-left-icon:hover {
  border-radius: 8px;
  background: #e8edf2;
}*/

::v-deep .el-input__inner,
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
  border: none;
  padding: 0 15px;
  background-color: #e8edf2;
  border-radius: 8px;
  text-overflow: ellipsis;
}

.el-date-editor::v-deep input.el-input__inner {
  padding-left: 0;
}

/*::v-deep .el-input__inner:hover,
.el-date-editor.el-input__inner:hover {
  border-radius: 8px;
  background: #e8edf2;
}*/

::v-deep .el-form-item__label {
  padding: 0;
  line-height: 1.56rem;
}

.icon-style-right {
  padding-left: 4px;
}
.icon-style-left {
  padding-right: 14px;
}

.toggle-icon-parent {
  display: flex;
  cursor: pointer;
}
.icon-text {
  color: red;
}


.largeText::v-deep .el-textarea__inner {
  background: #e8edf2;
  border: none;
  border-radius: 8px;
  padding-left: 15px;
  padding-top: 12px;
}
.el-select-dropdown__item {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}
.create-button {
  width: 89px;
  margin: 1rem 10px 0 auto;
  font-size: 16px;
  font-weight: 600;
}
.create-btn-container {
  width: 550px;
  position: fixed;
  background-color: white;
  display: flex;
  bottom: 0;
  right: 0;
  padding: 0 10px 1rem 0;
}
.disabled-content {
  pointer-events: none; /* Disable click events */
  opacity: 0.3; /* Reduce opacity to visually indicate it's disabled */
  /* Add other styling as needed */
}
.disabled-content2 {
  /* pointer-events: none;  */
  /* opacity: 0.3;  */
  cursor: pointer !important;
}

.task-lead-location ::v-deep.el-form-item__content,
.task-description ::v-deep.el-form-item__content{
  line-height: 20px;
}

.homeowner-name {
  line-height: initial;
  margin-top: 10px;
  display: flex;
}

.task-lead-location-value,
.task-description-value {
  margin-top: 8px;
}

.searchBarWrapper {
  text-align: left;
  /* border: 1px solid #c0c4cc; */
  height: 40px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  height: 44px;
}
.searchBarWrapper input {
  height: 48px;
  outline: none;
  border: none;
  box-sizing: border-box;
  padding-left: 8px;
  width: 100%;
  font-size: 16px;
  background-color: #e8edf2;
  border-radius: 4px;
}

/* Style the map*/
.big-div {
  flex-basis: 40%; /* Adjust the width as needed */
  padding: 10px 0px;
  box-sizing: border-box;
}

.forLabel {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.map {
  background-color: #e8edf2;
  /*height: 100%;*/
  width: 100%;
  height: 150px;
}

.map-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.map-disabled-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #e8edf2;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.map-disabled-text {
  width: 60%;
}

.collaborators {
  padding-left: 6px;
}
.collaborators >>> .el-input__inner {
  padding: 0 !important;
  max-width: 100%;
}
.collaborators >>> .el-input__inner:hover {
  border: transparent;
  background-color: transparent;
}
.collaborators {
  background-color: #e8edf2;
  border-radius: 8px;
}
.collaborators > div {
  height: 40px;
}

.non-editable-collaborators {
  padding: 0;
  background-color: unset;
}

/* Document upload stuff*/
.grid-document-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: start;
  align-items: start;
  margin-top: 20px;
  gap: 10px;
  margin: 25px 10px 10px 10px;
}
.PDcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #e8edf2;
  border-radius: 6px;
  padding: 10px;
  width: 160px;
  min-height: 140px;
  cursor: pointer;
}
.PDcard > img {
  width: 38px;
}
.activity-timeline {
  padding: 2rem 2rem 0 2rem;
}
.footer {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  padding: 1rem;
  box-shadow: 0px 0px 10px 0px #00000040;
}
.footer-comment {
  width: 90%;
}
.el-textarea >>> .el-textarea__inner {
  resize: none;
}
.icons {
  display: flex;
  justify-content: center;
}
.contentOne {
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
  margin-top: 10px;
}
.delBtn,
.dwnldIcon {
  cursor: pointer;
  margin-top: 10px;
  width: 20px;
  height: 20px;
}
.dwnldIcon {
  margin-left: 2px;
  width: 17px;
  margin-right: 10px;
}
.lastPDcard {
  background-color: white;
  border: 2px dashed #777777;
}
.addDocCon {
  color: #777777;
  font-size: 12px;
  font-weight: 400;
}
.addIcon {
  opacity: 0.6;
  pointer-events: none;
}
.lastPDcard > img {
  width: 36px;
  height: 36px;
}
.el-select {
  width: 100%;
}
.option-avatar-text {
  position: relative;
  top: -1px;
}
.required-field {
  color: red;
}

.required-status {
  position: absolute;
  top: -30px;
  left: 42px;
  color: red;
}
.validation-text {
  position: absolute;
  font-size: 10px;
  color: red;
  left: 5px;
}
.home-owner {
  top: 28px;
}

.task-name-container {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.task-name {
  flex: 1;
  margin-left: 0.5em;
  font-size: 1.2em;
  font-weight: 600;
}
.delete-task-icon {
  position: absolute;
  z-index: 3;
  top: -218px;
  right: -492px;
  cursor: pointer;
}

.loading-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5em;
}

.task-status-icon {
  cursor: pointer;
  padding-left: 10px;
  width: 30px;
}

.icons-container {
  font-size: 1.5em;
  display: flex;
  gap: 0.5em;
  color: #777777;
}

.task-icon {
  cursor: pointer;
}

.date-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}
div >>> .el-form--label-top .el-form-item__label {
  padding: 0 !important;
  font-weight: 600;
}
div >>> .is-disabled > .el-input__inner {
  background-color: transparent;
}
div >>> .el-input__inner::placeholder {
  color: #777777;
  opacity: 1;
}
div >>> .el-textarea__inner::placeholder {
  opacity: 1;
  color: #777777;
}
div >>> .is-disabled > .el-input__inner::placeholder {
  opacity: 0.2;
  color: black;
}
.date-picker .is-disabled >>> .el-input__inner {
  opacity: 0.2 !important;
}
.disabled-img {
  opacity: 0.2 !important;
}
.disabled-lead-stage {
  background-color: #e8edf2;
  border-radius: 8px;
}

.disabled-lead-stage ::v-deep.el-input__inner {
  color: #606266 !important;
  opacity: 0.3;
}
.disabled-lead-stage ::v-deep.el-select__caret{
  display: none !important; 
}
.assignee-selection ::v-deep.el-input__inner{
  width: 90%;
  text-overflow: ellipsis;
}
.location-disabled-input {
  font-size: 16px;
  font-weight: 400;
  color: #222222;
}

.update-task-status {
  position: sticky;
  background-color: rgba(255,255,255,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  z-index: 50;
  transition: 0.2s;
  top: 0;
}

.update-task-status.show {
  padding: 1em;
}

@media (max-width: 768px) {
  .task-form {
    padding-top: 16px;
  }
  .create-btn-container {
    width: 100%;
    padding: 0px;
  }

  .create-button {
    width: 100%;
    margin: 0px;
    border-radius: 0px;
  }
}
</style>
