<template>
  <div class='col'>
    <div class="upload_area" @dragover="enableDragAndDrop && dragover" @drop="enableDragAndDrop && drop">
      <div :class="[(!$props.fromEsign) ? 'insicloseUploadde_content' : '', isBulkLeadsImport ? 'removeBorder' : '']">
       
        <div :class="['browse_button', (($props.fromEsign) ? 'backGround' : '')]">
          <label :class="['select_label', isBulkLeadsImport ? 'labelLead' : '', isPipelineSelected ? 'cursorClass' : '']" v-show="!isWeatherFileUploading">
          
            <p :class="['Drag-files-to-upload', isBulkLeadsImport ? 'font20Normal' : '']">{{ uploadFileText }}</p>
            <p :class="['or', isBulkLeadsImport ? 'font20Normal': '']">or</p>
            <input
              v-show="!isWeatherFileUploading"
              class="btn btn-outline"
              type="file"
              ref="file"
              @change="onChange"
              :disabled="isWeatherFileUploading || isPipelineSelected"
              :multiple="ispdf?false:true" 
              :accept="ispdf ? 'application/pdf' : '*/*'"
              
            />
            <div :class="['browseBtnLead', isPipelineSelected ? 'cursorClass disabledBrowseBtnLead' : '']" v-if="isBulkLeadsImport">
              <p class="addContentSDLead" >Browse file</p>
            </div>
            <div class="BrowseBtn" v-else>
            <p class="addContentSD" >Browse File</p>
            </div>
          </label>
          <i class="el-icon-loading" v-show="isWeatherFileUploading"></i>
        </div>
      
      </div>
    </div>
    <p v-show="isInvalidFile" class="error-msg">* {{ errorMsg }}</p>
  </div>
</template>
<script>
export default {
  emits: ["openFiles"],
  props: {
    fromEsign: {
      type: Boolean,
    },
    enableDragAndDrop: {
      type: Boolean,
      default: true,
    },
    ispdf:{
      type:Boolean,
      default:false
    },
    isBulkLeadsImport: {
      type: Boolean,
      default: false,
    },
    uploadFileText: {
      type: String,
      default: 'Drag files to upload',
    },
    isPipelineSelected: {
      type: Boolean,
      default: false,
    }
  },

  
  data() {
    return {
      isWeatherFileUploading: false,
      fileList: [],
      errorMsg: "",
      isInvalidFile: false,
    };
  },
  mounted(){
    if(!this.$props.fromEsign){
      if(this.$refs.file){
        if(!this.$props.ispdf){
          this.$refs.file.setAttribute("multiple","");
        }
      } 
    }else{
       if(this.$refs.file){
       this.$refs.file.setAttribute("accept","image/png,image/jpeg,image/jpg");
       }
    }
  },
  methods: {
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },
    async uploadWeatherFile(file) {
      console.log("@@file", file);
    },
    onChange() {
      console.log("!!this.$refs", this.$refs);
      this.fileList = this.$refs.file.files;
      this.uploadWeatherFile(this.fileList);
      this.$emit("openFiles", this.fileList);
    },
  },
};
</script>


<style scoped>

.btn-outline {
  display: none;

}
.insicloseUploadde_content{
  border: 1px dashed #999;
  border-radius: 4px;
}
.browse_button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  height: 160px;
  border-radius: 4px;
  position: relative;
  text-align: center;
  border: none;
  background-color: #e8edf2;

}

.backGround{
  padding-top: 0px;
  margin-top: -24px;
  background-color: #fff;
}

.addContentSD {
  line-height: 1.5;
  margin-top: 3px;
  font-size: 16px;
  color: #222;
  word-break: break-word;
  font-weight: 500;
}

.createIcon {
  cursor: pointer;
}
.BrowseBtn {
  width: 117px;
  height: 32px;
  margin-left: 41px;
  margin-top: 14px;
  padding: 0px 8px;
  border-radius: 4px;
  border: solid 1px #222;
  cursor: pointer;
}

.browseBtnLead {
  border-radius: 4px;
  background: #409EFF;
  height: 42px;
  width: 135px;
  display: grid;
  place-items: center;
  margin: auto;
  cursor: pointer;
}

.disabledBrowseBtnLead {
  background: var(--CCC, #CCC);
}

.addContentSDLead {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}

.Drag-files-to-upload {
  width: 123px;
  height: 10px;
  margin: 5px 38px 14px 38px;
  font-size: 14px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #222;
}
.or {
  width: 18px;
  height: 18px;
  margin:0px 10px 0px 88px;
  font-size: 16px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #777;
}

.labelLead {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.font20Normal {
  font-size: 20px;
  color: #777;
  width: initial;
  height: initial;
  margin: auto;
}

.removeBorder {
  border: none;
}

.cursorClass {
  cursor: not-allowed;
}

@media (max-width: 700px) {
.font20Normal {
  font-size: 16px;
}

  .labelLead {
    gap: 16px;
  }
}


</style>