<template>
    <div>
        <div class="main-card"  v-for="(notification , index) in notificationsList" :key="index" @click="handleClick(notification)">
            <div class="main-card-div" :style="{'--padding-left': getAvatarExist(notification) ? '5px' : '35px'}">
                <p
                    :class="[$props.isSlide ? 'flex-card-slide' : 'flex-card']"
                    :style="{maxWidth: $props.maxWidth}" 
                    :key="index"
                >
                    <span
                        v-for="(comp, index) in notification.notification_details.components"
                        :key="index"
                    >
                        <template v-if="comp == '{action_by}'">
                            <div class="avatar-container">
                                <el-avatar 
                                    :size="24"
                                    :style="getAvatarStyle(notification.notification_details.action_by_name)"
                                    >{{ generateInitials(notification.notification_details.action_by_name) }}
                                </el-avatar>
                            </div>
                            <span :class="['assigner', notification.notification_details.content_obj_type == 'user' ? 'dark-text' : '' ] "
                            >{{ getFirstName(notification.notification_details.action_by_name) }}</span>
                        </template>
                        <span
                            v-else-if="comp == '{content_object_name}'"
                            class="activity-div"
                            :class="[ isSlide ? 'activity-assignee-toast' : 'activity-assignee', notification.notification_details.content_obj_type == 'user' ? 'dark-text' : '' ]" 
                        >
                            <img
                                v-if="notification.notification_details.content_obj_type == 'lead'"
                                class="person-badge"
                                src="../../assets/img/PersonBadge.svg"
                                alt="person-badge"
                            >
                            <span class="people-icon" v-else-if="notification.notification_details.content_obj_type == 'team'">
                                <b-icon style="width: 1.2em; height: 1.2em" icon="people-fill"></b-icon>
                            </span>
                            <span  v-else-if="notification.notification_details.content_obj_type == 'user'">
                                <el-avatar 
                                    :size="24"
                                    :style="getAvatarStyles(notification.notification_details.content_obj_name)"
                                    >{{ generateInitials(notification.notification_details.content_obj_name) }}
                                </el-avatar>
                            </span>

                            <span
                                v-else
                                class="task-icon-container"
                            >
                                <img class="circle-icon" 
                                src="../../assets/img/circleNotif.svg" alt="circle-icon">
                                <img class="tick-icon" 
                                src="../../assets/img/tickNotif.svg" alt="tick-icon">
                            </span>
                            <span class="assignee-text">
                                {{ notification.notification_details.content_obj_name }}
                            </span>
                        </span>
                        <template v-else-if="comp == '{action_for}'" >
                            <span v-if="isSelf(notification.notification_details.action_for)" class="self-span">you</span>
                            <span v-else :class=" ['notification-action-text',  isPopup && 'notification-word-break']" >
                                {{ notification.notification_details.action_for_name }}
                            </span>
                        </template>
                        <span v-else :class=" ['notification-text', isPopup && 'notification-word-break']">
                            {{ comp }}
                        </span>
                    </span>
                </p>

                <div class="right-info" v-if="!isSlide">    
                    <span class="time">{{ generateNotificationTime(notification.notification_details.created_at) }}</span>
                    <span class="blue-dot"> <img src="../../assets/img/blueDotNotif.svg" alt="blue-dot" width="8px" height="8px" v-show="!notification.is_read"> </span>
                </div>
            </div>
    
        </div>
    </div>
</template>
<script>
import {generateAvatarColor,isExpertServicesOrg} from '../../utils';
import timeDifferenceGenerator from '../../utils/timeDifferenceGenerator'
import { mapActions } from "pinia";
import API from "@/services/api/";
import { DateTime } from "luxon";
import {UsersList} from '../../utils/esUserList.js'
import { queryParamsFormater } from '../../utils/queryParamGenerator.js';
import { useUiStore } from "../../stores/ui";

    export default{
        emits: [
            "notificationRead",
            "showDrawer",
            "handleClick",
        ],

        name: 'NotificationCards',
        props: {
            notificationsList: {
                type: Array,
                default: () => [],
            },
            isSlide: {
                type: Boolean,
                default: false
            },
            maxWidth: {
                type: String,
                default: '500px'
            },
            page:{
                type: String,
                default: 'popup'
            },
            assigneeWidth:{
                type: String,
            },
            isPopup: {
                type: Boolean,
                default: false,
            }
        },
        methods: {
            ...mapActions(useUiStore, ["SET_CLOSE_CHAT_BOX"]),
            async handleClick(notif){
                if (notif.notification_details.content_obj_type == 'user') return
            
                let selectedNotif = notif.notification_details;
                const additionalData=selectedNotif?.additional_details;
                let qParams={
                    order_id: additionalData?.order_id,
                }
                const qParamFormate=    queryParamsFormater(qParams);
                qParamFormate?this.$emit('notificationRead', notif.notification_details, qParamFormate) : this.$emit('notificationRead', notif.notification_details)
                if(selectedNotif.content_obj_type){
                    let drawerType= isExpertServicesOrg()?additionalData?.notification_sub_type:selectedNotif.content_obj_type
                    if(!additionalData?.notification_sub_type){
                        this.$emit('showDrawer', drawerType)
                    }
                    let filteredData = {}
                    let id = selectedNotif.content_obj_id;
                    
                    try {
                   if(additionalData?.notification_sub_type){
                   if(isExpertServicesOrg()) {
                    this.$emit('showDrawer', drawerType)
                    let [users, nextUrl]= await UsersList();
                    let res = await API.DESIGN_ORDERS.FETCH_DESIGN_ORDER_BY_ID_METHOD(additionalData?.order_id);
                    filteredData = res.data
                            filteredData.type = 'order';
                            filteredData.users = users||[];
                            this.SET_CLOSE_CHAT_BOX(false)
                          
                            this.$emit('handleClick', filteredData)
                        }
                        else{
                            this.$emit('showDrawer', 'redirectdesign')
                       
                            filteredData.lead_id=additionalData?.lead_id;
                            filteredData.design_id=additionalData?.design_id;
                            filteredData.type='redirectdesign';
                            this.$emit('handleClick', filteredData)
                        }
                           
                   }
              else   {  if(selectedNotif.content_obj_type === 'lead'){
                            let res = await API.LEADS.FETCH_LEAD(id)
                            filteredData = {
                                ...res.data,
                                type: 'lead',
                                color: generateAvatarColor(res.data.name),
                                country: null,
                                created_at: this.formateDateTime(res.data.created_at),
                                lead_details: {
                                    pipeline: res.data.pipeline,
                                    stage_object: res.data.stage_object,
                                    created_at: res.data.created_at,
                                    deal_value: res.data.deal_value,
                                    email: res.data.email,
                                    estimated_system_size: res.data.estimated_system_size,
                                    id: res.data.id,
                                    last_contacted: res.data.last_contacted,
                                    latest_notes: res.data.latest_notes,
                                    lead_source_object: res.data.lead_source_object,
                                    name: res.data.name,
                                    owner: res.data.owner,
                                    phone: res.data.phone,
                                    stage: res.data.stage,
                                    target_closure_date: res.data.target_closure_date || null
                                },  
                                ...res.data.project_details,
                                focusNote: false,
                            }
                        }else if(selectedNotif.content_obj_type === 'task'){
                            let res = await API.TASKS.FETCH_TASK_BY_ID(id)
                            filteredData = res.data
                            filteredData.type = 'task'
                            filteredData.isTask = true
                        }
                        // setTimeout(() => {
                            this.$emit('handleClick', filteredData)
                        // }, 3000);
                        }
                    } catch (err) {
                        console.error(err)
                        this.$message({
                        showClose: true,
                        message:
                            "There was an error while getting the lead data. Please try again.",
                        type: "error",
                        center: true,
                        });
                    }
                }
            },
            formateDateTime(dateTime) {
                if (
                    DateTime.fromISO(dateTime).startOf("day").toISO() ===
                    DateTime.local().startOf("day").toISO()
                ) {
                    const date = DateTime.fromISO(dateTime);
                    const time = date.toLocaleString(DateTime.TIME_SIMPLE);
                    return `Today at ${time}`;
                }

                if (
                    DateTime.fromISO(dateTime).startOf("day").toISO() ===
                    DateTime.local().startOf("day").minus({ days: 1 }).toISO()
                ) {
                    const date = DateTime.fromISO(dateTime);
                    const time = date.toLocaleString(DateTime.TIME_SIMPLE);
                    return `Yesterday at ${time}`;
                }

                return DateTime.fromISO(dateTime).toFormat("dd/MM/y  hh:mm a");
            },
            modifyNotifications() {
                function extractParts(input) {
                    const regex = /(\{[^{}]+\}|[^{}]+)/g;
                    return input.match(regex);
                }
                this.notificationsList.forEach((msg) => {
                    msg.notification_details.action = msg.notification_details.action.replace(/\%\%(.*?)\%\%/g, '{$1}'); // replacing %% with { }
                    let components = extractParts(msg.notification_details.action);
                    this.$set(msg.notification_details, "components", components);
                });
            },
            generateInitials(name) {
                if (!isNaN(name) || name === null || !name || name.trim().length === 0 ) {
                    return "N/A"; // Return empty string for blank names
                }

                const names = name.trim().split(" ");
                const initials = names.map((n) => n.charAt(0).toUpperCase());

                if (initials.length === 1) {
                    return initials[0]; // Return single initial if only one name is available
                } else {
                    return initials[0] + initials[initials.length - 1]; // Return first and last initials for multiple names
                }
            
            },
            getFirstName(name){
                if(name === null || undefined){return 'NA'}
                let firstName = name.trim().split(' ')
                if(firstName[1]) return firstName[0]
                return name
            },
            getAvatarStyle(value) {
                const backgroundColor = generateAvatarColor(value);
                return {
                    display: 'inline-block',
                    marginRight: "5px",
                    backgroundColor: backgroundColor,
                    fontSize: "12px",
                    position: 'relative',
                    top: this.isSlide ? '-11px' : '0',
                    left: '-30px'
                };
            },
            getAvatarStyles(value) {
                const backgroundColor = generateAvatarColor(value);
                return {
                    display: 'inline-block',
                    marginRight: "5px",
                    backgroundColor: backgroundColor,
                    fontSize: "12px",
                    position: 'relative',
                    top:'6px',
                    left: '0px'
                };
            },
            generateNotificationTime(notificationDate){
               return timeDifferenceGenerator(notificationDate, true)
            },
            isSelf(id){
                return JSON.parse(localStorage.getItem('user')).user_id === id ? true : false
            },
            getAvatarExist(notif){
                if(notif.notification_details.action_by){
                    return false
                }
                return true
            }
        },
        created() {
            this.modifyNotifications();
        },
        watch: {
            notificationsList: {
                handler(){
                    this.modifyNotifications();
                }
            }
        }
    }
</script>
<style scoped>
.main-card > div{
    display: flex;
    position: relative;
    flex-direction: row;
    cursor: default;
    color: #555555;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    padding-bottom: 4px;
    margin-bottom: 4px;
    background: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
    padding-left: var(--padding-left);
    align-items:center;
    justify-content: space-between;
}
.main-card:hover{
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px #090A0B26;

}
.right-info{
    position: relative;
}
.flex-card{
    flex: 1;
    overflow: hidden;
    margin-bottom: 2.5px;
    padding-bottom: 2.5px;
    word-break: break-word;
}
.flex-card-slide{
    overflow: hidden;
    /* text-overflow: ellipsis; */
    padding-bottom: 2.5px;
    margin-bottom: 2.5px;
    white-space: nowrap;
}
.flex-card > * , .flex-card-slide > * {
    display: inline;
}
.main-card-div{
    padding: 8px;
    padding-bottom: 0;
}
.assigner{
    font-size: 14px;
    font-weight: 600;
    color: #222222;
    position: relative;
    bottom: 0;
    padding: 0;
    margin: 0;
}
.activity-assignee, .activity-assignee-toast{ 
    overflow: hidden;
    color: #409EFF;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    word-break: break-word;
}
.activity-assignee-toast{
    top: 0;
    display: inline;
}
.notification-text, .notification-action-text{
    overflow: hidden;
    margin: 0 0 0 2px;
}
.notification-action-text{
    margin-left: 0;
    padding-left: 0;
}
.person-badge {
    position: relative;
    width: 16px;
    top: 3px;
}
.task-icon-container {
    display: inline-block;
    position: relative;
    width: 20px;
}
.circle-icon, .tick-icon{
    position: absolute;
    left: 1px;
    bottom: -3px;
}
.tick-icon {
    bottom: 1px;
    left: 5px;
}
.time{
    color: #999999;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    margin-right: 1.5rem;
}
.blue-dot{
    position: absolute;
    right: 0px;
}
.status{
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #409EFF;
}
.self-span{
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
}
.activity-div{
    margin-left: 2px;
}
.avatar-container{
    padding: 0;
    margin: 0;
    position: absolute;
}
.notification-word-break{
    word-break:break-word;
}
.dark-text{
    color: black;
}
.people-icon{
    border-radius: 50px;
    border: 1px solid #5aeb5a;
    padding: 3px 3px 1px 3px;
    font-size: 10px;
    background: #5aeb5a;
    color: white;
}
</style>
