<template>
  <div style="height: 100% ; ">
    <CardElement :headingText="computeHeadingText" :chartType="chartType" :editMode="editMode"
      @open-popover="handleOpenPopover" v-if="chartsData.length">
      <template v-slot:body>
        <div calss="chart-container" style="height: 100%">
          <Chart v-loading="!chartsData[0]" v-if="!isMap" :type="chartType" :data="chartsData[0]"
            :options="computeOptions" @chart-click="handleDrilldownOpen" :dashboardId="dashboardId" />
          <div v-else style="height: 100%">
            <HomeMap ref="homeMapImport" :all-projects="chartsData[0]?.position.lat ? chartsData : []"
              :page-flag="'dashboard'" />
              <!-- <div v-else style="display:flex;justify-content:center;align-items:center;height:100%">No Data Available</div> -->
          </div>
        </div>
      </template>
    </CardElement>
    <CardElement :headingText="computeHeadingText" :chartType="chartType" :editMode="editMode"
      @open-popover="handleOpenPopover" v-else-if="errorData === true">
      <template v-slot:body>
        <div calss=" nodata-container" style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50vh;
          ">
          {{ noDataValue }}
        </div>
      </template>
    </CardElement>
    <Skeleton  v-else />
  </div>
</template>
<script>
import Chart from "../models/Chart.vue";
import HomeMap from "../../../home/components/homeMap.vue";
import CardElement from "../models/Card/CardElement.vue";
import API from "../../../../services/api";
import { generateColorHex } from "../../../../utils/colorGenerator";
import Skeleton from "../models/Card/Skeleton.vue";
import {
  getLocaleAbbreviations,
  getCurrencySymbol,
} from "../../../../utils/numberFormat/currencyFormatter";
import { mapState, mapActions } from "pinia";
import { useDashboardStore } from "../../../../stores/dashboard";

export default {
  emits: ["update:updateCheck", "click-drilldown", "mode-open"],
  name: "AllChart",
  components: {
    CardElement,
    Chart,
    HomeMap,
    Skeleton,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },

    chartData: {
      type: Array,
      default() {
        return []
      },
    },
    isMap: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "",
    },
    widgetsData: {
      type: Array,
      default() {
        return []
      },
    },
    dashboardId: {
      type: Number,
      default: null,
    },
    updateWidgetData: {
      type: String,
      default: "",
    },
    updateCheck: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    activeWidgetId:{
      type:[Number,String],
      default:null
    }
  },
  data() {
    return {
      // chartType: null,
      chartsData: [],
      chartDetails: {
        name: "No Data",
      },
      chartType: "",
      chartLables: [],
      drillDownUrl: "",
      errorData: false,
      metricType: "",
      gradient1: null,
      gradient2: null,
      noDataValue: "No Data Available",
      updateWidgetFlag: "",
    };
  },
  methods: {
    formatLabels(arr) {
      if (this.chartDetails.type === "Donut") return arr;
      if (arr.length > 10) {
        return arr.map((e) => {
          if (e.length > 18) return e.slice(0, 15) + "...";
          return e;
        });
      }
      arr = arr.map((e) => {
        if (e.includes(" ")) {
          if (e.includes("/ ")) {
            let filteredArr = e.split("/ ");
            return filteredArr.map((e, index) => {
              if (index < filteredArr.length - 1) return e + "/";
              return e;
            });
          }
          return e.split(" ");
        }
        return e;
      });
      return arr;
    },
    checkEmptyData(data) {
      if (data.length > 0) {
        const zeroData = data.filter((d) => d === 0);
        if (zeroData.length === data.length) {
          this.chartsData = [];
          this.errorData = true;
          return false;
        } else {
          return true;
        }
      } else {
        this.chartsData = [];
        this.errorData = true;
        return false;
      }
    },
    colorPickerForBar(chartType) {
      if (chartType) {
        const filterdData = this.$props.widgetsData.filter(
          (d) => d.type === chartType
        );
        let dataSequence = "";
        const colorsData = this.getColorHex(filterdData.length);
        filterdData.map((f, index) => {
          if (f.id === this.$props.id) {
            dataSequence = colorsData[index];
          }
        });
        return dataSequence ? dataSequence : "#409EFF";
      } else {
        return "#F88227";
      }
    },
    formatNumber(number, dataSet) {
      const chartTypes = dataSet.metric_data_type;
      let countryCode = JSON.parse(
        localStorage.getItem("organisation")
      ).country_code;
      return chartTypes === "number"
        ? `${getLocaleAbbreviations(countryCode, number, true)}`
        : `${getCurrencySymbol(countryCode)}${getLocaleAbbreviations(
          currencyCode,
          number,
          true
        )}`;
    },
    handleSum(numbers) {
      let sum = 0;
      if (numbers.length) {
        numbers.map((d) => {
          sum += d;
        });
      }

      return sum;
    },
    getFormatedData(data) {
      const formatData = [];
      data.data.map((d, inx) => {
        formatData[inx] = getLocaleAbbreviations(
          JSON.parse(localStorage.getItem("organisation")).currency_code,
          d,
          true
        );
      });
      return formatData;
    },
    getColumnData(colData) {
      let labels = [];
      let colDataSet = colData.dataset[0];

      const filterdData = this.$props.widgetsData.find(
        (d) => d.id === this.$props.id
      );
      colData.labels.map((d, inx) => {
        labels[inx] = d.label;
      });
      if (this.checkEmptyData(colDataSet.data)) {
        this.chartsData = [
          {
            labels: this.formatLabels(labels),
            datasets: [
              {
                label: colDataSet.column_label,
                data: colDataSet.data,
                // backgroundColor: "#EF6C00",
                backgroundColor: filterdData?.color ?? this.colorPickerForBar(
                  this.chartDetails?.type)
                ,
                borderRadius: 3,
                maxBarThickness: 30,
              },
            ],
          },
          {
            type: "bar",
          },
          {
            options: {
              indexAxis: "y",
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
              },
            },
          },
        ];
      }
    },
    getDonutData(colData) {
      let labels = [];
      let colDataSet = colData.dataset[0];
      let plotData = colDataSet?.data || [];
      colData.labels.map((d, inx) => {

        // labels[inx] = `${d.label} (${plotData[inx].toFixed(
        //   this.metricType === "number" && !colDataSet?.unit ? 0 : 2
        // )}${colDataSet?.unit || ""})`;
        labels[inx] =  `${d.label} (${ (this.metricType === "currency" ||this.metricType === "number")?this.formatNumber(plotData[inx], colDataSet) :  plotData[inx].toFixed(
          this.metricType === "number" && !colDataSet?.unit ? 0 : 2
        )}${colDataSet?.unit || ""})`
      });
      if (this.checkEmptyData(colDataSet.data)) {
        this.chartsData = [
          {
            labels: this.formatLabels(labels),
            datasets: [
              {
                label: "",
                data: colDataSet.data,
                backgroundColor: this.getColorHex(colDataSet.data.length),
              },
            ],
          },
          {
            type: "Donut",
          },
          {
            options: {
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
              },
            },
          },
        ];
      }
    },
    async getMapData(colData) {
      let mapData = [];
      if (colData.length > 0) {
        await colData.map((d, inx) => {
          mapData[inx] = {
            position: {
              lat: d.latitude,
              lng: d.longitude,
            },
            info: {
              projectName: d.lead_name,
              projectId: d.lead_id,
            },
          };
        });
      }
      this.chartsData =
        colData.length > 0
          ? mapData
          : [
            {
              position: {
                lat: "",
                lng: "",
              },
              info: {
                projectName: "",
                projectId: "",
              },
            },
          ];
      this.chartType = "map";
    },
    getLineTrindsData(colData) {
      let labels = [];
      let colDataSet = colData.dataset[0];
      let comparisionData = colData.comparison_dataset[0];
      const sumOfData = this.handleSum(colDataSet?.data || []);
      const previousSum = this.handleSum(comparisionData?.data || []);
      const currentData = this.formatNumber(sumOfData, colDataSet);
      const previousData = this.formatNumber(previousSum, colDataSet);

      colData.labels.map((d, inx) => {
        labels[inx] = d.label;
      });
      this.chartsData = [
        {
          labels: this.formatLabels(labels),
          datasets: [
            {
              label: `${currentData} /Current Period`,
              data: colDataSet.data,
              tension: 0.4,
              borderColor: "#409EFF",
              backgroundColor: "#409EFF",
              pointStyle: true,
              borderWidth: 1,
              // fill: true,
            },
            {
              label: `${previousData} /Previous Period`,
              data: comparisionData?.data || [],
              tension: 0.4,
              borderColor: "#EF6C00",
              backgroundColor: "#EF6C00",
              pointStyle: true,
              borderWidth: 1,
              // fill: true,
            },
          ],
        },
        {
          type: "line",
        },
        {
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: "top",
                labels: {
                  boxWidth: 10,
                  boxHeight: 10,
                  useBorderRadius: true,
                  borderRadius: 5,
                },
              },
            },
          },
        },
      ];
    },
    async getWidgets(id) {

      this.charstData = null;
      this.chartType = "";
      if (this.$props.id) {
        try {
          let { data } = id
            ? await API.CRM_DASHBOARD.GET_WIDGET_BY_ID(id)
            : await API.CRM_DASHBOARD.GET_WIDGET_BY_ID(this.$props.id);
          if (
            this.$props.widgetsData &&
            data &&
            this.$props.widgetsData.find((d) => d.id === data.id)
          ) {
            this.chartDetails = data;
            let chartDt = data.result.current_time_range_resp.dataset[0]?.data;

            this.chartLables = data.result.current_time_range_resp.labels;
            this.drillDownUrl = data.result.current_time_range_resp.drill_down;

            this.metricType =
              data.result.current_time_range_resp.dataset[0]?.metric_data_type;

            if (data.type === "Map") {
              this.chartType = "map";
              this.getMapData(
                data.result.current_time_range_resp.dataset[0]?.data
              );
            } else if (data.type === "Column" || data.type === "Bar") {
              this.chartType = "bar";
              this.getColumnData(data.result.current_time_range_resp);
            } else if (data.type === "Donut") {
              this.chartType = "doughnut";
              this.getDonutData(data.result.current_time_range_resp);
            } else if (data.type === "Trend Line") {
              this.chartType = "line";
              this.getLineTrindsData(data.result.current_time_range_resp);
            } else {
              this.chartsData =
                data.result.current_time_range_resp.dataset[0]?.data;
            }
          }
        } catch (err) {
          this.errorData = true;
        }
      }
      this.$emit('update:updateCheck', false)
    },
    handleDrilldownOpen(data) {
      if (!this.$props.editMode) {
        let paramsValue = this.chartLables.find(
          (d, inx) => inx === data[0].index
        );
        const queryParams = `${this.drillDownUrl.query_param}=${paramsValue.data_value}`;
        if (this.drillDownUrl?.query_param) {
          this.$emit(
            "click-drilldown",
            this.drillDownUrl,
            paramsValue,
            this.chartDetails
          );
        }
      }
    },
    checkDoughnut() {
      return this.$props.type !== "Donut";
    },
    getColorHex(num) {
      return generateColorHex(num);
    },
    handleOpenPopover(value) {
      this.$emit("mode-open", value, this.$props.id);
    },
  },
  computed: {
    ...mapState(useDashboardStore, {
      dashboardData: (state) => state.dashboardupdate,
    }),
    computeHeadingText() {
      return this.chartDetails.name;
    },
    computeOptions() {
      if (this.$props.type === "Map") return;
      const chartData = this.chartDetails;

      let filteredOptions = {};
      const titleData = this.chartDetails.result.current_time_range_resp;

      filteredOptions = {
        ...filteredOptions,
        indexAxis: this.chartDetails.type === "Bar" ? "y" : "x",
        hover: {
          mode: "nearest",
          intersect: true,
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle:
                this.chartDetails.type === "Trend Line" ? false : true, // Use circular indicators

              boxWidth: 13,
              boxHeight: 13,
              useBorderRadius: true,
              borderRadius: 6,
              padding: this.chartDetails.type === "Trend Line" ? 10 : 25,
              color: "black",
              font: {
                size: "12px", // Change this value to your desired font size
              },
              cutout: "50%",
            },
            display:
              this.chartDetails.type === "Bar" ||
                this.chartDetails.type === "Column"
                ? false
                : true,
            position: this.chartDetails.legend_position,

            align: this.chartDetails.type === "Trend Line" ? "start" : "center",
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            callbacks: {},
          },
        },

        onHover: (event, elements) => {
          const canvas = event.chart.canvas;
          canvas.style.cursor =
            this.$props.editMode ? 'move' :
              elements.length > 0 && this.chartDetails.type !== "Trend Line"
                ? "pointer"
                : "default";
        },
        scales: {
          x: {
            ticks: {
              precision: 0,
              autoSkip: false,
              beginAtZero: true,
              stepSize: 1,
            },
            border: {
              display: true,
              color: "#CCCCCC",
            },
            title: {
              display: true,
              text: titleData.x_axis_label
                ? this.metricType === "currency" &&
                  this.chartDetails.type === "Bar"
                  ? `${titleData.x_axis_label} (${JSON.parse(localStorage.getItem("organisation"))
                    .currency_code
                  })`
                  : titleData.x_axis_label
                : "",
              color: "black",
              font: {
                size: "12px",
                weight: 600,
              },
            },
            display: this.checkDoughnut(),
            grid: {
              display: false,
              borderColor: "green",
            },
          },
          y: {
            ticks: {
              autoSkip: false,
              beginAtZero: true,
              stepSize: 1,
            },
            border: {
              display: true,
              color: "#CCCCCC",
            },
            display: this.checkDoughnut(),
            title: {
              display: true,

              text: titleData.y_axis_label
                ? this.metricType === "currency" &&
                  this.chartDetails.type === "Column"
                  ? `${titleData.y_axis_label} (${JSON.parse(localStorage.getItem("organisation"))
                    .currency_code
                  })`
                  : titleData.y_axis_label
                : "",
              color: "black",
              font: {
                size: "12px",
                weight: 600,
                color: "black",
              },
            },
            grid: {
              display: false,
              borderColor: "green",
            },
          },
        },
      };
      if (this.metricType && this.chartDetails.type !== "Bar") {
        const metricsType = this.metricType;
        const chartData = this.chartDetails;
        filteredOptions.scales.y.ticks = {
          precision: 0,
          font: {
            size: "12px",
          },
          callback: function (value, index, values) {
            if (
              metricsType === "currency" ||
              (metricsType === "number" && chartData.type !== "Bar")
            ) {
              return getLocaleAbbreviations(
                JSON.parse(localStorage.getItem("organisation")).currency_code,
                value,
                true
              );
            } else {
              return value;
            }
          },
        };
      }
      if (this.metricType && this.chartDetails.type === "Bar") {
        const metricsType = this.metricType;
        const chartData = this.chartDetails;
        filteredOptions.scales.x.ticks = {
          precision: 0,
          font: {
            size: "12px",
          },
          callback: function (value, index, values) {
            if (metricsType === "currency" || metricsType === "number") {
              return getLocaleAbbreviations(
                JSON.parse(localStorage.getItem("organisation")).currency_code,
                value,
                true
              );
            } else {
              return value;
            }
          },
        };
      }
      if (this.metricType && this.chartDetails.type === "Donut") {
        filteredOptions.plugins.tooltip.callbacks.label = function (context) {
          return "";
        };
      }
      return filteredOptions;
    },
  },
  mounted() {
    this.charstData = [];
  },
  watch: {
    dashboardData: {
      deep: true,
      handler(val) {
        // if (val && this.$props.id === val.id) {
        // this.getWidgets();
        // }
      },
    },
    dashboardId: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.chartDetails.name = "";
        }
      },
    },
    id: {
      immediate: true,
      deep: true,
      handler(val) {
        this.charstData = null;
     
          this.getWidgets();

      },
    },
    chartsData: {
      immediate: true,
      deep: true,
      handler(val) {
        this.chartsData = val;
      },
    },
    chartType: {
      immediate: true,
      handler(val) { },
    },
    updateCheck: {
      immediate: true,
      handler(val) {
        if(val===true){
 
          if(this.$props.activeWidgetId===this.$props.id||!this.$props.editMode){
            this.getWidgets();

          }

        }
      },
    },
  },
};
</script>

<style scoped>
.chart-container {
  height: 50vh !important;
}

.card-body {
  height: 50vh;
}

.nodata-container {
  height: 50vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
