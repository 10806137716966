<template>
  <div>
    <GlobalDialog
      :dialogTitle="dialogTitle"
      :dialogVisible.sync="dialogVisible"
      :width="width"
      :footerButtonText="footerButtonText"
      :footerButtonType="footerButtonType"
      :disableCancel="disableCancel"
      :disableButton="disableButton"
      @handleClose="handleClose"
      @handleClick="handleClick"
    >
 
      <template v-slot:body>
        <div style="margin: 0; padding: 0">
          <!-- <div class="icon-container" @click="handleClose">
            <b-icon
              :style="{
                marginRight: '-8px',
                color: 'black',
                fontSize:'12px',
              }"
              class="crossicon"
              :icon="'x'"
            ></b-icon>
          </div> -->
          
          <div class="delete-body">
            <div class="alert">

              <b-icon
              :style="{
               
                color:iconColor ,
              }"
              class="alert-icon"
              :icon="iconName"
            ></b-icon>
              <!-- <img
                src="../../../../assets/img/alert (1).svg"
                alt="alert"
                class="warningImage"
              /> -->
            </div>
            <p class="info-container">{{ deleteMessage }}</p>
            <p v-if="infoMessage" class="info-container">{{ infoMessage }}</p>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="footer">
          <el-button
          v-if="showNoButton"
          class="btn"
          plain
            @click="handleClose"
           
            >{{ 'No' }}</el-button>
          <el-button
          class="btn"
            :type="footerButtonType"
            @click="handleClick"
            :disabled="disableButton"
            :loading="isLoading"
            >{{ footerButtonText }}</el-button
          >
        </div>
      </template>
    
    </GlobalDialog>
  </div>
</template>

<script>
import GlobalDialog from "../../../commonComponents/GlobalDialog.vue";

export default {
  emits: ["handleClose", "handleClick"],
  name: "GlobalDeleteDialog",
  components: {
    GlobalDialog,
  },
  props: {
    dialogTitle: {
      type: String,
      default: "",
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    showNoButton: {
      type: Boolean,
      default: true,
    },
    footerButtonText: {
      type: String,
      default: "Delete",
      required: false,
    },
    width: {
      type: String,
      default: "30%",
      required: false,
    },
    footerButtonType: {
      type: String,
      default: "danger",
      required: false,
    },
    disableCancel: {
      type: Boolean,
      default: false,
      required: false,
    },
    disableButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    deleteMessage: {
      type: String,
      required: true,
    },
    infoMessage:{
      type:String,
      default:''
    },
    iconName:{
      type:String,
      default:'exclamation-triangle'
    },
    iconColor:{
      type:String,
      default:'red'
    },
    isLoading:{
      type:Boolean,
      default:false,
    },
    loader:{
      type:Boolean,
      default:false,
    }
  },

  mounted() {
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    handleClick() {
      this.$emit("handleClick");
      if(!this.$props.loader){
        this.$emit("handleClose");
      }
    },
  },
};
</script>

<style scoped>
.delete-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.warningImage {
  width: 3.675rem !important;
  height: 3.194rem !important;
  object-fit: contain !important;
}

.alert {
  margin-bottom: 1rem;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  padding-bottom: 24px;
  padding-top: 1.5rem;
}
.crossicon {
  font-size: 2rem;
}
.icon-container {
  display: flex;
  justify-content: end;
  cursor: pointer;
}
.info-container{
  text-align: center;
  font-size: 14px;
  word-break: break-word;
}
.alert-icon{
  font-size: 4rem;
}
.btn{
  padding:8px 32px;
  height: 40px;
}

</style>
