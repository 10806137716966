
<div id="app">
  <leadsDrawer
    v-if="openDrawer"
    :drawer.sync="openDrawer"
    :lead="selectedLead"
    :activeTab="'first'"
    :focusNote="focusLeadDrawerNote"
    @close="[(openDrawer = false), (selectedLead = null)]"
  />
  <GlobalDrawer
    :title="'View Task'"
    :isOpen="isTaskDrawerOpen"
    :drawer-size="750"
    :handleCloseDialog="handleTaskDrawerClose"
    class="task-drawer-dialog"
  >
    <template v-if="isTaskDrawerOpen" v-slot:body>
      <taskDrawer
        v-if="isTaskDrawerOpen"
        :reminderData.sync="reminderDetails"
        @updateTask="updateTaskData"
        @deleteTask="onDeleteTask"
        :mode="'view'"
        :taskData="selectedLead"
        @allowDocumentUpload="handleAllowDocUpload"
        :documentUploaded="documentUploaded"
        @documentUploading="documentUploaded = false"
      />
    </template>
  </GlobalDrawer>
  <setReminder
    :key="setKey"
    :showCreateDrawer="showSetReminderDrawer"
    @close="showSetReminderDrawer = false"
    @save="onSave"
    :drawerSize="400"
    :page="'task'"
  />
  <div v-if="environmentError" class="environment-error-page">
    <div class="environment-error-message">
      {{ environmentErrorMessage }}
    </div>
    <div class="environment-error-code">
      Code: {{ environmentError }}
    </div>
  </div>
  <router-view
    v-else
  />
  <IntercomIcon v-show="isMobileView && intercomIconStatus == 'Hide'" />

  <BulkLeadUploadedPopup v-if="
    bulkLeadImportState.isBulkLeadUploadedPopupVisible"/>
    
  <!-- <div id="ps-loading-background" class="ps-loading-background"></div>
      <div id="ps-loading-container" class="ps-loading-container">
          <div id="ps-loader"></div>
      </div> -->
  <reviewerDialog v-if="isReviewDialogVisible" :isReviewDialogVisible="isReviewDialogVisible"/>
  <GlobalDrawer
        :isOpen="isDesignOrderDrawerOpen&&selectedLead"
        :title="''"
        :handleCloseDialog="handleCloseDesignOrderDrawer"
      >
        <template v-slot:body>
          
          <DesignOrdersDrawer
            :propsData="{
              order: selectedLead,
              users: selectedLead?.users||[],
              orderDetailsPopVisible: true,
              orderStatusOptions: [],
              drawer: isDesignOrderDrawerOpen,
            }"
            @save="drawer = false"
            :drawerSize="700"
            @close="drawer = false"
        
          />
        </template>
      </GlobalDrawer>
</div>
