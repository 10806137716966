import { DateTime } from "luxon";

export function calculateDuration(startTime, endTime) {

    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    const duration = endDate - startDate;

    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours} hours,  ${minutes} minutes`

}

export function formatDateTime(dateString, timeString) {
    const day = new Date(dateString);
    const offsetMinutes = day.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesPart = Math.abs(offsetMinutes) % 60;
    const offsetSign = offsetMinutes > 0 ? '-' : '+';
    const formattedOffset = `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutesPart).padStart(2, '0')}`;

    const options = {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
    }
    const newDays = day.toLocaleString('en-US', options)
    const [month, date, year] = newDays.split('/');
    const extraZero = month >= 10 ? "" : 0
    const formattedDateTime = `${year}-${extraZero}${month}-${date}T${timeString}:00${formattedOffset}`;
    return formattedDateTime;
}


export function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const options = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    };

    const formattedDate = date.toLocaleString('en-US', options).replace(',', '') + " at " + date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    return formattedDate;
}



export function convertToUTC(timeString) {
    const date = new Date(timeString);
    const options = {
        hour: 'numeric', minute: 'numeric', hour12: false
    }
    const formattedTime = date.toLocaleTimeString('en-US', options);
    let hours = String(formattedTime.split(":")[0]).padStart(2, '0');
    const minutes = String(formattedTime.split(":")[1]).padStart(2, '0');
    return `${hours}:${minutes}`;
}

export function extractEmail(str) {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str)) {
        return str;
    }
    const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/;
    const match = str.match(emailPattern);

    return match ? match[0] : null;
}


export function formatDateString(dateString) {
    const date = new Date(dateString)
    const optionsDate = { year: 'numeric', month: 'short', day: 'numeric' };
    const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedDate = date.toLocaleDateString('en-US', optionsDate);
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);
    const formattedString = `${formattedDate} at ${formattedTime}`;

    return formattedString;
}

export function getCurrentTime() {
    const date = new Date();
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    minutes = Math.ceil(minutes / 15) * 15;
    if (minutes === 60) {
        minutes = 0;
        hours = (parseInt(hours) + 1).toString().padStart(2, '0');
    }
    minutes = minutes.toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}


export function addMinutesToTime(timeStr, minutesToAdd) {
    let [hours, minutes] = timeStr.split(':').map(Number);

    minutes += minutesToAdd;
    let hoursToAdd = Math.floor(minutes / 60);

    minutes = minutes % 60;

    hours += hoursToAdd;

    hours = hours % 24;

    hours = hours.toString().padStart(2, '0');
    minutes = minutes.toString().padStart(2, '0');


    return `${hours}:${minutes}`;
}

export function checkDateStatus(inputDate) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const dateToCheck = new Date(inputDate);
    const normalizedDateToCheck = new Date(dateToCheck.getFullYear(), dateToCheck.getMonth(), dateToCheck.getDate());
    if (normalizedDateToCheck.toDateString() === today.toDateString()) {
        return false;
    } else if (normalizedDateToCheck > today) {
        return true
    } else {
        return false;
    }
}

function isValidTime(time) {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return regex.test(time);
}

export function compareTimes(time1, time2) {
    if (!isValidTime(time1) || !isValidTime(time2)) {
        return false;
    }
    const [hours1, minutes1] = time1.split(":").map(Number);
    const [hours2, minutes2] = time2.split(":").map(Number);
    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;
    return totalMinutes1 < totalMinutes2;
}

export function mergeAndRemove(arr1, arr2, emailToRemove) {
    const firstEmail = arr1.map(item => extractEmail(item)).filter(email => email !== null);
    const secondEmail = arr2.map(item => extractEmail(item)).filter(email => email !== null);
    const mergedArray = [...firstEmail, ...secondEmail];
    const filteredArray = mergedArray.filter(email => email !== emailToRemove);

    return filteredArray;
}

export function emailExtraExtractor(array) {
    const extractedArray = array.map(item => extractEmail(item)).filter(email => email !== null)
    return extractedArray
}

export function formatDateForOutlook(obj) {
    const { dateTime } = obj;
    const formattedDate = DateTime.fromISO(dateTime, { zone: 'UTC' })
    .setZone(DateTime.now().zoneName) // Convert to the local time zone
    .toFormat("MMM d yyyy 'at' h:mm a");
    return formattedDate;
}
export function convertToUTCForMicrosOft(timeString) {
    // Parse the input time string as UTC
    const utcTime = DateTime.fromISO(timeString, { zone: 'UTC' })
    .setZone(DateTime.now().zoneName);

    // Format the time in 24-hour format (HH:mm)
    return utcTime.toFormat('HH:mm');
}