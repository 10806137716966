<template>
  <div class="createLead">
    <slot name="header">
      <div class="title">
        <h4
          style="
            font-weight: bolder;
            padding: 1rem 0.5rem 20px;
            user-select: none;
          "
          @click="autofillValues"
        >
          Create Lead
        </h4>
        <span
          class="cross"
          @click="onClose()"
          style="
            font-weight: bolder;
            padding: 0.75rem 0.5rem 20px;
            cursor: pointer;
          "
          >&#x2716;</span
        >
      </div>
    </slot>
    <slot name="body">
      <div class="parentBody">
        <div class="parent">
          <div class="forLabel">
            <label for="" class="labelClass"
              >Full Name<span class="astrisk"> *</span></label
            >
            <el-input
              placeholder="Enter Name"
              v-model="userDetails.leadName"
              v-validate="firstNameValidation"
              name="firstName"
              @input="isLeadName($event)"
            ></el-input>
            <p class="errorMsg" v-show="errors.has('firstName')">
              This field is required.
            </p>
          </div>
          <div class="forLabel">
            <label for="" class="labelClass"
              >Select Owner<span class="astrisk"> *</span></label
            >
            <el-select
              placeholder="Select an Owner"
              v-model="userDetails.ownerName"
              name="ownerName"
              v-validate="ownerValidation"
            >
              <el-option
                v-for="owner in owners"
                :key="owner.id"
                :label="owner.first_name + ' ' + owner.last_name"
                :value="owner.id"
              >
                <span
                  v-if="checkdifferntOrg(owner)"
                  style="padding-right: 0.5rem"
                >
                  <img src="../../../assets/img/personfill.svg" alt="" />
                </span>
                <span v-else style="padding-right: 0.5rem">
                  <img src="../../../assets/img/buildings.svg" alt="" />
                </span>
                <span> {{ owner.first_name + " " + owner.last_name }} </span>
              </el-option>
              <ScrollObserver
                  :showLoader="isLoadingPaginationResults"
                  @intersect="loadMoreUsers"
                />
            </el-select>
            <p class="errorMsg" v-show="errors.has('ownerName')">
              This field is required.
            </p>
          </div>
          <div class="forLabel">
            <label for="" class="labelClass"
              >Email ID<span class="astrisk"> *</span></label
            >
            <el-input
              type="email"
              placeholder="Enter Email ID"
              v-model="userDetails.leadEmailId"
              v-validate="emailValidation"
              name="email id"
              @input="isEmail()"
            ></el-input>
            <p
              class="errorMsg"
              v-show="errors.has('email id') && isEmailTooLong"
            >
              <span>Email address is too long.</span>
            </p>
            <p class="errorMsg" v-show="errors.has('email id')">
              Please enter a valid email ID.
            </p>
            <p
              class="errorMsg"
              v-if="hasInteractedWithEmail && userDetails.leadEmailId == ''"
            >
              This field is required.
            </p>
          </div>
          <div class="forLabel">
            <label for="" class="labelClass">Phone number</label>
            <el-input
              onkeypress="return ((event.charCode > 47 && 
              event.charCode < 58) || (event.charCode === 40 || event.charCode === 41 || event.charCode === 43||event.charCode === 45))"
              placeholder="Enter phone number"
              v-model="userDetails.leadPhone"
              type="text"
              v-validate="phNoValidation"
              name="mobile number"
              @input="isPhNo()"
            >
            </el-input>
            <p
              class="errorMsg"
              v-show="errors.has('mobile number') || isPhoneNoTooLong"
            >
              <span v-show="isPhoneNoTooLong">Phone Number is too long.</span>
            </p>
            <p class="errorMsg" v-show="errors.has('mobile number')">
              This field is required.
            </p>
          </div>
          <div class="forLabel">
            <label for="" class="labelClass"
              >Property Type<span class="astrisk"> *</span></label
            >
            <el-select
              placeholder="Select a property type"
              v-model="userDetails.propertyType"
              name="propertyType"
              v-validate="propertyTypeValidation"
              @change="onChangePropertyType"
            >
              <el-option
                v-for="propertyType in propertyType"
                :key="propertyType.value"
                :label="propertyType.label"
                :value="propertyType.value"
              >
              </el-option>
            </el-select>
            <p class="errorMsg" v-show="errors.has('propertyType')">
              This field is required.
            </p>
          </div>
          <div class="forLabel">
            <label for="" class="labelClass">Lead Source</label>
            <el-select
              placeholder="Select a lead source type"
              v-model="userDetails.leadSource"
              name="leadSource"
              @change="onLeadSourceChange(userDetails.leadSource)"
            >
              <el-option
                v-for="stage in leadSource"
                :key="stage.id"
                :label="stage.name"
                :value="stage.id"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="forLabel"
            v-if="selectedLeadSourceObject.name == 'Referral'"
          >
            <label for="" class="labelClass">Referral Agent <span class="astrisk"> *</span></label>

            <div class="referral_agent">
              <el-select
                v-model="userDetails.referralAgent"
                filterable
                :filter-method="filterReferralMethod"
                @visible-change="getReferralAgentData"
                name="referral"
                placeholder="Select Existing Lead"
                v-validate="referralAgentValidation"
              >
                <el-option
                  v-for="item in agentsDetails"
                  :key="item?.lead_details?.id"
                  :label="item?.lead_details?.name"
                  :value="item?.lead_details?.id"
                >
                  <div style="display: flex; flex-direction: column">
                    <el-tooltip class="item" effect="dark" :content="item?.lead_details?.name" placement="top-start">
                    <span
                      style="
                        color: rgba(34, 34, 34, 1);
                        font-size: 16px;
                        font-weight: 400;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width:250px
                      "
                      >{{ item?.lead_details?.name }}</span
                    >
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" :content="item?.lead_details?.email" placement="top-start">
                    <span
                      style="
                        color: rgba(119, 119, 119, 1);
                        font-size: 12px;
                        font-weight: 400;
                        margin-top: -15px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width:200px
                      "
                      >{{ item?.lead_details?.email }}</span
                    >
                  </el-tooltip>
                  </div>
                </el-option>
                <ScrollObserver
                  :showLoader="isLoadingPaginationResults"
                  @intersect="loadMoreReferralAgents"
                />
              </el-select>
              <p class="errorMsg" v-show="errors.has('referral')">
              This field is required.
            </p>
            </div>
          </div>
          <div
            class="forLabel"
            v-if="selectedLeadSourceObject.name == 'Partner'"
          >
            <label for="" class="labelClass">
              Partner Agent <span class="astrisk"> *</span>
            </label>
            <el-select
              v-model="userDetails.partnersDetail"
              filterable
              placeholder="Search Existing Partners"
              v-validate="partnerValidation"
              name="partners"
              @change="selectedPartnersCostPerLead(userDetails.partnersDetail)"
            >
              <el-option
                v-for="item in filteredPartnersDetails"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              <div style="display:flex">
                <span
                      style="
                        color: rgba(34, 34, 34, 1);
                        font-size: 16px;
                        font-weight: 400;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width:250px
                      "
                      >{{ item.name }}
                      </span>
              </div>
   
              </el-option>
              <el-option
                v-if="partnersDetails.length === 0"
                label="Add Partners"
                disabled
                >Add Partners</el-option
              >
              <ScrollObserver
                :showLoader="isLoadingPaginationResults"
                @intersect="loadMorePartnersData"
              />
            </el-select>
            <p class="errorMsg" v-show="errors.has('partners')">
              This field is required.
            </p>
          </div>
          <div
            class="forLabel"
            v-if="selectedLeadSourceObject.name == 'Partner'"
          >
            <label for="" class="labelClass">Cost Per Lead</label>
            <el-input
              onkeypress="return ((event.charCode > 45 && 
              event.charCode < 58) || (event.charCode === 40 || event.charCode === 41 || event.charCode === 43||event.charCode === 45))"
              placeholder="Enter Cost Per Lead"
              v-model="userDetails.costPerLead"
              maxlength="5"
            >
              <template #prepend>
                {{ selectedCurrencyCode ? selectedCurrencyCode : "$" }}
              </template>
            </el-input>
          </div>
          <div
            class="forLabel"
            v-if="selectedLeadSourceObject.name == 'Partner'"
          >
            <label for="" class="labelClass">Paid to Partner</label>
            <el-radio-group v-model="userDetails.paidToPartners">
              <el-radio label="Yes">Yes</el-radio>
              <el-radio label="No">No</el-radio>
            </el-radio-group>
          </div>
          <div class="forLabel">
            <label for="" class="labelClass"
              >Pipeline<span class="astrisk"> *</span></label
            >
            <el-select
              placeholder="Select a Pipeline"
              @change="selectedPipeline"
              v-model="userDetails.pipeline"
              name="pipeline"
              v-validate="stageValidation"
              :disabled="isPipelineSelectionDisabled"
            >
              <el-option
                v-for="stage in pipeline"
                :key="stage.id"
                :label="stage.name"
                :value="stage.id"
              >
              </el-option>
            </el-select>
            <p class="errorMsg" v-show="errors.has('pipeline')">
              This field is required.
            </p>
          </div>
          <div class="forLabel">
            <label for="" class="labelClass"
              >Stage<span class="astrisk"> *</span></label
            >
            <el-select
              placeholder="Select a Stage"
              v-model="userDetails.stage"
              name="stage"
              v-validate="stageValidation"
              :disabled="disablestages"
            >
              <el-option
                v-for="stage in stages"
                :key="stage.id"
                :label="stage.name"
                :value="stage.id"
              >
                {{ stage.name }}
                <b-icon
                  style="margin-left: 10px"
                  :color="iconColor(stage)"
                  :icon="showIcon(stage)"
                ></b-icon>
              </el-option>
            </el-select>
            <p class="errorMsg" v-show="errors.has('stage')">
              This field is required.
            </p>
          </div>
          <div class="forLabel">
            <label for="" class="labelClass"
              >Task List<span class="astrisk"> *</span></label
            >
            <el-select
              placeholder="Select a Task list"
              v-model="userDetails.task_list"
              name="task list"
              v-validate="stageValidation"
            >
              <el-option
                v-for="task in taskListOption"
                :key="task.id"
                :label="task.name"
                :value="task.id"
              >
              {{ task.name }}
                <b-icon
                  style="margin-left: 10px"
                  :color="iconTaskColor(task)"
                  :icon="showTaskIcon(task)"
                ></b-icon>
              </el-option>
            </el-select>
            <p class="errorMsg" v-show="errors.has('task list')">
              This field is required.
            </p>
          </div>
          <div v-if="false" class="forLabel">  <!--  JIRA ticket - AT-10988 -->
            <label for="" class="labelClass">Region</label>
            <el-select
              placeholder="Select a Region"
              v-model="userDetails.region"
              name="region"
            >
              <el-option
                v-for="region in regionsOptions"
                :key="region.id"
                :label="region.name"
                :value="region.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="forLabel" v-if="false"> <!--  JIRA ticket - AT-10988 -->
            <label for="" class="labelClass">Probability</label>
            <el-input
              placeholder="Probability"
              type="number"
              v-model="userDetails.probability"
              name="probability"
              :min="0"
              :max="100"
              @input="validateInput"
            >
              <template v-slot:append><span class="suffex">%</span></template>
            </el-input>
          </div>
        </div>
        <div class="container">
          <div class="oneColumn">
            <div class="column forLabel">
              <label for="" class="labelClass">Address</label>
              <div class="createLeadSearchBarWrapper">
                <GoogleMapsAutocompleteInputVue
                  :isNew="true"
                  @input="showManualInputError = false"
                  @placesChanged="
                    isMapDisabled = false;
                    showManualInputError = false;
                  "
                  :placeholder="'Enter the property address'"
                />
                <p class="errorMsg" v-show="showManualInputError">
                  Please select an address from the dropdown.
                </p>
              </div>
            </div>
            <div class="column forLabel">
              <label for="" class="labelClass">Target Close Date</label>
              <DatePicker
                :label="false"
                @dateChanged="onDateChange"
                :disabledPrev="true"
              />
            </div>
            <div class="column forLabel dealValueDiv">
              <label for="" class="labelClass">Deal Value</label>
              <p class="currencyIcon">{{ currencySymbol }}</p>
              <el-input
                placeholder="Enter potential deal value"
                class="inputs-with-units"
                v-model="userDetails.dealValue"
                name="dealValue"
                type="number"
              >
              </el-input>
            </div>
            <!-- <div class="column forLabel" style="width: 173%">
              <label for="" class="labelClass">Tags</label>
              <Tags />
            </div> -->
          </div>
          <div class="big-div forLabel">
            <div class="map">
              <div class="map-container">
                <div
                  class="map-disabled-overlay"
                  v-if="isMapDisabled"
                  style="display: flex; flex-direction: column"
                >
                  <img src="./assests/location_logo.svg" />
                  <p style="padding-top: 26px" class="map-disabled-text">
                    Enter an address to view the map
                  </p>
                </div>
                <!-- <p class="formHeadings"> Mark Location </p> -->
                <newProjectMapSelector
                  :geo-location="geoLocation"
                  :place="placeForMap"
                  @change="showManualInputError = false"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="column forLabel" style="width: 100%">
          <label for="" class="labelClass">Tags</label>
          <!-- <el-select multiple filterable allow-create placeholder="Add tags to help your group leads (optional)" v-model="userDetails.tags"></el-select> -->
          <Tags
            :isCreateLeadMode="true"
            :isUsingStore="false"
            @fetchTagsToSendForLead="fetchTagsToSendForLead"
          />
        </div>
      </div>
    </slot>
    <slot name="footer">
      <div class="footerContainer">
        <el-button
          type="primary"
          class="leadBtn"
          @click="confirmSubmit()"
          :loading="loadingStateButton"
          :disabled="errors.items.length > 0 || isdisabled"
          >Create</el-button
        >
      </div>
    </slot>
    <DealValue
      v-if="dealCheck"
      :isDeleteProjectDocumentPopupOpen="true"
      :title="`Deal Value Check`"
      :info="`Deal value for this lead is ${currencySymbol}0.`"
      :info2="` Do you want to continue without updating it?`"
      @cancelDelete="cancelDelete"
      @confirmDelete="confirmDelete"
    />
  </div>
</template>

<script>
import API from "../../../services/api";
import DealValue from "../../leadManagement/components/dealValueCheckDialog.vue";
import { mapState, mapActions } from "pinia";
import { useMiscStore } from "../../../stores/misc";
import { useReviewStore } from "../../../stores/review"
import Tags from "../../leadManagement/components/tags.vue";
import newProjectMapSelector from "./../../../components/ui/newProject/newProjectMapSelector.vue";
import GoogleMapsAutocompleteInputVue from "./../../../components/googleMaps/GoogleMapsAutocompleteInput.vue";
import DatePicker from "../../setReminder/components/datePicker.vue";
import ScrollObserver from "../../../components/ui/ScrollObserver.vue";
import {
  getUiFromStorage,
  setUiInStorage,
  isPatriotOrg,
  getDefaultCoordinates,
} from "../../../utils";

import currencySymbolNameMap from "@/pages/currency-symbol-name-map";
import countryToCurrency from "country-to-currency";
import { faker } from "@faker-js/faker";
import { useUiStore } from "../../../stores/ui";
import debounce from "debounce";
import { useLeadStore } from '../../../stores/lead';

export default {
  emits: ["save", "close", "handleShowWarning"],
  data() {
    return {
      isUserInResponse: false,
      nextUsersUrl: null,
      isReliance: JSON.parse(localStorage.getItem("organisation")) || {},
      isLoadingPaginationResults: false,
      isdisabled: false,
      dealCheck: false,
      disablestages: true,
      geoLocation: {
        center: {
          lat: getDefaultCoordinates().lat,
          lng: getDefaultCoordinates().lng,
        },
        zoom: 18,
      },
      firstNameValidation: {
        required: true,
      },
      emailValidation: {
        required: true,
        email: true,
      },
      phNoValidation: {
        required: false,
      },
      ownerValidation: {
        required: true,
      },
      propertyTypeValidation: {
        required: true,
      },
      stageValidation: {
        required: true,
      },
      partnerValidation: {
        required: true,
      },
      referralAgentValidation: {
        required: true,
      },
      userDetails: {
        leadName: "",
        ownerName: JSON.parse(localStorage.getItem("user")).user_id || "",
        leadEmailId: "",
        leadPhone: null,
        propertyType: "residential",
        stage: "",
        leadSource: "",
        referralAgent: "",
        targetCloseDate: "",
        dealValue: "",
        tags: [],
        region: null,
        task_list:null,
        probability: null,
        address: "",
        longitude: null,
        latitude: null,
        pipeline: null,
        quotaType: null,
        isPublicSharingEnabled: false,
        partnersDetail: null,
        costPerLead: null,
        paidToPartners: "No",
      },
      partnersDetails: [],
      agentsDetails: [],
      partnersNextUrl: null,
      referralAgentsNextUrl: null,
      selectedCurrencyCode: null,
      hasInvalidName: false,
      placeForMap: {},
      propertyType: [
        {
          label: "Residential",
          value: "residential",
        },
        {
          label: "Commercial",
          value: "commercial",
        },
      ],
      owners: [],
      pipeline: [],
      stages: [],
      leadSource: [],
      regionsOptions: [],
      taskListOption:[],
      loadingStateButton: false,
      isMapDisabled: true,
      currencyCode: null,
      showManualInputError: false,
      selectedLeadSourceObject: {},
      hasInteractedWithEmail: false,
    };
  },
  props: {
    showWarningPopUp: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    newProjectMapSelector,
    GoogleMapsAutocompleteInputVue,
    DatePicker,
    DealValue,
    Tags,
    ScrollObserver,
  },

  computed: {
    ...mapState(useUiStore, {
      isMobileView: (state) => state.isMobileView,
    }),
    filteredPartnersDetails() {
      return this.partnersDetails.length > 0 ? this.partnersDetails : [];
    },
    currentUserObj() {
      return JSON.parse(localStorage.getItem("user"));
    },
    isEmailTooLong() {
      if (this.userDetails.leadEmailId.length > 50) {
        this.isdisabled = true;
        return true;
      }
      this.isdisabled = false;
      return false;
    },
    isPhoneNoTooLong() {
      const phoneNumber = this.userDetails.leadPhone;
      if (phoneNumber && phoneNumber.length > 20) {
        this.isdisabled = true;
        return true;
      }
      this.isdisabled = false;
      return false;
    },
    ...mapState(useMiscStore, {
      googleMapsState: "GET_GOOGLE_MAPS_STATE",
    }),
    currencySymbol() {
      return currencySymbolNameMap[this.currencyCode];
    },
    isPipelineSelectionDisabled() {
      let pipelineList = this.pipeline
      let residentialPipeline = pipelineList.find(pipeline => pipeline.name.toLowerCase().includes("residential"))
      let commercialPipeline = pipelineList.find(pipeline => pipeline.name.toLowerCase().includes("commercial"))
      
      // Disable the pipeline selection if all 3 conditions are met for Patriot Org:
      if (
        isPatriotOrg() &&
        pipelineList.length == 2 &&
        (residentialPipeline && commercialPipeline)
      ) {
        return true
      }
      return false
    },
  },
  async mounted() {
    this.getPipelines();
    this.getTaskList();
    this.getLeadSource();
    this.getRegions();
    this.getPartnersData();
    this.getReferralAgentData();
  },
  methods: {
    ...mapActions(useReviewStore, ["SET_REVIEW_STATUS"]),
    ...mapActions(useMiscStore, [
      "INITIALIZE_COUNTRY_CODE",
      "resetGoogleMapsState",
    ]),
    async getPartnersData() {
      try {
        const { data } = await API.REFERRAL_MANAGEMENT.GET_PARTNERS();
        this.partnersDetails = data.results;
        this.partnersNextUrl = data.next;
      } catch (err) {
        this.$message({
          showClose: true,
          message: "There was an unknown error while fetching the list of partners.",
          type: "error",
          center: true,
        });
      }
    },
    async loadMorePartnersData() {
      if (!this.partnersNextUrl) { return }
      
      this.isLoadingPaginationResults = true
      try {
        const { data } = await API.REFERRAL_MANAGEMENT.GET_MORE_PARTNERS_DATA(
          this.partnersNextUrl
        );
        this.partnersNextUrl = data.next;
        this.partnersDetails = [...this.partnersDetails, ...data.results];
      } catch (err) {
        console.error(err)
        this.$message({
          showClose: true,
          message: "There was an unknown error while fetching the Partners.",
          type: "error",
          center: true,
        });
      }
      this.isLoadingPaginationResults = false
    },
    async getReferralAgentData() {
      try {
        const { data } = await API.LEADS.FETCH_LEAD_LIST();
        this.agentsDetails = data.results;
        this.referralAgentsNextUrl = data.next;
      } catch (err) {
        this.$message({
          showClose: true,
          message: "There was an unknown error while fetching the Agents.",
          type: "error",
          center: true,
        });
      }
    },
    async loadMoreReferralAgents() {
      if (!this.referralAgentsNextUrl) { return; }

      this.isLoadingPaginationResults = true
      try {
        const { data } = await API.LEADS.LOAD_MORE_Leads(
          this.referralAgentsNextUrl
        );

        this.referralAgentsNextUrl = data.next;
        this.agentsDetails = [...this.agentsDetails, ...data.results];
      } catch (err) {
        console.error(err)
        this.$message({
          showClose: true,
          message: "There was an unknown error while fetching the Agents.",
          type: "error",
          center: true,
        });
      }
      this.isLoadingPaginationResults = false
    },

    selectedPartnersCostPerLead(val) {
      const selectedPartner = this.partnersDetails.find((item) => item.id == val);
      this.userDetails.costPerLead = selectedPartner.cost_per_lead;
      this.selectedCurrencyCode = currencySymbolNameMap[selectedPartner.currency_code];
    },
    loadMoreUsers() {
      if (!this.nextUsersUrl) return;
      debounce(this.getNextUsers(), 500);
    },
    async getNextUsers() {
      let res = await API.USERS.LOAD_MORE_USERS(this.nextUsersUrl);
      this.nextUsersUrl = res.data.next || null;
      if (
        this.owners[this.owners.length - 1].id ==
        res.data.results[res.data.results.length - 1].id
      )
        return;
      this.owners = [...this.owners, ...res.data.results];
    },
    checkUserExistsOnResponse(users) {
      users.find((e) => e.id == this.owners[0].id) && this.owners.shift();
    },
    showIcon(id) {
      if (id.stage_type == "close") {
        if (id.close_stage_type == "won") {
          return "hand-thumbs-up";
        } else if (id.close_stage_type == "lost") {
          return "hand-thumbs-down";
        } else {
          return "dash-square";
        }
      }
    },
    iconColor(id) {
      if (id.stage_type == "close") {
        if (id.close_stage_type == "won") {
          return "green";
        } else if (id.close_stage_type == "lost") {
          return "red";
        } else {
          return "grey";
        }
      }
    },
    showTaskIcon(task){
      if(task.is_starred){
        return 'star-fill'
      }
    },
    iconTaskColor(task){
      if(task.is_starred){
        return 'grey'
      }
    },
    cancelDelete() {
      this.isDeleteProjectDocumentPopupOpen = false;
      this.dealCheck = false;
    },
    confirmDelete() {
      this.isDeleteProjectDocumentPopupOpen = false;
      this.dealCheck = false;
      this.submitDetails();
    },
    validateInput() {
      this.userDetails.probability = this.userDetails.probability.replace(
        /^0|[^0-9]/g,
        ""
      );
      this.$validator.validate("probability", this.userDetails.probability);
      const input = this.userDetails.probability;
      if (input < 0) {
        this.userDetails.probability = 0;
      } else if (input > 100) {
        this.userDetails.probability = 100;
      }
    },
    async getLeadSource() {
      let response = await API.CRMSETTINGS.FETCH_ALL_LEAD_SOURCE();
      this.leadSource = response.data;
    },
    async getRegions() {
      let response = await API.CRMSETTINGS.FETCH_ALL_REGIONS();
      this.regionsOptions = response.data;
    },
    selectedPipeline(val) {
      this.userDetails.stage = "";
      let Stages = this.pipeline.filter((pipeline) => pipeline.id == val);
      this.stages = Stages[0].pipeline_stages;
      this.disablestages = false;
    },

    filterReferralMethod: debounce(async function (value) {
     const {data} = await API.LEADS.SEARCH_ALL_LEADS(value)
     this.agentsDetails = data.results
     this.referralAgentsNextUrl = data.next;
    },1000),
    async getPipelines() {
      // Fetch the list of pipelines
      let response = await API.LEADS.GET_PIPELINES();
      let sortData = response.data.pipelines.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
      this.pipeline = sortData;

      // Set the active pipeline
      if (isPatriotOrg()) {
        // Different logic for Patriot Org
        this.setPipelineForPatriotOrg()
      } else {
        this.userDetails.pipeline = this.propsData.activePipelineId;
        this.selectedPipeline(this.userDetails.pipeline);
      }
    },
    async getTaskList() {
      let response = await API.CUSTOM_TASK_LIST.GET_ALL_TASKLIST();
      let sortData = response.data.sort((a, b) => {
          if (a.is_starred && !b.is_starred) {
              return -1; // a comes before b
          } else if (!a.is_starred && b.is_starred) {
              return 1; // b comes before a
          } else {
              return new Date(a.created_at) - new Date(b.created_at);
          }
      });
      this.taskListOption = sortData;
      this.userDetails.task_list=sortData[0].id
    },
    fetchTagsToSendForLead(finalTagsToSend) {
      let tags = finalTagsToSend.map((item) => item.id);
      this.userDetails.tags = [...tags];
    },
    isLeadName(value) {
      if (value.length > 100) {
        this.hasInvalidName = true;
      } else {
        this.hasInvalidName = false;
      }
      this.$validator.validate("firstName", this.userDetails.leadName);
    },
    isEmail() {
      // this.hasInteractedWithEmail = true;
      this.$validator.validate("email id", this.userDetails.leadEmailId);
    },
    isPhNo() {
      this.$validator.validate("mobile number", this.userDetails.leadPhone);
    },
    getDataFromLS() {
      let dataFromLS = JSON.parse(localStorage.getItem("organisation"));
      let pipelineFromLS = dataFromLS.pipelines[0].id;
      let isSharingEnabledFromLS = dataFromLS.is_public_sharing_enable;
      // this.userDetails.pipeline = pipelineFromLS;
      this.currencyCode = JSON.parse(
        localStorage.getItem("organisation")
      ).currency_code;
      // this.userDetails.isPublicSharingEnabled = isSharingEnabledFromLS;
    },
    setPlace(place) {
      this.placeForMap = place;
    },
    onSave() {
      this.$emit("save", false);
    },
    onClose() {
      this.$emit("close", false);
    },
    onDateChange(val) {
      if (val) {
        let dateStr = val;
        let date = new Date(dateStr);
        let day = String(date.getDate()).padStart(2, "0");
        let month = String(date.getMonth() + 1).padStart(2, "0");
        let year = String(date.getFullYear()).slice(0);
        const formattedDate = `${year}-${month}-${day}`;
        this.userDetails.targetCloseDate = formattedDate;
      } else {
        this.userDetails.targetCloseDate = val;
      }
    },
    async fetchAllUsers() {
      let response;
      try {
        response = await API.USERS.FETCH_ALL_USERS();
        if (
          response.data.results.find((e) => e.id == this.currentUserObj.user_id)
        ) {
          this.isUserInResponse = true;
        } else {
          let owner = {
            email: this.currentUserObj.email,
            first_name: this.currentUserObj.first_name,
            last_name: this.currentUserObj.last_name,
            id: this.currentUserObj.user_id,
            organisation_id:this.currentUserObj.organisation_id,
          };
          this.owners.push(owner);
        }
        for (let i = 0; i < response.data.results.length; i++) {
          this.owners.push(response.data.results[i]);
        }
        this.nextUsersUrl = response.data.next || null;
      } catch (e) {
        this.$message({
          showClose: true,
          message:
            "There was an error while fetching the list of users from your organization.",
          type: "error",
          center: true,
        });
      }
    },
    async postSharingPermissions(projectID) {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      let isChangePermissionAllowed = true;
      // let isChangePermissionAllowed = ["DESIGNER", "ADMIN"].includes(user.role); // commneted for now for future references
      let payload = {
        new_users: [
          {
            email: user.email,
            permission: isChangePermissionAllowed ? "change" : "view",
          },
        ],
        lead_owner: [
          {
            user_id: this.userDetails.ownerName,
          },
        ],
      };

      try {
        const response = await API.PROJECTS.POST_PROJECT_PERMISSIONS(
          projectID,
          payload
        );
      } catch (e) {
      }
    },
    async confirmSubmit() {
      let isFormValid = false;
      let stageType = this.stages.find(
        (stage) => stage.id == this.userDetails.stage
      );
      await this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$validator.errors.all();
        } else {
          isFormValid = true;
        }
      });
      if (this.googleMapsState.isAddressManuallyEntered) {
        this.showManualInputError = true;
      }
      if (!isFormValid || this.showManualInputError) {
        return;
      }

      if (
        (this.userDetails.dealValue == "" &&
          stageType.close_stage_type == "won") ||
        (this.userDetails.dealValue == 0 && stageType.close_stage_type == "won")
      ) {
        this.dealCheck = true;
      } else {
        this.submitDetails();
      }
    },
    async submitDetails() {
      let isFormValid = false;
      await this.$validator.validateAll().then((result) => {
        if (!result) {
          // validation failed, display error messages
          const errors = this.$validator.errors.all();
          // do something with the errors, such as displaying them in a div on the page
        } else {
          isFormValid = true;
          // validation passed, submit the form normally
        }
      });

      if (this.hasInvalidName) {
        this.$message({
          showClose: true,
          message: "Full Name length can't be more than 100.",
          type: "error",
          center: true,
        });
        return;
      }

      if (isFormValid) {
        this.loadingStateButton = true;
        let response;
        try {
          const postData = {
            name: this.userDetails.leadName,
            email: this.userDetails.leadEmailId,
            address: this.googleMapsState.address,
            stage: "",
            stage_object: this.userDetails.stage,
            owner: this.userDetails.ownerName,
            probability: this.userDetails.probability || null,
            region: this.userDetails.region,
            task_list:this.userDetails.task_list,
            lead_source_object: this.userDetails.leadSource,
            referral_agent_obj: this.userDetails.referralAgent,
            target_closure_date: this.userDetails.targetCloseDate || undefined,
            deal_value: this.userDetails.dealValue || undefined,
            pipeline: this.userDetails.pipeline,
            tags: this.userDetails.tags,
            project_type: this.userDetails.propertyType,
            quota_type: this.userDetails.quotaType,
            is_public_sharing_enabled: this.userDetails.isPublicSharingEnabled,
            partner_agent: this.userDetails.partnersDetail,
            cost_per_lead:
              parseFloat(this.userDetails.costPerLead) != null
                ? parseFloat(this.userDetails.costPerLead)
                : 0,
            paid_to_partner:
              this.userDetails.paidToPartners == "Yes" ? true : false,
            country_code: this.googleMapsState.countryCode
          };
          if (this.userDetails.leadPhone)
            postData.phone = this.userDetails.leadPhone;
          if (this.googleMapsState.address) {
            postData.latitude = this.geoLocation.center.lat
              ? this.geoLocation.center.lat
              : null;
            postData.longitude = this.geoLocation.center.lng
              ? this.geoLocation.center.lng
              : null;
            postData.zoom =
              parseInt(this.geoLocation.zoom) >= 21
                ? parseInt(this.geoLocation.zoom)
                : parseInt(this.geoLocation.zoom) + 1;
            postData.pincode = this.googleMapsState?.postalCode?.[0]?.long_name
              ? this.googleMapsState?.postalCode?.[0]?.long_name
              : null;
            postData.state = this.googleMapsState.state;
            postData.county = this.googleMapsState?.county?.[0]?.long_name
              ? this.googleMapsState?.county?.[0]?.long_name
              : null;
            postData.city = this.googleMapsState?.city?.[0]?.long_name
              ? this.googleMapsState?.city?.[0]?.long_name
              : null;
          }
          response = await API.LEADS.POST_USER_INFO(postData);
          let createdProjectId = response.data.project_details.id;
          let createdLeadId = response.data.id;
          if (!this.userDetails.isPublicSharingEnabled) {
            await this.postSharingPermissions(createdProjectId);
          }
          let ui = getUiFromStorage();
          if (this.isMobileView) {
            ui.leadSummary.activeLeadSummaryTab = "leadInfo";
          } else {
            ui.leadSummary.activeLeadSummaryTab = "timeline";
          }
          setUiInStorage(ui);
          useUiStore().isCreatingNewLead = true
          this.$router.push({
            name: "leadSummary",
            params: { leadId: createdLeadId },
          });
          this.loadingStateButton = false;
          // review dialog
          this.SET_REVIEW_STATUS(true, false, 'leads')
        } catch (e) {
          this.$message({
            showClose: true,
            message: "There was an error while creating a new lead.",
            type: "error",
            center: true,
          });
          this.loadingStateButton = false;
        }
      }
    },
    autofillValues(e) {
      // Only if "Create Lead" is triple clicked, do autofill
      // Thanks to: https://stackoverflow.com/a/6480113
      if (e.detail % 3 != 0) {
        return;
      }

      this.userDetails.leadName = "[TEST] " + faker.person.fullName();
      this.userDetails.leadEmailId = faker.internet.email();

      // The fields inside this fake if condition
      // are either already prefilled or not mandatory anymore.
      if (false) {
        this.userDetails.ownerName = faker.helpers.arrayElement(this.owners).id;
        this.userDetails.leadPhone = faker.phone.number("##########");
        this.userDetails.propertyType = faker.helpers.arrayElement(
          this.propertyType
        ).value;
        this.userDetails.leadSource = faker.helpers.arrayElement(
          this.leadSource
        ).value;
      }
      if (!this.userDetails.pipeline) {
        this.userDetails.pipeline = faker.helpers.arrayElement(
          this.pipeline
        ).id;
        this.selectedPipeline(this.userDetails.pipeline);
      }
      this.userDetails.stage = faker.helpers.arrayElement(this.stages).id;
    },
    checkdifferntOrg(user) {
      const userInfo = JSON.parse(localStorage.getItem("user")) || {};
      return user.organisation_id === userInfo.organisation_id ? true : false;
    },
    onLeadSourceChange(leadSourceId) {
      this.selectedLeadSourceObject = this.leadSource.find(
        (source) => source.id == leadSourceId
      );
    },
    setPipelineForPatriotOrg() {
      let pipelineList = this.pipeline
      let residentialPipeline = pipelineList.find(pipeline => pipeline.name.toLowerCase().includes("residential"))
      let commercialPipeline = pipelineList.find(pipeline => pipeline.name.toLowerCase().includes("commercial"))
      if (this.userDetails.propertyType == "residential" && residentialPipeline) {
        this.userDetails.pipeline = residentialPipeline.id;
      } else if (this.userDetails.propertyType == "commercial" && commercialPipeline) {
        this.userDetails.pipeline = commercialPipeline.id;
      }
      this.selectedPipeline(this.userDetails.pipeline);
    },
    onChangePropertyType() {
      if (isPatriotOrg()) {
        this.setPipelineForPatriotOrg()
      }
    },
  },
  created() {
    this.resetGoogleMapsState();
    this.INITIALIZE_COUNTRY_CODE(
      JSON.parse(localStorage.getItem("organisation"))?.country_code
    );
    this.fetchAllUsers();
    this.getDataFromLS();
    this.userDetails.stage = this.$props.drawerStage
      ? this.$props.drawerStage
      : "";
  },
  props: {
    drawerStage: {
      type: String,
    },
    propsData: {
      type: Object,
    },
  },

  watch: {
    drawerStage(val) {
      this.userDetails.stage = this.$props.drawerStage
        ? this.$props.drawerStage
        : "";
    },
    googleMapsState: {
      deep: true,
      handler(val) {
        this.currencyCode = countryToCurrency[val?.countryCode];
      },
    },
    userDetails: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.leadName || val.leadEmailId || val.stage) {
          this.$emit("handleShowWarning", true);
        } else {
          this.$emit("handleShowWarning", false);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.errorMsg {
  color: rgb(214, 12, 12);
  font-size: 14px;
  margin-top: 4px;
}

.astrisk {
  font-size: 12px;
  font-weight: 600;
  color: #f00;
}

.wholeBody {
  margin-left: 1rem;
  margin-right: 1rem;
  min-width: 300px;
}

.title {
  margin-left: 1rem;
  margin-right: 1rem;
  min-width: 300px;
  display: flex;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.parentBody {
  padding: 24px 24px 88px 24px;
}

.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 20px;
  padding-bottom: 24px;
  border-bottom: 1px solid #ccc;
}

.forLabel {
position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.forLabel ::v-deep .el-radio-group {
  margin-top: 5%;
}

.forLabel ::v-deep .el-input-group__prepend {
  background-color: #e8edf2;
  border: none;
  border-radius: 4px, 0px;
}

.forLabel ::v-deep .el-radio__label {
  font-size: 16px;
  font-weight: 600;
}

.el-select-dropdown__item {
  height: auto;
}

.forLabel ::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: rgba(28, 51, 102, 1);
}

.forLabel ::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: rgba(28, 51, 102, 1);
  background: rgba(28, 51, 102, 1);
}

.map {
  background-color: lightblue;
  height: 100%;
}

.labelClass {
  font-size: 14px;
  color: #777;
  font-weight: 600;
}

.inputs-with-units::v-deep .el-input-group__append {
  padding: 0 1em;
  background-color: #e8edf2;
  border: none;
  color: #777;
}


.map-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.map-disabled-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #e8edf2;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.map-disabled-text {
  width: 60%;
}

.oneColumn {
  flex-basis: 60%;
}

.container {
  display: flex;
  padding: 16px 0px 0px 0px;
  gap: 24px;
}

/* Style for the column divs */
.column {
  flex-basis: 25%;
  /* Adjust the width as needed */
  padding: 10px 0px;
  box-sizing: border-box;
}

/* Style for the big div */
.big-div {
  flex-basis: 40%;
  /* Adjust the width as needed */
  padding: 10px 0px;
  box-sizing: border-box;
}

.footerContainer {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  padding: 24px;
  background-color: #fff;
  width: 100%;
  z-index: 10;
}
.createLead ::v-deep .el-input-group__append,
.el-input-group__prepend {
  background-color: #e8edf2;
  color: #909399;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 1px solid transparent;
  padding: 0 5px;
  width: 1px;
  white-space: nowrap;
}

.currencyIcon {
  position: absolute;
  bottom: 24px;
  left: 14px;
  color: #777;
  z-index: 1;
}

.leadBtn {
  font-size: 16px;
  font-weight: 600;
  width: 100px;
  height: 40px;
  padding: 0px;
  border-radius: 8px;
}
</style>

<style scoped>
.createLead >>> .el-input__inner {
  height: 48px !important;
  background-color: #e8edf2;
  border: none;
  font-size: 16px;
  color: #222;
}

.createLead >>> .el-input__inner::placeholder {
  color: #777;
}

.createLead >>> .el-select {
  height: 45px !important;
  background-color: #e8edf2;
  width: 100% !important;
  max-width: none !important;
  border-radius: 4px;
}

.createLead >>> .el-select .el-input .el-select__caret {
  color: #222;
  font-size: 16px;
  font-weight: 600;
}

.datePicker {
  padding: 0px;
}

.dealValueDiv >>> .el-input__inner {
  padding-left: 30px;
}

</style>
